@font-face {
  font-family: roboto;
  font-style: normal;
  font-weight: 300;
  src: local(Roboto Light), local(Roboto-Light), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-300.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-300.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: roboto;
  font-style: normal;
  font-weight: 400;
  src: local(Roboto), local(Roboto-Regular), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: roboto;
  font-style: normal;
  font-weight: 700;
  src: local(Roboto Bold), local(Roboto-Bold), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 400;
  src: local(Roboto Slab Regular), local(RobotoSlab-Regular), url("//img2.cncenter.cz/fonts/roboto-slab/roboto-slab-v6-latin-ext_latin-regular.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/roboto-slab/roboto-slab-v6-latin-ext_latin-regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 700;
  src: local(Roboto Slab Bold), local(RobotoSlab-Bold), url("//img2.cncenter.cz/fonts/roboto-slab/roboto-slab-v6-latin-ext_latin-700.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/roboto-slab/roboto-slab-v6-latin-ext_latin-700.woff") format("woff");
  font-display: swap;
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  clear: both;
  border: 0;
  border-top: 1px solid #ccc;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  display: block;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button {
  -webkit-appearance: button;
  appearance: button;
  cursor: pointer;
}

html input[type="button"] {
  -webkit-appearance: button;
  appearance: button;
  cursor: pointer;
}

input[type="reset"] {
  -webkit-appearance: button;
  appearance: button;
  cursor: pointer;
}

input[type="submit"] {
  -webkit-appearance: button;
  appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

html {
  width: 100%;
  height: 100%;
}

body {
  color: #1e1e1e;
  overflow-anchor: none;
  background: #f0f0f0;
  min-height: 100%;
  font-family: Roboto, arial, sans-serif;
  font-size: 16px;
}

.bg-color-dark {
  background-color: #1e1e1e;
}

.bg-color-ligth {
  background-color: #fff;
}

* {
  box-sizing: border-box;
}

::selection {
  color: #f0f0f0;
  text-shadow: none;
  background: #ea6c6c;
}

a {
  color: #1e1e1e;
  text-decoration: none;
  transition: color .4s ease-in-out;
}

a:hover {
  color: #d21e1e;
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

p a {
  color: #d21e1e;
}

img, input {
  max-width: 100%;
}

.smart-underline {
  text-shadow: 0 -2px #fff, 0 -1px #fff, 0 0 #fff, 2px -2px #fff, 2px -1px #fff, 2px 0 #fff, -2px -2px #fff, -2px -1px #fff, -2px 0 #fff, 1px -2px #fff, 1px -1px #fff, 1px 0 #fff, -1px -2px #fff, -1px -1px #fff, -1px 0 #fff, 0 -2px #fff, 0 -1px #fff, 0 0 #fff;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -1px #fff, inset 0 -2px rgba(0, 0, 0, 0);
}

.smart-underline::selection {
  color: #fff;
  text-shadow: none;
  background: #ea6c6c;
}

.smart-underline:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -1px #fff, inset 0 -2px #d21e1e;
}

.smart-underline-header {
  text-shadow: 0 -2px #fff, 0 -1px #fff, 0 0 #fff, 2px -2px #fff, 2px -1px #fff, 2px 0 #fff, -2px -2px #fff, -2px -1px #fff, -2px 0 #fff, 1px -2px #fff, 1px -1px #fff, 1px 0 #fff, -1px -2px #fff, -1px -1px #fff, -1px 0 #fff, 0 -2px #fff, 0 -1px #fff, 0 0 #fff;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -3px #fff, inset 0 -5px rgba(0, 0, 0, 0);
}

.smart-underline-header::selection {
  color: #fff;
  text-shadow: none;
  background: #ea6c6c;
}

.smart-underline-header:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -3px #fff, inset 0 -5px #d21e1e;
}

.footer {
  width: 1080px;
  margin: 0 auto;
}

body.supperlife-body p a {
  color: #02a555;
}

@media screen and (max-width: 760px) {
  .smart-underline, .smart-underline-header {
    text-shadow: none;
    box-shadow: none;
  }

  .smart-underline:hover, .smart-underline-header:hover {
    box-shadow: none;
  }
}

.sport-color, a.sport-color {
  color: #d21e1e;
}

.sport-color-bg {
  background-color: #d21e1e;
}

.sport-color-border {
  border-color: #d21e1e;
}

.sport-color-hover:hover, a.sport-color-hover:hover {
  color: #d21e1e;
}

.sport-color-hover-bg-darken:hover, a.sport-color-hover-bg-darken:hover {
  background-color: #a51818;
}

.sport-color-hover-bg-lighten:hover, a.sport-color-hover-bg-lighten:hover {
  background-color: #e43f3f;
}

.sport-color-svg path {
  fill: #d21e1e;
}

.football .sport-color, .football a.sport-color {
  color: #00a442;
}

.football .sport-color-bg {
  background-color: #00a442;
}

.football .sport-color-bg-darken {
  background-color: #00712d;
}

.football .sport-color-border {
  border-color: #00a442;
}

.football .sport-color-hover:hover, .football a.sport-color-hover:hover {
  color: #00a442;
}

.football .sport-color-hover-bg-darken:hover, .football a.sport-color-hover-bg-darken:hover {
  background-color: #00712d;
}

.football .sport-color-hover-bg-lighten:hover, .football a.sport-color-hover-bg-lighten:hover {
  background-color: #00d757;
}

.football .sport-color-svg path {
  fill: #00a442;
}

.football .active .sport-color-active {
  color: #00a442;
}

.football-euro .sport-color, .football-euro a.sport-color {
  color: #02849a;
}

.football-euro .sport-color-svg path {
  fill: #02849a;
}

.hockey .sport-color, .hockey a.sport-color {
  color: #325edc;
}

.hockey .sport-color-bg {
  background-color: #325edc;
}

.hockey .sport-color-bg-darken {
  background-color: #2048bb;
}

.hockey .sport-color-border {
  border-color: #325edc;
}

.hockey .sport-color-hover:hover, .hockey a.sport-color-hover:hover {
  color: #325edc;
}

.hockey .sport-color-hover-bg-darken:hover, .hockey a.sport-color-hover-bg-darken:hover {
  background-color: #2048bb;
}

.hockey .sport-color-hover-bg-lighten:hover, .hockey a.sport-color-hover-bg-lighten:hover {
  background-color: #5e80e3;
}

.hockey .sport-color-svg path {
  fill: #325edc;
}

.hockey .active .sport-color-active {
  color: #325edc;
}

.tennis .sport-color, .tennis a.sport-color {
  color: #ff6000;
}

.tennis .sport-color-bg {
  background-color: #ff6000;
}

.tennis .sport-color-bg-darken {
  background-color: #cc4d00;
}

.tennis .sport-color-border {
  border-color: #ff6000;
}

.tennis .sport-color-hover:hover, .tennis a.sport-color-hover:hover {
  color: #ff6000;
}

.tennis .sport-color-hover-bg-darken:hover, .tennis a.sport-color-hover-bg-darken:hover {
  background-color: #cc4d00;
}

.tennis .sport-color-hover-bg-lighten:hover, .tennis a.sport-color-hover-bg-lighten:hover {
  background-color: #ff8033;
}

.tennis .sport-color-svg path {
  fill: #ff6000;
}

.tennis .active .sport-color-active {
  color: #ff6000;
}

.other .sport-color, .other a.sport-color {
  color: #d21e1e;
}

.other .sport-color-bg {
  background-color: #d21e1e;
}

.other .sport-color-border {
  border-color: #d21e1e;
}

.other .sport-color-hover:hover, .other a.sport-color-hover:hover {
  color: #d21e1e;
}

.other .sport-color-hover-bg-darken:hover, .other a.sport-color-hover-bg-darken:hover {
  background-color: #a51818;
}

.other .sport-color-hover-bg-lighten:hover, .other a.sport-color-hover-bg-lighten:hover {
  background-color: #e43f3f;
}

.other .sport-color-svg path {
  fill: #d21e1e;
}

.other .active .sport-color-active {
  color: #d21e1e;
}

.dark .sport-color, .dark a.sport-color {
  color: #1e1e1e;
}

.dark .sport-color-bg {
  background-color: #1e1e1e;
}

.dark .sport-color-bg-darken {
  background-color: #050505;
}

.dark .sport-color-border {
  border-color: #1e1e1e;
}

.dark .sport-color-hover:hover, .dark a.sport-color-hover:hover {
  color: #1e1e1e;
}

.dark .sport-color-hover-bg-darken:hover, .dark a.sport-color-hover-bg-darken:hover {
  background-color: #050505;
}

.dark .sport-color-hover-bg-lighten:hover, .dark a.sport-color-hover-bg-lighten:hover {
  background-color: #e43f3f;
}

.dark .sport-color-svg path {
  fill: #1e1e1e;
}

.dark .active .sport-color-active {
  color: #1e1e1e;
}

.oh .sport-color {
  color: #77c10e;
}

.oh .sport-color-border {
  border-color: #77c10e;
}

.oh .sport-color-svg path {
  fill: #77c10e;
}

.oh.participants-detail .table-default-small a:hover {
  color: #1e1e1e;
}

.oh.participants-detail .table-default-small a.online-match-running, .oh.participants-detail .table-default-small a.online-match-running:hover {
  color: #d21e1e;
}

.zoh-pchjongcchang .sport-color {
  color: #006cb5 !important;
}

.zoh-pchjongcchang .sport-color-border {
  border-color: #006cb5 !important;
}

.zoh-pchjongcchang .icon-medail-green.medail-blue {
  background: url("/images/isportcz/dist/non-sprite/zoh-medail-blue.png");
}

.zoh-pchjongcchang a:hover {
  color: #006cb5;
}

.zoh-pchjongcchang .sport-color-svg path {
  fill: #006cb5;
}

.zoh-pchjongcchang .smart-underline:hover {
  color: #006cb5;
  box-shadow: inset 0 -1px #fff, inset 0 -2px #006cb5;
}

.light .sport-color, .light a.sport-color {
  color: #fff;
}

.light .sport-color-bg {
  background-color: #fff;
}

.light .sport-color-svg path {
  fill: #fff;
}

.supperlife-body .other .sport-color, .supperlife-body .other a.sport-color {
  color: #02a555;
}

.supperlife-body .other .sport-color-bg {
  background-color: #02a555;
}

.supperlife-body .other .sport-color-border {
  border-color: #02a555;
}

.supperlife-body .other .sport-color-hover:hover, .supperlife-body .other a.sport-color-hover:hover {
  color: #02a555;
}

.supperlife-body .other .sport-color-hover-bg-darken:hover, .supperlife-body .other a.sport-color-hover-bg-darken:hover {
  background-color: #01733b;
}

.supperlife-body .other .sport-color-hover-bg-lighten:hover, .supperlife-body .other a.sport-color-hover-bg-lighten:hover {
  background-color: #03d76f;
}

.supperlife-body .other .sport-color-svg path {
  fill: #02a555;
}

.supperlife-body .other .active .sport-color-active {
  color: #02a555;
}

.loh-tokio .sport-color {
  color: #94754d !important;
}

.loh-tokio .sport-color-border {
  border-color: #94754d !important;
}

.loh-tokio .sport-color-svg path {
  fill: #94754d;
}

.loh-paris .sport-color {
  color: #ae9e56 !important;
}

.loh-paris .sport-color-border {
  border-color: #ae9e56 !important;
}

.loh-paris .sport-color-svg path {
  fill: #ae9e56;
}

.zoh-peking .sport-color {
  color: #017eff !important;
}

.zoh-peking .sport-color-border {
  border-color: #017eff !important;
}

.zoh-peking .sport-color-svg path {
  fill: #017eff;
}

body {
  font-family: Roboto, arial, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Roboto Slab, Times New Roman, serif;
}

h1 {
  font-size: 35px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 13px;
}

p {
  margin: 0 0 15px;
  font-size: 16px;
  line-height: 1.6;
}

p:last-of-type {
  margin-bottom: 0;
}

.h1-size {
  font-size: 35px;
}

.h2-size {
  font-size: 30px;
}

.h3-size {
  font-size: 26px;
}

.h4-size {
  font-size: 22px;
}

.h5-size {
  font-size: 16px;
}

.h6-size {
  font-size: 13px;
}

.small-text {
  font-size: 9px;
}

.paragraph p {
  font-size: 17px;
  line-height: 1.6;
}

body {
  overflow-anchor: none;
  padding-top: 103px;
}

body.supperlife-body {
  padding-top: 0;
}

.wrapper {
  text-align: left;
  width: 1080px;
  margin: 0 auto;
  padding: 15px 0 0;
}

.wrapper.with-bg {
  background-color: #f0f0f0;
}

.wrapper:after, .wrapper:before {
  content: "";
  clear: both;
  display: table;
}

.inner-wrapper {
  clear: both;
  width: 1080px;
  position: relative;
}

.inner-wrapper:after, .inner-wrapper:before {
  content: "";
  clear: both;
  display: table;
}

.row {
  margin: 0 -15px;
}

.row:after, .row:before {
  content: "";
  clear: both;
  display: table;
}

.one-col, .two-col, .three-col, .four-col, .five-col, .six-col, .seven-col, .eight-col, .nine-col, .ten-col, .eleven-col, .twelve-col {
  clear: none;
  float: left;
  margin: 0 15px;
  padding: 0;
  display: inline;
  position: relative;
}

.one-col.no-margin, .two-col.no-margin, .three-col.no-margin, .four-col.no-margin, .five-col.no-margin, .six-col.no-margin, .seven-col.no-margin, .eight-col.no-margin, .nine-col.no-margin, .ten-col.no-margin, .eleven-col.no-margin, .twelve-col.no-margin {
  margin: 0;
}

.one-col {
  width: 60px;
}

.two-col {
  width: 150px;
}

.three-col {
  width: 240px;
}

.four-col {
  width: 330px;
}

.five-col {
  width: 420px;
}

.six-col {
  width: 510px;
}

.seven-col {
  width: 600px;
}

.eight-col {
  width: 690px;
}

.nine-col {
  width: 780px;
}

.ten-col {
  width: 870px;
}

.eleven-col {
  width: 960px;
}

.twelve-col {
  width: 1050px;
  margin-right: 0;
}

.prefix-one {
  margin-left: 90px;
}

.prefix-two {
  margin-left: 180px;
}

.prefix-three {
  margin-left: 270px;
}

.prefix-four {
  margin-left: 360px;
}

.prefix-five {
  margin-left: 450px;
}

.prefix-six {
  margin-left: 540px;
}

.prefix-seven {
  margin-left: 630px;
}

.prefix-eight {
  margin-left: 720px;
}

.prefix-nine {
  margin-left: 810px;
}

.prefix-ten {
  margin-left: 900px;
}

.prefix-eleven {
  margin-left: 990px;
}

.suffix-one {
  margin-right: 120px;
}

.suffix-two {
  margin-right: 210px;
}

.suffix-three {
  margin-right: 300px;
}

.suffix-four {
  margin-right: 390px;
}

.suffix-five {
  margin-right: 480px;
}

.suffix-six {
  margin-right: 570px;
}

.suffix-seven {
  margin-right: 660px;
}

.suffix-eight {
  margin-right: 750px;
}

.suffix-nine {
  margin-right: 840px;
}

.suffix-ten {
  margin-right: 930px;
}

.suffix-eleven {
  margin-right: 1020px;
}

.last-col {
  margin-right: 0;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.m-5 {
  margin: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.m-10 {
  margin: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.m-15 {
  margin: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.m-20 {
  margin: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.m-25 {
  margin: 25px;
}

.mt-25 {
  margin-top: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.m-30 {
  margin: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.m-35 {
  margin: 35px;
}

.mt-35 {
  margin-top: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.m-40 {
  margin: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.m-45 {
  margin: 45px;
}

.mt-45 {
  margin-top: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.m-50 {
  margin: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.m-55 {
  margin: 55px;
}

.mt-55 {
  margin-top: 55px;
}

.mr-55 {
  margin-right: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.m-60 {
  margin: 60px;
}

.mt-60 {
  margin-top: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.m-65 {
  margin: 65px;
}

.mt-65 {
  margin-top: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.m-70 {
  margin: 70px;
}

.mt-70 {
  margin-top: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.m-75 {
  margin: 75px;
}

.mt-75 {
  margin-top: 75px;
}

.mr-75 {
  margin-right: 75px;
}

.mb-75 {
  margin-bottom: 75px;
}

.ml-75 {
  margin-left: 75px;
}

.m-80 {
  margin: 80px;
}

.mt-80 {
  margin-top: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.m-85 {
  margin: 85px;
}

.mt-85 {
  margin-top: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.mb-85 {
  margin-bottom: 85px;
}

.ml-85 {
  margin-left: 85px;
}

.m-90 {
  margin: 90px;
}

.mt-90 {
  margin-top: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.m-95 {
  margin: 95px;
}

.mt-95 {
  margin-top: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.mb-95 {
  margin-bottom: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.m-100 {
  margin: 100px;
}

.mt-100 {
  margin-top: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-100 {
  margin-left: 100px;
}

form.default-form input {
  box-sizing: border-box;
  max-width: 100%;
}

form.default-form input.input-text {
  color: #1e1e1e;
  border: 2px solid #e6e6e6;
  border-radius: 1px;
  outline: none;
  width: 100%;
  height: 40px;
  margin-top: 20px;
  padding: 8px 9px;
  font-weight: 700;
  transition: all .3s ease-in-out;
  display: block;
}

form.default-form input[type="text"] {
  color: #1e1e1e;
  border: 2px solid #e6e6e6;
  border-radius: 1px;
  outline: none;
  width: 100%;
  height: 40px;
  margin-top: 20px;
  padding: 8px 9px;
  font-weight: 700;
  transition: all .3s ease-in-out;
  display: block;
}

form.default-form input[type="password"] {
  color: #1e1e1e;
  border: 2px solid #e6e6e6;
  border-radius: 1px;
  outline: none;
  width: 100%;
  height: 40px;
  margin-top: 20px;
  padding: 8px 9px;
  font-weight: 700;
  transition: all .3s ease-in-out;
  display: block;
}

form.default-form input.input-text::placeholder {
  color: #828282;
}

form.default-form input[type="text"]::placeholder {
  color: #828282;
}

form.default-form input[type="password"]::placeholder {
  color: #828282;
}

form.default-form input.input-text:disabled {
  color: #bebebe;
  background-color: #fff;
  border-color: #f3f3f3;
}

form.default-form input[type="text"]:disabled {
  color: #bebebe;
  background-color: #fff;
  border-color: #f3f3f3;
}

form.default-form input[type="password"]:disabled {
  color: #bebebe;
  background-color: #fff;
  border-color: #f3f3f3;
}

form.default-form input.input-text:hover:disabled {
  cursor: not-allowed;
}

form.default-form input[type="text"]:hover:disabled {
  cursor: not-allowed;
}

form.default-form input[type="password"]:hover:disabled {
  cursor: not-allowed;
}

form.default-form input.input-text:hover:enabled {
  border-color: #dcdcdc;
}

form.default-form input[type="text"]:hover:enabled {
  border-color: #dcdcdc;
}

form.default-form input[type="password"]:hover:enabled {
  border-color: #dcdcdc;
}

form.default-form input.input-text:focus, form.default-form input.input-text:hover:focus {
  border: 2px solid #5a91f0;
}

form.default-form input[type="text"]:focus {
  border: 2px solid #5a91f0;
}

form.default-form input[type="text"]:hover:focus {
  border: 2px solid #5a91f0;
}

form.default-form input[type="password"]:focus {
  border: 2px solid #5a91f0;
}

form.default-form input[type="password"]:hover:focus {
  border: 2px solid #5a91f0;
}

form.default-form input.submit-button {
  color: #fff;
  background-color: #d21e1e;
  border: 0;
  outline: none;
  width: 200px;
  margin-top: 20px;
  padding: 5px;
  font-weight: 700;
  display: block;
}

form.default-form textarea {
  box-sizing: border-box;
  resize: vertical;
  color: #1e1e1e;
  border: 2px solid #e6e6e6;
  border-radius: 1px;
  outline: none;
  width: 100%;
  max-width: 100%;
  min-height: 80px;
  margin-top: 20px;
  padding: 5px;
  transition: all .3s ease-in-out;
  display: block;
}

form.default-form textarea::placeholder {
  color: #828282;
}

form.default-form textarea:disabled {
  color: #bebebe;
  background-color: #fff;
  border-color: #f3f3f3;
}

form.default-form textarea:hover:disabled {
  cursor: not-allowed;
}

form.default-form textarea:hover:enabled {
  border-color: #dcdcdc;
}

form.default-form textarea:focus, form.default-form textarea:hover:focus {
  border: 2px solid #5a91f0;
}

form.default-form .success-message {
  float: left;
  color: #d21e1e;
  margin-left: 10px;
  display: none;
}

form.default-form .form-field {
  clear: both;
  margin-bottom: 20px;
}

form.default-form .form-field label {
  float: left;
  width: 100%;
  padding: 7px 0;
  font-size: 13px;
}

form.default-form .form-field input {
  float: left;
  margin-top: 0;
}

form.default-form .form-field input[type="checkbox"] {
  margin: 7px;
}

form.default-form .form-field .error-message {
  float: left;
  color: #d21e1e;
  margin-top: 5px;
  font-size: 13px;
  display: none;
}

form.default-form .form-field.with-error .error-message {
  display: block;
}

form.default-form .form-field.with-error input, form.default-form .form-field.with-error textarea, form.default-form .form-field.with-error input:hover, form.default-form .form-field.with-error textarea:hover {
  border: 2px solid #d21e1e;
}

form.default-form .form-field.with-error label {
  color: #d21e1e;
}

form.default-form .form-field .counter {
  color: #464646;
  margin: 5px 0;
}

form.default-form .form-field:after, form.default-form .form-field:before {
  content: "";
  clear: both;
  display: table;
}

form.default-form .form-field-info {
  width: 100%;
}

form.default-form .form-field-info .dataimg-circle-info {
  float: right;
  width: 15px;
  height: 15px;
  position: relative;
}

form.default-form .form-field-info .dataimg-circle-info path {
  fill: #828282;
  transition: all .3s ease-in-out;
}

form.default-form .form-field-info .dataimg-circle-info:hover path {
  fill: #5a91f0;
}

form.default-form .form-field-info .dataimg-circle-info[data-tooltip]:hover:before {
  content: "A";
  opacity: 1;
  border-top: 15px solid #fffbd2;
  border-left: 12px solid rgba(0, 0, 0, 0);
  border-right: 12px solid rgba(0, 0, 0, 0);
  font-weight: normal;
  position: absolute;
  top: -15px;
  left: -5px;
}

form.default-form .form-field-info .dataimg-circle-info[data-tooltip]:hover:after {
  content: attr(data-tooltip);
  color: #1e1e1e;
  z-index: 20;
  background: #fffbd2;
  border-radius: 1px;
  width: 120px;
  padding: 10px;
  font-size: 13px;
  font-weight: normal;
  position: absolute;
  bottom: 30px;
  right: -20px;
}

form.default-form .form-group {
  width: auto;
  position: relative;
}

form.default-form .form-group input[type="text"] {
  padding-right: 60px;
}

form.default-form .form-group .currency-label {
  color: #646464;
  border-left: 2px solid #e6e6e6;
  width: 55px;
  margin: 10px 0;
  padding-left: 10px;
  position: absolute;
  right: 0;
}

form.default-form .form-group .icon-label {
  width: 20px;
  height: 20px;
  margin: 12px;
  position: absolute;
  right: 0;
}

form.default-form .form-inline-button .main-button {
  float: right;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 120px;
  margin: 1px 0;
  padding-top: 9px;
  padding-bottom: 9px;
}

form.default-form .form-inline-button input[type="text"] {
  float: left;
  border-right: 0;
  width: calc(100% - 120px);
}

form.default-form .form-inline-button input[type="text"]:focus {
  border: 2px solid #5a91f0;
  border-right: 0;
}

form.default-form .form-inline-button input[type="text"]:hover:focus {
  border: 2px solid #5a91f0;
  border-right: 0;
}

form.default-form .form-short input[type="text"] {
  max-width: 90px;
}

form.default-form .form-code input[type="text"] {
  text-align: center;
  max-width: 30px;
  margin-right: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

form.default-form [type="checkbox"] {
  display: none;
}

form.default-form [type="checkbox"] + label {
  cursor: pointer;
  padding-left: 30px;
  font-weight: 700;
  position: relative;
}

form.default-form [type="checkbox"] + label:hover:before {
  border-color: #dcdcdc;
}

form.default-form [type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

form.default-form [type="checkbox"]:disabled + label {
  color: #bebebe;
  cursor: not-allowed;
}

form.default-form [type="checkbox"]:disabled + label:before {
  color: #bebebe;
  cursor: not-allowed;
  border-color: #f3f3f3;
}

form.default-form [type="checkbox"]:disabled + label:after {
  color: #ea6c6c;
  cursor: not-allowed;
}

form.default-form input[type="radio"] {
  display: none;
}

form.default-form input[type="radio"] + label {
  cursor: pointer;
  padding-left: 30px;
  font-weight: 700;
  position: relative;
}

form.default-form input[type="radio"] + label:after {
  content: "";
  color: #d21e1e;
  background: #d21e1e;
  border-radius: 12px;
  width: 0;
  height: 0;
  font-size: 0;
  transition: all .3s;
  position: absolute;
  top: 18px;
  left: 12px;
}

form.default-form input[type="radio"] + label:hover:before {
  border-color: #dcdcdc;
}

form.default-form input[type="radio"]:checked + label:after {
  width: 11.5px;
  height: 11.5px;
  top: 11px;
  left: 5px;
}

form.default-form input[type="radio"]:disabled + label {
  color: #bebebe;
  cursor: not-allowed;
}

form.default-form input[type="radio"]:disabled + label:before {
  border-color: #f3f3f3;
}

form.default-form input[type="radio"]:disabled + label:after {
  background-color: #ea6c6c;
}

form.default-form:after, form.default-form:before {
  content: "";
  clear: both;
  display: table;
}

.table-default-small, .main-article .content table, .table-default-big {
  width: 100%;
}

.table-default-small thead, .main-article .content table thead, .table-default-big thead {
  border-bottom: 1px solid #e6e6e6;
}

.table-default-small thead tr, .main-article .content table thead tr, .table-default-big thead tr {
  color: #aaa;
  background-color: #fff;
}

.table-default-small tbody, .main-article .content table tbody, .table-default-big tbody {
  border-bottom: 1px solid #e6e6e6;
}

.table-default-small tbody tr:nth-child(odd), .main-article .content table tbody tr:nth-child(odd), .table-default-big tbody tr:nth-child(odd) {
  background-color: #fff;
}

.table-default-small tbody tr:nth-child(2n), .main-article .content table tbody tr:nth-child(2n), .table-default-big tbody tr:nth-child(2n) {
  background-color: #f0f0f0;
}

.table-default-big td, .table-default-big th {
  padding: 10px;
}

.table-default-big td .description-small, .table-default-big th .description-small {
  font-size: 13px;
}

.table-default-small, .main-article .content table {
  font-size: 12px;
}

.table-default-small td, .table-default-small th, .main-article .content table td, .main-article .content table th {
  height: 30px;
  padding: 4px;
}

.main-article .content table {
  margin-bottom: 20px;
}

.main-article .content table.table-league th, .main-article .content table.table-league td {
  padding: 4px 2px;
}

.main-article .content table.table-league th.logo, .main-article .content table.table-league td.logo {
  padding: 0;
}

table.table-basic tbody tr th, table.table-basic tbody tr td {
  vertical-align: top;
  width: 26.6%;
  font-size: 16px;
  font-weight: 400;
}

table.table-basic tbody tr th:first-child, table.table-basic tbody tr td:first-child {
  vertical-align: middle;
  width: 21.3%;
  font-weight: 700;
}

table.table-basic tbody tr td {
  border-bottom: 2px solid #dcdcdc;
  padding: 15px 15px 15px 0;
}

table.table-basic tbody tr th {
  border-bottom: 2px solid #1e1e1e;
  padding: 20px 5px;
  font-size: 13px;
  font-weight: 700;
}

.table-league th, .table-league td {
  text-align: center;
}

.table-league .order {
  max-width: 26px;
  padding-right: 0;
}

.table-league .teamName {
  text-align: left;
}

.table-league .teamName > a {
  text-decoration: underline;
}

.table-league.table-default-big td.logo {
  padding: 5px 0;
  line-height: 1;
}

.table-league.table-default-big td.logo img {
  max-height: 25px;
}

.table-league.table-default-big td .position {
  float: left;
  text-align: right;
  width: 28px;
  padding-right: 3px;
  display: block;
}

.table-league.table-default-big th:first-child, .table-league.table-default-big td:first-child {
  text-align: left;
}

.table-league.table-default-big.table-league--color tr td:first-child {
  margin: 1px 0;
}

.table-league.table-default-big.table-league--color tr td:first-child strong {
  margin-left: 18px;
  font-size: 16px;
  line-height: 38px;
}

.table-league.table-default-big.table-league--color tr .position, .table-league.table-default-big.table-league--color tr .order {
  color: #fff;
  text-align: center;
  background: #00a442;
  width: 30px;
  height: 38px;
  padding: 0;
  font-size: 15px;
  line-height: 38px;
  display: inline-block;
}

.table-league.table-default-big.table-league--color tr .teamName {
  padding-left: 6px;
  font-size: 16px;
}

.table-league.table-default-big.table-league--color tr .teamName > a {
  text-decoration: underline;
}

.table-league.table-default-big.table-league--color tr:nth-child(n+7) .position, .table-league.table-default-big.table-league--color tr:nth-child(n+7) .order {
  background: #325edc;
}

.table-league.table-default-big.table-league--color tr:nth-child(n+11) .position, .table-league.table-default-big.table-league--color tr:nth-child(n+11) .order {
  background: #d21e1e;
}

.table-league.table-default-big.table-league--color-hockey tr:nth-child(n+11) .position, .table-league.table-default-big.table-league--color-hockey tr:nth-child(n+11) .order {
  color: #1e1e1e;
  background: none;
}

.table-league.table-default-big.table-league--color-hockey tr:nth-child(14) .position, .table-league.table-default-big.table-league--color-hockey tr:nth-child(14) .order {
  color: #fff;
  background: #d21e1e;
}

.table-league.table-default-big.table-league--color-hockey-no-red tr:nth-child(n+5) .position, .table-league.table-default-big.table-league--color-hockey-no-red tr:nth-child(n+5) .order {
  background: #325edc;
}

.table-league.table-default-big.table-league--color-hockey-no-red tr:nth-child(n+13) .position, .table-league.table-default-big.table-league--color-hockey-no-red tr:nth-child(n+13) .order {
  color: #1e1e1e;
  background: none;
}

.table-league.table-default-small caption.title-section {
  display: table-caption;
}

.table-league.table-default-small td .position {
  float: left;
  text-align: center;
  width: 19px;
  padding: 0;
}

.table-league.table-default-small td .teamName {
  line-height: inherit;
  width: 101px;
  margin-left: 22px;
  display: block;
}

.table-league.table-default-small td.logo {
  width: 25px;
  padding: 0;
  line-height: 1;
}

.table-league.table-default-small td.logo img {
  max-height: 25px;
}

.table-league.table-default-small td.double-line-content .position {
  line-height: 30px;
}

.table-league.table-default-small td.score {
  padding: 4px 2px;
}

.table-league.table-default-small th:first-child {
  text-align: left;
  padding-left: 4px;
}

.table-league.table-default-small td:first-child {
  text-align: left;
  padding-left: 0;
}

.table-league--color, .table-league.table-default-small.table-league--color {
  font-size: 12px;
}

.table-league--color tr td, .table-league.table-default-small.table-league--color tr td {
  padding: 1px 2px;
}

.table-league--color tr td:first-child, .table-league.table-default-small.table-league--color tr td:first-child {
  padding: 1px 0;
}

.table-league--color tr td.logo, .table-league.table-default-small.table-league--color tr td.logo {
  padding: 0;
}

.table-league--color tr .position, .table-league--color tr .order, .table-league.table-default-small.table-league--color tr .position, .table-league.table-default-small.table-league--color tr .order {
  color: #fff;
  text-align: center;
  background: #00a442;
  width: 22px;
  height: 30px;
  font-size: 12px;
  line-height: 30px;
  display: inline-block;
}

.table-league--color tr .teamName, .table-league.table-default-small.table-league--color tr .teamName {
  padding-left: 6px;
  line-height: 30px;
}

.table-league--color tr .teamName > a, .table-league.table-default-small.table-league--color tr .teamName > a {
  text-decoration: underline;
}

.table-league--color tr:nth-child(n+7) .position, .table-league--color tr:nth-child(n+7) .order, .table-league.table-default-small.table-league--color tr:nth-child(n+7) .position, .table-league.table-default-small.table-league--color tr:nth-child(n+7) .order {
  background: #325edc;
}

.table-league--color tr:nth-child(n+11) .position, .table-league--color tr:nth-child(n+11) .order, .table-league.table-default-small.table-league--color tr:nth-child(n+11) .position, .table-league.table-default-small.table-league--color tr:nth-child(n+11) .order {
  background: #d21e1e;
}

.table-league.table-default-small.table-league--color-international {
  font-size: 12px;
}

.table-league.table-default-small.table-league--color-international tr td {
  padding: 1px 2px;
}

.table-league.table-default-small.table-league--color-international tr td:first-child {
  padding: 1px 0;
}

.table-league.table-default-small.table-league--color-international tr td.logo {
  padding: 0;
}

.table-league.table-default-small.table-league--color-international tr .position, .table-league.table-default-small.table-league--color-international tr .order {
  color: #fff;
  text-align: center;
  background: #00a442;
  width: 22px;
  height: 30px;
  font-size: 12px;
  line-height: 30px;
  display: inline-block;
}

.table-league.table-default-small.table-league--color-international tr .teamName {
  padding-left: 6px;
  line-height: 30px;
}

.table-league.table-default-small.table-league--color-international tr .teamName > a {
  text-decoration: underline;
}

.table-league.table-default-small.table-league--color-international tr:nth-child(n+7) .position, .table-league.table-default-small.table-league--color-international tr:nth-child(n+7) .order {
  background: #00a442;
}

.table-league.table-default-small.table-league--color-international tr:nth-child(n+9) .position, .table-league.table-default-small.table-league--color-international tr:nth-child(n+9) .order {
  background: #325edc;
}

.table-league.table-default-small.table-league--color-international tr:nth-child(n+25) .position, .table-league.table-default-small.table-league--color-international tr:nth-child(n+25) .order {
  color: #1e1e1e;
  background: none;
}

.table-league.table-default-small.table-league--color-hockey tr:nth-child(n+5) .position, .table-league.table-default-small.table-league--color-hockey tr:nth-child(n+5) .order {
  background: #325edc;
}

.table-league.table-default-small.table-league--color-hockey tr:nth-child(n+13) .position, .table-league.table-default-small.table-league--color-hockey tr:nth-child(n+13) .order {
  color: #1e1e1e;
  background: none;
}

.table-league.table-default-small.table-league--color-hockey tr:nth-child(14) .position, .table-league.table-default-small.table-league--color-hockey tr:nth-child(14) .order {
  color: #fff;
  background: #ff6000;
}

.table-league.table-default-small.table-league--color-hockey tr:nth-child(15) .position, .table-league.table-default-small.table-league--color-hockey tr:nth-child(15) .order {
  color: #fff;
  background: #d21e1e;
}

.table-league.table-default-small.table-league--color-hockey-no-red tr:nth-child(n+5) .position, .table-league.table-default-small.table-league--color-hockey-no-red tr:nth-child(n+5) .order {
  background: #325edc;
}

.table-league.table-default-small.table-league--color-hockey-no-red tr:nth-child(n+13) .position, .table-league.table-default-small.table-league--color-hockey-no-red tr:nth-child(n+13) .order {
  color: #1e1e1e;
  background: none;
}

.legend-table-league-color {
  margin: 14px 0 0;
  padding: 0;
}

.legend-table-league-color li {
  margin: 0 18px 14px 0;
  font-size: 12px;
  line-height: 1;
  display: inline-block;
}

.legend-table-league-color li span {
  float: left;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  display: inline-block;
}

.legend-table-league-color li .square-green {
  background-color: #00a442;
}

.legend-table-league-color li .square-blue {
  background-color: #325edc;
}

.legend-table-league-color li .square-red {
  background-color: #d21e1e;
}

.legend-table-league-color li .square-orange {
  background-color: #ff6000;
}

.table-synottip {
  margin-bottom: 40px;
}

.table-synottip thead {
  border-bottom: 2px solid #e6e6e6;
}

.table-synottip thead tr th {
  color: #aaa;
  padding: 10px;
  font-weight: 400;
}

.table-synottip tbody {
  border-bottom: 2px solid #e6e6e6;
}

.table-synottip tbody tr td {
  padding: 7px;
}

.table-synottip tbody tr td:not(.sport-color):last-child {
  color: #1e1e1e;
}

.table-filter-search {
  position: relative;
}

.table-filter-search__input {
  color: #1e1e1e;
  border: 2px solid #e6e6e6;
  border-radius: 1px;
  outline: none;
  width: 100%;
  height: 40px;
  padding: 8px 40px 8px 9px;
  font-weight: 700;
  transition: all .3s ease-in-out;
  display: block;
}

.table-filter-search__input::placeholder {
  color: #828282;
}

.table-filter-search__input:disabled {
  color: #bebebe;
  background-color: #fff;
  border-color: #f3f3f3;
}

.table-filter-search__input:hover:disabled {
  cursor: not-allowed;
}

.table-filter-search__input:hover:enabled {
  border-color: #dcdcdc;
}

.table-filter-search__input:focus, .table-filter-search__input:hover:focus {
  border: 2px solid #5a91f0;
}

.table-filter-search__icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.table-filter-search__icon svg {
  height: 20px;
}

ul.list-links {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.list-links li {
  color: #1e1e1e;
  text-transform: uppercase;
  margin: 0 15px 0 0;
  padding: 0;
  font-size: 13px;
  font-weight: 700;
  list-style: none;
  display: inline-block;
}

ul.list-links li:last-child {
  margin: 0;
}

ul.list-links li a {
  color: #1e1e1e;
  text-decoration: none;
}

ul.list-links li a:hover {
  color: #d21e1e;
}

ul.list-links.light li, ul.list-links.light li a {
  color: #e6e6e6;
}

ul.list-links.simple li {
  text-transform: none;
  font-size: 16px;
  font-weight: 400;
}

ul.list-links.list-links-vertical li {
  text-transform: none;
  border-bottom: 1px solid #dcdcdc;
  margin: 0;
  display: block;
}

ul.list-links.list-links-vertical li:last-child {
  border-bottom: none;
}

ul.links-separated li {
  margin-right: 30px;
  font-size: 13px;
  position: relative;
}

ul.links-separated li a:after {
  content: "";
  background-color: #1e1e1e;
  width: 1px;
  height: 11px;
  margin: 4px 15px 0;
  position: absolute;
}

ul.links-separated li:last-of-type a {
  margin-right: 0;
}

ul.links-separated li:last-of-type a:after {
  content: none;
}

.football .links-separated a:after {
  background-color: #00a442;
}

.hockey .links-separated a:after {
  background-color: #325edc;
}

.tennis .links-separated a:after {
  background-color: #ff6000;
}

.menu-tabs {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}

.menu-tabs li {
  float: left;
  color: #1e1e1e;
  text-transform: uppercase;
  width: 33%;
  margin: 0;
  padding: 0 0 2px 4px;
  font-size: 13px;
  font-weight: 700;
  list-style: none;
}

.menu-tabs__item, .menu-tabs__item-inline {
  color: #aaa;
  text-transform: none;
  text-align: center;
  box-sizing: border-box;
  background: #fff;
  border: 2px solid #aaa;
  border-radius: 3px;
  height: 42px;
  margin: 0 2px 6px;
  padding: 0;
  font-size: 13px;
  font-weight: 700;
  line-height: 40px;
  text-decoration: none;
  display: block;
}

.menu-tabs__item.active, .menu-tabs__item:hover, .menu-tabs__item-inline.active, .menu-tabs__item-inline:hover {
  color: #1e1e1e;
  border-color: #d21e1e;
  text-decoration: none;
}

.menu-tabs__item-inline {
  height: 40px;
  margin: 0 6px 5px 0;
  padding: 0 10px;
}

.menu-tabs.football .menu-tabs__item.active, .menu-tabs.football .menu-tabs__item:hover, .menu-tabs.football .menu-tabs__item-inline.active, .menu-tabs.football .menu-tabs__item-inline:hover {
  border-color: #00a442;
}

.menu-tabs.hockey .menu-tabs__item.active, .menu-tabs.hockey .menu-tabs__item:hover, .menu-tabs.hockey .menu-tabs__item-inline.active, .menu-tabs.hockey .menu-tabs__item-inline:hover {
  border-color: #325edc;
}

.menu-tabs.tennis .menu-tabs__item.active, .menu-tabs.tennis .menu-tabs__item:hover, .menu-tabs.tennis .menu-tabs__item-inline.active, .menu-tabs.tennis .menu-tabs__item-inline:hover {
  border-color: #ff6000;
}

.menu-tabs.others .menu-tabs__item.active, .menu-tabs.others .menu-tabs__item:hover, .menu-tabs.others .menu-tabs__item-inline.active, .menu-tabs.others .menu-tabs__item-inline:hover {
  border-color: #d21e1e;
}

.menu-tabs--inline li {
  width: auto;
  min-width: 110px;
  padding: 0;
}

.menu-tabs--inline li:last-child a {
  margin-right: 0;
}

.menu-tab__content {
  display: none;
}

.menu-tab__content.current {
  display: block;
}

hr {
  background-color: #dcdcdc;
  border: 0;
}

.dropdown ul {
  display: inline-table;
}

.dropdown ul ul {
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  display: none;
}

.dropdown ul li {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.dropdown ul li:not(.dropdown-click):not(.dropdown-submenu) ul {
  transition: all;
}

.dropdown ul li:not(.dropdown-click):not(.dropdown-submenu):hover > ul, .dropdown ul li:not(.dropdown-click):not(.dropdown-submenu):active > ul, .dropdown ul li:not(.dropdown-click):not(.dropdown-submenu):focus > ul {
  opacity: 1;
  visibility: visible;
  transition: opacity .5s .2s;
  display: block;
}

.dropdown ul li:not(.dropdown-click):not(.dropdown-submenu):hover > ul li, .dropdown ul li:not(.dropdown-click):not(.dropdown-submenu):active > ul li, .dropdown ul li:not(.dropdown-click):not(.dropdown-submenu):focus > ul li, .dropdown ul li:not(.dropdown-click):not(.dropdown-submenu):hover > ul li a, .dropdown ul li:not(.dropdown-click):not(.dropdown-submenu):active > ul li a, .dropdown ul li:not(.dropdown-click):not(.dropdown-submenu):focus > ul li a {
  cursor: pointer;
}

.dropdown ul li.dropdown-show > ul {
  opacity: 1;
  z-index: 2;
  visibility: visible;
  pointer-events: auto;
  transition: opacity .5s .2s;
  display: block;
}

.dropdown ul li.dropdown-show > ul li ul {
  top: 0;
  right: -100%;
}

.dropdown-menu {
  float: left;
  font-weight: 700;
}

.dropdown-menu ul {
  padding: 0;
  list-style: none;
  position: relative;
}

.dropdown-menu li {
  float: left;
  display: block;
}

.dropdown-menu li a {
  color: #1e1e1e;
  cursor: pointer;
  font-size: 13px;
  text-decoration: none;
}

.dropdown-menu li .dataimg-arrow-down {
  color: #1e1e1e;
  width: 10px;
  height: 5px;
  position: absolute;
  top: 29px;
  right: 12px;
}

.dropdown-menu li.active > a:after {
  content: " ";
  border-bottom: 2px solid #1e1e1e;
  width: 100%;
  display: block;
  position: relative;
  top: 5px;
}

.dropdown-menu li.dropdown-title {
  height: 60px;
  padding: 0;
  position: relative;
}

.dropdown-menu li.dropdown-title > a:hover:after {
  content: " ";
  border-bottom: 2px solid #fff;
  width: 100%;
  display: block;
  position: relative;
  top: 5px;
}

.dropdown-menu li.dropdown-title > a, .dropdown-menu li.dropdown-title > span {
  box-sizing: border-box;
  text-transform: uppercase;
  padding: 24px 30px 19px 10px;
  display: block;
}

.dropdown-menu li.dropdown-title.no-childs > a, .dropdown-menu li.dropdown-title.no-childs > span {
  padding: 24px 8px 19px;
}

.dropdown-menu li.dropdown-show > ul li ul {
  background: #828282;
}

.dropdown-menu li.dropdown-show > ul li ul a {
  color: #fff;
}

.dropdown-menu li.dropdown-show > ul li ul a:after {
  border-color: #e6e6e6;
}

.dropdown-menu li .dataimg-arrow-right {
  color: #1e1e1e;
  width: 5px;
  height: 10px;
  position: absolute;
  top: 10px;
  right: 12px;
}

.dropdown-menu li .dataimg-arrow-right path {
  fill: #1e1e1e;
}

.dropdown-menu li ul {
  background: #fff;
  width: 200px;
  padding: 0;
  position: absolute;
  top: 100%;
  box-shadow: 0 5px 6px rgba(0, 0, 0, .2);
}

.dropdown-menu li ul li {
  float: none;
  font-size: 16px;
  position: relative;
}

.dropdown-menu li ul li a {
  padding: 10px;
  display: block;
}

.dropdown-menu li ul li:last-child a {
  border-bottom: 0;
}

.dropdown-menu li ul li:hover {
  background: #dcdcdc;
}

.dropdown-menu li ul li:hover .dataimg-arrow-right path {
  fill: #1e1e1e;
}

.dropdown-menu li ul li:hover ul {
  color: #1e1e1e;
  background: #dcdcdc;
  position: absolute;
  top: 0;
  right: -100%;
  box-shadow: 3px 1px 3px 1px rgba(0, 0, 0, .2);
}

.dropdown-menu li ul li:hover ul li a {
  color: #1e1e1e;
}

.dropdown-menu li ul li:hover ul li a:after {
  content: "";
  border-bottom: 1px solid #bebebe;
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
}

.dropdown-menu li ul li:hover ul li:hover {
  background: #bebebe;
}

.dropdown-menu li ul li:hover ul li:hover a:after {
  border-color: #bebebe;
}

.dropdown-menu li ul li:hover ul li:not(:first-child):hover a:before {
  content: "";
  border-bottom: 1px solid #bebebe;
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
}

.dropdown-menu li ul li:hover ul li:last-child a:after {
  border-bottom: 0;
}

.dropdown-menu--full-col {
  width: 100%;
}

.dropdown-menu--full-col ul {
  width: 100%;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.dropdown-menu--full-col li {
  width: 100%;
}

.dropdown-menu--full-col li.dropdown-title {
  background: #fff;
  border: 2px solid #e6e6e6;
  border-radius: 2px;
  height: 40px;
  padding: 0;
  position: relative;
}

.dropdown-menu--full-col li.dropdown-title > a {
  text-transform: none;
  color: #707070;
  padding: 0 30px 0 10px;
  font-family: Roboto, arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
}

.dropdown-menu--full-col li.dropdown-title > a > span > svg {
  position: absolute;
}

.dropdown-menu--full-col li.dropdown-title > a:hover:after {
  display: none;
}

.dropdown-menu--full-col li.dropdown-title .dataimg-arrow-down {
  top: 16px;
}

.dropdown-menu--full-col li ul {
  width: 100%;
  margin-top: 3px;
}

.dropdown-menu--full-col li ul li a {
  color: #1e1e1e;
  font-size: 13px;
}

.dropdown-menu--half-items ul li:not(:-webkit-any(.dropdown-click, .dropdown-submenu)):hover > ul, .dropdown-menu--half-items ul li:not(:-webkit-any(.dropdown-click, .dropdown-submenu)):active > ul, .dropdown-menu--half-items ul li:not(:-webkit-any(.dropdown-click, .dropdown-submenu)):focus > ul {
  flex-flow: wrap;
  width: 260px;
  display: flex;
}

.dropdown-menu--half-items ul li:not(:is(.dropdown-click, .dropdown-submenu)):hover > ul, .dropdown-menu--half-items ul li:not(:is(.dropdown-click, .dropdown-submenu)):active > ul, .dropdown-menu--half-items ul li:not(:is(.dropdown-click, .dropdown-submenu)):focus > ul {
  flex-flow: wrap;
  width: 260px;
  display: flex;
}

.dropdown-menu--half-items ul li:not(:-webkit-any(.dropdown-click, .dropdown-submenu)):hover > ul li, .dropdown-menu--half-items ul li:not(:-webkit-any(.dropdown-click, .dropdown-submenu)):active > ul li, .dropdown-menu--half-items ul li:not(:-webkit-any(.dropdown-click, .dropdown-submenu)):focus > ul li {
  float: left;
  flex-basis: 50%;
  width: auto;
}

.dropdown-menu--half-items ul li:not(:is(.dropdown-click, .dropdown-submenu)):hover > ul li, .dropdown-menu--half-items ul li:not(:is(.dropdown-click, .dropdown-submenu)):active > ul li, .dropdown-menu--half-items ul li:not(:is(.dropdown-click, .dropdown-submenu)):focus > ul li {
  float: left;
  flex-basis: 50%;
  width: auto;
}

.dropdown-menu .football.dropdown-title > a:hover {
  border-color: #00a442;
}

.dropdown-menu .hockey.dropdown-title > a:hover {
  border-color: #325edc;
}

.dropdown-menu .tennis.dropdown-title > a:hover {
  border-color: #ff6000;
}

.dropdown-menu:after, .dropdown-menu:before {
  content: "";
  clear: both;
  display: table;
}

a.additional-value-container {
  color: #aaa;
  float: left;
  width: auto;
  margin-top: 5px;
  display: block;
}

a.additional-value-container .additional-value {
  float: left;
  clear: both;
  font-size: 30px;
  line-height: 33px;
}

a.additional-value-container .additional-description {
  float: left;
  clear: both;
}

a.additional-value-container span {
  font-size: 13px;
  font-weight: 400;
}

a.additional-value-container .additional-value-small {
  float: left;
  clear: none;
  margin-right: 3px;
  font-size: 13px;
}

a.additional-value-container .additional-description-small {
  clear: none;
}

a.additional-value-container .icon {
  float: left;
  width: 5px;
  height: 9px;
  margin: 4px;
}

a.additional-value-container path {
  fill: #aaa;
}

.caption-arrow {
  float: left;
  background: #fff;
  width: 135px;
  height: 190px;
  margin-right: 30px;
  position: relative;
}

.caption-arrow .caption-title {
  color: #323232;
  text-align: left;
  width: 135px;
  padding: 44px 0 44px 30px;
  font-size: 26px;
  font-weight: 300;
}

.caption-arrow .arrow {
  border: 95px solid rgba(0, 0, 0, 0);
  border-left: 30px solid #fff;
  border-right-width: 0;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  right: -30px;
}

.title-meta {
  color: #bebebe;
  font-size: 13px;
}

.box-container {
  background-color: #fff;
  margin-bottom: 30px;
  padding: 15px 0;
}

.box-container.no-padding {
  padding: 0;
}

.box-container.no-margin {
  margin: 0;
}

.box-container.no-padding-bottom {
  padding-bottom: 0;
}

.box-container .box-content {
  padding: 0 15px;
}

.box-container p {
  font-size: 16px;
}

.box-container:after, .box-container:before {
  content: "";
  clear: both;
  display: table;
}

.box-container__title {
  text-transform: uppercase;
  color: #d21e1e;
  margin: 13px 9px 15px;
  font-family: roboto, arial, sans-serif;
  font-size: 1em;
  font-weight: 700;
}

.box-container__title a {
  color: inherit;
}

@media screen and (min-width: 760px) {
  .box-container__title {
    font-size: 22px;
  }
}

.promo-bar {
  z-index: 1;
  background: #fff;
  min-width: 1080px;
  min-height: 40px;
  margin: 0 -20px;
  padding-top: 10px;
  position: relative;
}

.promo-bar > .wrapper {
  padding: 0;
}

.promo-bar ul {
  justify-content: space-between;
}

.promo-bar ul li:first-child {
  color: #d21e1e;
}

.promo-bar:after, .promo-bar:before {
  content: "";
  clear: both;
  display: table;
}

.link-large {
  margin: 30px 0;
  transition: all .5s;
}

.link-large a {
  padding: 20px;
  display: block;
}

.link-large a .title {
  float: left;
  color: #fff;
  width: 70%;
  margin-top: 5px;
  font-size: 22px;
  line-height: 30px;
}

.link-large a .button {
  float: right;
  color: #fff;
  text-align: center;
  border: 2px solid #fff;
  width: 30%;
  padding: 9px 60px 8px;
  font-size: 16px;
  font-weight: 700;
}

.link-large a:after, .link-large a:before {
  content: "";
  clear: both;
  display: table;
}

.link-large.link-flex a {
  display: flex;
}

.link-large.link-flex.link-flex--vertical a {
  flex-direction: column;
}

@media screen and (max-width: 760px) {
  .link-large a {
    padding: 10px;
  }

  .link-large a .title {
    float: none;
    width: 100%;
    font-size: 1.2em;
  }

  .link-large a .button {
    float: none;
    width: 80%;
    margin: 10px auto;
    padding: 9px;
  }
}

.paging {
  width: 100%;
  font-size: 22px;
  font-weight: 300;
  display: block;
}

.paging button, .paging a.next, .paging a.prev {
  cursor: pointer;
  border: 0;
  outline: 0;
  width: 9%;
  height: 60px;
  margin: 0;
  display: block;
}

.paging button:hover, .paging a.next:hover, .paging a.prev:hover {
  transition: all .2s;
}

.paging button.disabled, .paging a.next.disabled, .paging a.prev.disabled {
  opacity: .7;
  cursor: default;
}

.paging button .arrow-listing, .paging a.next .arrow-listing, .paging a.prev .arrow-listing {
  width: 13px;
  height: 26px;
}

.paging button .arrow-listing {
  margin: auto;
}

.paging a .arrow-listing {
  margin: 17px auto;
}

.paging .prev {
  float: left;
}

.paging .next {
  float: right;
}

.paging .paging-holder {
  float: left;
  text-align: center;
  background-color: #e6e6e6;
  width: 82%;
  height: 60px;
  margin: 0 auto;
  padding-left: 0;
  display: block;
}

.paging .paging-holder.bg-transparent {
  background: none;
}

.paging .paging-holder a {
  min-width: 29px;
  height: auto;
  padding: 16px;
  text-decoration: none;
  display: inline-block;
}

.paging .paging-holder .active, .paging .aktpage {
  font-weight: 700;
}

.paging--superlife .sport-color-bg {
  background-color: #02a555;
}

.paging--superlife .sport-color-hover:hover, .paging--superlife a.sport-color-hover:hover, .paging--superlife .sport-color.active {
  color: #02a555;
}

.paging:after, .paging:before {
  content: "";
  clear: both;
  display: table;
}

.old-isport-link {
  text-align: center;
  width: 1080px;
  margin: 0 auto;
  padding: 0 15px;
}

.old-isport-link a.link {
  font-size: 16px;
  display: inline-block;
}

.old-isport-link a.link .icon {
  float: right;
  margin-top: 8px;
}

.sms-box {
  min-height: 170px;
  margin-bottom: 20px;
  display: block;
}

.sms-box:last-of-type {
  margin-bottom: 0;
}

.sms-box .sms-text {
  color: #828282;
  word-wrap: break-word;
  font-size: 16px;
}

.sms-box .sms-up-bold {
  text-transform: uppercase;
  color: #323232;
  font-weight: 700;
}

.sms-box .sms-red {
  color: #d21e1e;
}

.sms-days-price {
  font-size: 0;
}

.sms-days-price .sms-days {
  color: #d21e1e;
  border-right: 1px solid #e6e6e6;
  font-size: 50px;
  font-weight: 700;
}

.sms-days-price .sms-price {
  color: #646464;
  padding-left: 10px;
  font-size: 50px;
}

.sms-days-price .sms-days:after, .sms-days-price .sms-price:before {
  content: " ";
}

.question-with-photo {
  padding: 20px;
}

.question-with-photo .photo-container {
  float: left;
  width: 70px;
}

.question-with-photo .info-container {
  float: left;
  width: calc(100% - 70px);
  padding: 5px 0 0 10px;
  font-size: 22px;
  font-weight: 700;
}

.question-with-photo .info-container .form-field {
  margin: 5px 0 0;
}

.question-with-photo:nth-of-type(odd) {
  background-color: #f0f0f0;
}

.question-with-photo.can-vote {
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
  padding: 20px 0;
  transition: background-color .5s;
}

.question-with-photo.can-vote .photo-container, .question-with-photo.can-vote .info-container {
  cursor: pointer;
}

.question-with-photo.can-vote:nth-of-type(odd) {
  background-color: #fff;
}

.question-with-photo.can-vote:hover {
  background-color: #fdf2f2;
}

.question-with-photo .votes-container {
  float: right;
  font-size: 30px;
  font-weight: 300;
}

.question-with-photo:after, .question-with-photo:before {
  content: "";
  clear: both;
  display: table;
}

.league-name {
  text-transform: uppercase;
  font-size: .7em;
  font-weight: 700;
}

.round-pic {
  text-align: center;
  width: 78px;
  font-family: Roboto Slab, Times New Roman, serif;
  font-weight: 700;
  display: inline-block;
}

.round-pic img {
  border-radius: 78px;
  margin-bottom: 5px;
  overflow: hidden;
}

.round-pic:hover a {
  color: #00a442;
  text-decoration-color: #00a442;
}

.text-bordered {
  text-align: center;
  color: #d21e1e;
  border: 2px solid #d21e1e;
  border-width: 2px 0;
  width: 100%;
  margin: 0 0 15px;
  padding: 10px 0;
  font-size: 13px;
  font-weight: 700;
  line-height: 2.5;
}

.text-bordered a {
  color: #d21e1e;
}

.box-content-article {
  background-color: #fff;
  padding: 5px;
}

.text-dot-grey {
  color: #828282;
  margin-top: -7px;
  margin-left: 7px;
  display: inline-block;
}

.text-dot-grey:before {
  content: ". ";
  vertical-align: bottom;
  font-size: 25px;
}

.liga-videos-wrapp {
  width: 50px;
  margin: 0 8px;
  position: relative;
}

.liga-videos-wrapp.het {
  margin-left: 0;
  padding-left: 20px;
}

.liga-videos-wrapp .video-count {
  text-align: center;
  color: #fff;
  background: #d21e1e;
  border: 2px solid #fff;
  border-radius: 36px;
  width: 34px;
  height: 34px;
  padding-top: 5px;
  font-weight: 700;
  position: absolute;
  top: -9px;
  right: -12px;
}

.subscription-title {
  margin: 0 15px 15px;
}

.subscription-issue-detail-left {
  float: left;
  margin: 0 0 0 15px;
}

.subscription-issue-detail-left img {
  height: 275px;
}

.subscription-icons {
  float: right;
  text-align: center;
  height: 270px;
  margin: 0 28px 0 0;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  top: 5px;
}

.subscription-icons .liga-zive {
  margin-top: 9px;
}

.subscription-icons .goly-z-ligy {
  position: absolute;
  bottom: 0;
}

.subscription-icons .podcasty {
  margin-top: 9px;
}

.subscription-link-left {
  float: left;
  margin: 30px 0 30px 15px;
}

.subscription-link-right {
  float: right;
  margin: 30px 8px 30px 0;
}

.siteversion {
  background-color: #fff;
  width: 1050px;
  margin: 20px auto 0;
  padding: 0 15px;
  overflow: hidden;
}

.siteversion hr {
  width: auto;
}

.siteversion .link-with-icon {
  clear: both;
  color: #1e1e1e;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
  position: relative;
}

.siteversion .link-with-icon:hover {
  color: #d21e1e;
  text-decoration: none;
}

.siteversion .link-with-icon:hover .icon path {
  fill: #d21e1e;
  transition: fill .2s;
}

.siteversion .link-with-icon.no-icon {
  padding-right: 0;
  line-height: 100%;
}

.siteversion .link-with-icon.icon-left .icon {
  float: left;
  margin-right: 10px;
}

.siteversion .link-with-icon.icon-right .icon {
  float: right;
  margin-left: 10px;
}

.siteversion .link-with-icon .icon {
  width: 15.1px;
  height: 15px;
  display: inline-block;
}

.siteversion .link-with-icon .icon.dataimg-desktop {
  width: 22px;
  margin-top: -3px;
}

.siteversion .link-with-icon .icon.dataimg-mobil {
  margin-top: -5px;
}

.siteversion .link-with-icon .icon.icon-menu {
  top: 0;
  right: 0;
}

.siteversion .link-with-icon .menu-icon-container {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -6px;
  right: 0;
}

.isport-premium {
  background: #cca550;
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

.isport-premium.abs {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.isport-premium.small {
  width: 115px;
  height: 32px;
}

.isport-premium.smaller {
  width: 85px;
  height: 24px;
}

.isport-premium.smallest {
  width: 73px;
  height: 20px;
}

.isport-premium.smallest.alt {
  width: 75px;
}

.isport-premium.mid {
  width: 137px;
  height: 32px;
}

.isport-premium.large {
  width: 138px;
  height: 38px;
}

.isport-premium .dataimg-isport-premium {
  min-height: 1px;
  display: block;
}

.logo-fortuna-liga {
  width: 40px;
  height: 40px;
  margin-top: 13px;
  margin-left: 10px;
}

.four-col.right-col .link-big .dataimg-arrow-right {
  position: relative;
}

.four-col.right-col .link-big .dataimg-arrow-right svg, .four-col.right-col .box-container .align-center .link .dataimg-arrow-right svg {
  position: absolute;
}

.clear {
  clear: both;
}

.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.block-center {
  margin-left: auto;
  margin-right: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.margin-0 {
  margin: 0;
}

.margin-top {
  margin-top: 15px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-bottom {
  margin-bottom: 15px;
}

.margin-left {
  margin-left: 15px;
}

.margin-left-70 {
  margin-left: 70px;
}

.margin-left-10 {
  margin-left: 10px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-right {
  margin-right: 15px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-bottom-double {
  margin-bottom: 30px;
}

.padding-top {
  padding-top: 15px;
}

.padding-bottom {
  padding-bottom: 15px;
}

.padding-left {
  padding-left: 15px;
}

.padding-left-10 {
  padding-left: 10px;
}

.margin-left-mobile {
  margin-left: 2%;
}

.margin-right-mobile {
  margin-right: 2%;
}

.bg-color-row {
  background-color: #fff;
}

.bg-color-row:nth-of-type(odd) {
  background-color: #f0f0f0;
}

.text-color-light {
  color: #fff;
}

.text-color-grey1 {
  color: #323232;
}

.text-color-red {
  color: #d21e1e;
}

.text-color-light-grey {
  color: #e6e6e6;
}

.text-grey-middle {
  color: #828282;
}

.text-uppercase {
  text-transform: uppercase;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-basic {
  font-weight: 400;
}

.font-weight-bold {
  font-weight: 700;
}

.font-basic {
  font-family: Roboto, arial, sans-serif;
}

.font-size-7, .font-size-6 {
  font-size: 9px;
}

.font-size-4 {
  font-size: 22px;
}

.border-bottom {
  border-bottom: 1px solid #dcdcdc;
}

.border-bottom-none, .border-bottom-none .title-section.border-bottom {
  border-bottom: none;
}

.inline-block {
  display: inline-block;
}

.border-top {
  border-top: 1px solid #dcdcdc;
}

.border-top-2px {
  border-top: 2px solid #dcdcdc;
}

.border-top-3px {
  border-top: 3px solid #dcdcdc;
}

.border-top-red {
  border-top-color: #d21e1e;
}

.border-right {
  border-right: 1px solid #dcdcdc;
}

.border-none {
  border: 0;
}

.flag-wrapp {
  position: absolute;
  top: 0;
  left: 0;
}

.flag-wrapp div {
  width: 17px;
  height: 15px;
}

.display-none {
  display: none;
}

.half-width {
  width: 50%;
}

.full-width {
  width: 100%;
}

hr.divider {
  height: 1px;
}

hr.divider--big {
  height: 2px;
}

.superlife-border-top-strong {
  border-top: 3px solid #02a555;
}

.superlife.space-sides {
  padding: 0 2%;
}

.superlife .title-section .main-title {
  color: #02a555;
}

.gam-wrapper {
  clear: both;
  display: block;
}

.gam-wrapper--padding {
  padding: 20px 0;
}

.content .cnc_article_ad_position {
  width: 690px;
  height: auto;
  margin: -14px 0 30px -100px;
  padding: 0;
}

.content .cnc_article_ad_position .cnc-ads {
  clear: both;
}

.mobile .content .cnc_article_ad_position {
  width: auto;
  height: auto;
  margin: -14px 0 30px;
}

.advert {
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  padding: 5px 0 10px;
  font-family: Roboto, arial, sans-serif;
}

.advert a.type {
  display: block;
  overflow: auto;
}

.advert a.type:hover .title {
  color: #d21e1e;
}

.advert a.type span {
  color: #aaa;
  float: left;
  text-transform: uppercase;
  width: 100%;
  padding: 2px 0 5px;
  font-size: 9px;
  font-weight: 700;
}

.advert a.type .title {
  color: #1e1e1e;
  float: right;
  text-transform: none;
  width: 75%;
  padding: 0;
  font-size: 13px;
  transition: color .2s;
}

.advert a.type .title:hover {
  text-decoration: underline;
}

.advert a.type img {
  float: left;
  width: 62px;
  height: 42px;
}

.advert:after, .advert:before {
  content: "";
  clear: both;
  display: table;
}

.sklik-ads {
  background-color: #fff;
  margin-bottom: 30px;
  padding: 30px 30px 35px;
}

.sklik-ads .sklik-ad h2 {
  margin-bottom: 30px;
  font-family: Roboto, arial, sans-serif;
  overflow: auto;
}

.sklik-ads .sklik-ad h2 a {
  font-family: Roboto, arial, sans-serif;
  display: block;
}

.sklik-ads .sklik-ad h2 a span {
  float: left;
}

.sklik-ads .sklik-ad h2 a .title {
  clear: both;
  font-size: 26px;
}

.sklik-ads .sklik-ad h2 a .dash {
  clear: both;
  font-size: 16px;
  font-weight: 400;
  display: none;
}

.sklik-ads .sklik-ad h2 a .text {
  width: 95%;
  font-size: 16px;
  font-weight: 400;
}

.sklik-ads .sklik-ad h2 a:hover .title {
  text-decoration: underline;
  transition: all .2s;
}

.sklik-ads .sklik-ad h2 a:hover .text {
  color: #1e1e1e;
}

.sklik-ads .sklik-ad:last-of-type h2 {
  margin-bottom: 35px;
}

.sklik-ads a.sklik-mark {
  color: #dcdcdc;
  font-size: 13px;
  font-weight: 400;
}

.sklik-ads:after, .sklik-ads:before {
  content: "";
  clear: both;
  display: table;
}

.sticky-wrapper, .sticky-wrapper-polyfill {
  margin-bottom: 20px;
  padding: 0 15px;
  display: block;
  position: relative;
}

.sticky-wrapper.sticky-bottom, .sticky-wrapper-polyfill.sticky-bottom {
  display: block;
}

.sticky-wrapper .fixed, .sticky-wrapper-polyfill .fixed {
  position: fixed;
  top: 80px;
}

.sticky-wrapper .sticky-content.polyfill-sticky, .sticky-wrapper-polyfill .sticky-content.polyfill-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
}

.superlife .sticky-wrapper, .superlife .sticky-wrapper-polyfill {
  padding: 0;
}

.sticky-wrapper-polyfill {
  display: block;
}

.widesquare {
  margin: 20px 0;
}

.main-article .article-ad-wrapper {
  text-align: left;
  background: #fff;
  width: 555px;
  margin: 0 0 30px 100px;
  padding: 20px 0;
}

.article-ad-wrapper {
  display: inline;
}

@media screen and (max-width: 555px) {
  .article-ad-wrapper {
    width: 100%;
  }
}

.bg-color-dark .ads-leader-board {
  margin-bottom: 20px;
}

.ads {
  margin-bottom: 40px;
}

.promo-bar ul.list-links.simple li {
  font-size: 13px;
}

.promo-bar .promo-wrapp {
  float: right;
  margin-right: 23px;
}

.promo-bar .promo-wrapp:hover {
  text-decoration: none;
}

.promo-bar .promo-wrapp:hover .promo-title {
  text-decoration: underline;
}

.promo-bar .promo-wrapp .promo-img {
  vertical-align: middle;
  width: 152px;
  height: 28px;
  margin-top: -5px;
  display: inline-block;
}

.promo-bar .promo-wrapp .promo-title {
  margin-left: 15px;
  font-weight: 700;
}

.promo-bar .promo-wrapp .promo-title:hover {
  text-decoration: underline;
}

.cpex, .adform-adbox {
  margin-bottom: 20px;
}

.banner-verticals {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.banner-verticals .half {
  cursor: pointer;
  width: 50%;
  height: 100%;
  position: absolute;
  left: 0;
}

.banner-verticals .half.right-half {
  left: auto;
  right: 0;
}

.predplatne {
  width: 1050px;
  margin: 20px auto;
}

.mimibazar-block.customizable {
  border-top-color: #d21e1e;
}

.mimibazar-block.customizable h2 {
  color: #d21e1e;
  font-family: roboto, arial, sans-serif;
}

.mimibazar-block.customizable .item .title {
  color: #1e1e1e;
}

.mimibazar-block.customizable .item .price {
  color: #828282;
}

.mimibazar-block.customizable .item .more {
  color: #d21e1e;
  border-bottom-color: #d21e1e;
}

.mimibazar-block.customizable .item .more:before, .mimibazar-block.customizable .item .more:after {
  background-color: #d21e1e;
}

.mimibazar-block.customizable .item .more:hover {
  color: #828282;
}

.mimibazar-block.customizable .item .more:hover:before, .mimibazar-block.customizable .item .more:hover:after {
  background-color: #828282;
}

.list-article.list-article-normal.idvert-wrapper {
  display: none;
}

.button-secondary, .main-button, .button-main {
  text-align: center;
  border: 0;
  border-radius: 2px;
  width: 240px;
  padding: 10px 0;
  font-weight: bolder;
  line-height: 20px;
  transition: all .5s;
  display: block;
  position: relative;
}

.button-secondary:disabled, .main-button:disabled, .button-main:disabled {
  color: #aaa;
  background: #dcdcdc;
}

.button-secondary:focus, .main-button:focus, .button-main:focus, .button-secondary:active, .main-button:active, .button-main:active, .button-secondary:hover, .main-button:hover, .button-main:hover {
  color: #fff;
  outline: 0;
}

.button-secondary .info, .main-button .info, .button-main .info {
  float: right;
  background: #fff;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  height: 40px;
  margin: -10px -10px -10px 10px;
  padding: 0 10px;
  line-height: 40px;
  transition: all .5s;
  display: block;
  position: relative;
}

.main-button, .button-main {
  color: #fff;
  background: #d21e1e;
}

.main-button:hover:enabled, .main-button:focus, .button-main:hover:enabled, .button-main:focus {
  color: #fff;
  background: #791111;
  text-decoration: none;
}

.main-button.half-divided, .button-main.half-divided {
  float: left;
  margin: 0 45px;
}

.main-button.half-divided.button-video .dataimg-play, .button-main.half-divided.button-video .dataimg-play {
  float: right;
  width: 20px;
  display: block;
  position: relative;
  top: 0;
  right: 20px;
}

.button-secondary {
  color: #fff;
  background: #aaa;
}

.button-secondary:hover:enabled {
  color: #fff;
  background: #828282;
  text-decoration: none;
}

.button-with-icon {
  background-color: #d21e1e;
  width: auto;
  padding: 10px;
  display: inline-block;
}

.button-with-icon .icon {
  float: left;
  width: 20px;
  height: 20px;
  position: relative;
}

.button-with-icon .icon path {
  fill: #fff;
}

.button-with-icon .label {
  margin-left: 10px;
}

.button-facebook {
  color: #fff;
  background-color: #3b5998;
}

.button-facebook .info {
  color: #3b5998;
  border: 2px solid #3b5998;
}

.button-facebook:hover, .button-facebook:hover:enabled {
  background-color: #3b5998;
  border-color: #3b5998;
}

.button-facebook:hover .info {
  color: #3b5998;
  border-color: #3b5998;
}

.button-twitter {
  background-color: #50abf1;
}

.button-twitter .icon {
  top: 2px;
}

.button-twitter .info {
  color: #50abf1;
  border: 2px solid #50abf1;
}

.button-twitter:hover, .button-twitter:hover:enabled {
  background-color: #50abf1;
}

.button-twitter:hover .info {
  color: #50abf1;
  border-color: #50abf1;
}

.button-with-info {
  text-align: left;
  min-width: 240px;
}

.button-menu span {
  background: #d21e1e;
  border-radius: 2px;
  padding: 5px;
  display: inline;
}

.button-small {
  color: #fff;
  letter-spacing: .1em;
  text-transform: uppercase;
  text-align: center;
  background: #d21e1e;
  border-radius: 2px;
  padding: 5px;
  font-size: 9px;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
}

.button-small.button-gray {
  background: #aaa;
}

.fb-comments {
  background: #fff;
  margin: 0 0 30px;
  padding: 20px;
  overflow: hidden;
}

.fb-comments iframe {
  min-height: 290px;
  width: 100% !important;
}

.facebook-like-container {
  color: #dcdcdc;
  background: #f0f0f0;
  border-radius: 2px;
  min-width: 149px;
  height: 40px;
  padding: 10px;
  display: inline-block;
  position: relative;
  top: 1px;
}

.facebook-like-container .fb-like {
  display: inline;
}

.facebook-like-container iframe {
  z-index: 100;
}

@media screen and (max-width: 760px) {
  .fb-comments {
    padding: 0;
  }

  .fb-comments iframe {
    min-height: 90px;
  }
}

.icon {
  display: inline-block;
}

.icon--play, .icon--arrow-right {
  border: 4.8px solid rgba(0, 0, 0, 0);
  border-left: 6px solid #fff;
  border-right-width: 0;
  width: 0;
  height: 0;
  margin: 0 0 0 5px;
}

header.header-main {
  position: relative;
}

.title-section {
  justify-content: space-between;
  width: 100%;
  margin: 0;
  display: flex;
  position: relative;
}

.title-section.border-top {
  border-top-style: solid;
  border-top-width: 2px;
  padding-top: 5px;
}

.title-section.border-no {
  border-bottom-width: 0;
}

.title-section.border-bottom {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  width: auto;
  margin: 7px 0 5px;
  padding-bottom: 2px;
}

.title-section.in-box {
  padding-left: 15px;
  padding-right: 15px;
}

.title-section.color-inverze {
  color: #fff;
}

.title-section .main-title {
  float: left;
  margin: 5px 0;
  font-family: Roboto, arial, sans-serif;
  font-size: 22px;
  font-weight: 700;
}

.title-section .main-title.search-title {
  float: none;
  display: inline-block;
}

.title-section .main-title--flex {
  flex-basis: 100%;
  justify-content: space-between;
  display: flex;
}

.title-section .sub-title {
  float: right;
  color: #aaa;
  margin-top: 13px;
  font-family: Roboto, arial, sans-serif;
  font-size: 16px;
}

.title-section .sub-link {
  margin-top: 9px;
  display: block;
}

.title-section .sub-link span {
  float: right;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
}

.title-section .sub-link .logout-user {
  text-transform: lowercase;
  font-weight: 400;
}

.title-section .sub-link:hover, .title-section .sub-link:hover span {
  text-decoration: underline;
}

.title-section .logged-user {
  float: right;
  font-size: 13px;
}

.title-section .list-links {
  float: right;
  margin: 12px 0 0;
  font-family: Roboto, arial, sans-serif;
}

.title-section.sport-color-bg .main-title {
  color: #fff;
  height: 40px;
  margin: 10px 0;
  padding: 7px 10px;
}

.title-section.sport-color-bg .main-title a {
  color: #fff;
}

.title-section.box-title .main-title {
  text-transform: uppercase;
  float: none;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Roboto, arial, sans-serif;
  font-size: 13px;
}

.title-section .icon {
  float: right;
  width: 5px;
  height: 9px;
  margin-top: -5px;
  margin-left: 3px;
  padding-top: 3px;
}

.title-section .icon-section {
  background-image: none;
  width: 25px;
  height: 25px;
  margin-top: -8px;
  margin-right: 10px;
  display: inline-block;
  position: relative;
  top: 5px;
}

.title-section .dropdown-menu {
  float: right;
}

.title-section .dropdown-menu > ul {
  margin: 5px 0 0;
}

.title-section .dropdown-menu > ul li.dropdown-title {
  height: auto;
}

.title-section .dropdown-menu > ul li.dropdown-title:hover {
  background: #fff;
}

.title-section .dropdown-menu > ul li.dropdown-title > a {
  padding: 10px 30px 10px 10px;
}

.title-section .dropdown-menu > ul li.dropdown-title > a:after {
  border: 0;
}

.title-section .dropdown-menu > ul li.dropdown-title .dataimg-arrow-down {
  top: 14px;
}

.title-section .dropdown-menu > ul li.dropdown-title ul {
  right: 0;
}

.title-section .dropdown-menu.half-items .dropdown-title ul li {
  float: left;
  width: 50%;
}

.title-all-seasons .title-section .dropdown-menu > ul li.dropdown-title > a {
  color: #1e1e1e;
}

.title-all-seasons:after, .title-all-seasons:before {
  content: "";
  clear: both;
  display: table;
}

@media screen and (max-width: 760px) {
  .title-section .main-title {
    margin-top: 13px;
    font-size: 1em;
  }

  .title-section .icon-section {
    width: 20px;
    height: 20px;
    top: 3px;
  }

  .title-section.box-title .main-title {
    font-size: .8em;
  }

  .title-section .list-links {
    float: none;
    clear: both;
    font-family: Roboto, arial, sans-serif;
  }

  .title-section .list-links li {
    border-bottom: 1px solid #bebebe;
    margin: 0;
    display: block;
  }

  .title-section .list-links li:first-of-type {
    border-top: 1px solid #bebebe;
  }

  .title-section .list-links li a {
    padding: 8px;
    display: block;
  }
}

.results__league-title-link {
  align-items: center;
  display: flex;
}

.results__league-title-link .icon-section {
  width: 20px;
  height: 20px;
  margin: 0 7px 0 0;
  display: block;
  position: relative;
  top: 50%;
}

.results__league-title-icon svg {
  width: 18px;
  height: 18px;
}

article.list-article {
  clear: both;
  color: #1e1e1e;
  background: #fff;
  width: 100%;
  margin-bottom: 30px;
  position: relative;
}

article.list-article:hover > .title-container h2 a {
  color: #d21e1e;
  box-shadow: none;
  font-weight: 700;
  text-decoration: underline;
}

article.list-article > .image-container {
  display: block;
  position: relative;
}

article.list-article > .image-container img {
  width: 100%;
  height: auto;
}

article.list-article > .image-container .flag {
  z-index: 2;
  width: 50px;
  height: auto;
  display: block;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

article.list-article > .image-container .flag.dataimg-play, article.list-article > .image-container .flag .circle {
  opacity: .6;
  transition: opacity .2s ease-in-out;
}

article.list-article > .image-container .logo-blesk {
  width: 130px;
  height: 40px;
  position: absolute;
  bottom: 0;
  right: 0;
}

article.list-article > .image-container .logo-blesk .logo-bg {
  opacity: .8;
}

article.list-article > .image-container .logo-blesk .dataimg-logo-blesk {
  width: 100%;
  height: 100%;
}

article.list-article > .image-container:hover .flag.dataimg-play, article.list-article > .image-container:hover .flag .circle {
  opacity: .8;
}

article.list-article > .title-container h2 {
  margin-bottom: 0;
}

article.list-article > .title-container h2 a {
  color: #1e1e1e;
}

article.list-article > .title-container h2 a:hover {
  color: #d21e1e;
}

article.list-article .meta .datetime {
  color: #aaa;
  margin-left: auto;
  font-size: 13px;
}

article.list-article .meta .category-name {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
}

article.list-article .meta:after, article.list-article .meta:before, article.list-article:after, article.list-article:before {
  content: "";
  clear: both;
  display: table;
}

.list-article__header {
  display: flex;
}

.inverted article.list-article {
  background: #d21e1e;
}

.inverted article.list-article > .title-container h2 a {
  color: #fff;
  text-shadow: 0 -2px #d21e1e, 0 -1px #d21e1e, 0 0 #d21e1e, 2px -2px #d21e1e, 2px -1px #d21e1e, 2px 0 #d21e1e, -2px -2px #d21e1e, -2px -1px #d21e1e, -2px 0 #d21e1e, 1px -2px #d21e1e, 1px -1px #d21e1e, 1px 0 #d21e1e, -1px -2px #d21e1e, -1px -1px #d21e1e, -1px 0 #d21e1e, 0 -2px #d21e1e, 0 -1px #d21e1e, 0 0 #d21e1e;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -4px #d21e1e, inset 0 -6px rgba(0, 0, 0, 0);
}

.inverted article.list-article > .title-container h2 a::selection {
  color: #d21e1e;
  text-shadow: none;
  background: #fff;
}

.inverted article.list-article > .title-container h2 a:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -4px #d21e1e, inset 0 -6px #fff;
}

.inverted article.list-article .meta .datetime, .inverted article.list-article .meta .category-name a {
  color: #fff;
}

@media screen and (max-width: 760px) {
  article.list-article {
    margin-bottom: 20px;
  }

  article.list-article > .title-container {
    margin-top: 5px;
  }

  article.list-article .meta .datetime {
    display: none;
  }
}

article.list-article.list-article-normal .image-container {
  float: left;
  width: 47.83%;
  max-height: 180px;
  position: relative;
  overflow: hidden;
}

article.list-article.list-article-normal .image-container .flag {
  width: 50px;
  height: 50px;
}

article.list-article.list-article-normal .title-container {
  float: right;
  width: 47.83%;
  margin: 10px 1.5% 0 0;
}

article.list-article.list-article-normal .title-container h2 {
  font-size: 26px;
  line-height: 30px;
}

article.list-article.list-article-normal .title-container.no-image {
  float: none;
  width: 100%;
  margin: 0;
  padding: 10px;
}

article.list-article.list-article-normal .meta {
  margin-bottom: 2px;
  display: flex;
}

@media screen and (max-width: 760px) {
  article.list-article.list-article-normal {
    padding-bottom: 8px;
    position: relative;
    overflow: hidden;
  }

  article.list-article.list-article-normal > .image-container {
    width: 40%;
    margin-top: 10px;
  }

  article.list-article.list-article-normal > .title-container {
    width: 55%;
  }

  article.list-article.list-article-normal > .title-container h2 {
    font-size: 1.3em;
    line-height: 1.3;
  }
}

@media screen and (max-width: 450px) {
  article.list-article.list-article-normal > .image-container .flag {
    width: 40px;
    height: 40px;
  }

  article.list-article.list-article-normal > .title-container h2 {
    font-size: 1em;
  }
}

article.list-article.list-article-vertical .meta {
  margin: 0 0 0 auto;
}

article.list-article.list-article-vertical .meta:empty {
  display: none;
}

article.list-article.list-article-vertical .datetime {
  padding: 10px 15px;
}

article.list-article.list-article-vertical > .image-container {
  width: 100%;
  max-height: none;
  position: relative;
  overflow: hidden;
}

article.list-article.list-article-vertical > .image-container a.image {
  display: block;
}

article.list-article.list-article-vertical > .image-container a.image .flag {
  width: 70px;
  height: 70px;
}

article.list-article.list-article-vertical > .title-container {
  margin: 10px 20px 15px;
}

article.list-article.list-article-vertical > .title-container h2 {
  width: 100%;
  font-size: 35px;
  line-height: 1.3;
}

article.list-article.list-article-vertical > .title-container.no-image {
  width: 100%;
  margin: 0;
  padding: 10px;
}

@media screen and (max-width: 760px) {
  article.list-article.list-article-vertical > .meta {
    margin: 5px 10px;
  }

  article.list-article.list-article-vertical > .title-container {
    margin: 10px;
  }

  article.list-article.list-article-vertical > .title-container h2 {
    font-size: 1.6em;
  }

  article.list-article.list-article-vertical > .image-container a.image {
    display: block;
  }

  article.list-article.list-article-vertical > .image-container a.image .flag {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 450px) {
  article.list-article.list-article-vertical > .title-container h2 {
    font-size: 1em;
  }
}

article.list-article.list-article-vertical-half.isport-premium-true .meta .category-name {
  float: right;
  margin: 10px 15px;
}

article.list-article.list-article-vertical-half.isport-premium-true .meta .isport-premium {
  top: 38px;
}

article.list-article.list-article-vertical-half .image-container {
  width: 100%;
  max-height: none;
}

article.list-article.list-article-vertical-half .image-container .flag {
  width: 50px;
  height: 50px;
}

article.list-article.list-article-vertical-half .title-container {
  margin: 15px 20px 20px;
}

article.list-article.list-article-vertical-half .title-container h2 {
  width: 100%;
  font-size: 22px;
  line-height: 1.3;
}

article.list-article.list-article-vertical-half .title-container.no-image {
  width: 100%;
  margin: 0;
  padding: 10px;
}

article.list-article.list-article-vertical-half .meta {
  align-items: baseline;
  height: 38px;
  margin: 0;
  display: flex;
}

article.list-article.list-article-vertical-half .meta:empty {
  display: none;
}

@media (max-width: 760px) {
  article.list-article.list-article-vertical-half .meta {
    height: auto;
  }
}

article.list-article.list-article-vertical-half .meta .datetime {
  padding: 0 10px;
}

@media screen and (max-width: 760px) {
  article.list-article.list-article-vertical-half > .title-container {
    margin: 10px;
  }

  article.list-article.list-article-vertical-half > .title-container h2 {
    font-size: 1.1em;
  }
}

article.list-article-horizontal-small {
  border-bottom: 1px solid #dcdcdc;
  width: calc(100% - 30px);
  margin: 0 15px;
  padding: 15px 0;
  position: relative;
}

article.list-article-horizontal-small:first-of-type {
  padding-top: 0;
}

article.list-article-horizontal-small:last-of-type {
  margin-bottom: 20px;
}

article.list-article-horizontal-small .image-container {
  float: left;
  width: 49%;
  min-width: 150px;
  max-height: 180px;
  overflow: hidden;
}

article.list-article-horizontal-small .image-container .isport-premium.small {
  width: 73px;
  height: 20px;
  padding: 4px 0 0;
}

article.list-article-horizontal-small .image-container .flag {
  width: 40px;
  height: 40px;
  left: 50%;
  transform: translateX(-50%);
}

article.list-article-horizontal-small .title-container {
  float: right;
  width: 46.5%;
  margin: 0;
  position: relative;
}

article.list-article-horizontal-small .title-container h2 {
  padding-bottom: 0;
  font-family: Roboto, arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.3em;
}

article.list-article-horizontal-small .title-container h2 a {
  color: #1e1e1e;
}

article.list-article-horizontal-small:after, article.list-article-horizontal-small:before {
  content: "";
  clear: both;
  display: table;
}

article.list-article-blog {
  color: #1e1e1e;
  background: #fff;
  border-bottom: 2px solid #f0f0f0;
  width: 100%;
  min-height: 246px;
  margin-bottom: 20px;
  position: relative;
}

article.list-article-blog:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

article.list-article-blog .image-container {
  width: 33%;
  height: 100%;
  display: block;
  position: absolute;
  overflow: hidden;
}

article.list-article-blog .image-container picture {
  position: absolute;
  bottom: 0;
  left: 0;
}

article.list-article-blog .image-container picture img {
  width: 1450px;
  height: auto;
}

article.list-article-blog .title-container {
  float: left;
  width: 65%;
  margin-top: 5%;
  margin-left: 35%;
}

article.list-article-blog .title-container .author-container .author-name {
  color: #d21e1e;
  float: left;
  text-transform: uppercase;
  margin-right: 5%;
  font-size: 13px;
  font-weight: bold;
}

article.list-article-blog .title-container .author-container .author-name a, article.list-article-blog .title-container .author-container .author-name a:hover {
  color: #d21e1e;
}

article.list-article-blog .title-container .author-container .datetime {
  float: left;
  color: #aaa;
  font-size: 13px;
  line-height: 15px;
}

article.list-article-blog .title-container .author-container:after, article.list-article-blog .title-container .author-container:before {
  content: "";
  clear: both;
  display: table;
}

article.list-article-blog .title-container h2 {
  margin: 2% 5% 2% 0;
  font-size: 26px;
}

article.list-article-blog .title-container h2 a {
  color: #1e1e1e;
}

article.list-article-blog .title-container h2 a:hover {
  color: #d21e1e;
}

article.list-article-blog .title-container .forum-container .dataimg-comments {
  float: left;
  width: 14px;
  height: 12px;
  margin-right: 10px;
}

article.list-article-blog .title-container .forum-container .comments {
  color: #aaa;
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 12px;
}

article.list-article-blog:after, article.list-article-blog:before {
  content: "";
  clear: both;
  display: table;
}

article.list-article-blog-small {
  color: #1e1e1e;
  background: #fff;
  border-bottom: 2px solid #f0f0f0;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}

article.list-article-blog-small:last-child {
  border-bottom: 0;
  margin-top: 16px;
  margin-bottom: 0;
}

article.list-article-blog-small .image-container {
  width: 25%;
  height: 100%;
  display: block;
  position: absolute;
}

article.list-article-blog-small .image-container picture {
  position: absolute;
  bottom: 0;
  left: 0;
}

article.list-article-blog-small .image-container picture img {
  width: auto;
  max-height: 100%;
}

article.list-article-blog-small .title-container {
  float: left;
  width: 65%;
  margin-top: 10px;
  margin-left: 35%;
}

article.list-article-blog-small .title-container .author-container .author-name {
  color: #d21e1e;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
}

article.list-article-blog-small .title-container .author-container .author-name a, article.list-article-blog-small .title-container .author-container .author-name a:hover {
  color: #d21e1e;
}

article.list-article-blog-small .title-container h2 {
  margin: 10px 5px 5px 0;
  font-family: Roboto, arial, sans-serif;
  font-size: 13px;
  font-weight: 700;
}

article.list-article-blog-small .title-container h2 a {
  color: #1e1e1e;
}

article.list-article-blog-small .title-container h2 a:hover {
  color: #d21e1e;
}

article.list-article-blog-small:after, article.list-article-blog-small:before {
  content: "";
  clear: both;
  display: table;
}

@media screen and (max-width: 760px) {
  article.list-article-blog-small {
    min-height: 71px;
  }

  article.list-article-blog-small .image-container img {
    height: 70px;
  }
}

.mobile article.list-article-blog-small .image-container img {
  height: 70px;
}

.list-score {
  color: #1e1e1e;
  border-bottom: 1px solid #dcdcdc;
  width: 100%;
  position: relative;
}

.list-score .datetime-container {
  border-right: 1px solid #dcdcdc;
  width: 20%;
  padding: 7px;
  font-size: 13px;
  font-weight: 700;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.list-score .datetime-container .flag {
  position: absolute;
  top: 10px;
  right: 10px;
}

.list-score .datetime-container--show {
  display: block;
}

.list-score .league-name, .list-score .phase-name {
  text-align: center;
  text-transform: uppercase;
  color: #828282;
  font-size: 9px;
  font-weight: 700;
  position: absolute;
  top: 9px;
  left: 140px;
}

.list-score .match-container .match-bet-cover a:hover {
  text-decoration: none;
}

.list-score .match-container .match-bet-cover a img.bets {
  width: 50px;
  height: auto;
  margin: 2px auto;
  display: block;
}

.list-score .match-container .match-bet-cover.with-match-online {
  width: calc(50% + 27px);
  margin-left: calc(50% - 27px);
}

.list-score .match-container .match-bet-cover.with-match-online a img.bets {
  margin: 2px 6px 4px 0;
}

.list-score .match-container .match-bet-cover.with-match-online .icone-tv-tipsport {
  width: 18px;
  height: 13px;
  margin: 0 6px;
  display: inline-block;
}

.list-score .match-container .match-bet-cover.with-match-online .icone-tv-tipsport img {
  width: 100%;
  height: 100%;
}

.list-score .match-container .match-bet-cover.with-match-online .match-online {
  width: 222px;
  margin-top: 0;
  font-weight: normal;
}

.list-score .match-container:after, .list-score .match-container:before {
  content: "";
  clear: both;
  display: table;
}

.list-score .team-container {
  float: left;
  width: 44%;
}

.list-score .team-container .team-name {
  width: 80%;
  margin-top: 21px;
  font-size: 22px;
  display: block;
  position: relative;
}

.list-score .team-container .logo-container {
  text-align: center;
  width: 20%;
  margin-top: 6px;
}

.list-score .team-container .logo-container__lazy {
  width: 24px;
  height: 24px;
}

.list-score .team-container .logo-container__lazy picture {
  border-radius: 25px;
  margin: 2px 0 0;
}

.list-score .team-container .logo-container img, .list-score .team-container .logo-container svg {
  max-width: 100%;
  height: auto;
}

.list-score .team-container.team-home .logo-container {
  float: left;
}

.list-score .team-container.team-home .team-name {
  float: left;
  padding-left: 5px;
}

.list-score .team-container.team-away .logo-container {
  float: right;
}

.list-score .team-container.team-away .team-name {
  float: right;
  text-align: right;
  padding-right: 5px;
}

.list-score .score-container {
  float: left;
  text-align: center;
  width: 12%;
}

.list-score .score-container .score {
  margin-top: 7px;
  font-size: 30px;
  font-weight: 300;
}

.list-score .score-container .score span {
  font-size: 22px;
  position: relative;
  top: -4px;
}

.list-score .score-container .score .score-pen {
  letter-spacing: 1px;
  font-size: 9px;
}

.list-score .score-container .score .score-pen-ot, .list-score .score-container .score .score-pen-sn {
  height: 20px;
  line-height: 1;
  display: inline-block;
  position: relative;
}

.list-score .score-container .score .score-pen-ot:before, .list-score .score-container .score .score-pen-sn:before {
  z-index: 300;
  letter-spacing: 1px;
  margin-left: 2px;
  font-size: 9px;
  display: inline-block;
  position: absolute;
  bottom: 6px;
}

.list-score .score-container .score .score-pen-ot:before {
  content: "P";
}

.list-score .score-container .score .score-pen-sn:before {
  content: "SN";
}

.list-score .score-container .date-container {
  margin-top: 16px;
  font-size: 13px;
  font-weight: 700;
}

.list-score .score-container .play-container {
  width: 60px;
  height: 16px;
  margin: auto;
}

.list-score .score-container .play-container .dataimg-play {
  float: left;
  width: 15px;
  height: 15px;
  margin-top: 1px;
}

.list-score .score-container .play-container .flag-video {
  color: #d21e1e;
  float: left;
  padding-left: 2px;
  font-size: 13px;
  font-weight: 700;
}

.list-score .score-container .flag-online {
  color: #d21e1e;
  font-size: 13px;
  font-weight: 700;
}

.list-score .score-container .flag-paid {
  color: #d21e1e;
  font-size: 9px;
  font-weight: 700;
  display: none;
  position: absolute;
  bottom: 0;
}

.list-score .score-container .periods, .list-score .score-container .bets {
  font-size: 13px;
}

.list-score .score-container .series-score {
  margin-top: 5px;
  font-size: 13px;
}

.list-score .series-score-container {
  text-align: center;
  width: 100%;
  margin-bottom: 3px;
}

.list-score .series-score-container .series-score {
  font-size: 12px;
}

.list-score .series-score-container:after, .list-score .series-score-container:before {
  content: "";
  clear: both;
  display: table;
}

.list-score.status-fin .score-container .date-container, .list-score.status-fin .score-container .flag-online, .list-score.status-fin .score-container .flag-paid {
  display: none;
}

.list-score.status-fin .score-container .score {
  margin-top: 16px;
}

.list-score.status-sched .score-container .score, .list-score.status-sched .score-container .flag-online {
  display: none;
}

.list-score.status-sched .link--online .icon .dataimg-play {
  display: block;
}

.list-score.status-sched .link--online .icon .dataimg-play svg {
  height: 16px;
}

.list-score.status-live .score-container .date-container {
  display: none;
}

.list-score.status-live .score-container .score {
  color: #d21e1e;
}

.list-score.dark {
  border-color: #646464;
}

.list-score.dark .team-container .team-name, .list-score.dark .score-container {
  color: #fff;
}

.list-score.formule1 .datetime-container {
  width: 10%;
}

.list-score.formule1 .datetime-container:last-of-type {
  left: inherit;
  border-left: 1px solid #dcdcdc;
  width: 10%;
  right: 0;
}

.list-score .match-online {
  width: 232px;
  font-size: 13px;
  font-weight: bold;
}

.list-score .link--online {
  color: #d21e1e;
  margin-top: 20px;
  padding: 5px 7px;
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  display: inline-block;
}

.list-score .link--online .link--left {
  z-index: 300;
  position: relative;
}

.list-score .link--online.link--right {
  margin-top: 0;
}

.list-score .link--online .icon .dataimg-play, .list-score .link--online .icon .dataimg-arrow-right {
  float: right;
  margin-left: 3px;
}

.list-score .link--online .icon .dataimg-arrow-right {
  margin-top: 3px;
}

.list-score .link--online .icon .dataimg-arrow-right svg {
  height: 10px;
}

.list-score .link--online .icon .dataimg-arrow-right path {
  fill: #d21e1e;
}

.list-score .link--online.link--red {
  color: #fff;
  text-align: center;
  background: #d21e1e;
  border-radius: 2px;
  justify-content: space-around;
  align-items: center;
  margin-top: 17px;
  margin-right: 6px;
  padding: 0 7px;
  font-size: 13px;
  font-weight: normal;
  line-height: 15px;
  text-decoration: none;
  display: flex;
}

.list-score .link--online.link--red .icon .dataimg-icon-play {
  float: right;
  width: 9px;
  height: 9px;
  margin-top: 1px;
}

@media only screen and (max-width: 376px) {
  .list-score .link--online.link--red .icon .dataimg-icon-play {
    width: 7px;
    height: 7px;
    margin-top: 3px;
    margin-left: 3px;
    line-height: 12px;
  }
}

@media only screen and (min-width: 425px) {
  .list-score .link--online.link--red {
    width: 70px;
    height: 27px;
  }
}

@media only screen and (max-width: 425px) {
  .list-score .link--online.link--red {
    margin-top: 20px;
    margin-right: 6px;
  }
}

@media only screen and (max-width: 321px) {
  .list-score .link--online.link--red {
    margin-top: 4px;
    margin-right: 3px;
  }
}

.list-score .link--online.link--red-small {
  color: #fff;
  text-align: center;
  background: #d21e1e;
  border-radius: 2px;
  justify-content: space-around;
  align-items: center;
  width: 60px;
  height: 20px;
  margin-top: 3px;
  margin-right: 6px;
  padding: 0 5px;
  font-size: 12px;
  font-weight: normal;
  text-decoration: none;
  display: flex;
}

.list-score .link--online.link--red-small .icon .dataimg-icon-play {
  float: right;
  width: 9px;
  height: 9px;
  margin-top: 1px;
}

.list-score .link--right, .list-score .link--right-b {
  position: absolute;
  top: 0;
  right: 0;
}

.list-score .link-external {
  cursor: pointer;
}

.list-score:after, .list-score:before {
  content: "";
  clear: both;
  display: table;
}

.list-score-no-link {
  padding-bottom: 10px;
}

.list-score-no-link:last-of-type .list-score {
  border-bottom: 0;
}

a.list-score-link:nth-of-type(2n) .list-score {
  background-color: #f0f0f0;
}

a.list-score-link:nth-of-type(2n) .list-score.dark {
  background-color: #1e1e1e;
}

a.list-score-link .list-score-fortuna {
  background-color: #f0f0f0;
}

a.list-score-link:hover {
  text-decoration: none;
}

a.list-score-link:hover .list-score {
  background-color: #fdf2f2;
  transition: background-color .5s;
}

a.list-score-link:hover .list-score .score-container, a.list-score-link:hover .list-score .series-score-container, a.list-score-link:hover .list-score .date-container .date, a.list-score-link:hover .list-score .date-container .time {
  color: #d21e1e;
  transition: color .2s;
}

a.list-score-link:hover .list-score.dark .score-container {
  color: #791111;
}

a.list-score-link:hover .list-score.dark .score-container .score, a.list-score-link:hover .list-score.dark .date-container .date, a.list-score-link:hover .list-score.dark .date-container .time {
  color: #791111;
  transition: color .2s;
}

a.list-score-link.dark {
  border-color: #646464;
}

a.list-score-link:after, a.list-score-link:before {
  content: "";
  clear: both;
  display: table;
}

a.list-score-link .series-score, a.list-score-link:hover .series-score {
  text-decoration: none !important;
}

.list-score-structured-wapper:nth-of-type(2n) a.list-score-link .list-score, .list-score-structured-wapper:nth-of-type(2n) a.list-score-link:hover .list-score {
  background-color: #f0f0f0;
}

.list-score-structured-wapper:last-of-type a.list-score-link .list-score {
  border-bottom: 1px solid #dcdcdc;
}

.list-score-structured-wapper:first-of-type a.list-score-link:first-of-type .list-score-tennis, .list-score-structured-wapper a.list-score-link .list-score.list-score-middle {
  border-top: 1px solid #dcdcdc;
}

.list-score-structured-wapper a.list-score-link .list-score.list-score-middle .team-container .team-name {
  padding: 0 10px 0 5px;
  font-size: 13px;
}

.list-score-structured-wapper a.list-score-link:first-of-type .list-score-tennis {
  border-top: none;
}

.list-score-structured-wapper a.list-score-link:hover .list-score {
  background: inherit;
}

.list-score-structured-wapper .datetime-container--right-col {
  width: 33%;
}

.list-score-structured-wapper .list-score.list-score-other .event-container--right-col {
  width: 67%;
}

@media screen and (max-width: 760px) {
  .list-score .datetime-container {
    text-align: center;
    border-right: 0;
    width: 100%;
    padding: 7px 0 0;
    font-size: 9px;
    font-weight: 700;
    position: relative;
  }

  .list-score .datetime-container .flag {
    position: inherit;
    right: inherit;
    top: inherit;
    margin: 0 auto 3px;
  }

  .list-score .team-container {
    height: auto;
  }

  .list-score .team-container .team-name {
    width: 80%;
    font-size: 18px;
  }

  .list-score .team-container.team-home .logo-container, .list-score .team-container.team-away .logo-container {
    float: right;
    width: 20%;
  }

  .list-score .match-container .match-bet-cover a:hover {
    text-decoration: none;
  }

  .list-score .match-container .match-bet-cover a img.bets {
    width: 50px;
    height: auto;
    margin: 2px auto;
    display: block;
  }

  .list-score .match-container .match-bet-cover.with-match-online {
    margin: 2px auto 4px;
  }

  .list-score .match-container .match-bet-cover.with-match-online a img.bets {
    margin: 2px auto;
  }

  .mobile .list-score .league-name, .mobile .list-score .phase-name {
    text-align: center;
    position: static;
  }
}

.art-object.match-overview .list-score .match-container .team-container {
  width: 42%;
}

.art-object.match-overview .list-score .match-container .team-container .team-name {
  margin-top: 12px;
  margin-bottom: 12px;
}

.art-object.match-overview .list-score .match-container .team-container .logo-container {
  margin-top: 6px;
}

.art-object.match-overview .list-score .score-container {
  width: 16%;
}

.art-object.match-overview .list-score .score-container .score {
  margin-top: 7px;
  font-size: 26px;
}

.art-object.match-overview .list-score .score-container .date-container {
  margin-top: 12px;
  font-size: 9px;
  font-weight: 700;
}

.four-col .list-score .datetime-container .flag {
  position: relative;
  top: 2px;
  right: 0;
}

.list-score.list-score-small {
  min-height: 30px;
}

.list-score.list-score-small.date-show .datetime-container {
  display: block;
}

.list-score.list-score-small.date-show .match-container {
  float: right;
  width: 80%;
}

.list-score.list-score-small .flag-live {
  color: #d21e1e;
  font-weight: 700;
}

.list-score.list-score-small .team-container {
  width: 42%;
  height: 30px;
}

.list-score.list-score-small .team-container .team-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #1e1e1e;
  margin-top: 6px;
  font-size: 13px;
  font-weight: 700;
  overflow: hidden;
}

.list-score.list-score-small .team-container .team-name.team-looser {
  color: #828282;
}

.list-score.list-score-small .team-container .logo-container {
  margin-top: 0;
}

.list-score.list-score-small .team-container .logo-container img {
  max-width: 30px;
  height: auto;
}

.list-score.list-score-small .team-container .logo-container .dataimg-teamlogo-placeholder {
  max-width: 30px;
  height: auto;
  margin: 0 auto;
}

.list-score.list-score-small .team-container.team-home .team-name {
  text-align: right;
  padding-right: 10px;
}

.list-score.list-score-small .team-container.team-away .team-name {
  text-align: left;
  width: 80%;
  padding-left: 10px;
}

.list-score.list-score-small .play-container {
  width: 15px;
  margin: 0;
  position: absolute;
  top: 5px;
  right: 5px;
}

.list-score.list-score-small .play-container .dataimg-play {
  float: left;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 2px;
  right: 5px;
}

.list-score.list-score-small .play-container .flag-video {
  display: none;
}

.list-score.list-score-small .score-container {
  width: 16%;
}

.list-score.list-score-small .score-container .score {
  margin-top: 5px;
  font-size: 16px;
  font-weight: 400;
}

.list-score.list-score-small .score-container .score span {
  font-size: 13px;
  top: -2px;
}

.list-score.list-score-small .score-container .score .score-pen {
  letter-spacing: 1px;
  font-size: 9px;
}

.list-score.list-score-small .score-container .score .score-pen-ot, .list-score.list-score-small .score-container .score .score-pen-sn {
  height: 20px;
  line-height: 1;
}

.list-score.list-score-small .score-container .score .score-pen-ot:before, .list-score.list-score-small .score-container .score .score-pen-sn:before {
  bottom: 6px;
}

.list-score.list-score-small .score-container .flag-online {
  display: none;
}

.list-score.list-score-small .score-container .date-container {
  margin-top: 9px;
}

.list-score.list-score-small .score-container .date-container .date {
  display: none;
}

.list-score.list-score-small .score-container .date-container .time {
  color: #828282;
  font-size: 9px;
}

.list-score.list-score-small .score-container .bets {
  color: #d21e1e;
  text-transform: uppercase;
  margin: 10px 0 5px;
  font-size: 9px;
  font-weight: 700;
}

.list-score.list-score-small .score-container .bets:hover {
  text-decoration: underline;
}

.list-score.list-score-small .score-container .play-container {
  display: none;
}

.list-score.list-score-small .match-container .match-bet-cover.with-match-online {
  width: 100%;
  margin: 2px auto 6px;
}

.list-score.list-score-small .match-container .match-bet-cover.with-match-online a img.bets {
  margin: 2px auto;
}

.list-score.list-score-small .match-container .match-bet-cover.with-match-online .icone-tv-tipsport {
  width: 18px;
  height: 13px;
  margin: 0 6px;
  display: inline-block;
}

.list-score.list-score-small .match-container .match-bet-cover.with-match-online .icone-tv-tipsport img {
  width: 100%;
  height: 100%;
}

.list-score.list-score-small .match-container .match-bet-cover.with-match-online .match-online {
  width: 222px;
  margin: 4px auto 0;
  font-weight: normal;
}

.list-score.list-score-small.status-fin .flag-live {
  display: none;
}

.list-score.list-score-small.status-fin .team-container, .list-score.list-score-small.status-sched .team-container {
  height: 30px;
}

.list-score.list-score-small.status-sched .flag-live, .list-score.list-score-small.status-sched .dataimg-play {
  display: none;
}

.list-score.list-score-small.status-live .team-container {
  height: 30px;
}

.list-score.list-score-small.status-live .dataimg-play {
  display: none;
}

.list-score.list-score-small:nth-of-type(2n) {
  background-color: #f0f0f0;
}

a.list-score-link:last-of-type .list-score {
  border-bottom: 1px solid #dcdcdc;
}

a.list-score-link:first-of-type .list-score {
  border-top: 1px solid #dcdcdc;
}

.flag-live {
  float: none !important;
}

.bets-text {
  z-index: 1;
  position: absolute;
  top: 50px;
  left: 150px;
}

@media screen and (max-width: 360px) {
  .mobile .list-score .score-container {
    width: 26%;
  }

  .mobile .list-score .team-container {
    width: 37%;
  }
}

@media screen and (max-width: 760px) {
  .list-score.list-score-small.date-show .match-container {
    float: none;
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .mobile .list-score .score-container.score-big .score {
    font-size: 13px;
  }

  .mobile .list-score .score-container.score-big .score span {
    font-size: 9px;
  }
}

.list-score.list-score-middle {
  border-top: 0;
  border-bottom: 0;
  min-height: 65px;
}

.list-score.list-score-middle .datetime-container {
  display: none;
}

.list-score.list-score-middle .phase-name {
  width: 100%;
  top: 5px;
  left: 0;
}

.list-score.list-score-middle .team-container {
  width: 38.5%;
  height: 65px;
}

.list-score.list-score-middle .team-container .logo-container {
  width: 32%;
  margin-top: 13px;
}

.list-score.list-score-middle .team-container .team-name {
  flex-direction: column;
  justify-content: center;
  width: 68%;
  height: 62px;
  margin-top: 0;
  font-size: 16px;
  font-weight: 700;
  display: flex;
}

.list-score.list-score-middle .team-container.team-away .team-name {
  text-align: left;
  padding: 0 0 0 5px;
}

.list-score.list-score-middle .team-container.team-home .team-name {
  text-align: right;
  padding: 0 5px 0 0;
}

.list-score.list-score-middle .score-container {
  width: 23%;
  min-height: 59px;
  position: relative;
}

.list-score.list-score-middle .score-container.score-big .score {
  margin-top: 22px;
  font-size: 16px;
}

.list-score.list-score-middle .score-container.score-big .score span {
  font-size: 16px;
  position: relative;
  top: -1px;
}

.list-score.list-score-middle .score-container .score {
  margin-top: 14px;
}

.list-score.list-score-middle .score-container .score-pen {
  font-size: 9px;
  font-weight: bold;
}

.list-score.list-score-middle .score-container .score-pen-ot:before, .list-score.list-score-middle .score-container .score-pen-sn:before {
  line-height: inherit;
  margin-left: 3px;
  bottom: -5px;
}

.list-score.list-score-middle .score-container .flag {
  font-size: 9px;
  font-weight: 700;
  position: relative;
  top: -43px;
}

.list-score.list-score-middle .score-container .flag-end {
  text-align: center;
  text-transform: uppercase;
  color: #aaa;
  width: 100%;
  position: absolute;
  top: 5px;
}

.list-score.list-score-middle .score-container .flag-paid {
  display: block;
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
}

.list-score.list-score-middle .score-container .date-container .date {
  font-weight: 400;
}

.list-score.list-score-middle.status-fin .score-container .flag-paid {
  display: none;
}

.list-score.list-score-middle .match-container .match-bet-cover.with-match-online {
  width: 100%;
  margin: 2px auto 6px;
}

.list-score.list-score-middle .match-container .match-bet-cover.with-match-online a img.bets {
  margin: 2px auto;
}

.list-score.list-score-middle .match-container .match-bet-cover.with-match-online .icone-tv-tipsport {
  width: 18px;
  height: 13px;
  margin: 0 6px;
  display: inline-block;
}

.list-score.list-score-middle .match-container .match-bet-cover.with-match-online .icone-tv-tipsport img {
  width: 100%;
  height: 100%;
}

.list-score.list-score-middle .match-container .match-bet-cover.with-match-online .match-online {
  width: 222px;
  margin: 4px auto 0;
  font-weight: normal;
}

.football .list-score.list-score-middle .score-container .score .score-pen {
  font-size: 13px;
  font-weight: bold;
}

.football .list-score.list-score-middle .score-container .score .score-pen-ot, .football .list-score.list-score-middle .score-container .score .score-pen-sn {
  height: auto;
  display: block;
}

.football .list-score.list-score-middle .score-container .score .score-pen-ot:before, .football .list-score.list-score-middle .score-container .score .score-pen-sn:before {
  bottom: inherit;
  text-align: center;
  width: 39px;
  margin-left: 0;
  font-size: 13px;
  font-weight: bold;
  left: 0;
}

a.list-score-link .list-score.list-score-middle {
  border-top: 0;
  border-bottom: 0;
}

.synot-content .live-match {
  background-color: rgba(210, 30, 30, .1);
}

.synot-content .link {
  padding-bottom: 15px;
}

.score-middle .list-score.list-score-middle .team-container.team-away .team-name {
  text-align: left;
  padding: 0 82px 0 5px;
}

.list-score.list-score-scorers {
  color: #1e1e1e;
  min-height: 135px;
  padding: 30px 20px 5px;
  position: relative;
}

.list-score.list-score-scorers .team-container {
  width: 34%;
}

.list-score.list-score-scorers .team-container .team-name {
  color: #1e1e1e;
  text-align: center;
  width: 100%;
  margin: 100px 0 0;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.list-score.list-score-scorers .team-container .team-name a {
  text-decoration: underline;
}

.list-score.list-score-scorers .team-container.team-home {
  position: relative;
}

.list-score.list-score-scorers .team-container.team-home .logo-container {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.list-score.list-score-scorers .team-container.team-home .logo-container img, .list-score.list-score-scorers .team-container.team-home .logo-container svg {
  width: 100px;
}

.list-score.list-score-scorers .team-container.team-away {
  position: relative;
}

.list-score.list-score-scorers .team-container.team-away .logo-container {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.list-score.list-score-scorers .team-container.team-away .logo-container img, .list-score.list-score-scorers .team-container.team-away .logo-container svg {
  width: 100px;
}

.list-score.list-score-scorers .score-container {
  width: 32%;
}

.list-score.list-score-scorers .score-container .date-container {
  font-size: 16px;
}

.list-score.list-score-scorers .score-container.score-big .score {
  margin-top: 20px;
  font-size: 35px;
}

.list-score.list-score-scorers .score-container.score-big .score span {
  font-size: 26px;
}

.list-score.list-score-scorers .score-container .score {
  color: #1e1e1e;
  font-size: 50px;
  font-weight: 300;
}

.list-score.list-score-scorers .score-container .score span {
  font-size: 50px;
}

.list-score.list-score-scorers .score-container .score .score-pen {
  font-size: 16px;
  font-weight: bold;
}

.list-score.list-score-scorers .score-container .score .score-pen-ot, .list-score.list-score-scorers .score-container .score .score-pen-sn {
  height: 50px;
}

.list-score.list-score-scorers .score-container .score .score-pen-ot:before, .list-score.list-score-scorers .score-container .score .score-pen-sn:before {
  line-height: inherit;
  margin-left: 3px;
  bottom: 2px;
}

.list-score.list-score-scorers .score-container .score .score-pen-ot:before {
  content: "P";
}

.list-score.list-score-scorers .score-container .score .score-pen-sn:before {
  content: "SN";
}

.list-score.list-score-scorers .match-container .match-bet-cover.with-match-online {
  width: 100%;
  margin: 2px auto 6px;
}

.list-score.list-score-scorers .match-container .match-bet-cover.with-match-online a img.bets {
  margin: 2px auto;
}

.list-score.list-score-scorers .match-container .match-bet-cover.with-match-online .icone-tv-tipsport {
  width: 18px;
  height: 13px;
  margin: 0 6px;
  display: inline-block;
}

.list-score.list-score-scorers .match-container .match-bet-cover.with-match-online .icone-tv-tipsport img {
  width: 100%;
  height: 100%;
}

.list-score.list-score-scorers .match-container .match-bet-cover.with-match-online .match-online {
  width: 222px;
  margin: 100px auto 0;
  font-weight: normal;
}

.list-score.list-score-scorers .scorers {
  color: #aaa;
  margin: 130px 0 0;
  padding: 0;
  font-size: 13px;
  line-height: 20px;
  list-style: none;
  overflow: hidden;
  box-sizing: content-box !important;
}

.list-score.list-score-scorers .full-report {
  text-align: center;
  width: 100%;
  font-size: 13px;
  text-decoration: underline;
  position: absolute;
  bottom: 20px;
  left: 0;
}

.list-score.list-score-scorers .full-report a {
  color: #fff;
}

.list-score.list-score-scorers.dark {
  color: #fff;
  background-color: #1e1e1e;
  border-color: #646464;
}

.list-score.list-score-scorers.dark .team-container .team-name, .list-score.list-score-scorers.dark .score-container .score {
  color: #fff;
}

.table-scorers-overflow {
  max-height: 500px;
  overflow-y: auto;
}

@media screen and (max-width: 760px) {
  .mobile .league-name h1, .mobile .league-name h2 {
    font-family: Roboto, arial, sans-serif;
    font-size: 13px;
  }
}

@media screen and (max-width: 450px) {
  .mobile .list-score.list-score-scorers .team-container .team-name {
    line-height: 15px;
  }

  .mobile .list-score.list-score-scorers .team-container .team-name > a {
    text-decoration: underline;
  }

  .mobile .list-score .score-container.score-big .score {
    font-size: 26px;
  }

  .mobile .list-score .score-container.score-big .score span {
    font-size: 22px;
  }
}

.mobile .match-stats-wrapper {
  background: #fff;
  padding: 10px;
}

.mobile .match-stats {
  margin-bottom: 20px;
}

.mobile .match-stats .section-title {
  color: #00a442;
  border-bottom: 2px solid #00a442;
  margin: 0 0 10px;
  padding: 10px 0 5px;
  font-weight: bold;
}

.mobile .match-stats .home-kit, .mobile .match-stats .away-kit {
  display: none;
}

.mobile .match-stats .stats {
  float: left;
  width: 100%;
  min-height: 300px;
}

.mobile .match-stats .stats .stats-item {
  width: 100%;
  height: 20px;
  margin: 5px 0;
  padding: 5px 0;
  font-weight: bold;
}

.mobile .match-stats .stats .stats-item.suda {
  background-color: #e6e6e6;
}

.mobile .match-stats .stats .stats-item .bar {
  float: left;
  width: 30%;
}

.mobile .match-stats .stats .stats-item .bar .inner-bar {
  text-align: left;
  line-height: 20px;
  font-family: 700;
  color: #828282;
  background-color: #828282;
  width: 1px;
  max-width: 35px;
  height: 10px;
  padding: 0 5px;
  transition: all 1s;
  position: relative;
}

.mobile .match-stats .stats .stats-item .bar .inner-bar .value {
  width: 50px;
  display: block;
  position: absolute;
  top: -5px;
  right: -60px;
  text-align: left !important;
}

.mobile .match-stats .stats .stats-item .bar.home .inner-bar {
  float: right;
  text-align: right;
}

.mobile .match-stats .stats .stats-item .bar.home .inner-bar .value {
  left: -60px;
  right: auto;
  text-align: right !important;
}

.mobile .match-stats .stats .stats-item .title {
  float: left;
  text-align: center;
  text-transform: uppercase;
  width: 40%;
  font-family: Roboto, arial, sans-serif;
  font-size: 12px;
  position: relative;
  top: -1px;
}

.mobile .match-stats .stats .stats-item:before, .mobile .match-stats .stats .stats-item:after {
  content: "";
  display: table;
}

.mobile .match-stats .stats .stats-item:after {
  clear: both;
}

.mobile .match-stats .stats:before, .mobile .match-stats .stats:after {
  content: "";
  display: table;
}

.mobile .match-stats .stats:after {
  clear: both;
}

.mobile .match-stats:before, .mobile .match-stats:after {
  content: "";
  display: table;
}

.mobile .match-stats:after {
  clear: both;
}

.list-score-tennis {
  color: #1e1e1e;
  border-bottom: 1px solid #dcdcdc;
  width: 100%;
  min-height: 70px;
  padding: 6px 0 0;
}

.list-score-tennis .players-container {
  float: left;
  width: 100%;
}

.list-score-tennis .players-container .player-container {
  width: 100%;
  height: auto;
  min-height: 25px;
  position: relative;
}

.list-score-tennis .players-container .player-container .name-container {
  float: left;
  width: 50%;
}

.list-score-tennis .players-container .player-container .name-container .logo-container {
  text-align: center;
  float: left;
  width: 20%;
  max-width: 30px;
}

.list-score-tennis .players-container .player-container .name-container .logo-container img {
  width: auto;
  height: 25px;
  position: relative;
  top: 2px;
}

.list-score-tennis .players-container .player-container .name-container .player-name {
  float: left;
  max-width: 70%;
  margin: 8px 0 0;
  font-size: 13px;
  font-weight: 700;
}

.list-score-tennis .players-container .player-container .name-container .player-name.no-logo {
  margin-left: 20%;
}

.list-score-tennis .players-container .player-container .name-container .player-name.player-looser {
  color: #828282;
}

.list-score-tennis .players-container .player-container .name-container .score-container {
  float: right;
  padding: 8px 0 0 5px;
  font-size: 13px;
  font-weight: 700;
}

.list-score-tennis .players-container .player-container .name-container .service-container {
  float: right;
}

.list-score-tennis .players-container .player-container .name-container .service-container .dataimg-tennis-ball {
  width: 18px;
  height: 18px;
  margin: 0 5px 0 0;
  position: relative;
  top: 8px;
}

.list-score-tennis .players-container .player-container .result-container {
  float: left;
  width: 50%;
}

.list-score-tennis .players-container .player-container .result-container .set-container {
  float: left;
  padding-left: 25px;
}

.list-score-tennis .players-container .player-container .result-container .set-container .set-value {
  float: left;
  text-align: left;
  width: 25px;
  padding: 8px 0;
  font-size: 13px;
  font-weight: 400;
}

.list-score-tennis .players-container .player-container .result-container .set-container .gem {
  float: left;
  padding: 5px 0;
  display: block;
}

.list-score-tennis .players-container .player-container .bet-button {
  position: absolute;
  bottom: -10px;
  right: 0;
}

.list-score-tennis .players-container .player-container:before, .list-score-tennis .players-container .player-container:after {
  content: "";
  display: table;
}

.list-score-tennis .players-container .player-container:after {
  clear: both;
}

.list-score-tennis .players-container:before, .list-score-tennis .players-container:after {
  content: "";
  display: table;
}

.list-score-tennis .players-container:after {
  clear: both;
}

.list-score-tennis .match-info {
  color: #828282;
  float: left;
  width: 37%;
  padding: 14px 0 0 30px;
  font-size: 13px;
  font-weight: 700;
}

.list-score-tennis .league-section {
  position: relative;
}

.list-score-tennis.status-sched .players-container {
  width: 50%;
}

.list-score-tennis.status-sched .players-container .name-container {
  width: 100%;
}

.list-score-tennis.status-sched .players-container .result-container {
  width: 5%;
}

.list-score-tennis.status-live {
  padding: 4px 0 0;
}

.list-score-tennis.status-live .name-container .score-container {
  color: #d21e1e;
}

.list-score-tennis.status-live .set-container .set-value:last-of-type {
  color: #ff6000;
}

.list-score-tennis.dark {
  background-color: #1e1e1e;
  border-color: #646464;
}

.list-score-tennis.dark .players-container .player-container .name-container, .list-score-tennis.dark .players-container .set-container .set-value {
  color: #fff;
}

.list-score-tennis.dark.status-live .set-container .set-value:last-of-type {
  color: #ff6000;
}

.list-score-tennis:first-of-type .list-score {
  border-top: 1px solid #dcdcdc;
}

.list-score-tennis:before, .list-score-tennis:after {
  content: "";
  display: table;
}

.list-score-tennis:after {
  clear: both;
}

.list-score-no-link:first-of-type .list-score-tennis {
  border-top: 1px solid #dcdcdc;
}

a.list-score-link .list-score-tennis:hover {
  background-color: #fdf2f2;
  transition: all .5s;
}

a.list-score-link .list-score-tennis:hover .players-container .player-container .name-container .score-container .score {
  color: #d21e1e;
}

a.list-score-link .list-score-tennis.dark:hover .players-container .player-container .name-container .score-container .score {
  color: #791111;
  transition: all .2s;
}

a.list-score-link:first-of-type .list-score-tennis {
  border-top: 1px solid #dcdcdc;
}

.match-bet-cover-tennis {
  float: right;
  width: 222px;
  height: 68px;
  margin: -68px auto 4px;
  padding-bottom: 15px;
}

.match-bet-cover-tennis a {
  z-index: 10;
  display: block;
}

.match-bet-cover-tennis a:hover {
  text-decoration: none;
}

.match-bet-cover-tennis a img.bets {
  width: 50px;
  height: auto;
  margin: 2px auto;
  display: inline-block;
}

.match-bet-cover-tennis .tipsport-tv-url .match-online.tenis {
  width: 100%;
  margin-top: 4px;
  font-size: 13px;
  font-weight: normal;
}

.match-bet-cover-tennis .tipsport-tv-url .match-online.tenis .match-online-text .tipsport {
  float: right;
  color: #0197f4;
}

.four-col.right-col .match-bet-cover-tennis {
  display: none;
}

@media screen and (max-width: 760px) {
  .list-score-tennis {
    border-bottom: 0;
    min-height: 60px;
    padding: 15px 0 0;
  }

  .match-bet-cover-tennis {
    z-index: 100;
    margin-right: 15px;
    position: relative;
  }

  .match-bet-cover-tennis .match-online div.gray {
    margin-right: 6px;
  }
}

@media screen and (max-width: 680px) {
  .match-bet-cover-tennis {
    width: 111px;
  }

  .match-bet-cover-tennis .match-online div.gray {
    margin-right: 0;
  }
}

@media screen and (max-width: 450px) {
  .match-bet-cover-tennis {
    float: right;
    width: 68px;
    margin-top: -54px;
    margin-right: 2px;
  }
}

@media screen and (max-width: 360px) {
  .tipsport-tv-url {
    max-height: 54px;
  }

  .match-bet-cover-tennis {
    width: 46px;
  }
}

.list-score-other {
  min-height: 30px;
  font-size: 13px;
}

.list-score-other:nth-of-type(2n) {
  background-color: #f0f0f0;
}

.list-score-other:hover .datetime-container {
  color: #d21e1e;
  transition: all .2s;
}

.list-score-other .datetime-container {
  color: #828282;
  float: left;
  padding: 6px 10px;
  display: inline-block;
}

.list-score-other .event-container {
  float: right;
  width: 80%;
  padding: 6px 10px;
  font-weight: 700;
}

.list-score-other .event-container:hover {
  color: #1e1e1e;
  text-decoration: none;
}

.list-score-other .event-container .event-description {
  float: left;
  width: 85%;
}

.list-score-other .event-container .live {
  float: right;
  text-transform: uppercase;
  color: #d21e1e;
  width: auto;
  font-weight: 700;
}

.list-score-other.status-live .datetime-container {
  color: #d21e1e;
}

.list-score-other.dark .event-container {
  color: #fff;
}

.list-score-other.dark:hover .datetime-container {
  color: #791111;
  transition: all .2s;
}

@media screen and (max-width: 760px) {
  .list-score-other.date-show .event-container {
    float: none;
    width: 100%;
  }
}

.list-score-oh {
  min-height: 30px;
  font-size: 13px;
}

.list-score-oh:nth-of-type(2n) {
  background-color: #f0f0f0;
}

.list-score-oh:hover .datetime-container {
  color: #d21e1e;
  transition: all .2s;
}

.list-score-oh .datetime-container {
  color: #828282;
  float: left;
  padding: 6px 10px;
  display: inline-block;
}

.list-score-oh .event-container {
  float: right;
  width: 80%;
  padding: 6px 10px;
  font-weight: 700;
}

.list-score-oh .event-container:hover {
  color: #1e1e1e;
  text-decoration: none;
}

.list-score-oh .event-container .event-description {
  float: left;
  width: 85%;
}

.list-score-oh .event-container .live {
  float: right;
  text-transform: uppercase;
  color: #d21e1e;
  width: auto;
  font-weight: 700;
}

.status-live.list-score-oh .datetime-container {
  color: #d21e1e;
}

.dark.list-score-oh .event-container {
  color: #fff;
}

.dark.list-score-oh:hover .datetime-container {
  color: #791111;
  transition: all .2s;
}

.list-score-oh {
  min-height: 50px;
}

.list-score-oh.status-live .datetime-container span, .list-score-oh.status-live .event-container .event-description {
  color: #d21e1e;
}

.list-score-oh.status-live .event-container .medal-wrapp .icon-medail-green {
  background: url("/images/isportcz/dist/non-sprite/medail-red.png");
}

.list-score-oh.status-live .event-container .medal-wrapp .medal-text {
  color: #d21e1e;
}

.list-score-oh .datetime-container {
  width: 14%;
  padding: 0;
}

.list-score-oh .datetime-container span {
  color: #1e1e1e;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.list-score-oh .event-container {
  float: left;
  width: 86%;
  margin-left: 14%;
  padding: 9px 6px;
}

.list-score-oh .event-container .event-description {
  width: 65%;
}

.list-score-oh .event-container .medal-wrapp {
  margin-top: 3px;
  margin-left: 3%;
}

.list-score-oh .event-container .live {
  margin-top: 7px;
}

@media screen and (max-width: 760px) {
  .list-score.list-score-oh .datetime-container {
    border-right: 1px solid #dcdcdc;
    width: 14%;
    padding: 0;
    font-size: 13px;
    font-weight: 700;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  .list-score.list-score-oh .datetime-container span {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .list-score.list-score-oh .event-container .event-description {
    width: 58%;
  }

  .list-score.list-score-oh .event-container .medal-wrapp {
    margin-left: 1%;
  }

  .list-score.list-score-oh .event-container .medal-wrapp .medal-text {
    float: left;
    margin-top: -1px;
    margin-left: 20px;
    font-size: 9px;
  }
}

.link-basic {
  text-align: center;
}

a.link {
  color: #d21e1e;
  align-items: center;
  width: auto;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: underline;
  display: inline-flex;
}

a.link:hover {
  text-decoration: none;
  transition: color .2s;
}

a.link .icon {
  width: 5px;
  height: 9px;
  margin: 0 0 0 4px;
  display: flex;
  position: relative;
}

a.link.variant {
  text-transform: uppercase;
  font-weight: 700;
}

a.link.variant .icon {
  margin-top: 5px;
}

a.link--button-default {
  color: #fff;
  background: #d21e1e;
  border-radius: 2px;
  padding: 10px 37px;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
}

a.link.football-euro {
  color: #02849a;
}

a.link:before, a.link:after {
  content: "";
  display: table;
}

a.link:after {
  clear: both;
}

.link--small, .link-big {
  text-align: left;
  border-bottom: 2px solid #dcdcdc;
  padding: 12px 0;
  font-size: 22px;
  font-weight: 700;
  display: block;
}

.link--small:hover, .link-big:hover {
  text-decoration: underline;
  transition: color .4s;
}

.link--small:last-of-type, .link-big:last-of-type {
  border-bottom: 0;
  margin-bottom: 15px;
}

.no-margin.link--small, .no-margin.link-big {
  margin: 0;
}

.link--small .icon, .link-big .icon {
  float: right;
  width: 5px;
  height: 9px;
  margin-right: 10px;
  padding-top: 10px;
}

.active.link--small, .active.link-big {
  color: #d21e1e;
}

.active.left-overlap.link--small, .active.left-overlap.link-big {
  color: #1e1e1e;
  background: #fff;
  border-color: #fff;
  border-top-style: solid;
  border-top-width: 2px;
  width: 360px;
  padding-left: 30px;
  position: relative;
  top: -2px;
  left: -30px;
}

.link--small:first-of-type.active, .link-big:first-of-type.active {
  border-top: 0;
  top: 0;
}

.link-medium.link--small, .link-medium.link-big {
  border-bottom: 1px solid #dcdcdc;
  padding: 7px 0;
  font-size: 13px;
}

.link-medium.link--small .icon, .link-medium.link-big .icon {
  margin-right: 0;
  padding-top: 0;
}

.link-medium.link--small:first-of-type, .link-medium.link-big:first-of-type {
  border-top: 1px solid #dcdcdc;
}

.link-big:before, .link-big:after {
  content: "";
  display: table;
}

.link-big:after {
  clear: both;
}

.link--small {
  border-bottom: none;
  margin-bottom: 0;
  padding: 7px 0;
  font-size: 13px;
}

.link--small:last-of-type {
  margin-bottom: 0;
}

.link--small .icon {
  padding-top: 4px;
}

.link--small:before, .link--small:after {
  content: "";
  display: table;
}

.link--small:after {
  clear: both;
}

.list-title {
  color: #1e1e1e;
  width: 100%;
  margin-bottom: 15px;
}

.list-title:last-of-type {
  margin-bottom: 0;
}

.list-title .isport-premium-list-wrapper {
  width: 73px;
  height: 20px;
  margin: 0 7px 0 0;
  display: inline-block;
  position: relative;
  top: 1px;
}

.list-title .title-meta {
  padding-left: 30px;
}

.list-title .title-meta .isport-premium {
  top: 5px;
}

.list-title .flag-container {
  float: left;
  text-align: left;
  width: 30px;
  height: 20px;
  margin: 2px 0;
}

.list-title .flag-container .flag {
  display: inline-block;
  position: relative;
}

.list-title .flag-container .dataimg-play {
  width: 15px;
  height: 15px;
  top: 2px;
}

.list-title .flag-container .dataimg-indent {
  width: 12px;
  height: 12px;
  top: 5px;
}

.list-title .title-container {
  float: right;
  width: calc(100% - 30px);
}

.list-title .title-container h3 {
  margin: 2px 0 0;
  font-family: Roboto, arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.list-title.dark h3 a {
  color: #fff;
}

.list-title:before, .list-title:after {
  content: "";
  display: table;
}

.list-title:after {
  clear: both;
}

.list-title-indent {
  margin-bottom: 20px;
  padding-left: 30px;
}

.superlife .smart-underline:hover {
  color: #02a555;
  box-shadow: none;
}

.superlife .list-title-indent {
  padding-left: 0;
}

.superlife .list-title-indent .title-basic {
  color: #02a555;
  text-transform: none;
  text-decoration: none;
}

.superlife .link.sport-color {
  color: #02a555;
  font-weight: bold;
}

@media screen and (max-width: 760px) {
  article.list-title {
    margin-bottom: 12px;
  }

  article.list-title .title-meta {
    padding-left: 20px;
  }

  article.list-title > .flag-container {
    width: 20px;
  }

  article.list-title > .flag-container .flag {
    width: 8px;
    height: 8px;
    top: 3px;
  }

  article.list-title > .title-container {
    width: calc(100% - 20px);
  }

  article.list-title > .title-container h3 {
    font-size: .8em;
  }

  .list-title-indent {
    padding-left: 20px;
  }
}

.related-articles-list {
  padding: 0 20px 15px;
}

.related-articles-list hr {
  margin: 10px 0;
}

@media screen and (max-width: 760px) {
  .related-articles-list {
    padding: 0 10px 10px;
  }
}

.related-articles-list-in-photo {
  background-color: rgba(30, 30, 30, .5);
  width: 21.74%;
  height: 100%;
  padding: 20px 3% 0;
  position: absolute;
  top: 0;
  right: 0;
}

.related-articles-list-in-photo:before, .related-articles-list-in-photo:after {
  content: "";
  display: table;
}

.related-articles-list-in-photo:after {
  clear: both;
}

.breadcrumbs {
  margin: 0 0 15px 15px;
}

.breadcrumbs ol {
  margin: 0;
  padding: 0;
}

.breadcrumbs ol li {
  color: #aaa;
  font-size: 13px;
  font-weight: 700;
  list-style-type: none;
  display: inline-block;
}

.breadcrumbs ol li a {
  color: #aaa;
  font-weight: 400;
}

.breadcrumbs ol li:last-of-type a {
  font-weight: 700;
}

.breadcrumbs ol .arrow-icon {
  width: 5px;
  height: 9px;
  margin: 0 10px;
  display: inline-block;
  position: relative;
}

.breadcrumbs ol .arrow-icon path {
  fill: #aaa;
}

@media screen and (max-width: 760px) {
  .breadcrumbs {
    margin-bottom: 10px;
  }

  .breadcrumbs ol {
    width: 100%;
    padding: 0 5px;
    position: relative;
  }

  .supperlife-body .breadcrumbs {
    margin-top: 15px;
  }
}

article.list-article-smaller {
  background: none;
  margin-bottom: 20px;
}

article.list-article-smaller .title-container {
  margin-top: 4px;
}

article.list-article-smaller .title-container h2 a {
  font-family: Roboto, arial, sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 14px;
  display: inline-block;
}

article.list-article-smaller a.image-container span.flag {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

article.list-article-smaller a.image-container span.flag .dataimg-play {
  width: 26px;
  height: 26px;
}

article.list-article-smaller.dark .title-container h2 a {
  color: #fff;
}

article.list-article-smaller.dark .image-container img {
  border: 2px solid #fff;
}

article.list-article-smaller:before, article.list-article-smaller:after {
  content: "";
  display: table;
}

article.list-article-smaller:after {
  clear: both;
}

.list-article-small {
  position: relative;
}

.list-article-small .item-list {
  float: left;
  width: 165px;
  height: 205px;
  margin: 0 5px;
}

.list-article-small .item-list:first-child {
  margin-left: 0;
}

.list-article-small .item-list:last-child {
  margin-right: 0;
}

.list-article-small .item-list .title-container {
  background-color: #f0f0f0;
  padding: 10px;
}

.list-article-small .item-list .title-container h2 {
  font-family: Roboto, arial, sans-serif;
}

.list-article-small .item-list .title-container h2 a {
  font-size: 16px;
  line-height: 20px;
}

.list-article-small .item-list .image-container a .flag {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.list-article-small .item-list .image-container a .flag .dataimg-play {
  width: 26px;
  height: 26px;
}

.list-article-small:before, .list-article-small:after {
  content: "";
  display: table;
}

.list-article-small:after {
  clear: both;
}

.gallery-strip {
  width: 100%;
}

.gallery-strip.locked-true > ul li:not(.photos-left):before {
  content: "";
  background: rgba(0, 0, 0, .05);
  width: 100%;
  height: 60px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.gallery-strip.locked-true:before {
  z-index: 11;
  content: " ";
  width: 100%;
  height: 102px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.gallery-strip .dataimg-zamek {
  width: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gallery-strip .gallery-margin {
  margin-top: 35px;
}

.gallery-strip a.image {
  float: left;
  width: 110px;
  display: inline-block;
}

.gallery-strip a.image img {
  width: 100%;
  height: auto;
}

.gallery-strip a.text-container {
  margin-top: 7px;
}

.gallery-strip a.additional-value-container .additional-value {
  float: left;
  width: 100%;
  display: block;
}

.gallery-strip a.additional-value-container .additional-value strong {
  font-size: 13px;
}

.gallery-strip:before, .gallery-strip:after {
  content: "";
  display: table;
}

.gallery-strip:after {
  clear: both;
}

.gallery-items {
  background: #fff;
  height: 62px;
  margin: 0;
  font-size: 12px;
  position: relative;
  overflow: hidden;
}

.gallery-items ul {
  height: 60px;
  max-height: 60px;
  margin: 0;
  padding: 1px;
  list-style-type: none;
  transition: all .3s;
  position: relative;
}

.gallery-items ul li {
  margin: 0 18px 0 0;
  display: inline-block;
  position: relative;
}

.gallery-items ul li a {
  color: #1e1e1e;
  text-align: center;
  height: 61px;
  display: inline-block;
}

.gallery-items ul li a.hover {
  background-color: rgba(255, 255, 255, .08);
}

.gallery-items ul li:last-child {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0, #fff 4em);
  padding-left: 4em;
  position: absolute;
  bottom: -5px;
  right: 0;
}

.gallery-items ul li:last-child a {
  color: #aaa;
  margin-right: -5px;
  padding: 0;
}

.gallery-items ul li:last-child a:hover {
  text-decoration: none;
}

.gallery-items ul li:last-child a .additional-description {
  margin-left: 2px;
}

@media screen and (max-width: 450px) {
  .main-article .art-object .article-gallery-bottom .gallery-strip-bottom {
    margin-top: -50px;
  }

  .gallery-items {
    height: 45px;
  }

  .gallery-items ul li {
    margin: 0 8px 0 0;
  }

  .gallery-items ul li a.image {
    width: 70px;
    height: 45px;
    margin-top: 0;
  }

  .gallery-items ul li:last-child {
    bottom: inherit;
    top: 0;
  }

  .gallery-items ul li:last-child a {
    width: 70px;
    height: 45px;
  }

  .gallery-items ul li:last-child a.additional-value-container .additional-value {
    font-size: 22px;
    line-height: 26px;
  }

  .gallery-items ul li:last-child a.additional-value-container .additional-description {
    font-size: 9px;
  }

  .gallery-items ul li:last-child a.additional-value-container .icon {
    float: left;
    width: 3px;
    height: 7px;
    margin: 4px 0 0 4px;
  }
}

.list-mostread {
  float: left;
  position: relative;
}

.list-mostread .caption-arrow {
  margin-right: 35px;
}

.list-mostread .list-item {
  float: left;
  width: 165px;
  height: 205px;
  margin: 0 6px;
}

.list-mostread .list-item:last-child {
  margin-right: 0;
}

.list-mostread .list-item .title-container {
  margin: 10px;
}

.list-mostread .list-item .title-container h2 a {
  font-size: 16px;
  line-height: 20px;
  display: block;
}

.list-mostread + .list-mostread {
  float: right;
  display: inline-block;
}

.list-mostread:before, .list-mostread:after {
  content: "";
  display: table;
}

.list-mostread:after {
  clear: both;
}

.list-mostread-xml .list-item {
  width: 100%;
  height: auto;
  margin: 0;
}

.list-mostread-xml .list-item article.list-article {
  width: 100%;
  padding: 0 40px 0 20px;
}

.list-mostread-xml .list-item article.list-article .image-container {
  float: left;
  width: 80px;
  height: 53px;
  margin-top: 2px;
}

.list-mostread-xml .list-item article.list-article .image-container img {
  max-width: 80px;
  max-height: 53px;
}

.list-mostread-xml .list-item article.list-article .title-container {
  float: left;
  width: calc(100% - 90px);
  margin: 0 0 0 10px;
}

.recent-videos .list-mostread {
  background-color: #1e1e1e;
  margin-bottom: 30px;
}

.recent-videos .list-mostread .caption-arrow {
  width: 125px;
  height: 220px;
}

.recent-videos .list-mostread .caption-arrow .caption-title {
  width: 125px;
  padding: 80px 0 80px 15px;
  font-size: 24px;
}

.recent-videos .list-mostread .caption-arrow .arrow {
  border-width: 110px 0 110px 30px;
}

.recent-videos .list-mostread .list-item {
  height: 210px;
  margin: 10px 6px 0;
}

.recent-videos .list-mostread .list-item:last-child {
  margin-right: 0;
}

.recent-videos .list-mostread .list-item a.image-container span.flag {
  width: 36px;
  height: 36px;
}

.recent-videos .list-mostread .list-item .title-container {
  margin: 5px;
}

.recent-videos .list-mostread .list-item .title-container h2 a {
  color: #fff;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  font-size: 15px;
  display: -webkit-box;
  overflow: hidden;
}

.recent-videos .list-mostread .list-item .title-container:has(.category-flags--mostread) h2 a {
  -webkit-line-clamp: 4;
}

.recent-videos article.list-article > .image-container img {
  height: 90px;
}

.mostread-articles {
  float: left;
  position: relative;
}

.mostread-articles .list-article-smaller {
  float: left;
  clear: initial;
  width: 165px;
  height: 205px;
  margin: 0 0 0 10px;
  display: inline-block;
}

.mostread-articles .list-article-smaller:last-child {
  margin-right: 0;
}

.mostread-articles .list-article-smaller .title-container {
  margin: 10px;
}

.mostread-articles .list-article-smaller .title-container h2 a {
  font-size: 16px;
  line-height: 20px;
  display: block;
}

.mostread-articles:before, .mostread-articles:after {
  content: "";
  display: table;
}

.mostread-articles:after {
  clear: both;
}

.mostread-articles__caption-title {
  float: left;
  width: 135px;
  display: inline-block;
}

.mostread-articles__caption-title--arrow {
  color: #323232;
  background-color: #fff;
  margin-right: 40px;
  padding: 48px 0 48px 30px;
  font-size: 26px;
  font-weight: 300;
  position: relative;
}

.mostread-articles__caption-title--arrow:after {
  content: "";
  border: 95px solid rgba(0, 0, 0, 0);
  border-left: 30px solid #fff;
  border-right-width: 0;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  top: 0;
  right: -30px;
}

.list-blogs {
  background: #fff;
  margin: 0 0 30px;
}

.list-blogs.stretch-full .list-article-blog {
  height: 262px;
}

.list-blogs.stretch .list-article-blog {
  height: 256px;
}

.list-blogs .list-article-blog {
  float: left;
  border-bottom: 0;
  border-right: 2px solid #f0f0f0;
  width: 398px;
  margin-bottom: 0;
}

.list-blogs .list-article-blog-small {
  float: right;
  width: 270px;
  height: 105px;
  margin-top: 10px;
  margin-right: 10px;
}

.list-blogs .list-article-blog-small.isport-premium-true {
  height: 121px;
}

.list-blogs .list-article-blog-small.isport-premium-true:last-of-type {
  margin-top: 0;
}

.list-blogs .list-article-blog-small:last-child {
  margin-bottom: 0;
}

.list-blogs .list-article-blog .isport-premium, .list-blogs .list-article-blog-small .isport-premium {
  margin: -5px 0 5px;
}

.list-blogs:before, .list-blogs:after {
  content: "";
  display: table;
}

.list-blogs:after {
  clear: both;
}

.sms-news-description {
  background-color: #fff;
  margin-bottom: 30px;
  position: relative;
}

.sms-news-description .box-container {
  padding-top: 0;
}

.sms-news-description .box-container .box-content .title-section .main-title {
  padding-left: 20px;
}

.sms-news-description p {
  width: 48%;
  padding: 30px 20px;
}

.sms-news-description .table-basic {
  width: 72%;
  margin: 0 20px 30px;
}

.sms-news-description img {
  max-width: 25%;
  position: absolute;
  bottom: -22px;
  right: 2%;
}

.sms-news-description:before, .sms-news-description:after {
  content: "";
  display: table;
}

.sms-news-description:after {
  clear: both;
}

.promobox {
  margin-bottom: 30px;
  position: relative;
}

.promobox .items-big {
  background-color: #1e1e1e;
  height: 420px;
}

.promobox .items-small:before, .promobox .items-small:after {
  content: "";
  display: table;
}

.promobox .items-small:after {
  clear: both;
}

.promobox .item-list.item-big {
  display: none;
}

.promobox .item-list.item-big article.list-article {
  margin-bottom: 0;
}

.promobox .item-list.item-big article.list-article .meta {
  display: none;
}

.promobox .item-list.item-big article.list-article .image-container img {
  display: block;
}

.promobox .item-list.item-big article.list-article .title-container {
  height: 84px;
  margin: 8px 20px 18px;
}

.promobox .item-list.item-big article.list-article .title-container h2 {
  font-size: 35px;
}

.promobox .item-list.item-big.active article.list-article {
  background: #d21e1e;
}

.promobox .item-list.item-big.active article.list-article .title-container h2 a {
  color: #fff;
  text-shadow: 0 -2px #d21e1e, 0 -1px #d21e1e, 0 0 #d21e1e, 2px -2px #d21e1e, 2px -1px #d21e1e, 2px 0 #d21e1e, -2px -2px #d21e1e, -2px -1px #d21e1e, -2px 0 #d21e1e, 1px -2px #d21e1e, 1px -1px #d21e1e, 1px 0 #d21e1e, -1px -2px #d21e1e, -1px -1px #d21e1e, -1px 0 #d21e1e, 0 -2px #d21e1e, 0 -1px #d21e1e, 0 0 #d21e1e;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 -4px #d21e1e, inset 0 -6px rgba(0, 0, 0, 0);
}

.promobox .item-list.item-big.active article.list-article .title-container h2 a::selection {
  color: #d21e1e;
  text-shadow: none;
  background: #fff;
}

.promobox .item-list.item-big.active article.list-article .title-container h2 a:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 -4px #d21e1e, inset 0 -6px #fff;
}

.promobox .item-list.item-small {
  float: left;
  width: 165px;
  margin: 10px 5px 0;
  transition: all .3s ease-in-out;
}

.promobox .item-list.item-small:first-child {
  margin-left: 0;
}

.promobox .item-list.item-small:last-child {
  margin-right: 0;
}

.promobox .item-list.item-small.active {
  margin-top: 0;
  transition: all .3s ease-in-out;
}

.promobox .item-list.item-small.active article.list-article {
  background: #d21e1e;
}

.promobox .item-list.item-small.active article.list-article .title-container h2 a {
  color: #fff;
}

.promobox .item-list.item-small article.list-article {
  background: #fff;
  margin-bottom: 0;
  position: relative;
}

.promobox .item-list.item-small article.list-article .title-container {
  height: 75px;
  margin: 10px;
  overflow: hidden;
}

.promobox .item-list.item-small article.list-article .title-container h2 a {
  font-size: 16px;
  line-height: 1.2;
  transition: none;
  display: block;
}

.promobox .item-list.item-small article.list-article .title-container h2 a:hover {
  text-decoration: none;
}

.promobox.promobox__withAuthor article.list-article.list-article-vertical > .title-container h2 {
  font-size: 33px;
}

.promobox.promobox__withAuthor .items-big {
  background: #f0f0f0;
  height: 445px;
}

.promobox.promobox__withAuthor .item-list.item-big.active article.list-article {
  background: #02a555;
}

.promobox.promobox__withAuthor .item-list.item-big.active article.list-article .title-container h2 a {
  box-shadow: none;
  text-shadow: none;
}

.promobox.promobox__withAuthor .item-list.item-big.active article.list-article .title-container h2 a:hover {
  box-shadow: none;
  text-decoration: underline;
}

.promobox.promobox__withAuthor .item-list.item-small.active article.list-article {
  background: #02a555;
}

.promobox.promobox__withAuthor .list-article__img-bottom {
  border-bottom-width: 40px;
  border-bottom-color: #02a555;
  border-right-width: 690px;
}

.promobox.promobox__withAuthor .list-article__author-wrap {
  height: 25px;
}

.promobox.promobox__withAuthor .list-article__author-wrap .list-article__author-img {
  box-sizing: border-box;
  background: linear-gradient(to right, #e6e6e6, silver);
  border: 4px solid #fff;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  display: block;
  position: absolute;
  top: -68px;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%);
}

.promobox.promobox__withAuthor .list-article__author-wrap .list-article__author-name {
  color: #fff;
  background: #02a555;
  bottom: -10px;
}

.author-profile {
  margin-bottom: 15px;
}

.author-profile .title-section .main-title {
  padding-left: 20px;
}

.author-profile .title-section .icon {
  margin-right: 20px;
}

.author-profile .author-info {
  border-bottom: 2px solid #dcdcdc;
  min-height: 170px;
  position: relative;
}

.author-profile .author-info .name-container {
  float: right;
  text-align: right;
  max-width: 65%;
}

.author-profile .author-info .image-container {
  position: absolute;
  bottom: 0;
  left: 0;
}

.author-profile .author-info .social-buttons-container {
  float: right;
  clear: both;
  padding: 20px 0;
}

.author-profile .author-info .social-buttons-container a {
  margin-right: 10px;
}

.author-profile .author-info .social-buttons-container a:last-of-type {
  margin-right: 0;
}

.author-profile .author-info a img {
  max-height: 160px;
}

.author-profile .author-info:before, .author-profile .author-info:after {
  content: "";
  display: table;
}

.author-profile .author-info:after {
  clear: both;
}

.author-profile p {
  padding-top: 25px;
}

.author-profile .profile-container {
  padding: 20px 0 0;
  line-height: 24px;
}

.author-profile:before, .author-profile:after {
  content: "";
  display: table;
}

.author-profile:after {
  clear: both;
}

.title-basic {
  text-transform: uppercase;
  color: #d21e1e;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
}

.title-basic--superlife {
  color: #02a555;
  text-transform: none;
  font-size: 35px;
}

a .title-basic {
  text-decoration: none;
}

a .title-basic:hover {
  text-decoration: underline;
  transition: color .2s;
}

@media screen and (max-width: 760px) {
  .title-basic {
    font-size: .7em;
  }
}

.eventNejnovejsi .title-basic {
  font-size: 22px;
}

.title-medium {
  text-align: center;
  border-bottom: 2px solid #e6e6e6;
  padding: 10px 0;
  font-family: Roboto Slab, Times New Roman, serif;
  font-size: 26px;
  font-weight: 700;
}

a .title-medium {
  text-decoration: none;
}

a .title-medium:hover {
  text-decoration: underline;
  transition: color .2s;
}

.main-article .content {
  margin-left: 100px;
  font-size: 17px;
  line-height: 1.6;
}

.main-article .content h2 {
  font-size: 26px;
  line-height: 36px;
}

.main-article .content h3, .main-article .content h3 a {
  font-size: 22px;
}

.main-article .content p {
  margin: 0 0 30px;
  font-size: 17px;
}

.main-article .content .art-object {
  margin-left: -100px;
  line-height: normal;
}

.main-article .content .art-object p {
  margin: 0;
}

.main-article .content .art-object.image-in-article {
  margin-left: 0;
}

.main-article > .image-container {
  background: #fff;
  width: 1050px;
  position: relative;
}

.main-article > .image-container .box-gallery {
  float: left;
  width: 690px;
  height: 310px;
}

.main-article > .image-container .box-gallery .gallery-main-container .image-description {
  display: none;
}

.main-article > .image-container .box-gallery .gallery-main-container .gallery, .main-article > .image-container .box-gallery .gallery-main-container .gallery .btn {
  height: 100%;
}

.main-article > .image-container .box-gallery .gallery-main-container .gallery .next .image-count {
  display: none;
}

.main-article > .image-container .box-gallery .gallery-main-container .gallery .images-container .owl-item img {
  left: 0;
  right: 0;
}

.main-article > .image-container .box-gallery .gallery-main-container .gallery .images-container .desc-image {
  z-index: 10;
  color: #fff;
  background: rgba(0, 0, 0, .45);
  width: 82%;
  margin: 0 9%;
  padding: 10px;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.7;
  display: block;
  position: absolute;
  bottom: 0;
}

.main-article > .image-container .box-gallery .gallery-main-container .gallery .images-container .video-item .desc-image {
  margin: 0;
}

.main-article > .image-container img, .main-article > .image-container .video-wrapper {
  float: left;
  width: 690px;
}

.main-article > .image-container .video-wrapper {
  background: #1e1e1e;
  height: 390px;
  position: relative;
}

.main-article > .image-container .video-wrapper .video-desc {
  color: #fff;
  background: #323232;
  width: 360px;
  height: 80px;
  padding: 10px;
  position: absolute;
  bottom: 0;
  right: -360px;
}

.main-article > .image-container .video-wrapper .video-desc .title {
  max-height: 40px;
  font-size: 15px;
  overflow: hidden;
}

.main-article > .image-container .video-wrapper .video-desc .more-videos {
  text-align: right;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.main-article > .image-container .video-wrapper .video-desc .more-videos a {
  color: #fff;
  text-decoration: none;
}

.main-article > .image-container .video-wrapper .video-desc .more-videos a path {
  fill: #fff;
}

.main-article > .image-container .video-wrapper .video-desc .more-videos a:hover {
  text-decoration: underline;
}

.main-article > .image-container .video-wrapper .desc-arrow {
  z-index: 5;
  border-top: 40px solid rgba(0, 0, 0, 0);
  border-bottom: 40px solid rgba(0, 0, 0, 0);
  border-right: 4px solid #323232;
  font-size: 0;
  position: absolute;
  bottom: 0;
  right: 0;
}

.main-article > .image-container .result-block {
  float: left;
  color: #fff;
  background: #1e1e1e;
  width: 360px;
  height: 310px;
}

.main-article > .image-container #video-top-right {
  float: right;
  background: #1e1e1e;
  width: 360px;
  max-height: 412px;
  padding: 10px 15px;
  position: relative;
}

.main-article > .image-container #video-top-right .title-section.color-inverze .sport-color {
  color: #fff;
}

.main-article > .image-container #video-top-right .link.sport-color.color-inverze {
  text-align: center;
  color: #fff;
  margin: auto;
  display: block;
  position: absolute;
  bottom: 14px;
  left: 0;
  right: 0;
}

.main-article > .image-container:before, .main-article > .image-container:after {
  content: "";
  display: table;
}

.main-article > .image-container:after {
  clear: both;
}

.main-article .article-gallery-container {
  background-color: #fff;
  padding: 20px 10px 20px 20px;
}

.main-article .article-gallery-bottom, .main-article .article-gallery-bottom .img-first {
  position: relative;
}

.main-article .article-gallery-bottom .img-first .description {
  color: #fff;
  background: #1e1e1e;
  padding: 10px;
}

.main-article .article-gallery-bottom .gallery-strip-bottom {
  width: 96%;
  margin-top: -80px;
  margin-left: 15px;
}

.main-article .article-gallery-bottom .gallery-strip-bottom .gallery-items {
  background: none;
}

.main-article .article-gallery-bottom .gallery-strip-bottom .gallery-items ul li:last-child {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, #1e1e1e 4em);
  padding-right: 10px;
}

.main-article .article-gallery-bottom .gallery-strip-bottom .gallery-items .additional-value {
  color: #fff;
}

.main-article .article-gallery-bottom .gallery-strip-bottom .gallery-items .additional-description {
  color: #aaa;
}

.main-article .article-gallery-bottom .cover {
  background: linear-gradient(rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .9) 100%);
  width: 100%;
  height: 90px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.main-article .article-gallery-bottom .gallery-zoom {
  opacity: .6;
  background: #1e1e1e;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 32%;
  left: 44%;
}

.main-article .article-gallery-bottom .dataimg-magnifier-cross {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 39%;
  left: 47.5%;
}

.main-article .article-gallery-bottom .dataimg-magnifier-cross path {
  fill: #fff;
}

.main-article .perex {
  margin: 20px 20px 0;
  padding: 0 0 0 80px;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.7;
}

.main-article .perex p {
  margin: 0;
  font-size: 17px;
  line-height: 1.6;
}

.main-article .button-redirect {
  opacity: .9;
  z-index: 100;
  display: none;
  position: fixed;
  bottom: -15px;
  left: 42%;
}

.main-article .keywords {
  margin-bottom: 20px;
}

.main-article .keywords a {
  color: #d21e1e;
}

.main-article .image-in-article {
  background-color: #1e1e1e;
  max-width: 100%;
  margin: 30px 0;
}

.main-article .image-in-article img {
  max-width: 100%;
}

.main-article .image-in-article figure {
  margin: 0;
}

.main-article .image-in-article figure iframe {
  width: 100%;
  margin-left: 0;
}

.main-article .image-in-article .description {
  color: #fff;
  padding: 10px;
  font-size: 13px;
  line-height: 1.4;
  display: block;
}

.main-article .image-in-article .description.video-description {
  font-size: 17px;
}

.main-article .image-in-article.float-left {
  margin: 0 15px 15px 0;
}

.main-article .image-in-article.float-right {
  margin: 0 0 15px 15px;
}

.main-article .image-in-article.art-obj {
  margin-left: 0;
}

.main-article .enquiry-main-container {
  width: 690px;
  position: relative;
  left: -100px;
}

.main-article .enquiry-main-container .enquiry-block {
  float: left;
  background-color: #d21e1e;
  width: 100px;
  height: 100%;
  position: absolute;
}

.main-article .enquiry-main-container .enquiry-block .icon {
  width: 32px;
  height: 33px;
  margin: 15px auto 0;
  display: block;
}

.main-article .enquiry-main-container .enquiry-block .icon path {
  fill: #fff;
}

.main-article .enquiry-main-container .enquiry-block span {
  color: #fff;
  text-align: center;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 700;
  display: block;
}

.main-article .enquiry-main-container .box-container {
  float: right;
  width: 590px;
  margin-bottom: 0;
}

.main-article .enquiry-main-container:before, .main-article .enquiry-main-container:after {
  content: "";
  display: table;
}

.main-article .enquiry-main-container:after {
  clear: both;
}

.main-article p iframe {
  border: 0;
  width: 690px;
  min-height: 180px;
  margin-left: -100px;
}

.main-article p iframe.twitter-tweet-rendered {
  margin-left: 0;
}

.main-article p iframe.no-margin {
  width: 575px !important;
  left: 100px !important;
}

.main-article .youtube-media iframe {
  margin-left: 0;
}

.main-article .twitter-video-rendered iframe {
  min-height: initial;
  margin-left: auto;
}

.main-article .fb-post iframe {
  margin-left: 0;
}

.main-article .questionary .title-basic {
  margin: 15px 0;
}

.main-article .match-overview {
  min-width: 450px;
}

.main-article .match-overview .box-container p {
  font-size: 15px;
}

@media screen and (max-width: 760px) {
  .main-article > .image-container {
    width: auto;
  }

  .main-article > .image-container img, .main-article > .image-container .video-wrapper {
    float: none;
    width: auto;
    height: auto;
  }

  .main-article > .image-container .box-gallery {
    width: 100%;
    height: 200px;
  }

  .main-article .content {
    clear: both;
    margin: 0;
    padding: 0 10px;
  }

  .main-article .content .art-object {
    margin-left: 0;
  }

  .main-article .content .art-object .gallery-zoom {
    width: 40px;
    height: 40px;
    top: 28%;
  }

  .main-article .content .art-object .dataimg-magnifier-cross {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 34%;
    left: 47%;
  }

  .main-article .content .questionary .info-container {
    font-size: 16px;
  }

  .main-article .content .questionary .info-container .votes-container {
    font-size: 20px;
  }

  .main-article .content .questionary .title-basic {
    margin: 15px 0;
  }

  .main-article .content iframe:not(:-webkit-any(.instagram-media, [id^="google_ads_iframe"])) {
    border: 0;
    width: 100%;
    min-height: 180px;
    margin-left: 0;
  }

  .main-article .content iframe:not(:is(.instagram-media, [id^="google_ads_iframe"])) {
    border: 0;
    width: 100%;
    min-height: 180px;
    margin-left: 0;
  }

  .main-article .content iframe:not(:-webkit-any(.instagram-media, [id^="google_ads_iframe"])).no-margin {
    width: 100% !important;
    left: 0 !important;
  }

  .main-article .content iframe:not(:is(.instagram-media, [id^="google_ads_iframe"])).no-margin {
    width: 100% !important;
    left: 0 !important;
  }

  .main-article .content .enquiry-main-container, .main-article .content .enquiry-main-container .enquiry-block {
    width: 100%;
    position: static;
  }

  .main-article .content .enquiry-main-container .enquiry-block .icon {
    margin: 10px 0 10px 15px;
    display: inline-block;
  }

  .main-article .content .enquiry-main-container .enquiry-block span {
    margin: 0 0 0 10px;
    display: inline-block;
    position: relative;
    top: -10px;
  }

  .main-article .content .enquiry-main-container .box-container {
    width: 100%;
  }

  .main-article .content .match-overview {
    min-width: auto;
  }

  .main-article .perex {
    clear: both;
    margin: 20px 0 0;
    padding: 0 10px;
  }

  .main-article .share-container {
    padding: 0 10px;
  }

  .main-article .share-container .facebook-like-container {
    padding: 0;
  }
}

.wrapper {
  z-index: 5;
  position: relative;
}

.listed-container {
  width: auto;
  margin-bottom: 30px;
  list-style: none;
  position: relative;
  overflow: hidden;
}

.listed-container .paging {
  width: 100%;
  font-size: 22px;
  font-weight: 300;
  display: block;
}

.listed-container .paging button {
  cursor: pointer;
  border: 0;
  outline: 0;
  width: 9%;
  height: 60px;
  margin: 0;
  display: block;
}

.listed-container .paging button.disabled {
  cursor: default;
}

.listed-container .paging button .arrow-listing {
  width: 13px;
  height: 26px;
  margin: auto;
}

.listed-container .paging .prev {
  float: left;
}

.listed-container .paging .next {
  float: right;
}

.listed-container .paging .paging-holder {
  float: left;
  text-align: center;
  background-color: #e6e6e6;
  width: 82%;
  height: 60px;
  margin: 0 auto;
  padding-left: 0;
  display: block;
}

.listed-container .paging .paging-holder a {
  min-width: 29px;
  height: auto;
  padding: 16px 14px;
  text-decoration: none;
  display: inline-block;
}

.listed-container .paging .paging-holder a:nth-child(n+12) {
  padding: 16px 8px;
}

.listed-container .paging .paging-holder a.disabled {
  cursor: default;
}

.listed-container .paging .paging-holder a.disabled:hover {
  color: #1e1e1e;
}

.listed-container .paging .paging-holder .active {
  font-weight: 700;
}

.listed-container .listed {
  margin: 0;
  padding: 0;
}

.listed-container .listed > li {
  background-color: #fff;
  padding: 0 60px 20px;
  display: none;
}

.listed-container .listed > li .top-image {
  width: 690px;
  margin: 0 0 0 -60px;
  display: block;
  position: relative;
}

.listed-container .listed > li .top-image img {
  width: 100%;
}

.listed-container .listed > li > p:first-of-type {
  margin-top: 20px;
}

.listed-container .listed > li > p iframe {
  border: 0;
  width: 690px;
  min-height: 180px;
  margin-left: -60px;
}

.listed-container .listed > li > p iframe.twitter-tweet-rendered {
  margin-left: 0;
}

.listed-container .listed > li p, .listed-container .listed > li .perex {
  margin-left: 0;
  padding-left: 0;
}

.listed-container .listed > li .perex {
  border-bottom: 0;
  padding-bottom: 0;
}

.listed-container .listed .related {
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
}

.listed-container .listed .active {
  display: block;
}

.listed-container .listed .enquiry-main-container {
  width: 600px;
  left: 0;
}

.listed-container .listed .enquiry-main-container .box-container {
  width: 500px;
}

.listed-container .description {
  color: #1e1e1e;
  padding: 10px;
  font-style: italic;
  display: block;
}

.listed-container .description.description-main {
  width: 690px;
  margin-left: -60px;
  padding-top: 0;
}

.list-video-small {
  color: #1e1e1e;
  width: 100%;
  padding-bottom: 10px;
  position: relative;
}

.list-video-small a {
  float: left;
  display: block;
  position: relative;
}

.list-video-small a .flag {
  z-index: 2;
  width: 40px;
  height: auto;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.list-video-small a .flag .circle {
  opacity: .6;
  transition: opacity .2s ease-in-out;
}

.list-video-small .box-title {
  float: left;
  width: 46%;
  margin-left: 10px;
}

.list-video-small .box-title .title-container {
  float: left;
  min-height: 70px;
}

.list-video-small .box-title .title-container .title {
  margin-bottom: 0;
  font-family: Roboto, arial, sans-serif;
  font-size: 13px;
  font-weight: 700;
}

.list-video-small .box-title .title-container .title .title-link {
  float: initial;
}

.list-video-small .box-title .additional-value-container {
  clear: both;
  height: 15px;
  display: inline-block;
}

.list-video-small:hover .flag .circle {
  opacity: .8;
}

.list-video-small:before, .list-video-small:after {
  content: "";
  display: table;
}

.list-video-small:after {
  clear: both;
}

.menu-bar {
  z-index: 10000;
  background-color: #d21e1e;
  width: 100%;
  height: 60px;
  position: fixed;
  top: 43px;
  left: 0;
}

.menu-bar > .wrapper {
  padding: 0;
}

.menu-bar:before, .menu-bar:after {
  content: "";
  display: table;
}

.menu-bar:after {
  clear: both;
}

.mobile .menu-bar {
  top: 0;
}

.header-menu {
  background-color: #d21e1e;
  height: 60px;
  position: relative;
}

.header-menu .login-name {
  text-transform: lowercase;
  font-size: 9px;
  display: block;
  position: absolute;
  bottom: 5px;
  left: 0;
}

.header-menu .dataimg-logo-isport-white {
  float: left;
  width: 115px;
  height: 37px;
  margin: 12px 21px 12px 0;
  position: relative;
}

.header-menu .dataimg-logo-isport-white:after {
  content: "";
  color: #fff;
  font-size: 9px;
  font-weight: 700;
  position: absolute;
  top: -2px;
  right: 0;
}

.header-menu .menu-spacer {
  float: left;
  width: 50px;
  height: 100%;
  position: relative;
}

.header-menu .list-links {
  float: right;
  z-index: 40;
  height: 60px;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.header-menu .list-links li {
  color: #fff;
  min-height: 37px;
  margin: 0 15px 0 0;
  padding: 0;
  font-size: 13px;
  font-weight: 700;
  list-style: none;
  display: inline-block;
}

.header-menu .list-links li#login-target {
  float: left;
}

.header-menu .list-links li:nth-last-child(2), .header-menu .list-links li:last-child {
  margin: 0;
}

.header-menu .list-links li:nth-last-child(2) a:hover, .header-menu .list-links li:last-child a:hover {
  text-decoration: none;
}

.header-menu .list-links li:last-child {
  float: right;
  padding: 22px 0 0;
}

.header-menu .list-links li a {
  color: #fff;
  padding: 27px 0 24px;
  line-height: 10px;
  display: block;
  position: relative;
}

.header-menu .list-links li a:hover, .header-menu .list-links li a.logged-in {
  color: #fff;
}

.header-menu .list-links li a:hover path, .header-menu .list-links li a.logged-in path {
  fill: #fff;
  transition: all .5s;
}

.header-menu .list-links li path {
  fill: #791111;
}

.header-menu .list-links .dataimg-tv {
  float: left;
  width: 25px;
  height: 25px;
  margin: -8px 18px 0 0;
  position: relative;
}

.header-menu .list-links #login-source {
  width: 50px;
  height: 60px;
  overflow: hidden;
}

.header-menu .list-links #login-source .login-text {
  float: right;
  text-transform: none;
  text-align: center;
  width: 100%;
  height: 15px;
  margin: 0;
  font-size: 11px;
  font-weight: normal;
  line-height: 15px;
  display: block;
  position: relative;
  top: 0;
  overflow: hidden;
}

.header-menu .list-links .dataimg-circle-head, .header-menu .list-links .dataimg-circle-head-active {
  float: right;
  width: 22px;
  height: 22px;
  margin: -6px 15px 0 10px;
  position: relative;
}

.header-menu .list-links .dataimg-circle-head:hover path, .header-menu .list-links .dataimg-circle-head-active:hover path {
  fill: #fff;
  transition: all .5s;
}

.header-menu .list-links .search-container {
  float: left;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin: 0;
  position: relative;
}

.header-menu .list-links .search-container .link-with-icon {
  cursor: pointer;
  width: 40px;
  height: 60px;
  padding: 0;
  position: relative;
}

.header-menu .list-links .search-container .link-with-icon .dataimg-magnifier {
  position: relative;
}

.header-menu .list-links .search-container .link-with-icon .dataimg-magnifier svg {
  width: 20px;
  height: 20px;
}

.header-menu .list-links .search-container form {
  z-index: 40;
  top: -7px;
  right: 10px;
}

.header-menu .list-links .search-container:hover path {
  fill: #fff;
  transition: all .5s;
}

.header-menu .list-links:before, .header-menu .list-links:after {
  content: "";
  display: table;
}

.header-menu .list-links:after {
  clear: both;
}

.header-menu .dropdown-menu ul {
  margin: 0;
}

.header-menu .dropdown-menu li {
  transition: background-color .3s ease-in-out;
}

.header-menu .dropdown-menu li a {
  color: #1e1e1e;
  cursor: pointer;
  text-decoration: none;
}

.header-menu .dropdown-menu li .dataimg-arrow-down svg {
  position: absolute;
}

.header-menu .dropdown-menu li .dataimg-arrow-down path {
  fill: #fff;
}

.header-menu .dropdown-menu li.active > a:after {
  border-bottom: 2px solid #fff;
}

.header-menu .dropdown-menu li.dropdown-title > a, .header-menu .dropdown-menu li.dropdown-title > span {
  color: #fff;
}

.header-menu .dropdown-menu li.special {
  margin-left: 0;
}

.header-menu .dropdown-menu li.special > a:before {
  content: "SPECIÁL";
  color: #f09999;
  font-size: 9px;
  position: absolute;
  bottom: 38px;
}

.header-menu .dropdown-menu li.special-vysledky > a:before {
  content: "ONLINE";
  color: #f09999;
  font-size: 9px;
  position: absolute;
  bottom: 38px;
}

.header-menu .dropdown-menu li.label-isport > a:before {
  content: "iSport";
  color: #f09999;
  font-size: 9px;
  position: absolute;
  bottom: 38px;
}

.header-menu .promo-item {
  background: #f7c5c5;
  overflow: hidden;
}

.header-menu .promo-item.prestupy {
  background-color: #8ad095;
}

.header-menu .promo-item.prestupy:hover {
  background-color: #46ae56;
}

.header-menu .promo-item .item-icon {
  float: right;
  position: relative;
  top: -5px;
}

.header-menu .promo-item.no-background {
  background: none;
}

.header-menu .promo-item.no-background:hover {
  background: #dcdcdc;
}

.header-menu .promo-item:hover {
  background: #ea6c6c;
}

.header-dark {
  z-index: 100;
  background-color: #1e1e1e;
  width: 100%;
  height: 66px;
  position: fixed;
  top: 0;
}

.header-dark .header-container {
  width: 1050px;
  margin: auto;
}

.header-dark .header-container .logo {
  float: left;
  width: 100px;
  height: 34px;
  margin: 16px 25px 16px 0;
}

.header-dark .header-container .logo .vip-logo {
  width: 125px;
  margin-top: 5px;
}

.header-dark .header-container .gallery-close {
  color: #d21e1e;
  float: right;
  margin: 20px 0;
  font-size: 13px;
  font-weight: 700;
  display: block;
  position: relative;
}

.header-dark .header-container .gallery-close span {
  text-transform: uppercase;
  position: relative;
  top: 3px;
}

.header-dark .header-container .gallery-close .icon {
  float: right;
  width: 25px;
  height: 25px;
  margin: 0 0 0 20px;
}

.header-dark .header-container .titles-container {
  float: left;
  padding: 12px 20px;
  font-weight: 700;
}

.header-dark .header-container .titles-container .title {
  color: #aaa;
  float: left;
  text-transform: uppercase;
  font-size: 13px;
}

.header-dark .header-container .titles-container .article-title {
  color: #fff;
  float: left;
  clear: both;
  font-size: 16px;
}

.header-dark:before, .header-dark:after {
  content: "";
  display: table;
}

.header-dark:after {
  clear: both;
}

@media screen and (max-width: 760px) {
  body.bg-color-dark {
    padding-top: 0;
  }

  .header-dark {
    padding: 0 10px;
    position: relative;
  }

  .header-dark .header-container {
    width: 100%;
  }

  .header-dark .header-container .titles-container {
    display: none;
  }
}

.navigation-section {
  padding-bottom: 15px;
  display: flex;
}

.navigation-section .title-container {
  float: left;
  flex: 1;
  width: 20%;
}

.navigation-section .menu-container {
  background: #fff;
  flex: 4;
  width: 80%;
}

.navigation-section .menu-container > ul {
  z-index: 40;
  justify-content: space-around;
  margin: 0;
  display: flex;
}

.navigation-section .menu-container > ul > li > a {
  border-top: 0;
}

.navigation-section .menu-container > ul > li > ul {
  z-index: 40;
}

.navigation-section .menu-container > ul > li.dropdown-title > a > span > svg {
  position: absolute;
}

.navigation-section:before, .navigation-section:after {
  content: "";
  display: table;
}

.navigation-section:after {
  clear: both;
}

.league-promo .live-match {
  background-color: rgba(210, 30, 30, .1);
  padding: 6px 15px 0;
  position: relative;
}

.league-promo .live-match:first-of-type .white-line {
  z-index: 1000;
  background: #fff;
  width: 100%;
  height: 2px;
  position: absolute;
  top: -2px;
  left: 0;
}

.league-promo .live-match:last-of-type hr {
  display: none;
}

.league-promo .live-match hr {
  border-color: rgba(210, 30, 30, .3);
}

.league-promo .live-match a.list-score-link:hover .list-score {
  border-bottom: 0;
}

.league-promo .live-match a.list-score-link:hover .list-score .score-container .score, .league-promo .live-match a.list-score-link:hover .list-score .score-container .flag {
  color: #791111;
  transition: color .5s;
}

.league-promo .live-match .flag.flag-live {
  color: #d21e1e;
  top: -46px;
}

.league-promo .live-match .flag.flag-live:before {
  content: " ●";
  color: #d21e1e;
  margin-top: 3px;
  margin-right: 3px;
  font-size: 15px;
}

.league-promo .link {
  padding-bottom: 15px;
}

.league-promo hr {
  margin: 0;
}

.league-promo span.box-match {
  display: block;
}

.league-promo span.box-match:last-of-type hr, .league-promo div.box-match:nth-last-of-type(3) hr {
  display: none;
}

.league-promo .video-small {
  padding-bottom: 15px;
}

.social-block {
  float: left;
  color: #fff;
  text-align: center;
  background: #1e1e1e;
  width: 360px;
  height: 310px;
}

.social-block .facebook-block, .social-block .twitter-block, .social-block .other-block {
  padding: 30px 30px 15px;
  display: none;
}

.social-block .title {
  font-size: 26px;
  font-weight: 300;
}

.social-block .fb-plugin {
  height: 130px;
  margin: 30px 0;
}

.social-block .tw-plugin {
  height: 130px;
  margin: 30px 0;
  padding-top: 40px;
}

.social-block .fb-watch, .social-block .tw-watch {
  cursor: pointer;
  color: #aaa;
  font-size: 13px;
  text-decoration: underline;
}

.social-block .other-block {
  padding-top: 25px;
}

.social-block .other-block a.club-logo {
  margin: -15px auto 0;
  transition: all .2s ease-in-out;
  display: block;
}

.social-block .other-block a.club-logo:hover {
  transform: scale(1.1);
}

.meta-container {
  background: #fff;
  width: 1050px;
  position: relative;
}

.meta-container .author-container {
  float: left;
  width: 100px;
  height: 220px;
  position: relative;
  overflow: hidden;
}

.meta-container .author-container img.author-photo {
  border: 0;
  max-width: 105px;
  position: absolute;
  bottom: 0;
  left: -5px;
}

.meta-container .meta {
  float: left;
  width: 760px;
  margin: 20px 0 15px;
}

.meta-container .meta .category-name {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
}

.meta-container .meta .datetime {
  color: #aaa;
  font-size: 13px;
  line-height: 20px;
}

@media (max-width: 450px) {
  .meta-container .meta .datetime {
    width: 100%;
    margin-top: 8px;
  }
}

.meta-container .meta h1.title {
  width: 690px;
  margin: 5px 0 10px;
  line-height: 42px;
  display: block;
}

.meta-container .meta h1.title:before, .meta-container .meta h1.title:after {
  content: "";
  display: table;
}

.meta-container .meta h1.title:after {
  clear: both;
}

.meta-container .meta .author-name-container {
  float: left;
  color: #aaa;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 700;
}

.meta-container .meta .author-name-container a {
  color: #aaa;
}

.meta-container .meta__top {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 11px;
  display: flex;
}

@media (max-width: 450px) {
  .meta-container .meta__top {
    margin-bottom: 13px;
  }
}

.meta-container .meta__category-flags {
  align-items: center;
  margin-right: 30px;
}

.meta-container .related-article-container {
  float: left;
  margin-top: 15px;
}

.meta-container .related-article-container .isport-premium.smallest {
  width: 53px;
  height: 15px;
}

.meta-container .related-article-container .isport-premium.smallest .dataimg-isport-premium {
  width: 55px;
  margin-left: -1px;
}

.meta-container .related-article-container.tipsport-container {
  min-height: 130px;
}

.meta-container .related-article-container .caption-arrow {
  border: 0;
  width: 150px;
}

.meta-container .related-article-container .caption-arrow .caption-title {
  padding: 0 0 0 25px;
}

.meta-container .related-article-container .caption-arrow .caption-title .category-link {
  margin: 5px 0;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
}

.meta-container .related-article-container .caption-arrow .arrow {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #aaa;
  top: -1px;
}

.meta-container .related-article-container .caption-arrow .arrow-inner {
  border-width: 92px 0 92px 29px;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff;
  top: 2px;
  right: -29px;
}

.meta-container:before, .meta-container:after {
  content: "";
  display: table;
}

.meta-container:after {
  clear: both;
}

@media screen and (max-width: 760px) {
  .meta-container {
    width: 100%;
  }

  .meta-container .meta {
    float: none;
    width: auto;
    margin: 10px;
  }

  .meta-container .meta h1.title {
    width: 100%;
    font-size: 1.7em;
    line-height: 1.3;
  }
}

.image-popup {
  cursor: pointer;
  z-index: 9999;
  visibility: hidden;
  position: absolute;
}

.image-popup .close-popup {
  text-align: right;
  color: #fff;
  text-transform: uppercase;
  background: #1e1e1e;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 13px;
  font-weight: 700;
  line-height: 32px;
  text-decoration: none;
}

.image-popup .close-popup .ico {
  vertical-align: middle;
  margin: 0 5px;
  padding-bottom: 5px;
  font-size: 40px;
  font-weight: 100;
  display: inline-block;
  transform: rotate(45deg);
}

.image-popup .description {
  color: #fff;
  background: #1e1e1e;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 10px;
  font-size: 17px;
}

.image-popup .description span {
  margin-top: 5px;
  font-size: 14px;
  display: inline-block;
}

.search-container {
  z-index: 1;
  display: inline-block;
  position: relative;
}

.search-container .dataimg-magnifier {
  width: 20px;
  height: 20px;
  position: relative;
}

.search-container .link-with-icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 5px;
  position: relative;
}

.search-container .link-with-icon .dataimg-magnifier {
  position: relative;
  top: auto;
  left: auto;
}

.search-container form {
  width: 215px;
  padding: 0;
  display: none;
  position: absolute;
  top: -4px;
  right: -5px;
}

.search-container form .replaced-submit {
  cursor: pointer;
  float: left;
  width: 100%;
  display: block;
  position: relative;
}

.search-container form .replaced-submit .form-inline-button .button-search {
  background-color: #791111;
  width: 40px;
  padding-top: 9px;
  padding-bottom: 10px;
}

.search-container form .replaced-submit .form-inline-button .button-search .dataimg-magnifier {
  margin: auto;
}

.search-container form .replaced-submit .form-inline-button .button-search .dataimg-magnifier path {
  fill: #fff;
}

.search-container form .replaced-submit input[type="text"] {
  width: calc(100% - 40px);
  font-size: 13px;
}

.cover {
  width: 208px;
  display: inline-block;
}

.cover .cover-image {
  transition: transform .5s;
  position: relative;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, .25);
}

.cover .cover-image a {
  display: inline-block;
}

.cover .cover-image img {
  position: relative;
}

.cover .cover-image:hover {
  transform: translateY(-5px);
}

.cover .cover-image.animation-off:hover {
  transform: none;
}

.cover .cover-image .locked {
  z-index: 10;
  background-color: rgba(0, 0, 0, .65);
  width: 208px;
  height: 30%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.cover .cover-image .locked.ikiosek {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 1px;
  font-size: 15px;
  font-weight: 700;
  line-height: 90px;
}

.cover .cover-image .locked .dataimg-lock {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cover .cover-image .locked .dataimg-lock svg {
  height: 30px;
}

.cover .cover-image .locked .dataimg-lock:before, .cover .cover-image .locked .dataimg-lock:after {
  content: "";
  display: table;
}

.cover .cover-image .locked .dataimg-lock:after {
  clear: both;
}

.cover .cover-date {
  margin: 5px 0 15px;
  font-weight: bold;
}

.cover .cover-date a {
  color: #323232;
}

.cover .cover-date a:hover {
  color: inherit;
}

.cover .cover-date:before, .cover .cover-date:after {
  content: "";
  display: table;
}

.cover .cover-date:after {
  clear: both;
}

.cover .cover-indicator {
  border-bottom: 10px solid #d21e1e;
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  margin: 0 auto;
  display: none;
}

.gallery-container {
  background-color: #1e1e1e;
  height: 100%;
}

.gallery-container .gallery-container {
  width: 1050px;
  height: 620px;
  margin: 35px auto 60px;
}

.gallery-main-container.locked-true:before {
  z-index: 11;
  content: " ";
  width: 100%;
  height: 310px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.gallery-main-container .icons {
  z-index: 11;
  width: 184px;
  height: 31px;
  position: absolute;
  top: 5.6%;
  left: 12%;
}

.gallery-main-container .btn {
  top: 0;
}

@media screen and (max-width: 760px) {
  .gallery-container .gallery-container {
    width: 100%;
    height: 350px;
    margin-bottom: 60px;
  }

  .gallery-container .image-description {
    float: left;
    padding: 15px;
  }

  .gallery-main-container .gallery .btn {
    width: 50px;
  }

  .gallery-main-container .gallery .next .image-count {
    font-size: .8em;
  }
}

.match .header-container {
  margin-bottom: 10px;
}

.match .header-container .date {
  float: left;
  text-transform: uppercase;
  width: 80%;
  font-family: Roboto, arial, sans-serif;
  font-size: 13px;
  font-weight: 700;
}

.match .header-container .date h1 {
  font-family: Roboto, arial, sans-serif;
  font-size: 13px;
}

.match .header-container .status {
  float: right;
  text-align: right;
  color: #aaa;
  width: 20%;
  font-size: 13px;
}

.match .header-container:before, .match .header-container:after {
  content: "";
  display: table;
}

.match .header-container:after {
  clear: both;
}

.match .match-container .team-container {
  float: left;
  text-align: right;
  width: 440px;
}

.match .match-container .team-container .team-name {
  vertical-align: middle;
  width: 335px;
  font-size: 26px;
  font-weight: 700;
  display: inline-block;
}

.match .match-container .team-container .team-name > a {
  text-decoration: underline;
}

.match .match-container .team-container .logo-container {
  vertical-align: middle;
  width: 80px;
  height: 80px;
  margin-left: 20px;
  display: inline-block;
  overflow: hidden;
}

.match .match-container .team-container .scorers {
  width: 335px;
  padding: 0;
  font-size: 13px;
  line-height: 1.5;
  position: relative;
  top: -15px;
}

.match .match-container .team-container.team-away {
  text-align: left;
}

.match .match-container .team-container.team-away .logo-container {
  margin: 0 20px 0 0;
}

.match .match-container .team-container.team-away .scorers {
  float: right;
}

.match .match-container .team-container.team-away .scorers:before, .match .match-container .team-container.team-away .scorers:after {
  content: "";
  display: table;
}

.match .match-container .team-container.team-away .scorers:after {
  clear: both;
}

.match .match-container .team-container .dot-separator {
  vertical-align: middle;
  border: 2px solid #1e1e1e;
  border-radius: 2px;
  width: 2px;
  height: 2px;
  margin-right: 5px;
  display: inline-block;
}

.match .match-container .match-online {
  width: 232px;
  margin-top: 30px;
  font-size: 13px;
  font-weight: bold;
  display: block;
}

.match .match-container .match-online.tenis {
  float: left;
  margin-left: 40px;
}

.match .match-container:before, .match .match-container:after {
  content: "";
  display: table;
}

.match .match-container:after {
  clear: both;
}

.match .score-container {
  float: left;
  text-align: center;
  width: 130px;
  margin-bottom: 10px;
}

.match .score-container.score-big .score {
  margin-top: 20px;
  font-size: 35px;
}

.match .score-container.score-big .score .colon {
  vertical-align: 5px;
  font-size: 26px;
}

.match .score-container .score {
  margin-top: 6px;
  font-size: 50px;
  font-weight: 300;
}

.match .score-container .score .colon {
  vertical-align: 7px;
  margin: 0 2px;
  font-size: 30px;
  display: inline-block;
}

.match .score-container .score .score-pen {
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: bold;
}

.match .score-container .score .score-pen-ot, .match .score-container .score .score-pen-sn {
  height: 20px;
  display: inline-block;
  position: relative;
}

.match .score-container .score .score-pen-ot:before, .match .score-container .score .score-pen-sn:before {
  letter-spacing: 1px;
  margin-left: 3px;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  position: absolute;
  bottom: -5px;
}

.match .score-container .score .score-pen-ot:before {
  content: "P";
}

.match .score-container .score .score-pen-sn:before {
  content: "SN";
}

.match .score-container .periods {
  font-size: 13px;
}

.match .score-container .periods.periods-small {
  font-size: 11px;
}

.match .score-container .series-score {
  margin-top: 5px;
  font-size: 13px;
}

.match .score-container .date {
  font-size: 50px;
}

.match .score-container .flag-online {
  display: none;
}

.match .players-container {
  font-size: 13px;
  line-height: 1.5;
}

.match .players-container .team-players {
  float: left;
  width: 335px;
}

.match .players-container .team-players .team-name {
  font-weight: 700;
}

.match .players-container .team-players .all-players {
  padding-right: 50px;
}

.match .players-container .referee-container {
  text-align: center;
  float: left;
  color: #aaa;
  width: 340px;
  padding: 0 20px;
  font-weight: 700;
}

.match .players-container .referee-container .game-place {
  margin-top: 30px;
  font-weight: 400;
}

.match .players-container .referee-container .referee-name {
  font-weight: 400;
}

.match .players-container:before, .match .players-container:after {
  content: "";
  display: table;
}

.match .players-container:after {
  clear: both;
}

.match hr {
  margin-top: 0;
}

.match.status-live .score-container .score, .match.status-live .score-player-container .result-final {
  color: #d21e1e;
}

.match.ten {
  padding: 20px 20px 0;
}

.match.ten .match-container {
  float: none;
  width: 100%;
  padding: 0;
}

.match.ten .match-container .team-container {
  float: none;
  text-align: left;
  width: 100%;
  padding: 20px 0;
}

.match.ten .match-container .team-container div {
  vertical-align: middle;
  display: inline-block;
}

.match.ten .match-container .team-container .logo-container {
  margin: 0 20px 0 0;
}

.match.ten .match-container .team-container .logo-container.none-photo {
  background: #fff;
}

.match.ten .match-container .team-container .team-name-container {
  width: 360px;
  line-height: 31px;
}

.match.ten .match-container .team-container .service-container {
  width: 80px;
}

.match.ten .match-container .team-container .service-container div {
  width: 30px;
  height: 30px;
}

.match.ten .match-container .team-container .score-player-container {
  font-size: 26px;
  font-weight: 300;
}

.match.ten .match-container .team-container .score-player-container div {
  margin-right: 21px;
}

.match.ten .match-container .team-container .score-player-container .result-final {
  margin-right: 50px;
  font-size: 50px;
}

.match.ten .match-container .team-container .score-player-container .set-part {
  position: relative;
}

.match.ten .match-container .team-container .score-player-container .set-part sup {
  font-size: 13px;
  position: relative;
  top: -15px;
  left: 2px;
}

.match.ten .match-container .team-container .score-player-container .set-part .tiebreak {
  font-size: 13px;
  position: absolute;
  top: -3px;
  left: 15px;
}

.match.ten .match-container .team-container.team-away {
  border-top: 1px solid #dcdcdc;
}

.match.ten .match-container .team-container:before, .match.ten .match-container .team-container:after {
  content: "";
  display: table;
}

.match.ten .match-container .team-container:after {
  clear: both;
}

.match.box > h2 {
  display: none;
}

.match-stats {
  margin-bottom: 20px;
  position: relative;
}

.match-stats .section-title {
  color: #00a442;
  border-bottom: 2px solid #00a442;
  margin: 0 0 10px;
  padding: 10px 0 5px;
  font-weight: bold;
}

.match-stats .home-kit, .match-stats .away-kit {
  float: left;
  background-repeat: no-repeat;
  width: calc(50% - 280px);
  height: 350px;
  margin-top: -50px;
  position: relative;
  overflow: hidden;
}

.match-stats .home-kit img, .match-stats .away-kit img {
  max-width: unset;
  width: 320px;
  position: absolute;
  top: 45px;
}

.match-stats .home-kit {
  background-position: -310px 0;
  transition: all 1s;
}

.match-stats .home-kit.come-in {
  background-position: -130px 0;
}

.match-stats .home-kit img {
  left: -120px;
}

.match-stats .away-kit {
  background-position: 180px 0;
  transition: all 1s;
}

.match-stats .away-kit.come-in {
  background-position: 0 0;
}

.match-stats .away-kit img {
  right: -120px;
}

.match-stats .stats {
  float: left;
  width: 560px;
  min-height: 300px;
}

.match-stats .stats .stats-item {
  width: 560px;
  height: 20px;
  margin: 5px 0;
  padding: 5px 0;
  font-weight: bold;
}

.match-stats .stats .stats-item.suda {
  background-color: #e6e6e6;
}

.match-stats .stats .stats-item .bar {
  float: left;
  width: 170px;
}

.match-stats .stats .stats-item .bar .inner-bar {
  text-align: left;
  line-height: 20px;
  font-family: 700;
  color: #1e1e1e;
  background-color: #1e1e1e;
  width: 1px;
  height: 10px;
  padding: 0 5px;
  transition: all 1s;
  position: relative;
}

.match-stats .stats .stats-item .bar .inner-bar .value {
  width: 50px;
  display: block;
  position: absolute;
  top: -5px;
  right: -60px;
  text-align: left !important;
}

.match-stats .stats .stats-item .bar.home {
  margin-left: 5px;
}

.match-stats .stats .stats-item .bar.home .inner-bar {
  float: right;
  text-align: right;
}

.match-stats .stats .stats-item .bar.home .inner-bar .value {
  left: -60px;
  right: auto;
  text-align: right !important;
}

.match-stats .stats .stats-item .title {
  float: left;
  text-align: center;
  text-transform: uppercase;
  width: 200px;
  font-family: Roboto, arial, sans-serif;
  font-size: 12px;
  position: relative;
  top: -1px;
}

.match-stats .stats .stats-item:before, .match-stats .stats .stats-item:after {
  content: "";
  display: table;
}

.match-stats .stats .stats-item:after {
  clear: both;
}

.match-stats .stats:before, .match-stats .stats:after {
  content: "";
  display: table;
}

.match-stats .stats:after {
  clear: both;
}

.match-stats:before, .match-stats:after {
  content: "";
  display: table;
}

.match-stats:after {
  clear: both;
}

.view-more {
  width: 110px;
  margin: 0 auto;
  padding: 10px;
  position: relative;
  overflow: hidden;
}

.view-more .football .sport-color svg path {
  fill: #00a442;
}

.view-more .football .ico {
  float: right;
  width: 15px;
  height: 10px;
  margin: 7px 5px;
  position: relative;
}

.fortuna-hidden-box__head {
  background-color: #fff;
}

.fortuna-hidden-box__inner-animate {
  background-color: #fff;
  height: 0;
  overflow: hidden;
}

.fortuna-hidden-box__inner-height {
  height: auto;
  padding-bottom: 10px;
  overflow: hidden;
}

.field-container {
  background-image: url("../../../images/isportcz/dist/non-sprite/online/hriste.png?v=1");
  width: 1007px;
  height: 687px;
  margin: 15px 0;
  display: block;
  position: relative;
}

.field-container .photo-frame {
  background-position: top;
  background-repeat: no-repeat;
  height: 120px;
}

.field-container .photo-frame .numPlayer {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  top: 18px;
}

.field-container .photo-frame .name {
  color: #fff;
  text-align: center;
  position: relative;
  top: 42px;
}

.field-container .photo-frame .icons {
  width: 150px;
  padding: 0 0 0 65%;
}

.field-container .photo-frame .icons .icon {
  text-indent: 100%;
  white-space: nowrap;
  background-repeat: no-repeat;
  width: 15px;
  height: 18px;
  display: inline-block;
  overflow: hidden;
}

.field-container .photo-frame .icons .icon.goal {
  background-image: url("../../../images/isportcz/dist/non-sprite/online/ico-gol-middle.png");
}

.field-container .photo-frame .icons .icon.yellow-card {
  background-image: url("../../../images/isportcz/dist/non-sprite/online/ico-zluta-middle.png");
}

.field-container .photo-frame .icons .icon.red-card {
  background-image: url("../../../images/isportcz/dist/non-sprite/online/ico-cervena-middle.png");
}

.field-container .photo-frame .icons .icon.assistance {
  background-image: url("../../../images/isportcz/dist/non-sprite/online/ico-asistence-middle.png");
}

.field-container .photo-frame .icons .icon.sub {
  background-image: url("../../../images/isportcz/dist/non-sprite/online/ico-stridani-middle.png");
}

.field-container .lines-3 .line {
  width: 25%;
}

.field-container .lines-4 .line {
  width: 20%;
}

.field-container .line {
  position: relative;
}

.field-container .line.goalkeeper, .field-container .line.players-1 {
  top: 310px;
}

.field-container .line.players-2 {
  top: 170px;
}

.field-container .line.players-2 .photo-frame {
  height: 270px;
}

.field-container .line.players-3 {
  top: 130px;
}

.field-container .line.players-3 .photo-frame {
  height: 180px;
}

.field-container .line.players-4 {
  top: 50px;
}

.field-container .line.players-4 .photo-frame {
  height: 170px;
}

.field-container .line.players-5 {
  top: 80px;
}

.field-container .line.players-5 .photo-frame {
  height: 110px;
}

.field-container .home {
  float: left;
  width: 49%;
}

.field-container .home .line {
  float: right;
}

.field-container .home .line.goalkeeper {
  left: 15px;
}

.field-container .home .line.goalkeeper .numPlayer {
  color: #e60111;
}

.field-container .home .line.players-5 .pos-1 {
  position: relative;
  left: 20px;
}

.field-container .home .line.players-5 .pos-2 {
  position: relative;
  right: 20px;
}

.field-container .home .line.players-5 .pos-3 {
  position: relative;
  left: 20px;
}

.field-container .home .line.players-5 .pos-4 {
  position: relative;
  right: 20px;
}

.field-container .home .line.players-5 .pos-5 {
  position: relative;
  left: 20px;
}

.field-container .home .photo-frame {
  background-image: url("../../../images/isportcz/dist/non-sprite/online/dres-red.png?v=1");
}

.field-container .home .goalkeeper .photo-frame {
  background-image: url("../../../images/isportcz/dist/non-sprite/online/dres-red-light.png?v=1");
}

.field-container .away {
  float: right;
  width: 49%;
}

.field-container .away .line {
  float: left;
}

.field-container .away .line.goalkeeper {
  right: 20px;
}

.field-container .away .line.goalkeeper .numPlayer {
  color: #01bfe6;
}

.field-container .away .line.players-5 .pos-1 {
  position: relative;
  right: 20px;
}

.field-container .away .line.players-5 .pos-2 {
  position: relative;
  left: 20px;
}

.field-container .away .line.players-5 .pos-3 {
  position: relative;
  right: 20px;
}

.field-container .away .line.players-5 .pos-4 {
  position: relative;
  left: 20px;
}

.field-container .away .line.players-5 .pos-5 {
  position: relative;
  right: 20px;
}

.field-container .away .photo-frame {
  background-image: url("../../../images/isportcz/dist/non-sprite/online/dres-blue.png?v=1");
}

.field-container .away .goalkeeper .photo-frame {
  background-image: url("../../../images/isportcz/dist/non-sprite/online/dres-blue-light.png?v=1");
}

.image-container a.image {
  display: block;
}

.image-container a.image .img-wrapper {
  position: relative;
}

.image-container a.image .img-wrapper .flag {
  opacity: .6;
  width: 100px;
  height: 100px;
  transition: opacity .2s ease-in-out;
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
}

.image-container a.image .img-wrapper:hover .flag {
  opacity: 1;
}

.match-online-bets a:hover {
  color: #000;
}

.match-online-bets.detail-top-section .match-fortuna-button {
  background: url("//img2.cncenter.cz/images/isportcz/dist/non-sprite/fortuna-button.png") no-repeat;
  width: 82px;
  height: 23px;
  margin: 5px auto 10px;
  display: block;
}

.match-online-bets.detail-top-section .tipsport-courses {
  margin: 20px 0;
}

.match-online-bets.detail-top-section .tipsport-courses .match-bet {
  color: #0197f4;
  float: left;
  width: 200px;
  margin: 30px 20px 0 290px;
  font-size: 15px;
  font-weight: bold;
}

.match-online-bets.detail-top-section .tipsport-courses .match-bet .tipsport-triangle {
  float: right;
  background: url("//img2.cncenter.cz/images/isportcz/dist/non-sprite/tipsport-triangle.png") no-repeat;
  width: 22px;
  height: 22px;
}

.match-online-bets.detail-top-section .tipsport-courses .match-bet.with-match-online {
  margin-left: 138px;
}

.match-online-bets.detail-top-section .tipsport-courses .match-courses {
  float: left;
  text-align: center;
  font-weight: bold;
}

.match-online-bets.detail-top-section .tipsport-courses .match-courses .name {
  font-size: 13px;
  line-height: 23px;
}

.match-online-bets.detail-top-section .tipsport-courses .match-courses .course {
  color: #fff;
  background: #0197f4;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 15px;
}

.match-online-bets.detail-top-section .tipsport-courses .match-courses .home-win, .match-online-bets.detail-top-section .tipsport-courses .match-courses .draw, .match-online-bets.detail-top-section .tipsport-courses .match-courses .away-win {
  float: left;
  margin-right: 10px;
}

.match-online-bets.detail-top-section .tipsport-courses .match-online {
  float: left;
  width: 252px;
  margin: 30px 78px 0 68px;
  font-size: 15px;
  font-weight: bold;
  display: block;
}

.match-online-bets.detail-top-section .tipsport-courses .match-online .gray {
  width: 112px;
}

.match-online-bets.detail-top-section .tipsport-courses .match-online .icone {
  margin-top: 0;
}

.match-online-bets.detail-top-section .age-restriction {
  text-align: center;
  margin: 5px 0;
  font-size: 11px;
}

.match-online-bets.detail-top-section .age-restriction > img {
  position: relative;
  top: 7px;
  left: 5px;
}

.match-online-bets.detail-top-section:before, .match-online-bets.detail-top-section:after {
  content: "";
  display: table;
}

.match-online-bets.detail-top-section:after {
  clear: both;
}

.tipsport-special {
  background: #fff;
  padding: 18px 18px 18px 12px;
}

.tipsport-special a:hover {
  color: #000;
  text-decoration: none;
}

.tipsport-special .tipsport-header .head {
  float: left;
  font-size: 25px;
  font-weight: bold;
}

.tipsport-special .tipsport-header .logo {
  float: right;
}

.tipsport-special .tipsport-bets {
  margin: 20px 0;
}

.tipsport-special .tipsport-bets > a:hover {
  color: #000;
  text-decoration: none;
}

.tipsport-special .tipsport-bets .match-courses .home-win, .tipsport-special .tipsport-bets .match-courses .draw, .tipsport-special .tipsport-bets .match-courses .away-win {
  float: left;
  text-align: center;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  width: 200px;
  margin-right: 30px;
}

.tipsport-special .tipsport-bets .match-courses .home-win .course, .tipsport-special .tipsport-bets .match-courses .draw .course, .tipsport-special .tipsport-bets .match-courses .away-win .course {
  color: #0197f4;
  font-size: 25px;
  font-weight: bold;
  line-height: 35px;
}

.tipsport-special .tipsport-bets .match-courses .home-win .graph, .tipsport-special .tipsport-bets .match-courses .draw .graph, .tipsport-special .tipsport-bets .match-courses .away-win .graph {
  background: #dcdcdc;
  width: 122px;
  height: 4px;
  margin: 0 auto 10px;
}

.tipsport-special .tipsport-bets .match-courses .home-win .graph .bar, .tipsport-special .tipsport-bets .match-courses .draw .graph .bar, .tipsport-special .tipsport-bets .match-courses .away-win .graph .bar {
  background: #0197f4;
  height: 4px;
}

.tipsport-special .tipsport-bets .match-courses .home-win .name, .tipsport-special .tipsport-bets .match-courses .draw .name, .tipsport-special .tipsport-bets .match-courses .away-win .name {
  background: #f0f0f0;
  font-size: 15px;
  font-weight: bold;
  line-height: 35px;
}

.tipsport-special .tipsport-bets .match-courses .away-win {
  margin-right: 0;
}

.tipsport-special .tipsport-bets .tipsport-tickets {
  margin: 50px 0 40px;
}

.tipsport-special .tipsport-bets .tipsport-tickets .ticket {
  float: left;
  text-align: center;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  width: 200px;
  margin-top: 20px;
  margin-right: 30px;
  padding-top: 10px;
}

.tipsport-special .tipsport-bets .tipsport-tickets .ticket.last {
  margin-right: 0;
}

.tipsport-special .tipsport-bets .tipsport-tickets .ticket .label {
  font-size: 15px;
  font-weight: bold;
}

.tipsport-special .tipsport-bets .tipsport-tickets .ticket .value {
  margin-bottom: 20px;
  font-size: 15px;
}

.tipsport-special .tipsport-bets .tipsport-tickets .ticket .user {
  background: #f0f0f0;
}

.tipsport-special .tipsport-bets .tipsport-tickets .ticket .user .name {
  color: #0197f4;
  padding-top: 5px;
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
}

.tipsport-special .tipsport-bets .tipsport-tickets .ticket .user .inspirations {
  padding-bottom: 5px;
  font-size: 12px;
}

.tipsport-special .tipsport-bets .tipsport-tickets .ticket .show-ticket {
  color: #fff;
  background: #0197f4;
  padding: 10px 0;
  font-size: 15px;
}

.tipsport-special .tipsport-bets .tipsport-tickets .ticket .show-ticket a, .tipsport-special .tipsport-bets .tipsport-tickets .ticket .show-ticket a:hover {
  color: #fff;
}

.tipsport-special .tipsport-bets .tipsport-analyzes .analyze {
  margin: 20px 0;
}

.tipsport-special .tipsport-bets .tipsport-analyzes .analyze .left {
  float: left;
  width: 300px;
}

.tipsport-special .tipsport-bets .tipsport-analyzes .analyze .left .tip {
  font-size: 18px;
  font-weight: bold;
}

.tipsport-special .tipsport-bets .tipsport-analyzes .analyze .left .author {
  margin: 5px 0;
}

.tipsport-special .tipsport-bets .tipsport-analyzes .analyze .left .author .client {
  color: #0197f4;
  font-weight: bold;
}

.tipsport-special .tipsport-bets .tipsport-analyzes .analyze .right .course {
  float: right;
  color: #fff;
  text-align: center;
  background: #0197f4;
  border-radius: 5px;
  width: 200px;
  padding: 15px 0;
  font-size: 15px;
  line-height: 20px;
}

.tipsport-special .tipsport-bets .tipsport-analyzes .analyze .right .course a {
  color: #fff;
}

.tipsport-special .tipsport-bets .tipsport-analyzes .analyze .right .course a :hover {
  color: #fff;
  text-decoration: none;
}

.tipsport-special .tipsport-bets .tipsport-analyzes .analyze .right .course .rate {
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
}

.tipsport-special .tipsport-bets .tipsport-analyzes .analyze .text {
  margin: 10px 0;
  font-size: 15px;
  line-height: 21px;
}

.tipsport-special .age-restriction {
  text-align: center;
  margin: 5px 0;
  font-size: 11px;
}

.tipsport-special .age-restriction > img {
  position: relative;
  top: 7px;
  left: 5px;
}

.sazkabet {
  background: #fff;
  margin-bottom: 25px;
  padding: 10px 15px;
}

.sazkabet .head {
  border-bottom: 2px solid #ffde00;
}

.sazkabet .head .left {
  float: left;
}

.sazkabet .head .left > a {
  color: #010066;
  font-size: 22px;
  font-weight: bold;
  line-height: 47px;
}

.sazkabet .head .right {
  float: right;
}

.sazkabet .head .right img {
  position: relative;
  top: 8px;
}

.sazkabet .league {
  text-transform: uppercase;
  color: #010066;
  margin: 20px 0 15px;
  font-size: 13px;
  font-weight: bold;
}

.sazkabet .matches-list .match {
  border-top: 1px solid #dcdcdc;
  padding: 5px 0;
}

.sazkabet .matches-list .match .home-team {
  float: left;
  text-align: right;
  color: #000;
  width: 29%;
  margin-top: 5px;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
}

.sazkabet .matches-list .match .home-team-logo {
  float: left;
  text-align: center;
  width: 10%;
}

.sazkabet .matches-list .match .home-team-logo > img {
  height: 25px;
}

.sazkabet .matches-list .match .date {
  float: left;
  text-align: center;
  color: #828282;
  width: 22%;
  font-size: 10px;
  line-height: 12px;
}

.sazkabet .matches-list .match .away-team-logo {
  float: left;
  text-align: center;
  width: 10%;
}

.sazkabet .matches-list .match .away-team-logo > img {
  height: 25px;
}

.sazkabet .matches-list .match .away-team {
  float: left;
  text-align: left;
  color: #000;
  width: 29%;
  margin-top: 5px;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
}

.sazkabet .bet {
  text-align: center;
  color: #010066;
  text-transform: uppercase;
  margin: 10px 0 0;
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
}

.sazkabet .age-restriction {
  text-align: center;
  margin: 10px 0 5px;
  font-size: 9px;
  line-height: 9px;
}

.sazkabet .age-restriction > img {
  position: relative;
  top: 7px;
  left: 5px;
}

.tipsport-most-bets {
  background: #fff;
  margin: 2%;
}

.tipsport-most-bets .tipsport-header {
  background: #0197f4;
  padding: 5px 10px;
}

.tipsport-most-bets .tipsport-header .logo {
  float: left;
  width: 30%;
}

.tipsport-most-bets .tipsport-header .head {
  float: right;
  color: #fff;
  text-transform: uppercase;
  text-align: right;
  width: 70%;
  margin-top: 8px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1em;
}

.tipsport-most-bets .matches > table {
  width: 96%;
  margin: 5px 2%;
}

.tipsport-most-bets .matches > table tr.table-head {
  color: #828282;
}

.tipsport-most-bets .matches > table tr.table-head td {
  text-align: center;
  padding: 0;
  font-size: 13px;
  line-height: 15px;
}

.tipsport-most-bets .matches > table tr.match .names {
  padding: 0;
}

.tipsport-most-bets .matches > table tr.match .names a {
  color: #000;
  font-size: 13px;
  font-weight: bold;
  line-height: 15px;
}

.tipsport-most-bets .matches > table tr.match .names a > img {
  float: left;
  margin-right: 5px;
}

.tipsport-most-bets .matches > table tr.match .win, .tipsport-most-bets .matches > table tr.match .draw, .tipsport-most-bets .matches > table tr.match .loose {
  padding: 0;
}

.tipsport-most-bets .matches > table tr.match .win .value, .tipsport-most-bets .matches > table tr.match .draw .value, .tipsport-most-bets .matches > table tr.match .loose .value {
  color: #fff;
  text-align: center;
  background: #0197f4;
  border-radius: 5px;
  width: 90%;
  padding: 4px;
  font-size: 13px;
  font-weight: bold;
  line-height: 15px;
  display: inline-block;
}

.tipsport-most-bets .matches > table tr.separator {
  height: 5px;
}

.tipsport-most-bets .matches .more-matches {
  text-align: center;
  width: 100%;
  padding: 5px 0 10px;
}

.tipsport-most-bets .matches .more-matches > a {
  color: #0197f4;
  font-size: 15px;
  text-decoration: underline;
}

@media screen and (max-width: 760px) {
  .match-online-bets.detail-top-section .tipsport-courses .match-bet {
    float: none;
    width: 200px;
    margin: 10px auto;
  }

  .match-online-bets.detail-top-section .tipsport-courses .match-courses {
    float: none;
    width: 80%;
    margin: 10px auto;
  }

  .match-online-bets.detail-top-section .tipsport-courses .match-courses .home-win, .match-online-bets.detail-top-section .tipsport-courses .match-courses .draw, .match-online-bets.detail-top-section .tipsport-courses .match-courses .away-win {
    width: 32%;
    margin-right: 2%;
  }

  .match-online-bets.detail-top-section .tipsport-courses .match-courses .away-win {
    margin-right: 0%;
  }

  .match-online-bets.detail-top-section .tipsport-courses .match-online {
    float: none;
    width: 252px;
    margin: 30px auto 0;
  }

  .tipsport-special .tipsport-header .head {
    font-size: 16px;
  }

  .tipsport-special .tipsport-header .head .logo {
    width: 100px;
  }

  .tipsport-special .tipsport-bets .match-courses .home-win {
    width: 30%;
    margin-right: 3%;
  }

  .tipsport-special .tipsport-bets .match-courses .home-win .course {
    font-size: 20px;
  }

  .tipsport-special .tipsport-bets .match-courses .home-win .graph {
    width: 80%;
  }

  .tipsport-special .tipsport-bets .match-courses .home-win .name {
    font-size: 14px;
  }

  .tipsport-special .tipsport-bets .match-courses .draw {
    width: 30%;
    margin-right: 3%;
  }

  .tipsport-special .tipsport-bets .match-courses .draw .course {
    font-size: 20px;
  }

  .tipsport-special .tipsport-bets .match-courses .draw .graph {
    width: 80%;
  }

  .tipsport-special .tipsport-bets .match-courses .draw .name {
    font-size: 14px;
  }

  .tipsport-special .tipsport-bets .match-courses .away-win {
    width: 30%;
    margin-right: 3%;
  }

  .tipsport-special .tipsport-bets .match-courses .away-win .course {
    font-size: 20px;
  }

  .tipsport-special .tipsport-bets .match-courses .away-win .graph {
    width: 80%;
  }

  .tipsport-special .tipsport-bets .match-courses .away-win .name {
    font-size: 14px;
  }

  .tipsport-special .tipsport-bets .tipsport-tickets {
    margin: 20px 0;
  }

  .tipsport-special .tipsport-bets .tipsport-tickets .ticket {
    width: 48%;
    margin: 20px 1%;
  }

  .tipsport-special .tipsport-bets .tipsport-tickets .ticket.last {
    display: none;
  }

  .tipsport-special .tipsport-bets .tipsport-analyzes .analyze .left {
    width: 60%;
  }

  .tipsport-special .tipsport-bets .tipsport-analyzes .analyze .right {
    float: left;
    width: 40%;
  }

  .tipsport-special .tipsport-bets .tipsport-analyzes .analyze .right .course {
    width: 100%;
    padding: 5px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.event {
  border-bottom: 1px solid #e6e6e6;
  width: 100%;
  padding: 10px;
}

.event .event-part {
  float: left;
}

.event .event-time {
  color: #aaa;
  width: 15%;
  min-height: 10px;
  margin-top: 5px;
}

.event .ico-wrap {
  width: 10%;
  margin-top: 5px;
}

.event .ico-wrap .event-ico {
  width: 25px;
  min-height: 10px;
}

.event .ico-wrap .event-ico > div {
  width: 25px;
  height: 25px;
}

.event .ico-wrap .event-ico .dataimg-match-yellow-card svg g path.st1 {
  fill: #efc100;
}

.event .ico-wrap .event-ico .dataimg-match-red-card svg g path.st1 {
  fill: #d21e1e;
}

.event .event-text {
  width: 73%;
  font-size: 12.5pt;
  line-height: 150%;
}

.event .event-text.event-gol {
  font-weight: 700;
}

.event:last-of-type {
  border: 0;
}

.event:before, .event:after {
  content: "";
  display: table;
}

.event:after {
  clear: both;
}

.button-refresh-match {
  margin: 20px 0 20px 230px;
}

.button-refresh-match:focus {
  color: #fff;
  background: #d21e1e;
}

.online-report .event {
  padding: 5px 10px;
}

.online-report .event iframe {
  min-height: 10px;
  margin-left: 0;
}

.backbone-view {
  transition: opacity .2s ease-in-out;
}

.backbone-view.loading {
  opacity: .3;
}

#bb-refresh-view.loading .button-refresh-match {
  color: #fff;
  background: #791111;
}

#bb-refresh-view.loading .button-refresh-match:before {
  content: " ";
  background: url("/images/isportcz/online-prenosy/loading.png") no-repeat;
  width: 22px;
  height: 23px;
  margin-top: -12px;
  animation: 1s linear infinite rotate;
  display: block;
  position: absolute;
  top: 50%;
  left: -30px;
}

.backbone-bar a {
  text-transform: uppercase;
  padding: 0 10px 20px;
  font-weight: bold;
  display: inline-block;
}

.backbone-bar a.active {
  color: #e60111;
}

.backbone-view-extended .invisibleEvent {
  border: none;
  display: none;
}

.phase-matches {
  border-bottom: 2px solid #00a442;
  margin: 0 0 10px;
  padding: 10px 0 12px;
}

.phase-matches.hockey {
  border-color: #325edc;
}

.mobile .phase-matches {
  position: relative;
}

.phase-matches span {
  color: #00a442;
  font-weight: bold;
}

.phase-matches .navigation-section {
  width: 200px;
  display: inline-block;
  position: absolute;
  right: 0;
}

.mobile .phase-matches .navigation-section {
  background: #fff;
  top: 0;
}

.phase-matches .navigation-section .menu-container {
  width: 100%;
}

.mobile .phase-matches .navigation-section .dropdown-menu ul {
  width: 100%;
  margin: 5px 0;
  padding: 0 30px 0 20px;
}

.phase-matches .navigation-section .dropdown-menu li.dropdown-title {
  height: 30px;
}

.mobile .phase-matches .navigation-section .dropdown-menu li.dropdown-title {
  width: 100%;
}

.phase-matches .navigation-section .menu-container > ul > li > a {
  padding: 10px 30px 19px 10px;
}

.mobile .phase-matches .navigation-section .menu-container > ul > li > a {
  padding: 10px 30px 5px 10px;
}

.phase-matches .navigation-section .menu-container > ul > li > ul {
  width: 280px;
  right: -30px;
}

.mobile .phase-matches .navigation-section .menu-container > ul > li > ul {
  width: 280px;
}

.phase-matches .navigation-section .dropdown-menu li .dataimg-arrow-down {
  top: 15px;
}

.phase-matches-select {
  float: right;
  display: inline-block;
}

.pagination-hr {
  margin: 0;
}

.title-pagination {
  text-align: center;
  position: relative;
}

.title-pagination .dataimg-arrow-light-right {
  width: 13px;
  display: flex;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.title-pagination .dataimg-arrow-light-right path {
  fill: #d21e1e;
}

.title-pagination .dataimg-arrow-light-left {
  width: 13px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.title-pagination .dataimg-arrow-light-left path {
  fill: #d21e1e;
}

.title-pagination .current-item, .title-pagination .next-item, .title-pagination .prev-item {
  line-height: 60px;
}

.title-pagination .next-item, .title-pagination .prev-item {
  color: #828282;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
}

.title-pagination .next-item:hover, .title-pagination .prev-item:hover {
  text-decoration: none;
}

.title-pagination .prev-item {
  position: absolute;
  top: 0;
  left: 0;
}

.title-pagination .prev-span {
  margin-left: 20px;
}

.title-pagination .next-item {
  position: absolute;
  top: 0;
  right: 0;
}

.title-pagination .next-span {
  margin-right: 20px;
}

.title-pagination .current-item {
  color: #d21e1e;
  margin: 0 auto;
  font-family: Roboto, arial, sans-serif;
  font-size: 22px;
  font-weight: 700;
  display: inline-block;
}

@media screen and (max-width: 760px) {
  .title-pagination .next-span, .title-pagination .prev-span {
    visibility: hidden;
  }
}

.pattern-epaper {
  background-color: #f0f0f0;
  max-width: 1030px;
}

.code-errors {
  margin: 0;
}

.code-errors .error-text {
  color: #d21e1e;
  text-align: center;
  margin-top: -20px;
  margin-bottom: 20px;
}

.epaper-center {
  margin-top: 30px;
  margin-bottom: 30px;
}

.epaper-center p {
  color: #323232;
  font-size: 13px;
}

.epaper-center p a {
  text-decoration: underline;
}

.epaper-center h3 {
  color: #323232;
  margin-bottom: 30px;
  font-family: Roboto, arial, sans-serif;
  font-weight: 300;
}

.epaper-center form.default-form .button-main {
  width: 100%;
}

.epaper-center .epaper-center-left {
  float: left;
  width: 27.5%;
  padding-right: 30px;
}

.epaper-center .epaper-center-center {
  text-align: center;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  width: 45%;
  margin: 0 auto;
  padding: 0 40px;
  display: inline-block;
}

.epaper-center .epaper-center-right {
  float: right;
  width: 27.5%;
  padding-left: 30px;
}

.epaper-center .epaper-center-right .form-field input:first-of-type {
  margin-bottom: 10px;
}

.epaper-center .epaper-center-right .dataimg-appstore, .epaper-center .epaper-center-right .dataimg-google-play {
  float: left;
  text-align: center;
  width: 50%;
  display: block;
}

.epaper-center .epaper-center-right .dataimg-appstore {
  margin-top: 1px;
  padding-left: 20px;
}

.epaper-center .epaper-center-right .dataimg-appstore svg {
  width: auto;
  height: 28px;
}

.epaper-center .epaper-center-right .dataimg-google-play {
  padding-right: 20px;
}

.epaper-center .epaper-center-right .dataimg-google-play svg {
  width: auto;
  height: 30px;
}

.epaper-center .epaper-center-right .forgotten-pass {
  float: right;
  color: #d21e1e;
  font-size: 13px;
  display: inline-block;
}

.epaper-center .epaper-center-right .forgotten-pass .link {
  text-decoration: none;
}

.epaper-center .epaper-center-right .forgotten-pass .link:hover {
  text-decoration: underline;
}

.epaper-center .epaper-center-right p {
  margin-bottom: 20px;
}

.epaper-center:before, .epaper-center:after {
  content: "";
  display: table;
}

.epaper-center:after {
  clear: both;
}

.epaper-bottom {
  margin-bottom: 20px;
  position: relative;
}

.epaper-bottom .covers {
  text-align: center;
  margin: 30px 15px;
}

.epaper-bottom .covers .cover {
  height: 352px;
  margin: 0 15px;
}

.epaper-bottom .covers .cover .cover-indicator {
  border-bottom: 10px solid #f0f0f0;
}

.epaper-bottom form.default-form .button-main {
  margin: 0 auto;
}

.epaper-bottom .cover-popup {
  float: left;
  background-color: #f0f0f0;
  width: 100%;
  height: 370px;
  margin-bottom: 20px;
  padding: 0 20px;
  display: none;
}

.epaper-bottom .cover-popup h3 {
  text-align: left;
  color: #d21e1e;
  border-bottom: 2px solid #d21e1e;
  padding: 15px 0;
  font-family: Roboto, arial, sans-serif;
}

.epaper-bottom .cover-popup .cover-popup-left {
  float: left;
}

.epaper-bottom .cover-popup .cover-popup-middle {
  float: left;
  max-width: 240px;
  margin: 0 60px;
  display: inline-block;
}

.epaper-bottom .cover-popup .cover-popup-middle h3 {
  color: #323232;
  border: 0;
  margin: 0;
  font-family: Roboto, arial, sans-serif;
  font-weight: 300;
}

.epaper-bottom .cover-popup .cover-popup-middle label {
  text-align: left;
}

.epaper-bottom .cover-popup .cover-popup-middle p {
  color: #323232;
  margin-top: 20px;
  font-size: 13px;
}

.epaper-bottom .cover-popup .cover-popup-right {
  float: left;
  margin: 0;
}

.epaper-bottom .cover-popup .cover-popup-right h3 {
  text-align: center;
  color: #323232;
  border: 0;
  margin: 0;
  font-family: Roboto, arial, sans-serif;
  font-weight: 300;
}

.epaper-bottom .cover-popup .cover-popup-right .p-cover-popup {
  color: #323232;
  margin-top: 20px;
  font-size: 13px;
}

.epaper-bottom .cover-popup .cover-popup-right .p-cover-popup a {
  text-decoration: underline;
}

.epaper-bottom .cover-popup .cover-popup-right .sms-text span {
  font-size: 16px;
}

.carousel-date-margin {
  margin: 0 40px;
}

.carousel-date {
  height: 90px;
}

.carousel-date .carousel-date-item {
  width: 100%;
}

.carousel-date .carousel-link-item {
  width: 100%;
  height: 90px;
  padding-top: 12px;
  display: inline-block;
}

.carousel-date .carousel-link-item:hover {
  text-decoration: none;
}

.carousel-date .carousel-link-item:hover .carousel-day, .carousel-date .carousel-link-item:hover .carousel-month, .carousel-date .carousel-link-item:hover .carousel-year {
  color: #d21e1e;
}

.carousel-date .carousel-link-item.selected .carousel-day {
  font-weight: 700;
}

.carousel-date .carousel-link-item.selected .carousel-day, .carousel-date .carousel-link-item.selected .carousel-month, .carousel-date .carousel-link-item.selected .carousel-year {
  color: #d21e1e;
}

.carousel-date .owl-next, .carousel-date .owl-prev {
  cursor: pointer;
  width: 40px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
}

.carousel-date .owl-next .dataimg-arrow-light-right, .carousel-date .owl-next .dataimg-arrow-light-left, .carousel-date .owl-prev .dataimg-arrow-light-right, .carousel-date .owl-prev .dataimg-arrow-light-left {
  width: 13px;
  height: 60px;
  position: absolute;
  top: 15px;
}

.carousel-date .owl-next .dataimg-arrow-light-right path, .carousel-date .owl-next .dataimg-arrow-light-left path, .carousel-date .owl-prev .dataimg-arrow-light-right path, .carousel-date .owl-prev .dataimg-arrow-light-left path {
  fill: #d21e1e;
}

.carousel-date .owl-next .dataimg-arrow-light-right, .carousel-date .owl-prev .dataimg-arrow-light-right {
  left: 0;
}

.carousel-date .owl-next .dataimg-arrow-light-left, .carousel-date .owl-prev .dataimg-arrow-light-left {
  right: 0;
}

.carousel-date .owl-prev {
  left: -40px;
}

.carousel-date .owl-next {
  right: -40px;
}

.carousel-date .carousel-day {
  text-align: center;
  color: #646464;
  font-size: 26px;
  font-weight: 300;
  display: block;
}

.carousel-date .carousel-month, .carousel-date .carousel-year {
  text-align: center;
  color: #aaa;
  font-size: 13px;
  display: block;
}

.pattern-error .h1-error {
  margin-bottom: 0;
  font-size: 40px;
  font-weight: 300;
}

.pattern-error .h2-error {
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 700;
}

.pattern-error .list-article-small {
  float: left;
  margin-top: 20px;
}

.pattern-error .link-error {
  color: #d21e1e;
  text-decoration: underline;
}

.pattern-error .eight-col, .pattern-error .four-col {
  margin: 0;
}

.pattern-error .four-col img {
  margin-top: 20px;
}

.pattern-error:before, .pattern-error:after {
  content: "";
  display: table;
}

.pattern-error:after {
  clear: both;
}

@media screen and (max-width: 760px) {
  .pattern-error {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }

  .pattern-error .eight-col, .pattern-error .four-col {
    flex-direction: column;
    flex-wrap: initial;
    margin: 0 2vw;
    display: flex;
  }

  .pattern-error .four-col {
    order: -1;
    margin-bottom: 20px;
  }

  .pattern-error .four-col img {
    width: 100%;
    max-width: 360px;
    height: 100%;
    margin: 20px auto 0;
  }

  .pattern-error .eight-col .list-article {
    margin-top: 20px;
  }

  .pattern-error .h1-error {
    font-weight: 400;
  }
}

.synottip-bets {
  position: relative;
}

.synottip-bets .synottip-reklama {
  color: #aaa;
  font-size: 9px;
  position: absolute;
  top: -12px;
  right: 0;
}

.synottip-bets .status-live {
  color: #d21e1e;
  font-size: 13px;
  font-weight: 700;
  position: absolute;
  top: 25px;
  right: 15px;
}

.synottip-bets .table-header {
  box-sizing: border-box;
  border-bottom: 2px solid #d21e1e;
  height: 52px;
  padding: 15px 0 7px;
}

.synottip-bets .table-header .title-part1 {
  font-size: 26px;
}

.synottip-bets .table-header .title-part2 {
  font-size: 30px;
}

.synottip-bets .table-header .icon-synottip {
  display: inline-block;
}

.synottip-bets .table-header .tab-bets {
  color: #aaa;
  text-align: center;
  cursor: pointer;
  background: #fff;
  border: 2px solid #aaa;
  border-bottom: 0;
  width: 122px;
  height: 40px;
  padding-top: 10px;
  font-size: 13px;
  font-weight: 700;
  position: absolute;
  top: 10px;
  left: 172px;
}

.synottip-bets .table-header .tab-bets.day-rates {
  left: 299px;
}

.synottip-bets .table-header .tab-bets.super-rates {
  left: 426px;
}

.synottip-bets .table-header .tab-bets.nhl-rates {
  left: 553px;
}

.synottip-bets .table-header .tab-bets.active {
  color: #1e1e1e;
  border: 2px solid #d21e1e;
  border-bottom: 0;
  height: 42px;
}

.synottip-bets .table-part {
  display: none;
}

.synottip-bets .table-part:first-of-type, .synottip-bets .table-part.active {
  display: block;
}

.microsite-menu {
  z-index: 1000;
  color: #fff;
  background: #d21e1e;
  width: 100%;
  position: fixed;
  top: 0;
}

.microsite-menu .microsite-title {
  border-left: 2px solid #e7b1b1;
  min-height: 45px;
  margin: 8px 45px 8px 0;
  padding-left: 20px;
}

.microsite-menu .microsite-title h1 {
  font-family: Roboto Slab, Times New Roman, serif;
  font-size: 26px;
  line-height: 1;
}

.microsite-menu .microsite-title p {
  text-transform: uppercase;
  font-family: Roboto, arial, sans-serif;
  font-size: 13px;
  font-weight: 700;
}

.microsite-menu .dropdown-menu li.dropdown-title:last-of-type a {
  padding-right: 0;
}

.header-league {
  color: #fff;
}

.header-league .header-wrapper {
  font-size: 13px;
  position: relative;
}

.header-league .header-wrapper a {
  color: #fff;
}

.header-league .header-wrapper .title-wrapper {
  border-right: 1px solid #fff;
  width: 68%;
  min-height: 70px;
  max-height: 75px;
  margin: 10px 0;
}

.header-league .header-wrapper .title-wrapper .logo {
  text-align: center;
  width: 36%;
  margin-right: 5px;
}

.header-league .header-wrapper .title-wrapper .logo.wide {
  width: 43%;
}

.header-league .header-wrapper .title-wrapper .title {
  width: 61%;
}

.header-league .header-wrapper .title-wrapper .title h3 {
  margin: 15px 0 6px;
  font-family: Roboto, arial, sans-serif;
  font-weight: 300;
}

@media screen and (max-width: 376px) {
  .header-league .header-wrapper .title-wrapper .title h3.h3-header-league-fortuna {
    margin-top: 8px;
    font-size: 20px;
  }
}

.header-league .header-wrapper .title-wrapper .title.narrow {
  width: 53%;
}

.header-league .header-wrapper .title-wrapper .subtitle.with-rounds {
  cursor: pointer;
}

.header-league .header-wrapper .title-wrapper .subtitle .rounds-opener {
  width: 100px;
  margin-left: -22px;
  padding: 10px 10px 17px 80px;
  display: inline-block;
  position: absolute;
  top: 40px;
  left: 90px;
}

.header-league .header-wrapper .title-wrapper .subtitle .rounds-opener .rounds-visible {
  opacity: 1;
  visibility: visible;
}

.header-league .header-wrapper .title-wrapper .subtitle .rounds-opener .rotate-180 {
  transform: rotate(180deg);
}

.header-league .header-wrapper .title-wrapper .subtitle .rounds-opener-fortuna {
  margin-left: -6px;
  padding: 0 11px 17px 79px;
  top: 48px;
}

.header-league .header-wrapper .title-wrapper .subtitle .rounds-opener-fortuna .rounds-col {
  width: 50%;
}

@media (max-width: 376px) {
  .header-league .header-wrapper .title-wrapper .subtitle .rounds-opener-fortuna {
    top: 40px;
    left: 86px;
  }
}

@media (max-width: 321px) {
  .header-league .header-wrapper .title-wrapper .subtitle .rounds-opener-fortuna {
    top: 40px;
    left: 73px;
  }
}

.header-league .header-wrapper .title-wrapper:before, .header-league .header-wrapper .title-wrapper:after {
  content: "";
  display: table;
}

.header-league .header-wrapper .title-wrapper:after {
  clear: both;
}

.header-league .header-wrapper .title-wrapper-fortuna {
  margin: 0;
}

.header-league .header-wrapper .title-wrapper-fortuna .title h3.h3-header-league-fortuna {
  margin-top: 4px;
}

@media screen and (max-width: 426px) {
  .header-league .header-wrapper .title-wrapper-fortuna {
    min-height: 0;
    max-height: 66px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 375px) {
  .header-league .header-wrapper .title-wrapper-fortuna {
    max-height: 57px;
    margin-top: 5px;
  }
}

@media (max-width: 426px) {
  .header-league .header-wrapper .title-wrapper-fortuna .logo-fortuna {
    width: 78px;
  }
}

@media (max-width: 321px) {
  .header-league .header-wrapper .title-wrapper-fortuna .logo-fortuna {
    width: 59px;
  }
}

.header-league .header-wrapper .title-wrapper-fortuna .logo-fortuna .dataimg-logo-fortuna-liga svg {
  max-width: 78px;
  max-height: 78px;
}

@media (max-width: 426px) {
  .header-league .header-wrapper .title-wrapper-fortuna .logo-fortuna .logo-fortuna-league-svg {
    width: 76px;
    height: 76px;
    margin-top: -5px;
    margin-left: -1px;
  }
}

@media (max-width: 376px) {
  .header-league .header-wrapper .title-wrapper-fortuna .logo-fortuna .logo-fortuna-league-svg {
    width: 69px;
    height: 69px;
    margin-top: -5px;
    margin-left: -1px;
  }
}

@media (max-width: 321px) {
  .header-league .header-wrapper .title-wrapper-fortuna .logo-fortuna .logo-fortuna-league-svg {
    width: 69px;
    height: 69px;
    margin-top: -5px;
    margin-left: -1px;
  }
}

.header-league .header-wrapper .rounds {
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  width: 330px;
  padding-top: 0;
  transition: all .3s;
  position: absolute;
  top: 33px;
  left: -95px;
}

.header-league .header-wrapper .rounds .rounds-col {
  float: left;
  width: 165px;
  margin-top: 20px;
}

.header-league .header-wrapper .rounds a {
  color: #1e1e1e;
}

.header-league .header-wrapper .rounds .main-title a {
  color: #00a442;
}

@media (max-width: 426px) {
  .header-league .header-wrapper .rounds {
    width: 300px;
    margin-left: 20px;
    top: 35px;
  }
}

.header-league .header-wrapper .link-wrapper {
  text-transform: uppercase;
  width: 31%;
  padding: 0 0 0 7px;
  font-weight: 700;
}

.header-league .header-wrapper .link-wrapper .link {
  margin: 4px 0 5px !important;
}

.header-league .header-wrapper .link-wrapper .link a {
  color: #fff;
}

.header-league .header-wrapper .link-wrapper .link .arrow {
  float: right;
  width: 5px;
  max-height: 12px;
  margin: 3px 15px 0 0;
  display: inline-block;
}

.header-league .header-wrapper .link-wrapper .link:before, .header-league .header-wrapper .link-wrapper .link:after {
  content: "";
  display: table;
}

.header-league .header-wrapper .link-wrapper .link:after {
  clear: both;
}

.header-league .header-wrapper .link-wrapper .sidebar-text {
  line-height: 1.5em;
}

.header-league .header-wrapper .link-wrapper .sidebar-text-fortuna {
  margin-top: 0;
  font-size: 13px;
}

@media screen and (max-width: 376px) {
  .header-league .header-wrapper .link-wrapper .sidebar-text-fortuna {
    font-size: 11px;
  }
}

.header-league .header-wrapper .link-wrapper:before, .header-league .header-wrapper .link-wrapper:after {
  content: "";
  display: table;
}

.header-league .header-wrapper .link-wrapper:after {
  clear: both;
}

.header-league .header-wrapper:before, .header-league .header-wrapper:after {
  content: "";
  display: table;
}

.header-league .header-wrapper:after {
  clear: both;
}

@media screen and (max-width: 760px) {
  .header-league .header-wrapper .title-wrapper .logo {
    width: 34%;
  }

  .header-league .header-wrapper .title-wrapper .title {
    width: 63%;
  }

  .header-league .header-wrapper .link-wrapper {
    width: 32%;
  }
}

.title-small .header-league .title-wrapper .title h3 {
  font-size: 20px;
}

.promo-banner {
  color: #fff;
  width: 100%;
  height: 60px;
  position: relative;
}

.promo-banner .logo {
  width: 85px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.promo-banner .link {
  vertical-align: middle;
  width: 160px;
  height: 60px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
  display: table-cell;
}

.promo-banner .link:hover {
  text-decoration: underline;
}

.promo-banner .link .icon {
  float: none;
  margin-top: -3px;
  position: absolute;
  top: 50%;
  right: 7px;
}

.promo-banner > .link {
  position: absolute;
  right: 10px;
}

.promo-superlife {
  background-color: #9a5bff;
  width: 330px;
  padding: 10px 38px 20px;
}

.promo-superlife .logo-superlife {
  height: 80px;
  margin-bottom: 23px;
}

.promo-superlife .logo-superlife .dataimg-superlife-icon {
  float: left;
  width: 27.27%;
  display: inline-block;
}

.promo-superlife .logo-superlife .dataimg-superlife-text {
  float: left;
  width: 67.58%;
  margin-top: 27px;
  margin-left: 13px;
  display: inline-block;
}

.promo-superlife .button-superlife {
  text-align: center;
  background-color: #6900e1;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 17px;
  padding: 10px 0;
  transition: background-color .2s linear;
  display: block;
}

.promo-superlife .button-superlife:hover {
  background-color: #5100ae;
  text-decoration: none;
}

.promo-superlife .button-superlife span {
  color: #fff;
  font-size: 16px;
  text-decoration: none;
}

.promo-superlife .download-superlife .dataimg-appstore-cz, .promo-superlife .download-superlife .dataimg-google-play-cz {
  width: 46.24%;
  display: inline-block;
}

.promo-superlife .download-superlife .dataimg-appstore-cz {
  float: left;
}

.promo-superlife .download-superlife .dataimg-google-play-cz {
  float: right;
}

.promo-superlife .download-superlife:before, .promo-superlife .download-superlife:after {
  content: "";
  display: table;
}

.promo-superlife .download-superlife:after {
  clear: both;
}

.g_gracket {
  background-color: rgba(0, 0, 0, 0);
  width: 700px;
  padding: 80px 0 5px;
  line-height: 100%;
  position: relative;
  overflow: hidden;
}

.g_round {
  float: left;
  margin-right: 30px;
}

.g_game {
  width: 200px;
  margin-bottom: 45px;
  position: relative;
}

.g_gracket span {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
  line-height: 30px;
}

.g_gracket span .g_score {
  padding-right: 10px;
}

.g_gracket span .g_name {
  float: left;
  width: 130px;
}

.g_gracket span .g_name, .g_gracket span .g_seed, .g_gracket span .g_score {
  height: 45px;
  line-height: 45px;
}

.tournament-third-place.g_gracket span.g_label span {
  min-width: 60px;
  position: absolute;
  top: -16px;
}

.g_team {
  border: 1px solid #e6e6e6;
  height: 45px;
  padding: 0 3px;
  transition: all .3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.g_team[data-medal-position]:before {
  content: "";
  border: 11px solid rgba(0, 0, 0, 0);
  border-left-width: 0;
  border-right: 6px solid #965a38;
  width: 0;
  height: 0;
  display: none;
  position: absolute;
  top: 4px;
  right: 0;
}

.g_team[data-medal-position]:after {
  content: "";
  text-align: center;
  color: #fff;
  background: #965a38;
  width: 25px;
  height: 22px;
  line-height: 22px;
  display: none;
  position: absolute;
  top: 4px;
  right: -25px;
}

.g_team[data-medal-position="4"]:before {
  border-color: rgba(0, 0, 0, 0) #464646 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  display: block;
}

.g_team[data-medal-position="4"]:after {
  content: "4";
  background: #464646;
  display: block;
}

.g_team[data-medal-position="3"]:before {
  border-color: rgba(0, 0, 0, 0) #965a38 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  display: block;
}

.g_team[data-medal-position="3"]:after {
  content: "3";
  background: #965a38;
  display: block;
}

.g_team[data-medal-position="2"]:before {
  border-color: rgba(0, 0, 0, 0) silver rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  display: block;
}

.g_team[data-medal-position="2"]:after {
  content: "2";
  background: silver;
  display: block;
}

.g_team[data-medal-position="1"]:before {
  border-color: rgba(0, 0, 0, 0) gold rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  display: block;
}

.g_team[data-medal-position="1"]:after {
  content: "1";
  background: gold;
  display: block;
}

.g_team:last-child {
  border-top: 0;
}

.g_team:last-child .g_seed {
  border-top: 0;
  height: 29px;
}

.g_round:last-child {
  margin-right: 20px;
}

.g_winner {
  background: #d21e1e;
  display: none;
}

.g_winner .g_team {
  background: none;
}

.g_current {
  cursor: pointer;
  background: #99afed;
}

.g_label {
  color: #828282;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  position: absolute;
  top: -25px;
  left: 0;
}

.g_round_label {
  color: #323232;
  text-align: center;
  border: 1px solid #e6e6e6;
  width: 200px;
  padding: 5px;
  font-size: 13px;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
}

.g_round_label:empty {
  display: none;
}

.g_seed {
  text-align: center;
  color: #1e1e1e;
  float: right;
  width: 30px;
  height: 28px;
  margin-right: 5px;
  display: block;
}

.g_round_report {
  color: #828282;
  text-align: center;
  visibility: hidden;
  font-size: 13px;
  font-weight: normal;
  position: absolute;
  top: -17px;
  right: 0;
}

.g_round_report:after {
  content: "Online>>";
  visibility: visible;
  color: #d21e1e;
  font-weight: bold;
}

.g_score {
  float: right;
  color: #d21e1e;
  margin-right: 5px;
  display: block;
}

.g_no_labels .g_round_label {
  display: none;
}

.dragscroll, .tournament {
  height: auto;
}

.g_canvas {
  width: 700px !important;
}

.__sazkabet-longterm-tags {
  text-align: center;
  width: 100%;
  min-height: 25px;
  padding: 0;
  display: block;
}

.tournament--tennis .g_gracket span {
  font-size: 12px;
}

.tournament--tennis .g_gracket span .g_name {
  flex-direction: column;
  justify-content: center;
  width: 125px;
  line-height: 14px;
  display: flex;
}

.tournament--tennis .g_gracket span.g_seed {
  text-align: right;
  width: 46px;
  font-weight: normal;
  line-height: 45px;
}

.sazka-bet-view .tournament-bracket .g_canvas {
  height: 1420px !important;
  top: -26px !important;
}

.sazka-bet-view .tournament-bracket div:nth-child(8) div:first-child {
  height: 83px !important;
}

.sazka-bet-view .tournament-bracket div:nth-child(8) .g_spacer {
  height: 165px !important;
}

.sazka-bet-view .tournament-bracket div:nth-child(9) div:first-child {
  height: 248px !important;
}

.sazka-bet-view .tournament-bracket div:nth-child(9) .g_spacer {
  height: 493px !important;
}

.sazka-bet-view .tournament-bracket div:nth-child(10) .g_spacer {
  height: 577px !important;
}

.bracket__flex :after, .bracket__flex :before {
  box-sizing: border-box;
}

.bracket__flex .bracket {
  display: flex;
}

.bracket__flex .round {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.bracket__flex .round:first-child .match:before, .bracket__flex .round:first-child .match__content:before, .bracket__flex .round:last-child .match:after {
  display: none;
}

.bracket__flex .match {
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: 0 10px;
  padding: 30px 0;
  display: flex;
  position: relative;
}

.bracket__flex .match:before {
  content: "";
  border-left: 2px solid #323232;
  height: 30px;
  margin-top: -15px;
  margin-left: -2px;
  display: block;
  position: absolute;
  top: 50%;
  left: -10px;
}

.bracket__flex .match:nth-child(odd):after {
  content: "";
  border: 2px solid #323232;
  border-color: #323232 #323232 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  width: 10px;
  height: 50%;
  display: block;
  position: absolute;
  top: 50%;
  right: -10px;
}

.bracket__flex .match:nth-child(2n):after {
  content: "";
  border: 2px solid #323232;
  border-color: rgba(0, 0, 0, 0) #323232 #323232 rgba(0, 0, 0, 0);
  width: 10px;
  height: 50%;
  display: block;
  position: absolute;
  bottom: 50%;
  right: -10px;
}

.bracket__flex .match__content {
  border: 1px solid #e6e6e6;
  width: 100%;
  height: 90px;
  position: relative;
}

.bracket__flex .match__content:before {
  content: "";
  border-bottom: 2px solid #323232;
  width: 10px;
  margin-left: -2px;
  display: block;
  position: absolute;
  top: 50%;
  left: -10px;
}

.bracket__flex .team-logo {
  float: left;
  width: 30px;
  height: 30px;
  margin-top: 7px;
}

.bracket__flex .teams {
  float: left;
  text-indent: 10px;
  width: 60%;
  font-family: roboto, arial, sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 45px;
}

.bracket__flex .teams .team-first, .bracket__flex .teams .team-second {
  height: 45px;
}

.bracket__flex .teams .team-first.winner, .bracket__flex .teams .team-second.winner {
  border: 1px solid #d21e1e;
  border-right: none;
}

.bracket__flex .teams .team-first.winner:before {
  content: "Postupuje do Kvalifikace o EL";
  color: #d21e1e;
  font-size: 12px;
  line-height: 20px;
  display: block;
  position: absolute;
  top: -20px;
  left: 0;
}

.bracket__flex .teams .team-second.winner:before {
  content: "Postupuje do Kvalifikace o EL";
  color: #d21e1e;
  font-size: 12px;
  line-height: 20px;
  display: block;
  position: absolute;
  bottom: -25px;
  left: 0;
}

.bracket__flex .score-first, .bracket__flex .team-first {
  border-bottom: 1px solid #e6e6e6;
}

.bracket__flex .score {
  float: left;
  width: 20%;
  font-weight: 700;
  position: relative;
}

.bracket__flex .score:nth-child(2) .score-first.winner, .bracket__flex .score:nth-child(2) .score-second.winner {
  border: 1px solid #d21e1e;
  border-left: none;
  border-right: none;
}

.bracket__flex .score:nth-child(3) .score-first.winner, .bracket__flex .score:nth-child(3) .score-second.winner {
  border: 1px solid #d21e1e;
  border-left: none;
}

.bracket__flex .score > a {
  color: #323232;
}

.bracket__flex .score > a:hover {
  color: #d21e1e;
  text-decoration: none;
}

.bracket__flex .score .score-first, .bracket__flex .score .score-second {
  text-align: center;
  height: 45px;
  font-size: 13px;
  line-height: 45px;
}

.bracket__flex .score .score-date {
  color: #d21e1e;
  text-align: center;
  width: 100%;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  position: absolute;
  top: -20px;
}

.bracket__flex .score .score-date > a {
  color: #d21e1e;
}

.bracket__flex .score .score-date > a:hover {
  text-decoration: underline;
}

.bracket__flex--baraz .teams .team-first.winner:before, .bracket__flex--baraz .teams .team-second.winner:before {
  content: "Účast ve FORTUNA lize";
}

@media screen and (max-width: 760px) {
  .bracket__flex {
    width: 100%;
    overflow-x: scroll;
  }

  .bracket__flex--baraz .bracket {
    width: 100%;
  }

  .bracket {
    width: 620px;
  }
}

.sports-list {
  background: #fff;
  flex-wrap: wrap;
  display: flex;
}

.sports-list .ico-wrapp {
  text-align: center;
  border-top: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  width: 81px;
  height: 81px;
  font-size: 71px;
  display: block;
  position: relative;
}

.sports-list .ico-wrapp:hover {
  background: #fffcdf;
  text-decoration: none;
}

.sports-list .ico-wrapp:hover .sport-name {
  color: #1e1e1e;
}

.sports-list .ico-wrapp:nth-child(4n) {
  border-right: 0;
  width: 84px;
}

.sports-list .ico-wrapp:nth-child(-n+4) {
  border-top: 0;
}

.sports-list .ico-wrapp.future {
  color: #77c10e;
}

.sports-list .ico-wrapp .icon {
  width: 32px;
  height: 32px;
  margin-top: 20px;
  display: inline-block;
  position: relative;
}

.sports-list .ico-wrapp .icon.online {
  color: #d21e1e;
}

.sports-list .ico-wrapp .icon.sched {
  color: #77c10e;
}

.sports-list .ico-wrapp .icon svg {
  max-width: 32px;
  max-height: 32px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.sports-list .ico-wrapp .sport-name {
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  height: 28px;
  font-size: 9px;
  font-weight: 700;
  display: table;
  position: absolute;
  bottom: 0;
}

.sports-list .ico-wrapp .sport-name:hover {
  text-decoration: underline;
}

.sports-list .ico-wrapp .sport-name span {
  vertical-align: middle;
  display: table-cell;
}

.sports-list .ico-wrapp .flag-cz {
  opacity: .3;
  position: absolute;
  top: 5px;
  right: 5px;
}

.sports-list .ico-wrapp .flag-online {
  color: #d21e1e;
  font-size: 9px;
  font-weight: 700;
  position: absolute;
  top: 5px;
  right: 5px;
}

.sports-list .ico-wrapp .dataimg-ceremony {
  text-align: center;
  width: 23px;
  height: 46px;
  margin: 0 auto;
  display: block;
}

.sports-list .ico-wrapp:before, .sports-list .ico-wrapp:after {
  content: "";
  display: table;
}

.sports-list .ico-wrapp:after {
  clear: both;
}

.sports-list:before, .sports-list:after {
  content: "";
  display: table;
}

.sports-list:after {
  clear: both;
}

.sports-list-item {
  border-top: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  flex-flow: column;
  flex-basis: calc(25% - 1px);
  align-items: center;
  width: calc(25% - 1px);
  height: 82px;
  display: flex;
  position: relative;
}

.sports-list-item:nth-child(4n) {
  border-right: 0;
}

.sports-list-item:nth-child(-n+4) {
  border-top: 0;
}

.sports-list-item__name {
  text-align: center;
  text-transform: uppercase;
  word-break: break-word;
  word-wrap: break-word;
  width: 100%;
  height: 30px;
  padding: 5px 3px;
  font-size: 8px;
  font-weight: 700;
}

.sports-list-item__icon {
  width: 40px;
  height: 40px;
  margin-top: 10px;
}

.sports-list-item__flag {
  color: #d21e1e;
  font-size: 9px;
  font-weight: 700;
  position: absolute;
  top: 3px;
  right: 3px;
}

.sports-list-item__flag--cz {
  top: 0;
  left: 0;
  right: initial;
  width: 17px;
  height: 15px;
}

.sports-list-item__flag--cz-loh-paris {
  top: 0;
  left: -4px;
  right: initial;
  width: 24px;
  height: 20px;
}

.sports-list--zoh-2022 .sports-list-item, .sports-list--loh-2024 .sports-list-item {
  border: none;
  flex-basis: 12.5%;
  width: 12.5%;
  height: 86px;
}

.sports-list--zoh-2022 .sports-list-item__icon, .sports-list--loh-2024 .sports-list-item__icon {
  width: 35px;
  height: 35px;
  margin-top: 16px;
  margin-bottom: 8px;
}

.sports-list--zoh-2022 .sports-list-item__name, .sports-list--loh-2024 .sports-list-item__name {
  justify-content: center;
  align-items: center;
  height: 22px;
  padding: 0;
  font-size: 9px;
  display: flex;
}

.sports-calendar {
  background: #fff;
}

.sports-calendar table:not(.calendar-table) {
  z-index: 1;
  border-top: 1px solid #dcdcdc;
  margin: 0 auto;
  font-size: .8em;
  position: relative;
  overflow: hidden;
}

.sports-calendar.zoh-pchjongcchang th:hover, .sports-calendar.zoh-pchjongcchang tbody td:hover, .sports-calendar.zoh-pchjongcchang th:hover {
  background: #006cb5;
}

.sports-calendar.zoh-pchjongcchang tbody td a.day, .sports-calendar.zoh-pchjongcchang tbody td .day, .sports-calendar.zoh-pchjongcchang th a.day, .sports-calendar.zoh-pchjongcchang th .day {
  cursor: pointer;
}

.sports-calendar.zoh-pchjongcchang tbody td a.day.hovered:hover, .sports-calendar.zoh-pchjongcchang tbody td .day.hovered:hover, .sports-calendar.zoh-pchjongcchang th a.day.hovered:hover, .sports-calendar.zoh-pchjongcchang th .day.hovered:hover, .sports-calendar.zoh-pchjongcchang tbody td a.day.today, .sports-calendar.zoh-pchjongcchang tbody td .day.today, .sports-calendar.zoh-pchjongcchang th a.day.today, .sports-calendar.zoh-pchjongcchang th .day.today {
  background: #006cb5;
}

.sports-calendar tbody td, .sports-calendar th {
  text-align: center;
  border-right: 1px solid #dcdcdc;
  outline: 0;
  width: 26px;
  height: 31px;
  position: relative;
}

.sports-calendar tbody td a.day, .sports-calendar tbody td .day, .sports-calendar th a.day, .sports-calendar th .day {
  width: 26px;
  height: 100%;
  display: flex;
}

.sports-calendar tbody td a.day .dot-green, .sports-calendar tbody td .day .dot-green, .sports-calendar th a.day .dot-green, .sports-calendar th .day .dot-green {
  background: #77c10e;
  border-radius: 8px;
  width: 5px;
  height: 5px;
  margin: auto;
}

.sports-calendar tbody td a.day .dot-green.online, .sports-calendar tbody td .day .dot-green.online, .sports-calendar th a.day .dot-green.online, .sports-calendar th .day .dot-green.online {
  background: #d21e1e;
}

.sports-calendar tbody td a.day .dot-green.dot-blue, .sports-calendar tbody td .day .dot-green.dot-blue, .sports-calendar th a.day .dot-green.dot-blue, .sports-calendar th .day .dot-green.dot-blue {
  background: #006cb5;
}

.sports-calendar tbody td a.day .icon-medail-green, .sports-calendar tbody td .day .icon-medail-green, .sports-calendar th a.day .icon-medail-green, .sports-calendar th .day .icon-medail-green {
  margin: auto;
}

.sports-calendar tbody td a.day .icon-medail-green.medail-blue, .sports-calendar tbody td .day .icon-medail-green.medail-blue, .sports-calendar th a.day .icon-medail-green.medail-blue, .sports-calendar th .day .icon-medail-green.medail-blue {
  background: url("/images/isportcz/dist/non-sprite/zoh-medail-blue.png");
}

.sports-calendar tbody td a.day .dataimg-medal-blue svg, .sports-calendar tbody td .day .dataimg-medal-blue svg, .sports-calendar th a.day .dataimg-medal-blue svg, .sports-calendar th .day .dataimg-medal-blue svg {
  width: 18px;
  height: 22px;
  margin: 4px;
}

.sports-calendar tbody td a.day.hovered, .sports-calendar tbody td .day.hovered, .sports-calendar th a.day.hovered, .sports-calendar th .day.hovered {
  background-color: #fffcdf;
}

.sports-calendar tbody td a.day.hovered:hover, .sports-calendar tbody td .day.hovered:hover, .sports-calendar th a.day.hovered:hover, .sports-calendar th .day.hovered:hover {
  cursor: pointer;
  background: #77c10e;
}

.sports-calendar tbody td a.day.hovered:hover .dot-green, .sports-calendar tbody td .day.hovered:hover .dot-green, .sports-calendar th a.day.hovered:hover .dot-green, .sports-calendar th .day.hovered:hover .dot-green {
  background: #fff;
}

.sports-calendar tbody td a.day.hovered:hover .icon-medail-green, .sports-calendar tbody td .day.hovered:hover .icon-medail-green, .sports-calendar th a.day.hovered:hover .icon-medail-green, .sports-calendar th .day.hovered:hover .icon-medail-green {
  background: url("/images/isportcz/dist/non-sprite/medail-white.png");
}

.sports-calendar tbody td a.day.hovered:hover .dataimg-medal-blue svg path, .sports-calendar tbody td .day.hovered:hover .dataimg-medal-blue svg path, .sports-calendar th a.day.hovered:hover .dataimg-medal-blue svg path, .sports-calendar th .day.hovered:hover .dataimg-medal-blue svg path {
  fill: #fff;
}

.sports-calendar tbody td a.day.today, .sports-calendar tbody td .day.today, .sports-calendar th a.day.today, .sports-calendar th .day.today {
  background: #77c10e;
}

.sports-calendar tbody td a.day.today .day-name, .sports-calendar tbody td a.day.today .day-num, .sports-calendar tbody td .day.today .day-name, .sports-calendar tbody td .day.today .day-num, .sports-calendar th a.day.today .day-name, .sports-calendar th a.day.today .day-num, .sports-calendar th .day.today .day-name, .sports-calendar th .day.today .day-num {
  color: #fff;
}

.sports-calendar tbody td a.day.online, .sports-calendar tbody td .day.online, .sports-calendar th a.day.online, .sports-calendar th .day.online {
  background: rgba(255, 0, 0, .2);
}

.sports-calendar tbody td a.day.online .dot-green, .sports-calendar tbody td .day.online .dot-green, .sports-calendar th a.day.online .dot-green, .sports-calendar th .day.online .dot-green {
  background: #d21e1e;
}

.sports-calendar tbody td a.day.online .icon-medail-green, .sports-calendar tbody td .day.online .icon-medail-green, .sports-calendar th a.day.online .icon-medail-green, .sports-calendar th .day.online .icon-medail-green {
  background: url("/images/isportcz/dist/non-sprite/medail-red.png");
}

.sports-calendar tbody td a.day.online .icon-medail-green svg, .sports-calendar tbody td .day.online .icon-medail-green svg, .sports-calendar th a.day.online .icon-medail-green svg, .sports-calendar th .day.online .icon-medail-green svg {
  width: 18px;
  height: 22px;
}

.sports-calendar tbody td a.day.online .dataimg-medal-blue svg path, .sports-calendar tbody td .day.online .dataimg-medal-blue svg path, .sports-calendar th a.day.online .dataimg-medal-blue svg path, .sports-calendar th .day.online .dataimg-medal-blue svg path {
  fill: #d21e1e;
}

.sports-calendar tbody td:hover, .sports-calendar th:hover {
  cursor: pointer;
  color: #fff;
  background: #77c10e;
}

.sports-calendar tbody td:hover a, .sports-calendar tbody td:hover span, .sports-calendar th:hover a, .sports-calendar th:hover span {
  color: #fff;
  transition: all 10ms;
}

.sports-calendar tbody td:first-child, .sports-calendar th:first-child {
  text-align: left;
  width: 150px;
  padding: 0 0 0 13px;
}

.sports-calendar tbody td:first-child a, .sports-calendar th:first-child a {
  text-decoration: underline;
}

.sports-calendar tbody tr:hover {
  cursor: pointer;
  background-color: #fffcdf;
}

.sports-calendar thead th:last-child, .sports-calendar tbody td:last-child {
  border-right: 0;
}

.sports-calendar thead tr {
  border-bottom: 1px solid #dcdcdc;
}

.sports-calendar thead tr .day-name {
  color: #aaa;
  width: 26px;
  margin: 5px 0 0;
  padding-bottom: 5px;
  font-size: 13px;
  line-height: 1.5;
  display: inline-block;
}

.sports-calendar thead tr .day-name .day-num {
  color: #323232;
  font-size: 16px;
}

.sports-calendar thead tr .day-name:hover {
  color: #fff;
  cursor: default;
}

.sports-calendar thead tr .day-name:hover .day-num {
  color: #fff;
}

.sports-calendar thead th {
  border-top: 1px solid #dcdcdc;
}

.sports-calendar thead th .day {
  height: 54px;
}

.sports-calendar thead th:first-child {
  background: #fff;
  border-top: 0;
  width: 150px;
}

.sports-calendar thead th:first-child .title {
  font-size: 22px;
}

.sports-calendar thead th:first-child .month {
  font-size: 13px;
}

.sports-calendar thead th:first-child:hover span {
  color: #1e1e1e;
}

.sports-calendar thead.fixed {
  z-index: 1;
  background: #fff;
  position: fixed;
  top: 60px;
}

.sports-calendar.detail thead.fixed {
  position: static;
}

.sports-calendar tr:nth-child(2n) {
  background: #e6e6e6;
}

.sports-calendar .legend {
  color: #aaa;
  font-size: 13px;
}

.sports-calendar .legend .dot-green {
  vertical-align: middle;
  background: #77c10e;
  border-radius: 8px;
  width: 5px;
  height: 5px;
  margin: 0 10px 0 20px;
  display: inline-block;
}

.sports-calendar .legend .dot-green.dot-red {
  background: #d21e1e;
  margin-left: 70px;
}

.sports-calendar .legend .dot-green.dot-blue {
  background: #006cb5;
  margin-left: 30px;
}

.sports-calendar .legend .icon-medail-green {
  vertical-align: middle;
  margin: 0 10px 0 20px;
  display: inline-block;
}

.sports-calendar .legend .icon-medail-green svg {
  width: 18px;
  height: 22px;
}

.sports-calendar:before, .sports-calendar:after {
  content: "";
  display: table;
}

.sports-calendar:after {
  clear: both;
}

.oh .medal-wrapp {
  width: 17px;
  height: 23px;
}

.matches td {
  padding-left: 10px;
  position: relative;
}

.matches form.default-form .form-field {
  margin-bottom: 0;
}

.matches form.default-form .margin-left-20 {
  margin-left: 20px;
}

.matches form.default-form .icon-medail-green {
  position: absolute;
  top: 3px;
  left: 29px;
}

.matches .program-title {
  font-size: 22px;
  font-weight: 700;
}

.matches .medal-wrapp {
  width: 17px;
  height: 23px;
  margin-top: 2px;
  margin-right: 5px;
  position: absolute;
  top: 3px;
  left: 0;
}

.matches .event-time {
  font-weight: 700;
}

.matches .event-time.live {
  color: #d21e1e;
}

.matches .event-name a {
  text-transform: capitalize;
  font-weight: 700;
}

.matches .event-name .live {
  color: #d21e1e;
}

.matches .event-name .fin {
  color: #828282;
}

.matches .event-name img {
  margin-top: 6px;
}

.matches .event-name .czech-possible {
  opacity: .4;
}

.matches .event-medals {
  font-size: 9px;
}

.matches .event-medals img {
  vertical-align: bottom;
  margin: 3px 5px 0 13px;
}

.matches .event-medals svg {
  width: 18px;
  height: 22px;
}

.matches .event-online {
  color: #d21e1e;
  font-weight: 700;
}

.matches .table-header-oh {
  border-bottom: 2px solid #77c10e;
  height: 52px;
  padding: 15px 0 7px;
  position: relative;
}

.matches .table-header-oh .tabs {
  text-align: center;
  cursor: pointer;
  background: #fff;
  border: 2px solid #828282;
  border-bottom: 0;
  width: 150px;
  height: 40px;
  padding-top: 10px;
  font-size: 13px;
  font-weight: 700;
  position: absolute;
  top: 10px;
  left: 375px;
}

.matches .table-header-oh .tabs.time-sort {
  width: 125px;
  left: 535px;
}

.matches .table-header-oh .tabs.active {
  border: 2px solid #77c10e;
  border-bottom: 0;
  height: 42px;
}

.matches .dataimg-medal-blue svg, .matches .dataimg-medal-red svg {
  width: 18px;
  height: 22px;
}

.sports-calendar-carousel {
  margin-top: -5px;
}

.sports-calendar-carousel .owl-carousel, .sports-calendar-carousel .carousel-date {
  z-index: 0;
}

.sports-calendar-carousel .owl-carousel .owl-stage-outer:before, .sports-calendar-carousel .carousel-date .owl-stage-outer:before {
  z-index: 1;
  content: "";
  background: linear-gradient(to right, #fff 0% 26%, rgba(255, 255, 255, .76) 80%, rgba(255, 255, 255, .37) 100%);
  width: 35px;
  height: 89px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1px;
}

.sports-calendar-carousel .owl-carousel .owl-stage-outer:after, .sports-calendar-carousel .carousel-date .owl-stage-outer:after {
  z-index: 1;
  content: "";
  background: linear-gradient(to left, #fff 0% 26%, rgba(255, 255, 255, .76) 80%, rgba(255, 255, 255, .37) 100%);
  width: 35px;
  height: 89px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -1px;
}

.sports-calendar-carousel .owl-carousel .owl-prev, .sports-calendar-carousel .carousel-date .owl-prev {
  left: -15px;
}

.sports-calendar-carousel .owl-carousel .owl-prev .dataimg-arrow-light-left, .sports-calendar-carousel .carousel-date .owl-prev .dataimg-arrow-light-left {
  top: 35px;
}

.sports-calendar-carousel .owl-carousel .owl-prev .dataimg-arrow-light-left path, .sports-calendar-carousel .carousel-date .owl-prev .dataimg-arrow-light-left path {
  fill: #77c10e;
}

.sports-calendar-carousel .owl-carousel .owl-next, .sports-calendar-carousel .carousel-date .owl-next {
  right: -15px;
}

.sports-calendar-carousel .owl-carousel .owl-next .dataimg-arrow-light-right, .sports-calendar-carousel .carousel-date .owl-next .dataimg-arrow-light-right {
  top: 35px;
}

.sports-calendar-carousel .owl-carousel .owl-next .dataimg-arrow-light-right path, .sports-calendar-carousel .carousel-date .owl-next .dataimg-arrow-light-right path {
  fill: #77c10e;
}

.sports-calendar-carousel .owl-carousel .carousel-item, .sports-calendar-carousel .carousel-date .carousel-item {
  border-bottom: 1px solid #dcdcdc;
  border-left: 1px solid #dcdcdc;
  height: 90px;
}

.sports-calendar-carousel .owl-carousel .carousel-item .carousel-link-item, .sports-calendar-carousel .carousel-date .carousel-item .carousel-link-item {
  text-align: center;
  padding-top: 0;
}

.sports-calendar-carousel .owl-carousel .carousel-item .carousel-link-item.selected .day-name, .sports-calendar-carousel .carousel-date .carousel-item .carousel-link-item.selected .day-name {
  color: #fff;
  background-color: #77c10e;
}

.sports-calendar-carousel .owl-carousel .carousel-item .carousel-link-item.selected .day-name .day-num, .sports-calendar-carousel .carousel-date .carousel-item .carousel-link-item.selected .day-name .day-num {
  color: #fff;
}

.sports-calendar-carousel .owl-carousel .carousel-item .carousel-link-item .day-name, .sports-calendar-carousel .carousel-date .carousel-item .carousel-link-item .day-name {
  color: #aaa;
  border-bottom: 1px solid #dcdcdc;
  height: 60px;
  padding-top: 10px;
  font-weight: 700;
}

.sports-calendar-carousel .owl-carousel .carousel-item .carousel-link-item .day-name .day-num, .sports-calendar-carousel .carousel-date .carousel-item .carousel-link-item .day-name .day-num {
  color: #1e1e1e;
}

.sports-calendar-carousel .owl-carousel .carousel-item .carousel-link-item .icon-medail-green, .sports-calendar-carousel .owl-carousel .carousel-item .carousel-link-item .dx, .sports-calendar-carousel .carousel-date .carousel-item .carousel-link-item .icon-medail-green, .sports-calendar-carousel .carousel-date .carousel-item .carousel-link-item .dx {
  margin: 4px auto 0;
}

.sports-calendar-carousel .owl-carousel .carousel-item .carousel-link-item .dot-green, .sports-calendar-carousel .carousel-date .carousel-item .carousel-link-item .dot-green {
  background: #77c10e;
  border-radius: 8px;
  width: 5px;
  height: 5px;
  margin: 11px auto;
}

body.mobile .sports-calendar .legend {
  box-sizing: border-box;
  font-size: 11px;
}

body.mobile .sports-calendar .legend .legend-dots {
  float: left;
  width: 40%;
  display: inline-block;
}

body.mobile .sports-calendar .legend .legend-dots .dot-green, body.mobile .sports-calendar .legend .legend-dots .dot-red {
  margin: 0 0 0 10%;
}

body.mobile .sports-calendar .legend .legend-medails {
  float: left;
  text-align: right;
  width: 60%;
  display: inline-block;
}

body.mobile .sports-calendar .legend .legend-medails .icon-medail-green, body.mobile .sports-calendar .legend .legend-medails .medail-red {
  margin: 0 0 0 5%;
}

body.mobile .oh .matches td {
  padding-left: 4px;
}

body.mobile .oh .matches .table-header-oh {
  height: 40px;
}

body.mobile .oh .matches .table-header-oh .program-title {
  font-size: 13px;
}

body.mobile .oh .matches .table-default-small, body.mobile .oh .matches .table-default-small form.default-form .form-field label {
  font-size: 11px;
}

body.mobile .oh .matches .table-default-small form.default-form .form-field label span {
  text-align: initial;
}

body.mobile .oh .matches .event-medals img {
  margin-left: 0;
}

body.mobile .oh .matches .medal-wrapp {
  left: 27px;
}

body.mobile .medal-wrapp {
  vertical-align: middle;
  width: 17px;
  height: 23px;
  display: inline-block;
  left: 0;
}

.zoh-pchjongcchang .sports-calendar-carousel .owl-carousel .carousel-item .carousel-link-item.selected .day-name, .zoh-pchjongcchang .sports-calendar-carousel .carousel-date .carousel-item .carousel-link-item.selected .day-name {
  background: #006cb5;
}

.carousel-matches {
  float: none;
  margin: 15px auto;
  display: block;
}

.carousel-matches .list-video-small a {
  float: none;
}

.carousel-matches .list-video-small .box-title {
  float: none;
  width: 145px;
  margin: 10px auto;
}

.carousel-matches .list-video-small .box-title .title-container {
  float: none;
  margin-top: 10px;
}

.carousel-matches .list-video-small .box-title .title-container a {
  display: inline-block;
}

.carousel-matches .video-art.more-art {
  vertical-align: text-top;
  width: 27%;
  display: inline-block;
}

.carousel-matches .owl-carousel .owl-nav .owl-prev, .carousel-matches .owl-carousel .owl-nav .owl-next {
  position: absolute;
  top: 35px;
  left: -50px;
}

.carousel-matches .owl-carousel .owl-nav .owl-prev div, .carousel-matches .owl-carousel .owl-nav .owl-next div {
  cursor: pointer;
  width: 15px;
  height: 25px;
}

.carousel-matches .owl-carousel .owl-nav .owl-next {
  left: auto;
  right: -50px;
}

.carousel-matches .owl-carousel .owl-dots {
  counter-reset: dots;
  text-align: center;
  margin-bottom: 15px;
}

.carousel-matches .owl-carousel .owl-dots .owl-dot:before {
  counter-increment: dots;
  content: counter(dots);
}

.carousel-matches .owl-carousel .owl-dots .owl-dot {
  cursor: pointer;
  margin-right: 10px;
  display: inline-block;
}

.carousel-matches .owl-carousel .owl-dots .owl-dot.active:before {
  color: #00a442;
  font-weight: 700;
}

header.magazine-subscription {
  border-bottom: 1px solid #dcdcdc;
}

header.magazine-subscription h1 {
  text-align: right;
  float: right;
  width: 50%;
  margin: 70px 20px 50px;
  font-size: 50px;
}

header.magazine-subscription .issue-detail {
  float: left;
  width: 140px;
  height: 190px;
  margin: 55px 0 0 80px;
}

header.magazine-subscription p.info {
  font-size: 13px;
}

.magazine-subscription h3 {
  margin: 20px 0 10px;
  font-size: 22px;
}

.magazine-subscription h3.payer {
  margin-bottom: 64px;
}

.magazine-subscription .required-fields-info {
  margin-bottom: 13px;
  font-size: 13px;
}

.magazine-subscription .first-mag-subscription-row {
  margin-top: -20px;
}

.magazine-subscription .button-row {
  padding: 30px 0 50px;
}

.magazine-subscription .button-row .main-button {
  margin: 0 auto;
}

.magazine-subscription .magazine-subscription-row {
  border-bottom: 1px solid #dcdcdc;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-full {
  margin-bottom: 30px;
  padding-left: 10px;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-full .radioButtons label {
  margin: 10px 0 5px;
  padding: 10px 0 10px 30px;
}

.magazine-subscription .magazine-subscription-row .form-field {
  margin-bottom: 0;
}

.magazine-subscription .magazine-subscription-row .form-field .orderFromDateDiv {
  float: left;
}

.magazine-subscription .magazine-subscription-row .form-field .orderFromDateDiv .orderFromDate {
  float: none;
}

.magazine-subscription .magazine-subscription-row .form-row {
  margin-bottom: 6px;
}

.magazine-subscription .magazine-subscription-row .form-row .paymentOptions {
  white-space: nowrap;
  margin: 3px 24px 0 0;
}

.magazine-subscription .magazine-subscription-row .form-row .paymentOptions label {
  padding: 10px 0 10px 30px;
}

.magazine-subscription .magazine-subscription-row .submitForm {
  margin: 10px 0 20px;
}

.magazine-subscription .magazine-subscription-row .inline {
  align-items: flex-start;
  display: flex;
}

.magazine-subscription .magazine-subscription-row .width100 {
  width: 100px;
}

.magazine-subscription .magazine-subscription-row .width270 {
  width: 270px;
}

.magazine-subscription .magazine-subscription-row .widthFull {
  width: 100%;
}

.magazine-subscription .magazine-subscription-row .firstname {
  margin: 0 20px;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left, .magazine-subscription .magazine-subscription-row .magazine-subscription-right {
  box-sizing: border-box;
  width: 50%;
  margin-bottom: 15px;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-left {
  float: left;
  padding-right: 40px;
}

.magazine-subscription .magazine-subscription-row .magazine-subscription-right {
  float: right;
  padding-left: 5px;
  padding-right: 30px;
}

.magazine-subscription .magazine-subscription-row .receiver-title {
  margin: 20px 0 0;
  display: inline-block;
}

.magazine-subscription .magazine-subscription-row .receiver-field {
  float: right;
  margin-top: 22px;
}

.magazine-subscription .magazine-subscription-row .receiver-information {
  margin-top: 10px;
  display: none;
}

.magazine-subscription .magazine-subscription-row .are-same[type="checkbox"] + label {
  padding-left: 0;
  padding-right: 30px;
  font-weight: 500;
}

.magazine-subscription .magazine-subscription-row .are-same[type="checkbox"] + label:before {
  left: initial;
  right: 0;
}

.magazine-subscription .magazine-subscription-row .are-same[type="checkbox"] + label:after {
  left: initial;
  right: 5px;
}

.magazine-subscription .magazine-subscription-row:before, .magazine-subscription .magazine-subscription-row:after {
  content: "";
  display: table;
}

.magazine-subscription .magazine-subscription-row:after {
  clear: both;
}

.magazine-subscription label {
  font-weight: bold;
}

.magazine-subscription label.required:after {
  content: "*";
}

.magazine-subscription input::placeholder {
  color: #aaa;
  font-size: 13px;
  font-weight: normal;
}

.magazine-subscription input.form-one-third {
  width: 35%;
}

.magazine-subscription input.form-one-third[type="text"] {
  width: 35%;
}

.magazine-subscription select {
  box-sizing: border-box;
  color: #1e1e1e;
  border: 2px solid #e6e6e6;
  border-radius: 1px;
  outline: none;
  width: 100%;
  max-width: 100%;
  height: 40px;
  margin-top: 20px;
  padding: 8px 9px;
  font-weight: 700;
  transition: all .3s ease-in-out;
  display: block;
}

.magazine-subscription .slash {
  margin: 8px 7px;
}

.magazine-subscription .predplatne-text {
  margin: 20px 10px 10px;
}

.magazine-subscription .info {
  font-size: 13px;
}

.magazine-subscription .payer-space {
  height: 20px;
}

.magazine-subscription .form-field-space {
  height: 86px;
}

.magazine-subscription .margin-left-20 {
  margin-left: 20px;
}

.sazka-fantasy .sazka-fantasy-table {
  position: relative;
}

.sazka-fantasy .sazka-fantasy-table .synottip-reklama {
  color: #aaa;
  font-size: 9px;
  position: absolute;
  top: -12px;
  right: 0;
}

.sazka-fantasy .sazka-fantasy-table .status-live {
  color: #d21e1e;
  font-size: 13px;
  font-weight: 700;
  position: absolute;
  top: 25px;
  right: 15px;
}

.sazka-fantasy .sazka-fantasy-table .table-header {
  box-sizing: border-box;
  border-bottom: 2px solid #d21e1e;
  height: 52px;
  padding: 15px 0 7px;
}

.sazka-fantasy .sazka-fantasy-table .table-header .title-part1 {
  font-size: 26px;
}

.sazka-fantasy .sazka-fantasy-table .table-header .title-part2 {
  font-size: 30px;
}

.sazka-fantasy .sazka-fantasy-table .table-header .icon-synottip {
  display: inline-block;
}

.sazka-fantasy .sazka-fantasy-table .table-header .tab-bets {
  color: #aaa;
  text-align: center;
  cursor: pointer;
  background: #fff;
  border: 2px solid #aaa;
  border-bottom: 0;
  width: 122px;
  height: 40px;
  padding-top: 10px;
  font-size: 13px;
  font-weight: 700;
  position: absolute;
  top: 10px;
  left: 172px;
}

.sazka-fantasy .sazka-fantasy-table .table-header .tab-bets.day-rates {
  left: 299px;
}

.sazka-fantasy .sazka-fantasy-table .table-header .tab-bets.super-rates {
  left: 426px;
}

.sazka-fantasy .sazka-fantasy-table .table-header .tab-bets.nhl-rates {
  left: 553px;
}

.sazka-fantasy .sazka-fantasy-table .table-header .tab-bets.active {
  color: #1e1e1e;
  border: 2px solid #d21e1e;
  border-bottom: 0;
  height: 42px;
}

.sazka-fantasy .sazka-fantasy-table .table-part {
  display: none;
}

.sazka-fantasy .sazka-fantasy-table .table-part:first-of-type, .sazka-fantasy .sazka-fantasy-table .table-part.active {
  display: block;
}

.sazka-fantasy .sport-color, .sazka-fantasy a.sport-color {
  color: #000c78;
}

.sazka-fantasy .sport-color-svg path {
  fill: #000c78;
}

.sazka-fantasy .title-section.border-bottom {
  border-bottom: 0;
}

.sazka-fantasy .sazka-fantasy-table .table-header {
  border-bottom: 2px solid #000c78;
}

.sazka-fantasy .sazka-fantasy-table .table-header .tab-bets {
  left: 0;
}

.sazka-fantasy .sazka-fantasy-table .table-header .tab-bets.best-score {
  width: 40%;
}

.sazka-fantasy .sazka-fantasy-table .table-header .tab-bets.most-nominations {
  width: 58%;
  left: 42%;
}

.sazka-fantasy .sazka-fantasy-table .table-header .tab-bets.active {
  border: 2px solid #000c78;
  border-bottom: 0;
}

.sazka-fantasy .sazka-fantasy-table .table-default-small .player-name {
  font-size: 16px;
  font-weight: 700;
}

.isport-social-box {
  background: #fff;
  padding: 15px;
}

.isport-social-box h3 {
  color: #4267b2;
  border-bottom: 2px solid #4267b2;
  margin: 0 0 16px;
  padding: 0 0 7px;
  font-family: roboto, arial, sans-serif;
  font-size: 21px;
}

.isport-social-box .instagram-button {
  color: #fff;
  float: left;
  background: #9c4594 url("/images/isportcz/dist/non-sprite/social-box-instagram.png") 9px no-repeat;
  border-radius: 4px;
  height: 28px;
  margin: 0 0 10px 10px;
  padding: 0 10px 0 33px;
  font-size: 13px;
  font-weight: 100;
  line-height: 28px;
  display: inline-block;
}

.isport-social-box .instagram-button:hover {
  background-color: #812a79;
  text-decoration: none;
}

.isport-social-box:before, .isport-social-box:after {
  content: "";
  display: table;
}

.isport-social-box:after {
  clear: both;
}

.gallery-main-container .gallery.gallery-with-video .gallery-item {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.gallery-main-container .gallery.gallery-with-video .gallery-item .video-item {
  background: rgba(0, 0, 0, .45);
  width: 82%;
  margin-left: auto;
  margin-right: auto;
}

.gallery-main-container .gallery.gallery-with-video .images-container {
  opacity: 1;
  height: 100%;
  display: block;
  overflow-y: hidden;
}

.gallery-main-container .gallery.gallery-with-video a.btn.next, .gallery-main-container .gallery.gallery-with-video a.btn.prev, .gallery-main-container .gallery.gallery-with-video .image-description {
  display: none;
}

.gallery-main-container .gallery .owl-nav.disabled {
  height: 0;
  display: none;
}

.zoh-pchjongcchang .participants {
  background: #fff;
  margin-bottom: 30px;
  padding: 10px;
}

.zoh-pchjongcchang .participants .title-section {
  border-color: #006cb5;
}

.zoh-pchjongcchang .participants .title-section .main-title, .zoh-pchjongcchang .participants .title-basic {
  color: #006cb5;
}

.loh-tokio .participants {
  background: #fff;
  margin-bottom: 30px;
  padding: 10px;
}

.loh-tokio .participants .title-section {
  border-color: #94754d;
}

.loh-tokio .participants .title-section .main-title, .loh-tokio .participants .title-basic, .loh-tokio .participants-detail .sport-color {
  color: #94754d;
}

.loh-tokio .participants-detail .sport-color-border {
  border-color: #94754d;
}

#paid-zone #order-subscription-sms .logout, #paid-zone #order-subscription .logout {
  top: 14px;
  right: 0;
}

#paid-zone #order-subscription-sms #steps {
  margin: 12px 0;
  padding: 0;
  font-size: 16px;
}

#paid-zone #order-subscription-sms #steps .for-1:before {
  content: "1.";
  font-weight: 700;
  display: block;
  position: absolute;
  left: 0;
}

#paid-zone #order-subscription-sms #steps .for-2:before {
  content: "2.";
  font-weight: 700;
  display: block;
  position: absolute;
  left: 0;
}

#paid-zone #order-subscription-sms #steps .for-3:before {
  content: "3.";
  font-weight: 700;
  display: block;
  position: absolute;
  left: 0;
}

#paid-zone #order-subscription-sms #steps li {
  margin: 26px 0;
  padding: 0 0 0 22px;
  list-style-type: none;
  position: relative;
}

#paid-zone #order-subscription-sms #steps li:first-child {
  margin-top: 0;
}

#paid-zone #order-subscription-sms #steps li:last-child {
  margin-bottom: 0;
}

#paid-zone #order-subscription-sms #steps li form {
  margin-top: 10px;
  position: relative;
}

#paid-zone #order-subscription-sms #steps li form div {
  font-size: 13px;
  position: absolute;
}

#paid-zone #order-subscription-sms #steps li form span {
  color: #828282;
  font-size: 15px;
  font-weight: 700;
  display: block;
  position: absolute;
  top: 34px;
  left: 7px;
}

#paid-zone #order-subscription-sms #steps li .error-message {
  color: #d21e1e;
  margin-top: 3px;
  position: relative;
}

#paid-zone #order-subscription-sms #steps li .sms_input {
  float: left;
  width: 146px;
  margin: 24px 30px 0 0;
  padding: 0 0 0 44px;
  font-size: 15px;
}

#paid-zone #order-subscription-sms #steps li .sms_input.error {
  border-color: #d21e1e;
}

#paid-zone #order-subscription-sms #steps li .main-button {
  margin: 3px 0 0;
  padding: 9px 20px 10px;
}

#paid-zone #order-subscription {
  margin-top: 0;
}

#paid-zone #order-subscription h2 {
  border-top: 1px solid #dcdcdc;
  padding: 21px 0 17px;
  font-size: 22px;
}

#paid-zone #order-subscription form {
  text-align: center;
  margin-top: 0;
}

#paid-zone #order-subscription form.default-form .form-field {
  margin-bottom: 0;
}

#paid-zone #order-subscription form.default-form .form-field.with-error label {
  color: #1e1e1e;
}

#paid-zone #order-subscription input[type="submit"] {
  width: 190px;
  margin: 14px auto 0;
}

#paid-zone #order-subscription form.agreement {
  margin-top: 20px;
}

#paid-zone #order-subscription form.agreement .form-field label {
  text-align: left;
  padding: 3px 0 0 31px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
}

#paid-zone #order-subscription .backlink {
  margin-top: 15px;
}

#paid-zone #order-subscription .order-description strong {
  margin: 0 0 3px;
  display: block;
}

#paid-zone #order-subscription .order-description p {
  line-height: 1.3;
}

#paid-zone #order-subscription .order-description .variant {
  text-transform: lowercase;
}

#paid-zone #order-subscription .profile-info.prihlaseni-info {
  padding-left: 0;
}

#paid-zone #order-subscription .profile-info.headline {
  padding: 0;
}

#paid-zone #order-subscription .sidenote {
  color: #828282;
  margin-top: -13px;
  line-height: 1.3;
}

#paid-zone #order-subscription .table-default {
  width: 100%;
  margin: 24px 0 17px;
  font-size: 16px;
}

#paid-zone #order-subscription .table-default.total {
  border: 1px solid #dcdcdc;
  border-left: 0;
  border-right: 0;
  margin: 26px 0 22px;
  font-weight: 600;
}

#paid-zone #order-subscription .table-default.total td {
  padding: 10px 0;
}

#paid-zone #order-subscription .table-default.total td:last-child {
  text-align: right;
}

#paid-zone #order-subscription .table-default.total .price-note td {
  text-align: left;
}

#paid-zone #order-subscription .table-default.sms-payment label {
  font-size: 16px;
}

#paid-zone #order-subscription .table-default.sms-payment td:first-child {
  width: 75%;
}

#paid-zone #order-subscription .table-default.sms-payment tr:first-child td {
  padding-bottom: 2px;
}

#paid-zone #order-subscription .table-default.sms-payment tr:last-child td {
  padding-top: 2px;
}

#paid-zone #order-subscription .table-default.sms-payment .has-full-price {
  float: left;
  color: #828282;
  margin: -5px 0 5px;
  padding-left: 30px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
}

#paid-zone #order-subscription .table-default.sms-payment .has-full-price small {
  font-size: 13px;
}

#paid-zone #order-subscription .table-default td, #paid-zone #order-subscription .table-default th {
  padding: 5px 0;
}

#paid-zone #order-subscription .table-default tbody {
  border: 0;
}

#paid-zone #order-subscription .table-default tbody tr:nth-child(2n) {
  background: none;
}

#paid-zone #order-subscription .table-default th:last-child {
  text-align: right;
}

#paid-zone #order-subscription #form-subscribe {
  margin: 4px 0 0;
}

#paid-zone #additional-info {
  font-size: 14px;
}

#paid-zone #additional-info h2 {
  margin: 25px 0 6px;
  font-size: 16px;
}

#paid-zone #additional-info p {
  margin: 0 0 11px;
}

#paid-zone #additional-info ul {
  margin: 11px 0;
  padding: 0 0 0 19px;
}

#paid-zone #additional-info ul li {
  margin: 0 0 12px;
}

#paid-zone #additional-info .backlink {
  margin: 0;
}

#paid-zone #additional-info .cancel-sub {
  border-top: 1px solid #dcdcdc;
  margin: 15px 0 0;
  padding: 15px 0 0;
}

#paid-zone #additional-info .back-link {
  text-align: center;
  margin: 18px 0;
  font-size: 15px;
  display: block;
}

#paid-zone #additional-info .cnc, #paid-zone #additional-info .cnc a {
  font-size: 13px;
}

#paid-zone .subscription__voucher .subscription-notice {
  display: none;
}

#paid-zone .subscription__voucher .order-description h3 {
  margin: 20px 0;
  font-size: 15px;
  line-height: 18px;
}

#paid-zone .subscription__voucher .subscription__total-price .discount-code {
  display: none;
}

#paid-zone .subscription__voucher .subscription__total-price #final-price__text {
  text-align: left;
}

#paid-zone #order-subscription .subscription__total-price .discount-code {
  padding-bottom: 15px;
  font-size: 13px;
  line-height: 18px;
}

#paid-zone #order-subscription .subscription__total-price .discount-code .discount-code__label {
  margin: 0;
  display: block;
}

#paid-zone #order-subscription .subscription__total-price .discount-code .discount-code__input {
  color: #000001;
  border: 1px solid #d1d4e4;
  border-radius: 3px;
  outline: none;
  width: 180px;
  margin: 10px 5px 10px 0;
  padding: 5px;
  transition: all .3s ease-in-out;
  display: inline-block;
}

#paid-zone #order-subscription .subscription__total-price .discount-code .discount-code__button {
  color: #0000ca;
  background: none;
  border: none;
  font-weight: 700;
  display: inline-block;
}

#paid-zone #order-subscription .subscription__total-price .discount-code .discount-code__error {
  color: #e00a17;
}

#paid-zone #order-subscription .subscription__total-price .price-note, #paid-zone #order-subscription .subscription__total-price .price-note td {
  font-size: 13px;
  line-height: 18px;
}

#paid-zone #order-subscription .subscription__total-price .discount-code__price {
  color: #e00a17;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
}

@media screen and (max-width: 480px) {
  #paid-zone #order-subscription .subscription__total-price .discount-code .discount-code__input {
    width: 80px;
  }

  #paid-zone #order-subscription .subscription__total-price #final-price__text {
    text-align: left;
    font-size: 13px;
    line-height: 15px;
  }

  #paid-zone #order-subscription .subscription__total-price .final-price {
    font-size: 16px;
  }
}

#pick-subscription-plan .subscription-box {
  margin-top: 25px;
}

#pick-subscription-plan .subscription-box .header .headline {
  width: auto;
}

.activation-steps {
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin: 10px auto;
  display: flex;
}

.activation-step {
  color: #2e2e2e;
  background: #ececec;
  width: 50%;
  padding: 9px 20px 8px;
  font-weight: 700;
  position: relative;
}

.activation-step:last-of-type {
  text-indent: 25px;
  max-width: calc(50% - 30px);
  left: -30px;
}

.activation-step:before {
  content: "";
  z-index: 1;
  border: 35px solid rgba(0, 0, 0, 0);
  border-left: 30px solid #ececec;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 100%;
}

.activation-step.active-step {
  color: #d0112b;
  background: #f7f7f7;
}

.activation-step.active-step:before {
  border-left: 30px solid #f7f7f7;
}

.activation-steps__step {
  font-size: 15px;
  line-height: 25px;
}

.activation-steps__info {
  font-size: 17px;
  line-height: 28px;
}

#activation-login h2 {
  margin: 0 0 4px;
}

#activation-login input[type="radio"] {
  margin: 0 0 0 5px;
}

#activation-login .prihlaseni-info {
  font-size: 16px;
}

#activation-login .options {
  margin: 26px 0 0;
  line-height: 1.9;
}

#activation-login .options .main-button {
  width: 178px;
  margin: 21px auto 0;
  display: none;
}

#activation-login .prihlaseni-error {
  color: #d21e1e;
  font-size: 17px;
  font-weight: 600;
}

#activation-login .active-until {
  font-size: 16px;
}

#activation-login .active-until strong {
  color: #6cc424;
}

#activation-login .active-until .underline {
  font-size: 16px;
}

#activation-login .table-default {
  width: 100%;
}

#activation-login .table-default.valid-to.recurring-cancelled-true tr:last-child td {
  border: 0;
  padding-top: 15px;
}

#activation-login .table-default.valid-to tr:first-child td {
  padding-top: 15px;
}

#activation-login .table-default.valid-to td {
  padding-top: 0;
}

#activation-login .table-default.valid-to tr:last-child td {
  padding-bottom: 17px;
}

#activation-login .table-default.summary {
  border-top: 1px solid #646464;
  margin: 22px 0 24px;
}

#activation-login .table-default.summary td {
  padding-top: 23px;
  font-size: 18px;
}

#activation-login .table-default.summary td:last-child {
  padding: 17px 0 0;
}

#activation-login .table-default.recurring tbody {
  border: 0;
}

#activation-login .table-default.recurring td {
  padding: 16px 0 7px;
}

#activation-login .table-default td {
  border-top: 1px solid #dcdcdc;
  padding-left: 0;
  padding-right: 0;
  font-size: 16px;
}

#activation-login .table-default td + td {
  font-size: 14px;
}

#activation-login .table-default td:first-child {
  width: 84%;
  font-weight: 600;
}

#activation-login .table-default td:last-child {
  text-align: right;
}

#activation-login .table-default tbody tr:nth-child(2n) {
  background: none;
}

#activation-login .table-default .price {
  font-size: 22px;
  font-weight: 600;
}

#activation-login .subscription-box .headline {
  width: auto;
}

#activation-login .subscription-box .header {
  padding-bottom: 4px;
}

#activation-login .recurring-date #recurring-info {
  color: red;
  cursor: pointer;
  padding: 0 2px;
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
  position: relative;
}

#activation-login .recurring-date #recurring-info #recurring-info-popup {
  text-align: left;
  color: #2c2f3b;
  z-index: 2;
  background: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 0;
  width: 160px;
  padding: 10px;
  font-size: 13px;
  font-weight: 300;
  line-height: 17px;
  display: none;
  position: absolute;
  top: 0;
  right: 10px;
}

#subscription-login #login-wrapper .main-container, #paid-zone #activation-activate ol {
  margin-bottom: 0;
}

#paid-zone #activation-activate form {
  margin-top: 18px;
}

#paid-zone #activation-activate .error-message {
  text-align: left;
  width: 100%;
  margin: 0;
}

#paid-zone #activation-activate #form-activate input[type="text"] {
  text-align: center;
  width: 200px;
  height: 42px;
  padding: 0 10px;
  line-height: 42px;
  display: inline-block;
}

#paid-zone #activation-activate #form-activate input[type="submit"] {
  width: 113px;
  height: 42px;
  margin: 0 0 5px -5px;
  padding: 0;
}

#paid-zone #activation-activate #form-activate .form-field {
  width: 308px;
  margin: 0 auto 20px;
}

#paid-zone #activation-activate #login-wrapper .main-container.inline {
  box-shadow: none;
  text-align: center;
  width: auto;
}

#paid-zone #activation-activate #login-wrapper .main-container.inline a {
  color: #fff;
  background: #d21f1f;
  border-radius: 2px;
  padding: 12px 30px;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}

#paid-zone #activation-activate #login-wrapper .main-container.inline a:hover {
  background: #8f1414;
  text-decoration: none;
}

#isport-archive {
  background: #f0f0f0;
}

#isport-archive h1 {
  text-transform: none;
  border-top: 3px solid #d21e1e;
  width: 98%;
  margin: 0 auto;
  font-size: 22px;
}

#isport-archive .subscription-box {
  margin: 30px auto 0;
}

#isport-archive .subscription-box:not(.hidden-body) .headline {
  width: 100%;
}

#isport-archive .logged-user, #isport-archive .sub-link {
  display: none;
}

#isport-archive .title-section .icon {
  position: relative;
  top: 2px;
}

#isport-archive .dataimg-arrow-light-left {
  background-image: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2012.8%2025.6%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23d21e1e%22%20d%3D%22M0%2C12.8c0-0.2%2C0.1-0.3%2C0.2-0.4l0%2C0L11.7%2C0.2l0%2C0C11.8%2C0.1%2C12%2C0%2C12.2%2C0c0.4%2C0%2C0.6%2C0.3%2C0.6%2C0.6c0%2C0.2-0.1%2C0.3-0.2%2C0.4l0%2C0L1.5%2C12.8l11.1%2C11.7l0%2C0c0.1%2C0.1%2C0.2%2C0.3%2C0.2%2C0.4c0%2C0.4-0.3%2C0.6-0.6%2C0.6c-0.2%2C0-0.3-0.1-0.5-0.2l0%2C0L0.2%2C13.2l0%2C0C0.1%2C13.1%2C0%2C13%2C0%2C12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  height: 26px;
}

#isport-archive .dataimg-arrow-light-right {
  background-image: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2012.8%2025.6%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23d21e1e%22%20d%3D%22M12.8%2C12.8c0%2C0.2-0.1%2C0.3-0.2%2C0.4l0%2C0L1.1%2C25.4l0%2C0c-0.1%2C0.1-0.3%2C0.2-0.5%2C0.2C0.3%2C25.6%2C0%2C25.3%2C0%2C25c0-0.2%2C0.1-0.3%2C0.2-0.4l0%2C0l11.1-11.7L0.2%2C1.1l0%2C0C0.1%2C1%2C0%2C0.8%2C0%2C0.6C0%2C0.3%2C0.3%2C0%2C0.6%2C0C0.8%2C0%2C1%2C0.1%2C1.1%2C0.2l0%2C0l11.5%2C12.2l0%2C0C12.7%2C12.5%2C12.8%2C12.6%2C12.8%2C12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  height: 26px;
}

#isport-archive .dataimg-lock {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2268.376%22%20height%3D%2275.062%22%20viewBox%3D%220%200%2034.188%2037.531%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%20%3Cdefs%3E%20%3Cstyle%3E%20.cls-1%20%7B%20fill%3A%20%23f0f0f0%3B%20fill-rule%3A%20evenodd%3B%20%7D%20%3C%2Fstyle%3E%20%3C%2Fdefs%3E%20%3Cpath%20d%3D%22M32.694%2C37.544%20L1.495%2C37.544%20C0.667%2C37.544%20-0.005%2C36.872%20-0.005%2C36.044%20L-0.005%2C15.707%20C-0.005%2C14.878%200.667%2C14.208%201.495%2C14.208%20L22.614%2C14.208%20L22.614%2C9.525%20C22.614%2C6.481%2020.138%2C4.003%2017.092%2C4.003%20C14.049%2C4.003%2011.573%2C6.481%2011.573%2C9.525%20C11.573%2C10.077%2011.126%2C10.526%2010.573%2C10.526%20L8.573%2C10.526%20C8.020%2C10.526%207.573%2C10.077%207.573%2C9.525%20C7.573%2C4.276%2011.844%2C0.003%2017.092%2C0.003%20C22.342%2C0.003%2026.614%2C4.276%2026.614%2C9.525%20L26.614%2C14.208%20L32.694%2C14.208%20C33.522%2C14.208%2034.194%2C14.878%2034.194%2C15.707%20L34.194%2C36.044%20C34.194%2C36.872%2033.522%2C37.544%2032.694%2C37.544%20ZM17.092%2C19.719%20C15.337%2C19.719%2013.912%2C21.146%2013.912%2C22.907%20C13.912%2C24.088%2014.565%2C25.108%2015.522%2C25.656%20L15.522%2C31.731%20C15.522%2C32.063%2015.790%2C32.330%2016.121%2C32.330%20L18.067%2C32.330%20C18.398%2C32.330%2018.666%2C32.063%2018.666%2C31.731%20L18.666%2C25.656%20C19.624%2C25.108%2020.276%2C24.088%2020.276%2C22.907%20C20.276%2C21.146%2018.850%2C19.719%2017.092%2C19.719%20Z%22%20class%3D%22cls-1%22%2F%3E%3C%2Fsvg%3E");
  background-size: 27px;
  width: 28px;
  height: 30px;
}

#isport-archive .covers {
  min-height: 352px;
}

#isport-archive .covers.loading {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%22115px%22%20height%3D%22115px%22%20viewBox%3D%220%200%20100%20100%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%20%3Ccircle%20cx%3D%2250%22%20cy%3D%2250%22%20ng-attr-r%3D%22%7B%7Bconfig.radius%7D%7D%22%20ng-attr-stroke%3D%22%7B%7Bconfig.base%7D%7D%22%20ng-attr-stroke-width%3D%22%7B%7Bconfig.width%7D%7D%22%20fill%3D%22none%22%20r%3D%2230%22%20stroke%3D%22%23d21e1e%22%20stroke-width%3D%2210%22%2F%3E%20%3Ccircle%20cx%3D%2250%22%20cy%3D%2250%22%20ng-attr-r%3D%22%7B%7Bconfig.radius%7D%7D%22%20ng-attr-stroke%3D%22%7B%7Bconfig.stroke%7D%7D%22%20ng-attr-stroke-width%3D%22%7B%7Bconfig.innerWidth%7D%7D%22%20ng-attr-stroke-linecap%3D%22%7B%7Bconfig.linecap%7D%7D%22%20fill%3D%22none%22%20r%3D%2230%22%20stroke%3D%22%23ef9191%22%20stroke-width%3D%2210%22%20stroke-linecap%3D%22square%22%3E%20%3CanimateTransform%20attributeName%3D%22transform%22%20type%3D%22rotate%22%20calcMode%3D%22linear%22%20values%3D%220%2050%2050%3B180%2050%2050%3B720%2050%2050%22%20keyTimes%3D%220%3B0.5%3B1%22%20dur%3D%221s%22%20begin%3D%220s%22%20repeatCount%3D%22indefinite%22%2F%3E%20%3Canimate%20attributeName%3D%22stroke-dasharray%22%20calcMode%3D%22linear%22%20values%3D%2218.84955592153876%20169.64600329384882%3B94.2477796076938%2094.24777960769377%3B18.84955592153876%20169.64600329384882%22%20keyTimes%3D%220%3B0.5%3B1%22%20dur%3D%221%22%20begin%3D%220s%22%20repeatCount%3D%22indefinite%22%2F%3E%20%3C%2Fcircle%3E%20%3C%2Fsvg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
}

#isport-archive .cover {
  opacity: 0;
  width: 208px;
  transition: opacity .3s;
}

#isport-archive .cover.loaded {
  opacity: 1;
}

#isport-archive .cover img {
  max-width: 100%;
}

#isport-archive #archive-container {
  -webkit-user-select: none;
  user-select: none;
  width: 98%;
  margin: 0 auto 30px;
}

#isport-archive .subscription-box .header .subtitle, .archive-dialog .subscription-box .header .subtitle {
  margin-top: -1.5em;
  display: inline-block;
  position: relative;
}

.archive-dialog {
  z-index: 10003;
  background: #f0f0f0;
  border: 1px solid #dcdcdc;
  width: 770px;
  margin: -175px 0 0 -385px;
  padding: 12px 0 7px;
  display: none;
  position: fixed;
  top: 188px;
  left: 50%;
}

.archive-dialog .close-dialog {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 12px;
  right: 12px;
}

.archive-dialog .close-dialog:hover {
  cursor: pointer;
}

.archive-dialog .dataimg-cross-light, .archive-dialog .dataimg-cross {
  width: 100%;
}

.archive-dialog .subscription-box {
  width: 87%;
  margin: 0 auto;
}

.archive-dialog .subscription-box .in .headline {
  width: 100%;
}

.archive-dialog .subscription-box .in .login {
  margin: 8px 0 12px;
}

.archive-dialog .subscription-type {
  width: 187px;
}

.archive-dialog .body {
  margin: 0;
}

.archive-dialog-overlay {
  z-index: 10002;
  background: rgba(255, 255, 255, .9);
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#paid-zone {
  float: left;
  width: 100%;
  margin: 45px 0;
  font-size: 14px;
  position: relative;
}

#paid-zone h2 {
  font-family: Roboto, arial, sans-serif;
  font-size: 18px;
}

#paid-zone ol {
  margin: 15px 0;
  padding: 0 0 0 15px;
}

#paid-zone ol li {
  margin: 0 0 20px;
}

#paid-zone ol li:last-child {
  margin: 0;
}

#paid-zone a.dark {
  color: #1e1e1e;
  text-decoration: underline;
}

#paid-zone a.dark:hover {
  text-decoration: none;
}

#paid-zone .upgrade-plan {
  border-top: 1px solid #dcdcdc;
  margin: 20px 0 -18px;
  padding: 16px 0 0;
}

#paid-zone .upgrade-plan .backlink {
  margin-top: 10px;
}

#paid-zone .upgrade-plan .backlink a {
  margin: 0 13px 16px;
}

#paid-zone .alert {
  color: #d21e1e;
}

#paid-zone .logout {
  position: absolute;
  top: 27px;
  right: 21px;
}

#paid-zone .link-wrapper {
  border-top: 1px solid #dcdcdc;
}

#paid-zone .underline {
  color: #d21e1e;
  font-size: 14px;
  text-decoration: underline;
}

#paid-zone .underline:hover {
  text-decoration: none;
}

#paid-zone .content {
  width: 590px;
  margin: 0 auto;
}

#paid-zone .content.is-login {
  width: 600px;
}

#paid-zone .main-button, #paid-zone .button-secondary {
  width: auto;
  margin: 0 0 16px;
  padding: 13px 20px 12px;
  display: inline-block;
}

#paid-zone .main-info h1 {
  margin: 0 0 7px;
  padding: 0 0 13px;
  font-size: 30px;
  font-weight: 600;
}

#paid-zone .main-info p {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
}

#paid-zone .subscription-box {
  width: 600px;
  margin: 30px 0 0;
  padding: 0 20px 11px;
}

#paid-zone .subscription-box.nezaplaceno .headline {
  width: 85%;
}

#paid-zone .subscription-nav {
  text-align: center;
  margin: 23px 0 0;
}

#paid-zone .info-text {
  border-top: 1px solid #dcdcdc;
  margin: 15px 0 0;
  padding: 15px 0 0;
}

#paid-zone .backlink {
  margin-top: 24px;
}

#paid-zone .backlink + .tos-links, #paid-zone .tos-links {
  margin: 15px 0 -10px;
}

#paid-zone .tos-links h3 {
  margin: 0;
}

#paid-zone .tos-links .link-basic {
  margin: 4px 0 0;
  display: block;
}

#paid-zone .cancel-subscription-links a {
  margin-left: 13px;
  margin-right: 13px;
}

#paid-zone .link-basic {
  text-align: left;
  margin: 0 5px 8px;
  display: inline-block;
}

#paid-zone .link-basic:last-child {
  margin-bottom: 0;
}

#paid-zone .link-basic h3 {
  font-family: Roboto, arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
}

#paid-zone .link-basic a {
  color: #d21e1e;
  text-decoration: underline;
}

#paid-zone .profile-info, #paid-zone .sub-wrapper {
  background: #fff;
  padding: 0 20px 20px;
  position: relative;
}

#paid-zone .profile-info.prihlaseni-info, #paid-zone .sub-wrapper.prihlaseni-info {
  margin: -10px 0 0;
  padding: 0 20px 27px;
}

#paid-zone .profile-info.prihlaseni-info a, #paid-zone .sub-wrapper.prihlaseni-info a {
  position: absolute;
  top: -34px;
  right: 20px;
}

#paid-zone .profile-info.headline, #paid-zone .sub-wrapper.headline {
  padding: 12px 20px 0;
}

#paid-zone .profile-info.pt-20, #paid-zone .sub-wrapper.pt-20 {
  padding-top: 20px;
}

#paid-zone .profile-info .main-button.cancel-recurring, #paid-zone .sub-wrapper .main-button.cancel-recurring {
  width: 200px;
  margin-bottom: 0;
}

#paid-zone .sub-wrapper {
  padding-top: 20px;
}

#paid-zone #login-wrapper .main-container {
  margin-bottom: 0;
}

#paid-zone #pick-subscription-plan .backlink {
  margin: 0;
}

#paid-zone:before, #paid-zone:after {
  content: "";
  display: table;
}

#paid-zone:after {
  clear: both;
}

.icon-premium {
  z-index: 1;
  box-sizing: border-box;
  color: #403014;
  text-align: center;
  background-color: #e8c157;
  width: 80px;
  height: 20px;
  padding: 5px 0 0;
  font-size: 12px;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.icon-premium__x {
  color: #e50031;
}

.icon-premium__fallback {
  font-family: arial, sans-serif;
  line-height: 1;
}

.icon-premium__link {
  color: #403014;
  width: 100%;
  height: 100%;
}

.dataimg-icon-premium {
  z-index: 1;
  background-position: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.subscription {
  background: #f0f0f0;
  margin: 15px 0;
  position: relative;
}

.subscription.fade-out:before {
  content: " ";
  background: linear-gradient(rgba(255, 255, 255, 0) 0% 2%, #f0f0f0 60% 100%);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  top: -100px;
  left: 0;
}

.subscription__logo {
  background-size: contain;
  width: 190px;
  height: 115px;
  margin: 0 auto 15px;
}

.main-article .subscription__logo {
  width: 116px;
  height: 72px;
}

.subscription__logo.dataimg-isport-premium-welcome {
  width: 326px;
  height: 38px;
}

@media (max-width: 760px) {
  .subscription__logo {
    width: 150px;
    height: 92px;
  }
}

.subscription__headline {
  text-align: center;
  line-height: 40px;
  font-size: 30px !important;
}

.subscription__login {
  text-align: center;
  margin: 10px auto 25px;
  font-size: 13px;
  line-height: 22px;
}

.subscription__login a, .subscription__login-link {
  color: #d21e1e;
  font-weight: 700;
  text-decoration: underline;
}

.subscription__login-link:hover {
  text-decoration: none;
}

.subscription__login-link:after {
  content: "";
  background-image: url("data:image/svg+xml;charset%3DUS-ASCII,<svg%20xmlns%3D\"http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg\"%20viewBox%3D\"0%200%2022.1%2022.1\"%20><path%20fill%3D\"%23d21e1e\"%20d%3D\"M11%2C0C5%2C0%2C0%2C5%2C0%2C11c0%2C6.1%2C5%2C11%2C11%2C11s11-5%2C11-11C22.1%2C5%2C17.1%2C0%2C11%2C0z%20M16%2C19c-0.5-0.2-1-0.4-1.6-0.6c-1.1-0.4-1.2-1.1-1-2.7%20c0.5-0.6%2C0.8-1.3%2C1.1-2c0.1%2C0%2C0.1%2C0%2C0.2%2C0c0.6%2C0%2C0.6-0.6%2C0.7-0.8c0.3-0.6%2C0.4-1.6%2C0.1-2c-0.1-0.2-0.4-0.3-0.6-0.4%20c0-0.1%2C0-0.1%2C0.1-0.2c0.1-0.6%2C0.2-1.2%2C0.2-2c0-0.8-0.1-1.4-0.6-2c-0.8-1.2-3-1.7-4.1-1C9.8%2C4.6%2C7.6%2C5.3%2C7%2C6.7%20c-0.4%2C0.6-0.6%2C1.3-0.2%2C3.8c-0.2%2C0.1-0.4%2C0.2-0.5%2C0.5c-0.3%2C0.4%2C0.1%2C1.8%2C0.1%2C2c0%2C0.3%2C0.1%2C0.8%2C0.7%2C0.8c0.1%2C0%2C0.1%2C0%2C0.2%2C0%20c0.3%2C0.8%2C0.6%2C1.4%2C1.1%2C2c0.2%2C1.6%2C0.1%2C2.3-1%2C2.7c-0.6%2C0.2-1%2C0.4-1.4%2C0.5c-2.6-1.7-4.3-4.6-4.3-7.9c0-5.2%2C4.2-9.4%2C9.4-9.4%20c5.2%2C0%2C9.4%2C4.2%2C9.4%2C9.4C20.5%2C14.4%2C18.7%2C17.4%2C16%2C19z\"%2F><%2Fsvg>");
  background-repeat: no-repeat;
  width: 22px;
  height: 22px;
  display: inline-block;
  position: relative;
  top: 7px;
  left: 7px;
}

.subscription__advantages {
  border-top: 1px solid #dcdcdc;
  margin: 25px 0 0;
  padding: 25px 0;
}

.subscription__advantages-headline {
  margin: 0 0 8px;
  font-family: Roboto, arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  display: none;
}

.subscription__advantages-bullets {
  width: 300px;
  margin: 0 auto;
  padding: 0;
  font-family: Roboto, arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  display: block;
}

.subscription__advantages-bullet {
  background: url("/images/isportcz/dist/svg_fallback/advantage.png") 0 11px no-repeat;
  padding-left: 25px;
  line-height: 32px;
  list-style-type: none;
}

.subscription__types {
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin: 0 0 10px;
  padding: 0;
  display: flex;
}

.subscription__type {
  box-sizing: border-box;
  text-align: center;
  background: #fff;
  border: 0 solid #d1d4e4;
  flex: 0 0 48%;
  padding: 15px 15px 70px;
  list-style-type: none;
  position: relative;
}

.subscription__type-title {
  text-align: center;
  font-family: Roboto, arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  display: block;
}

.subscription__type-price {
  color: #d21e1e;
  text-align: center;
  border-bottom: 1px solid #d1d4e4;
  margin: 8px 0 15px;
  padding-bottom: 20px;
  font-family: Roboto, arial, sans-serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 38px;
  display: block;
}

.subscription__type-note small {
  color: #1e1e1e;
  font-family: Roboto, arial, sans-serif;
  font-weight: 700;
  font-size: 13px !important;
  line-height: 20px !important;
}

.subscription__type-order {
  color: #fff;
  text-align: center;
  background: #d21e1e;
  border-radius: 2px;
  width: calc(100% - 30px);
  height: 40px;
  font-family: Roboto, arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 40px;
  display: block;
  position: absolute;
  bottom: 15px;
  left: 15px;
}

.subscription__type-order:hover {
  color: #fff;
}

.subscription__type-note {
  margin: 0;
  font-size: 14px !important;
  line-height: 24px !important;
}

.subscription__type-highlight {
  color: #403014;
  background: #e8c157;
  padding: 0 8px;
  font-weight: 700;
  display: inline-block;
}

.subscription__type:last-child:before {
  content: "Nejvýhodnější";
  color: #403014;
  background: #e8c157;
  padding: 0 8px;
  font-size: 14px;
  font-weight: 700;
  display: none;
  position: absolute;
  top: -20px;
  left: -5px;
  transform: rotate(-5deg);
}

.subscription__payment-options {
  justify-content: space-between;
  margin: 30px 0 0;
  display: flex;
}

.subscription__payment-options-headline {
  min-width: 130px;
  margin: 8px 0 0;
  font-family: Roboto, arial, sans-serif;
  line-height: 20px;
  display: block;
  font-size: 13px !important;
}

.subscription__payment-options-bullets {
  width: 465px;
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 22px;
  overflow: hidden;
}

.subscription__payment-option {
  float: left;
  background: #fff;
  border-right: 0 solid #d1d4e4;
  margin: 0 10px;
  padding: 6px 15px;
  list-style-type: none;
  display: flex;
}

.subscription__payment-option:last-child {
  border: none;
  margin: 0;
}

.subscription__icon {
  height: 21px;
  margin: 0 0 0 6px;
  display: inline-block;
}

.subscription__help {
  color: #2c2f3b;
  content: "?";
  text-align: center;
  background: #d1d4e4;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 12px;
  display: inline-block;
  position: relative;
}

.subscription__help:hover {
  cursor: help;
}

.subscription__help:hover:after {
  content: attr(data-title);
  text-align: left;
  background: #fcf7db;
  width: 250px;
  padding: 15px;
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  bottom: 30px;
  left: -125px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

.subscription__help:hover:before {
  z-index: 2;
  content: "";
  filter: drop-shadow(0 5px 3px rgba(0, 0, 0, .16));
  border-top: 10px solid #fcf7db;
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  padding: 0;
  position: absolute;
  bottom: 22px;
  left: 0;
}

.dataimg-icon-premium-master-card {
  width: 26px;
}

.dataimg-icon-premium-visa {
  width: 29px;
}

.dataimg-icon-premium-gopay {
  width: 50px;
}

.dataimg-icon-premium-sms {
  width: 19px;
}

.subscription__corporate {
  border-top: 1px solid #dcdcdc;
  justify-content: space-between;
  margin: 20px 0;
  padding-top: 20px;
  display: flex;
}

.subscription__corporate-title {
  min-width: 130px;
  margin: 0;
  font-family: Roboto, arial, sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  display: block;
}

.subscription__corporate-note {
  text-align: left;
  width: 465px;
  margin: 0;
  font-family: Roboto, arial, sans-serif;
  font-size: 12px;
  line-height: 20px;
  display: block;
}

.subscription__corporate-link {
  color: #d21e1e;
  font-weight: 700;
  text-decoration: underline;
}

.subscription__type-voucher {
  background: #fff;
  width: 640px;
  min-height: 167px;
  margin: 20px auto;
  padding: 15px 5px 0 50%;
  position: relative;
  overflow: hidden;
}

.subscription__type-voucher .subscription__type-price {
  border: 0;
  margin: 10px;
  padding: 0;
}

.subscription__type-voucher .subscription__type-order {
  width: 100%;
  margin: 0;
  position: static;
}

.subscription__coupon-voucher {
  width: calc(50% - 15px);
  margin: 0;
  position: absolute;
  bottom: 7px;
  left: 15px;
  transform: rotate(-10deg);
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, .25);
}

.subscription--new.subscription--690 {
  width: 690px;
  margin-left: -50px;
}

.subscription--new .subscription__toggle {
  margin: 20px 0 30px;
  text-decoration: none;
}

.subscription--new .subscription__toggle:after {
  margin: 0;
}

.subscription--new .subscription__toggle.active:after {
  transform: rotate(180deg);
}

.subscription--new .subscription__toggle-button {
  background: none;
  border: 0;
  text-decoration: underline;
}

.subscription--new .subscription__toggle-button:hover {
  text-decoration: none;
}

.subscription--new .subscription__toggle-button:focus {
  outline: none;
}

.subscription--new .welcome {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: -3px;
  font-size: 31px;
  font-weight: bold;
  line-height: 37px;
  display: block;
}

.subscription--new .subscription__sub-headline {
  margin: 30px auto 20px;
  font-family: roboto-Bold, sans-serif;
  font-size: 18px;
  line-height: 24px;
}

.subscription--new .subscription__logo {
  background-image: url("https://img2.cncenter.cz/images/isportcz/dist/svg/logo-premium.svg");
  background-repeat: no-repeat;
  background-size: 190px;
  width: 190px;
  height: 115px;
  margin-bottom: 22px;
}

.subscription--new .subscription__login {
  margin: 10px 0 40px;
  font-size: 14px;
  line-height: 28px;
}

.subscription--new .subscription__login:after {
  top: 5px;
}

.subscription--new .subscription__login-link {
  font-weight: normal;
}

.subscription--new .subscription__login-link.no-icon:after {
  content: "";
  display: none;
}

.subscription--new .subscription__buttons {
  text-align: center;
  margin: 35px 0 40px;
}

.subscription--new .subscription__button {
  color: #403014;
  background: #fff;
  border: 1px solid #d2d4e4;
  border-radius: 2px;
  height: 28px;
  padding: 0 15px;
  font-size: 14px;
  line-height: 24px;
}

.subscription--new .subscription__button.active {
  color: #d21e1e;
  border: 1px solid #d21e1e;
}

.subscription--new .subscription__button:first-child {
  margin-right: 7px;
}

.subscription--new .subscription__button:focus {
  outline: 0;
}

.subscription--new .subscription__types {
  align-items: flex-start;
}

.subscription--new .subscription__type {
  margin: 0 0 30px;
  padding: 30px 0;
}

.subscription--new .subscription__type.id-2 {
  min-width: 93%;
  margin: 0 auto 30px;
}

.subscription--new .subscription__type:last-child {
  margin-bottom: 0;
}

.subscription--new .subscription__type-order {
  width: 150px;
  margin: 26px auto 0;
  line-height: 38px;
  position: relative;
  bottom: 0;
  left: 0;
}

.subscription--new .subscription__type-order.buttonOff {
  color: red;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid red;
  font-weight: 400;
}

.subscription--new .subscription__type-order.buttonOff:hover {
  color: #fff;
}

.subscription--new .subscription__type-order:focus {
  outline: 0;
}

.subscription--new .subscription__type-order:hover {
  background: #a81818;
  text-decoration: none;
}

.subscription--new .subscription__coupon-voucher {
  bottom: 40px;
}

.subscription--new .subscription__type-price {
  color: #1e1e1e;
  border: none;
  margin: 15px 0 2px;
  padding: 0;
}

.subscription--new .subscription__type-price-month {
  color: #1e1e1e;
  margin: 0 0 0 10px;
  font-family: Roboto Light, arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
}

.subscription--new .subscription__type-perks {
  text-align: left;
  margin: 12px 0 0;
  padding: 0;
  display: inline-block;
  overflow: hidden;
}

.subscription--new .subscription__type-perk {
  color: #403014;
  margin: 0 0 13px;
  padding-left: 25px;
  font-size: 14px;
  line-height: 20px;
  list-style-type: none;
  display: block;
  position: relative;
}

.subscription--new .subscription__type-perk:before {
  content: " ";
  background: url("/images/isportcz/dist/svg_fallback/advantage.png") 0 11px no-repeat;
  width: 25px;
  height: 25px;
  display: block;
  position: absolute;
  bottom: 1px;
  left: 0;
}

.subscription--new .subscription__type-perk.disadvantage:before {
  content: "❌";
  background: none;
  width: 25px;
  height: 25px;
  display: block;
  position: absolute;
  bottom: -6px;
  left: -2px;
}

.subscription--new .subscription__type-perk:last-child {
  margin: 0;
}

.subscription--new .subscription__type-title {
  margin: 0 0 12px;
  font-size: 24px;
  line-height: 28px;
}

.subscription--new .subscription__type-subtitle {
  text-align: center;
  color: #1e1e1e;
  margin: -8px 0 18px;
  font-family: roboto Light, sans-serif;
  font-size: 24px;
  line-height: 28px;
  display: block;
}

.subscription--new .subscription__payment-options-headline {
  font-size: 14px !important;
}

.subscription--new .subscription__corporate-title, .subscription--new .subscription__corporate-note, .subscription--new .subscription__advantages-bullet {
  font-size: 14px;
}

.subscription--new .subscription__corporate {
  border: 0;
}

.subscription--new .subscription__payment-options {
  width: 93%;
  margin: 30px auto 50px;
}

.subscription--new .subscription__advantages-headline {
  text-align: center;
  margin: 0 0 15px;
  font-size: 18px;
  line-height: 24px;
  display: block;
}

.subscription--new .subscription__advantages-bullet {
  margin-bottom: 5px;
  font-weight: normal;
}

.subscription--new .subscription__advantages-bullets {
  text-align: left;
  margin: 0;
}

.subscription--new .subscription__advantages {
  border: 0;
  margin: 50px 0 0;
  padding: 0;
  display: inline-block;
}

.subscription--new .subscription__advantages-wrapper {
  text-align: center;
}

.subscription--new .subscription__advantages-footnote {
  text-align: center;
  width: 50%;
  margin: 35px auto 50px;
  font-size: 14px;
  line-height: 18px;
}

.subscription--new .subscription__advantages-footnote a {
  color: #d21e1e;
  text-decoration: underline;
}

.subscription--new .subscription__advantages-footnote a:hover {
  text-decoration: none;
}

.subscription--new .subscription__type-minimal {
  background: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  font-size: 14px;
  display: flex;
}

.subscription--new .subscription__type-minimal:first-child {
  margin-bottom: 10px;
}

.subscription--new .subscription__type-minimal-link {
  color: #d21e1e;
  text-decoration: underline;
}

.subscription--new .subscription__type-minimal-link:hover {
  text-decoration: none;
}

.subscription--new .subscription__type-extra {
  box-sizing: border-box;
  text-align: center;
  background: #e7c257;
  border: 1px solid #d9af2b;
  border-radius: 50%;
  width: 71px;
  height: 71px;
  padding: 5px;
  font-size: 13px;
  line-height: 15px;
  position: absolute;
  top: -25px;
  right: -10px;
}

.subscription--new .subscription__type-extra strong {
  color: #fff;
  margin-top: -1px;
  font-family: roboto-Bold, sans-serif;
  font-size: 20px;
  line-height: 26px;
  display: block;
}

#paid-zone .subscription__note {
  width: 47%;
}

#paid-zone #activation-login .table-default td {
  border: 0;
  font-size: 13px;
  line-height: 18px;
}

#paid-zone #activation-login .table-default td.valid {
  font-weight: 700;
}

#paid-zone #activation-login .table-default td:first-child {
  width: 50%;
  font-weight: 500;
}

#paid-zone #activation-login .table-default td + td {
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
}

#paid-zone #activation-login .table-default td + td span.red {
  color: #d21e1e;
}

#paid-zone #activation-login .table-default td + td span.green {
  color: #65c41e;
}

#paid-zone #activation-login .table-default.summary, #paid-zone #activation-login .table-default.business {
  border-top: 1px solid #dcdcdc;
  margin: 0 0 12px;
}

#paid-zone #activation-login .table-default.summary td, #paid-zone #activation-login .table-default.business td {
  padding-top: 28px;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
}

#paid-zone #activation-login .table-default.summary td:first-child, #paid-zone #activation-login .table-default.business td:first-child {
  width: 45%;
  font-weight: 500;
}

#paid-zone #activation-login .table-default.business {
  margin-bottom: 5px;
}

#paid-zone #activation-login .table-default.valid-to tr:first-child td {
  border-top: 0;
  padding: 0 0 12px;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
}

#paid-zone #activation-login .table-default.valid-to tr:first-child td.valid {
  font-weight: 700;
}

#paid-zone #activation-login .table-default.valid-to tr:last-child {
  padding-bottom: 12px;
}

#paid-zone #activation-login .table-default.valid-to.recurring-cancelled-true tr:last-child td {
  padding: 0 0 12px;
}

#paid-zone #activation-login .table-default.recurring td {
  padding: 0;
}

#paid-zone #activation-login .user-bonuses + .table-default {
  border: none;
}

#paid-zone #login-wrapper .main-container {
  margin-top: 60px;
}

#paid-zone .link-basic a {
  color: #1e1e1e;
  font-size: 13px;
  line-height: 18px;
  text-decoration: underline;
}

#paid-zone .link-basic a:hover {
  text-decoration: none;
}

#paid-zone .profile-info {
  margin-bottom: 30px;
  padding: 0 15px 15px;
}

#paid-zone .profile-info.headline {
  margin-bottom: 0;
  padding: 15px;
}

#paid-zone .profile-info.headline > h1 {
  color: #d21e1e;
  font-family: Roboto, arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

#paid-zone .profile-info .main-button.cancel-recurring {
  color: #1e1e1e;
  background: none;
  width: auto;
  margin-top: 2px;
  margin-bottom: 15px;
  padding: 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-decoration: underline;
  position: relative;
  top: 0;
  right: 0;
}

#paid-zone .profile-info .main-button.cancel-recurring:hover {
  text-decoration: none;
}

#paid-zone .profile-info .change-business-term {
  text-align: right;
  margin-bottom: 15px;
}

#paid-zone .profile-info .change-business-term a {
  color: #1e1e1e;
  font-size: 13px;
  line-height: 15px;
  text-decoration: underline;
}

#paid-zone .profile-info .change-business-term a:hover {
  text-decoration: none;
}

#paid-zone .logout {
  color: #1e1e1e;
  font-size: 13px;
  line-height: 24px;
  text-decoration: underline;
  position: absolute;
  top: 15px;
  right: 15px;
}

#paid-zone .profile-info.prihlaseni-info {
  text-align: left;
  margin: 0;
  padding: 15px 15px 30px;
  font-family: Roboto, arial, sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
}

#paid-zone .active-until {
  text-align: right;
  margin-top: 3px;
  margin-bottom: 30px;
  font-size: 13px;
  line-height: 18px;
}

#paid-zone .active-until .underline {
  color: #1e1e1e;
  margin-top: 12px;
  font-size: 13px;
  line-height: 15px;
  display: inline-block;
}

#paid-zone .active-until .underline:hover {
  text-decoration: none;
}

#paid-zone #order-subscription {
  padding: 15px;
}

#paid-zone #order-subscription .well-content {
  background: #eef4f7;
  width: 64%;
}

#paid-zone #order-subscription .well-content p {
  padding: 15px;
}

#paid-zone #order-subscription .subscription-notice p {
  font-size: 13px;
}

#paid-zone #order-subscription .main-info h1 {
  color: #e50031;
  text-align: left;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

#paid-zone #order-subscription .logout {
  top: 0;
  right: 0;
}

#paid-zone #order-subscription .profile-info.prihlaseni-info {
  border-bottom: 1px solid #dcdcdc;
  margin: 0 0 25px;
  padding: 30px 0;
}

#paid-zone #order-subscription .table-default td {
  border: 0;
  font-size: 13px;
  line-height: 18px;
}

#paid-zone #order-subscription .table-default td.valid {
  font-weight: 700;
}

#paid-zone #order-subscription .table-default td:first-child {
  width: 45%;
  font-weight: 500;
}

#paid-zone #order-subscription .table-default td + td {
  text-align: right;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
}

#paid-zone #order-subscription .table-default td small {
  padding-right: 330px;
  font-size: 12px;
  line-height: 18px;
  display: block;
}

#paid-zone #order-subscription .table-default.total {
  border: 0;
  margin: 0;
}

#paid-zone #order-subscription .table-default.total.sms-payment tr td {
  padding: 0 0 10px;
}

#paid-zone #order-subscription .table-default.total.sms-payment label {
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
}

#paid-zone #order-subscription .table-default.total.sms-payment label .grey {
  color: #aaa;
}

#paid-zone #order-subscription .table-default.total tr.subscription__info {
  color: #2c2f3b;
  background: #fcf7db;
  font-size: 12px;
  line-height: 18px;
}

#paid-zone #order-subscription .table-default.total tr.subscription__info td {
  text-align: left;
  padding: 10px;
  font-size: 12px;
}

#paid-zone #order-subscription .order-description {
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 13px;
}

#paid-zone #order-subscription .subscription__total-price {
  border-bottom: 1px solid #dcdcdc;
}

#paid-zone #order-subscription .subscription__total-price tr td {
  padding-bottom: 30px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

#paid-zone #order-subscription .subscription__total-price tr td:first-child {
  font-weight: 500;
}

#paid-zone #order-subscription .default-form.agreement {
  border-bottom: 1px solid #dcdcdc;
  padding: 10px 0 30px;
}

#paid-zone #order-subscription .default-form.agreement .form-field label {
  font-size: 12px;
  line-height: 22px;
}

#paid-zone #order-subscription .default-form.agreement .form-field label .underline {
  color: #1e1e1e;
  font-size: 12px;
  line-height: 22px;
}

#paid-zone #order-subscription .default-form.agreement .personal__data {
  color: #1e1e1e;
  text-align: left;
  margin-left: 30px;
  font-size: 12px;
  line-height: 22px;
}

#paid-zone #order-subscription .default-form.agreement .personal__data a {
  color: #1e1e1e;
  text-decoration: underline;
}

#paid-zone #order-subscription .default-form.agreement .personal__data a:hover {
  color: #1e1e1e;
  text-decoration: none;
}

#paid-zone #order-subscription .default-form.agreement .error-message {
  color: #d21e1e;
  text-align: left;
  margin: 10px;
  font-size: 13px;
  line-height: 18px;
  display: block;
}

#paid-zone #order-subscription .default-form .triangle-arrow--right {
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  border-left: 3px solid #1e1e1e;
  width: 0;
  height: 0;
  display: inline-block;
  position: relative;
  top: 0;
  left: 3px;
}

#paid-zone .subscription__voucher .order__success-title {
  text-align: center;
  border: 0;
  outline: 0;
  margin: 0 40px 20px;
  padding: 0;
  font-family: arial, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

#paid-zone .subscription__voucher .order__success-title h2 {
  color: #e50031;
  font-size: 18px;
  line-height: 22px;
}

#paid-zone .subscription__voucher .order__success-voucher {
  margin: 0 0 30px;
}

#paid-zone .subscription__voucher .order__success-voucher .order__success-generated {
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  display: block;
}

#paid-zone .subscription__voucher .order__success-download {
  margin: 30px 0;
}

#paid-zone .subscription__voucher .order__success-pdf {
  color: #fff;
  text-align: center;
  background: #d0112b;
  width: 293px;
  margin: 0 auto;
  padding: 11px 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-decoration: none;
  display: block;
}

#paid-zone .subscription__voucher .dataimg-download-icon {
  float: left;
  width: 21px;
  height: 18px;
  margin-right: 5px;
}

#paid-zone .subscription__voucher .order__success-info {
  margin: 30px auto;
}

#paid-zone .subscription__voucher .order__success-info h2 {
  text-align: center;
  color: #1e1e1e;
  opacity: 1;
  margin-bottom: 20px;
  font: 700 15px / 25px Roboto, arial, sans-serif;
}

#paid-zone .subscription__voucher .order__success-info p {
  color: #2c2f3b;
  font: 15px / 25px Roboto, arial, sans-serif;
}

#paid-zone .subscription__voucher .order__success-howto {
  border-top: 1px solid #dcdcdc;
  padding-top: 20px;
  font-family: Roboto, arial, sans-serif;
}

#paid-zone .subscription__voucher .order__success-howto h2 {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

#paid-zone .subscription__voucher .order__success-howto ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#paid-zone .subscription__voucher .order__success-howto ul li {
  margin: 5px 0;
  font-size: 15px;
  line-height: 22px;
}

#paid-zone .subscription__voucher .order__success-contact {
  visibility: visible;
  border-top: 1px solid #dcdcdc;
  margin-top: 20px;
  padding-top: 20px;
  font-size: 13px;
  line-height: 21px;
}

form.default-form input[type="radio"] + label:before {
  content: "";
  background-color: #fff;
  border: 2px solid #e6e6e6;
  border-radius: 18px;
  width: 14px;
  height: 14px;
  transition: all .3s;
  position: absolute;
  top: 8px;
  left: 0;
}

form.default-form input[type="radio"]:checked + label:after {
  background: #1e1e1e;
  width: 8px;
  height: 8px;
  top: 13px;
  left: 5px;
}

#paid-zone #order-subscription input[type="submit"] {
  width: 220px;
}

form.default-form [type="checkbox"] + label:before {
  content: "";
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 2px;
  width: 14px;
  height: 14px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 6px;
  left: 0;
}

form.default-form [type="checkbox"] + label:after {
  content: "✔";
  color: #1e1e1e;
  opacity: 0;
  font-size: 16px;
  line-height: .8;
  transition: all .3s;
  position: absolute;
  top: 8px;
  left: 2px;
  transform: scale(0);
}

#paid-zone #order-subscription-sms {
  padding: 15px;
}

#paid-zone #order-subscription-sms .main-info h1 {
  color: #d21e1e;
  text-align: left;
  border-bottom: 1px solid #dcdcdc;
  margin: 0 0 30px;
  padding: 0 0 30px;
  font-family: roboto, arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
}

#paid-zone #order-subscription-sms #steps {
  border-bottom: 1px solid #dcdcdc;
  margin: 30px 0;
  padding: 0 0 30px;
  font-size: 13px;
  line-height: 18px;
}

#paid-zone #order-subscription-sms #steps li form {
  margin-top: 20px;
}

#paid-zone #order-subscription-sms #steps li form span {
  color: #aaa;
  font-size: 13px;
  line-height: 18px;
  top: 36px;
}

#paid-zone #order-subscription-sms #steps li .main-button {
  margin: 6px 0 0;
  font-size: 15px;
  line-height: 20px;
}

#paid-zone #order-subscription-sms #steps li .sms_input {
  float: left;
  border: 1px solid #d1d4e4;
  width: 218px;
  margin: 24px 10px 0 0;
  padding: 0 0 0 44px;
  font-size: 15px;
}

#paid-zone #order-subscription-sms #additional-info p {
  font-size: 13px;
  line-height: 18px;
}

#paid-zone #order-subscription-sms #additional-info h2 {
  margin: 30px 0 17px;
  font-size: 14px;
  line-height: 19px;
}

#paid-zone #order-subscription-sms #additional-info ul {
  margin: 20px 0;
  padding: 0;
  list-style: none;
}

#paid-zone #order-subscription-sms #additional-info ul li {
  margin: 0;
  font-size: 13px;
  line-height: 24px;
}

#paid-zone #order-subscription-sms #additional-info ul li:before {
  content: "";
  color: #d1d4e4;
  background: #d1d4e4;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin-right: 10px;
  display: inline-block;
}

#paid-zone #order-subscription-sms #additional-info .cancel-sub {
  background: #fcf7db;
  border: 0;
  margin: 30px 0;
  padding: 10px;
  font-size: 12px;
  line-height: 18px;
}

#paid-zone .main-button {
  padding: 10px;
  font-size: 15px;
  line-height: 20px;
}

#isport-archive.wrapper {
  max-width: 1050px;
}

#isport-archive .choose-subscription {
  width: -webkit-max-content;
  width: max-content;
  margin: 0 auto 20px;
  padding: 10px 15px;
}

#isport-archive .subscription {
  width: 100%;
  margin: 20px auto;
}

#isport-archive .wrapper {
  max-width: 1050px;
}

#isport-archive #archive-container {
  width: 100%;
}

.subscription__toggle {
  text-align: center;
  color: #d21e1e;
  cursor: pointer;
  margin: 10px 0 25px;
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  text-decoration: underline;
  display: block;
}

.subscription__toggle:after {
  content: "";
  background-image: url("data:image/svg+xml;charset%3DUS-ASCII,<svg%20viewBox%3D\"0%200%209%205\"%20xmlns%3D\"http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg\"%20><path%20fill%3D\"%23d21e1e\"%20d%3D\"M4.6%2C5L9%2C1.3L8.3%2C0L4.5%2C3.1L0.8%2C0L0%2C1.3L4.3%2C5H4.6z\"%2F><%2Fsvg>");
  background-repeat: no-repeat;
  width: 13px;
  height: 7px;
  margin-left: 5px;
  transition: all .5s;
  display: inline-block;
}

.subscription__toggle.open:after {
  transform: rotate(180deg);
}

.subscription__faq {
  clear: both;
  background: #fff;
  width: 100%;
  max-width: 600px;
  margin: 30px auto 35px;
  padding: 15px;
}

.subscription__faq-title {
  margin-bottom: 20px;
  font-family: Roboto, arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.subscription__faq-questions p {
  font-size: 14px;
  line-height: 18px;
}

.subscription__faq-questions p.subscription__faq-question {
  cursor: pointer;
  border-bottom: 1px solid #dcdcdc;
  margin: 10px 0 0;
  padding: 0 30px 10px 0;
  line-height: 23px;
  position: relative;
}

.subscription__faq-questions p.subscription__faq-question.opened {
  border-bottom: 0;
}

.subscription__faq-questions p.subscription__faq-question.opened:after {
  transform: rotate(180deg);
}

.subscription__faq-questions p.subscription__faq-question:after {
  content: "";
  background-image: url("data:image/svg+xml;charset%3DUS-ASCII,<svg%20viewBox%3D\"0%200%209%205\"%20xmlns%3D\"http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg\"%20><path%20fill%3D\"%23000000\"%20d%3D\"M4.6%2C5L9%2C1.3L8.3%2C0L4.5%2C3.1L0.8%2C0L0%2C1.3L4.3%2C5H4.6z\"%2F><%2Fsvg>");
  background-repeat: no-repeat;
  width: 12px;
  height: 6px;
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
}

.subscription__faq-questions p:not(.subscription__faq-question) {
  display: none;
}

.subscription__faq-questions p.last {
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 5px;
  padding-bottom: 15px;
}

.subscription__faq-footer {
  text-align: center;
  margin-top: 15px;
  font-size: 15px;
  line-height: 22px;
}

.subscription__faq-contact {
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
}

.subscription__faq-mail {
  color: #d21e1e;
  font-weight: 700;
  text-decoration: underline;
}

.subscription__faq-mail:hover {
  text-decoration: none;
}

.splash-faq.splash-product {
  padding: 0;
}

.splash-faq__question {
  width: 100%;
  display: inline-block;
}

.subscription--article {
  color: #fff;
  text-align: left;
  background: #1e1e1e;
  width: 590px;
  padding: 35px 50px;
}

.subscription--article .subscription__headline {
  letter-spacing: 0;
  text-align: left;
  color: #fff;
  margin-top: 25px;
  margin-bottom: 10px;
  font-family: roboto, arial, sans-serif;
  line-height: 29px;
  font-size: 26px !important;
}

.subscription--article .subscription__description {
  margin-bottom: 25px;
  font-size: 16px;
  line-height: 1.3;
}

.subscription--article .subscription__type-order {
  text-transform: uppercase;
  background-color: #d21e1e;
  border: none;
  border-radius: 4px;
  width: 150px;
  height: 52px;
  margin: 0 15px 0 0;
  font-size: 18px;
  line-height: 52px;
  display: inline-block;
  position: static;
}

.subscription--article .subscription__link {
  margin-top: 20px;
  font-size: 12px;
  display: inline-block;
}

.subscription--article .subscription__link a {
  color: #fff;
  text-decoration: underline;
}

.subscription--article .subscription__link a:hover {
  color: #fff;
  text-decoration: none;
}

.subscription--article .subscription__login {
  color: #fff;
  text-align: left;
  margin: 30px 0 0;
  font-size: 16px;
  line-height: normal;
}

.subscription--article .subscription__login a {
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  text-decoration: underline;
}

.subscription--article .subscription__login a:hover {
  text-decoration: none;
}

.subscription--article .subscription__login-link:after {
  display: none;
}

.subscription--article .subscription__logos {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.subscription--article .subscription__logos svg {
  display: block;
}

.subscription--article .subscription__logo--premium-plus {
  width: 125px;
}

.subscription--article .subscription__logo--divider {
  border-right: 1px solid #fff;
  width: 1px;
  height: 16px;
  margin: 0 16px;
}

.subscription--article .subscription__logo--isport {
  width: 120px;
}

.subscription-box .subscription-type {
  text-align: center;
  background: #fff;
  width: 180px;
  height: 380px;
  padding: 16px 15px 0;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}

.subscription-box .subscription-type p.label {
  padding: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.35;
}

.subscription-box .subscription-type p.label a {
  color: #d21e1e;
  text-decoration: underline;
}

.subscription-box .subscription-type p.label a:hover {
  text-decoration: none;
}

.subscription-box .subscription-type .variants {
  color: #828282;
  text-align: left;
  margin: 14px 0 0;
  font-size: 13px;
  position: relative;
}

.subscription-box .subscription-type .variants input[type="radio"] + label {
  padding-bottom: 4px;
  padding-left: 19px;
}

.subscription-box .subscription-type .variants input[type="radio"] + label:after {
  transform: scale(.58);
}

.subscription-box .subscription-type .variants input[type="radio"] + label:before {
  transform: scale(.58);
}

.subscription-box .subscription-type .variants input[type="radio"] + label:before {
  border: 3px solid silver;
  top: 4px;
  left: -4px;
}

.subscription-box .subscription-type .variants input[type="radio"] + label:after {
  top: 16px;
  left: 8px;
  transform: scale(.65);
}

.subscription-box .subscription-type .variants input[type="radio"]:checked + label:after {
  top: 10px;
  left: 2px;
}

.subscription-box .subscription-type .variants .variant {
  position: relative;
}

.subscription-box .subscription-type .variants .variant[data-checked="checked"] .title {
  color: #1e1e1e;
}

.subscription-box .subscription-type .variants .variant[data-default="default"] .dataimg-isport-premium-choice {
  content: " ";
  width: 84px;
  height: 44px;
  display: block;
  position: absolute;
  top: -18px;
  left: -87px;
}

.subscription-box .subscription-type .variants .variant + .variant {
  border-top: 1px solid #dcdcdc;
  margin: 6px 0 0;
  padding: 2px 0 0;
}

.subscription-box .subscription-type .variants .price {
  float: right;
  margin: -4px 0 0;
  font-size: 16px;
}

.subscription-box .subscription-type .variants .title {
  -webkit-user-select: none;
  user-select: none;
  outline: 0;
  font-weight: 600;
}

.subscription-box .subscription-type .main .price {
  color: #d21e1e;
  font-family: Roboto Slab, Times New Roman, serif;
  font-size: 35px;
}

.subscription-box .subscription-type .main .price small {
  margin: 0 7px 0 0;
  font-size: 20px;
  font-weight: 400;
}

.subscription-box .subscription-type .main .price .asterisk {
  font-family: Roboto, arial, sans-serif;
  font-size: 25px;
  font-weight: 100;
}

.subscription-box .subscription-type .main .title, .subscription-box .subscription-type .main .subtitle {
  display: block;
}

.subscription-box .subscription-type .main .title {
  font-family: Roboto, arial, sans-serif;
}

.subscription-box .subscription-type .main .subtitle {
  margin: -1px 0 13px;
  font-size: 13px;
  line-height: 1.3;
}

.subscription-box .subscription-type .button-main {
  width: 150px;
  margin-left: -75px;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  bottom: 18px;
  left: 50%;
}

.subscription-box .subscription-type .button-main[data-disabled="disabled"] {
  color: #aaa;
  background: #dcdcdc;
}

.subscription-box .subscription-type .button-main[data-disabled="disabled"]:active {
  background: #828282;
}

.subscription-box .subscription-type .dialog-wrapper {
  height: 0;
  position: absolute;
  top: -10px;
  left: 50%;
}

.subscription-box .subscription-type .dialog {
  color: #1e1e1e;
  text-align: center;
  background: #fff;
  border: 2px solid #d21e1e;
  border-radius: 5px;
  width: 160px;
  margin-left: -81px;
  padding: 6px 5px 8px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.35;
  transition: all;
  animation: 1.5s ease-in-out infinite float;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateY(0);
}

.subscription-box .subscription-type .dialog .arrow-wrapper {
  width: .65em;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.subscription-box .subscription-type .dialog .arrow-border, .subscription-box .subscription-type .dialog .arrow {
  border-left: .65em solid rgba(0, 0, 0, 0);
  border-right: .65em solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
}

.subscription-box .subscription-type .dialog .arrow-border {
  border-top: .65em solid #d21e1e;
  transform: translateY(110%)translateX(-50%);
}

.subscription-box .subscription-type .dialog .arrow {
  z-index: 2;
  border-top: .65em solid #fff;
  transform: translateY(75%)translateX(-50%);
}

.subscription-box .subscription-type .dialog .close {
  background: #d21e1e;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(50%)translateY(-50%);
}

.subscription-box .subscription-type .dialog .close:hover {
  cursor: pointer;
}

.subscription-box .subscription-type .dialog .close .dataimg-cross {
  width: 54%;
  height: 54%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.subscription-box .subscription-type .dialog .close path {
  fill: #fff;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(0);
  }
}

.subscription-box {
  z-index: 10;
  background: #f0f0f0;
  width: 640px;
  padding: 0 40px 25px;
  position: relative;
}

.subscription-box.hidden-body .header .headline {
  width: 85%;
}

.subscription-box.hidden-body .body {
  margin-top: 25px;
}

.subscription-box.hidden-body .show-body .dataimg-arrow-vip-down, .subscription-box.hidden-body .show-body .dataimg-arrow-vip-up {
  float: right;
  width: 10px;
  height: 10px;
  margin: 7px 0 0 5px;
  display: block;
}

.subscription-box.hidden-body .show-body .dataimg-arrow-vip-down .st0, .subscription-box.hidden-body .show-body .dataimg-arrow-vip-up .st0 {
  fill: #d21e1e;
}

.subscription-box.hidden-body .show-body .dataimg-arrow-vip-up {
  display: none;
}

.subscription-box.fade-out:before {
  content: " ";
  background: linear-gradient(rgba(240, 240, 240, 0) 0% 2%, #f0f0f0 50% 100%);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  top: -100px;
  left: 0;
}

.subscription-box form.default-form .form-field {
  margin-bottom: 15px;
}

.subscription-box ._link {
  color: #d21e1e;
  font-weight: 700;
  text-decoration: underline;
}

.subscription-box ._link:hover {
  text-decoration: none;
}

.subscription-box .corporate-order {
  font-size: 13px;
  font-weight: 700;
  line-height: 1.8;
}

.subscription-box .corporate-order span {
  font-weight: 400;
}

.subscription-box .corporate-order .bleskmobil {
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  margin: 10px 0;
  padding: 16px 0 15px;
}

.subscription-box .corporate-order .bleskmobil-title {
  font-size: 15px;
}

.subscription-box .corporate-order .bleskmobil-logo {
  width: 51px;
  height: 34px;
  margin: 0 auto;
}

.subscription-box .corporate-order .bleskmobil-button {
  width: 84px;
  height: 33px;
  margin: 5px auto 0;
  padding: 7px 0;
  font-weight: 400;
}

.subscription-box .header {
  text-align: center;
}

.subscription-box .header.no-content {
  border-bottom: 0;
}

.subscription-box .header.no-padding {
  padding: 0;
}

.subscription-box .header .dataimg-isport-premium {
  width: 241px;
  height: 41px;
}

.subscription-box .header .dataimg-isport-premium-welcome {
  width: 373px;
  height: 43px;
}

.subscription-box .header .svg-title {
  margin: 0 auto 20px;
}

.subscription-box .header .headline {
  width: 65%;
  margin: 20px auto 19px;
  font-family: Roboto Slab, Times New Roman, serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;
}

.subscription-box .header .headline + .login {
  margin: -10px 0 10px;
}

.subscription-box .header .subtitle {
  margin: 11px 0;
  font-size: 16px;
  font-weight: 600;
}

.subscription-box .header .subtitle .warning {
  content: " ";
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cdefs%3E%3ClinearGradient gradientTransform='matrix(1.31117 0 0 1.30239 737.39 159.91)' gradientUnits='userSpaceOnUse' id='0' y2='-.599' x2='0' y1='45.47'%3E%3Cstop stop-color='%23ffc515'/%3E%3Cstop offset='1' stop-color='%23ffd55b'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg transform='matrix(.85714 0 0 .85714-627.02-130.8)'%3E%3Cpath d='m797.94 212.01l-25.607-48c-.736-1.333-2.068-2.074-3.551-2.074-1.483 0-2.822.889-3.569 2.222l-25.417 48c-.598 1.185-.605 2.815.132 4 .737 1.185 1.921 1.778 3.404 1.778h51.02c1.483 0 2.821-.741 3.42-1.926.747-1.185.753-2.667.165-4' fill='url(%230)'/%3E%3Cpath d='m-26.309 18.07c-1.18 0-2.135.968-2.135 2.129v12.82c0 1.176.948 2.129 2.135 2.129 1.183 0 2.135-.968 2.135-2.129v-12.82c0-1.176-.946-2.129-2.135-2.129zm0 21.348c-1.18 0-2.135.954-2.135 2.135 0 1.18.954 2.135 2.135 2.135 1.181 0 2.135-.954 2.135-2.135 0-1.18-.952-2.135-2.135-2.135z' transform='matrix(1.05196 0 0 1.05196 796.53 161.87)' fill='%23000' stroke='%2340330d' fill-opacity='.75'/%3E%3C/g%3E%3C/svg%3E");
  width: 2em;
  height: 2em;
  margin: 0 .1em 0 0;
  display: inline-block;
  position: relative;
  top: .5em;
}

.subscription-box .header .headline, .subscription-box .header .subtitle {
  display: block;
}

.subscription-box .header .login {
  margin: 19px 0 12px;
  font-size: 13px;
  font-weight: 100;
  display: block;
}

.subscription-box .header .login .dataimg-circle-head {
  width: 23px;
  height: 23px;
  margin: 0 0 0 6px;
  display: inline-block;
  position: relative;
  top: 6px;
}

.subscription-box .header .login .dataimg-circle-head path {
  fill: #d21e1e;
}

.subscription-box .body {
  text-align: center;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 14px;
}

.subscription-box .body.no-footer {
  border-bottom: 0;
}

.subscription-box .body .subscription-type {
  float: left;
  margin: 0 10px 0 0;
  line-height: 1.5;
}

.subscription-box .body .subscription-type:last-of-type {
  margin: 0;
}

.subscription-box .body .submitForm {
  float: right;
  width: 180px;
}

.subscription-box .body .footnote {
  text-align: left;
  border-top: 1px solid #dcdcdc;
  margin: 16px 0 0;
  padding: 16px 0 0;
  font-size: 13px;
}

.subscription-box .body:before, .subscription-box .body:after {
  content: "";
  display: table;
}

.subscription-box .body:after {
  clear: both;
}

.subscription-box .footer {
  width: auto;
}

.subscription-box .footer span {
  margin: 20px 0 26px;
  font-size: 16px;
  font-weight: bold;
  display: block;
}

.subscription-box .footer ul {
  padding: 0;
  list-style: none;
}

.subscription-box .footer li {
  margin: 0 0 21px;
  font-size: 16px;
}

.subscription-box .footer li:before {
  content: "• ";
  color: #d21e1e;
  margin: 0 2px 0 0;
  font-family: arial, sans-serif;
  font-size: 35px;
  line-height: 0;
  position: relative;
  top: 7px;
}

.subscription-box .header, .subscription-box .body {
  padding: 0 0 15px;
}

.subscription-box .subscription-type.corporate {
  height: auto;
  padding: 16px 15px;
}

.subscription-box .subscription-type.corporate.selected {
  box-shadow: inset 0 0 0 2px #d21e1e;
}

.subscription-box .subscription-type.corporate input[type="radio"]:checked + label:after {
  top: 7px;
  left: 4px;
}

.subscription-box .subscription-type.corporate .main .price {
  font-size: 32px;
}

.subscription-box .subscription-type.corporate .main .title {
  font-size: inherit;
  padding: 0 0 0 15px;
}

.subscription-box .subscription-type.corporate .main .title:before {
  width: 15px;
  height: 15px;
  top: 3px;
}

.subscription-box .subscription-type.corporate .main .title:after {
  top: 13px;
  left: 10px;
}

.subscription-box .subscription-type.corporate .main .dph {
  color: #828282;
  font-family: Roboto Slab, Times New Roman, serif;
}

.subscription-box.corporate {
  margin: 22px auto 0;
}

.subscription-box.corporate .header .headline {
  width: 50%;
}

.subscription-box.corporate .body {
  border: none;
  margin-top: 0;
}

.subscription-box.corporate .contact-form {
  border-top: 1px solid #dcdcdc;
  margin: 13px 0 0;
  padding: 13px 0 0;
}

.subscription-box.corporate .contact-form input, .subscription-box.corporate .contact-form textarea {
  font-size: 14px;
  font-weight: 400;
}

.subscription-box.corporate .contact-form label {
  text-align: left;
}

.subscription-box.corporate .contact-form input[type="email"] {
  width: 100%;
  padding: 0 .5em;
  line-height: 2.6;
}

.subscription-box.corporate .contact-form textarea {
  padding: .5em;
}

.subscription-box.corporate .contact-form input.captcha {
  text-align: center;
}

.subscription-box.corporate .contact-form .captcha-txt {
  width: 25%;
  margin: 0 0 0 10px;
}

.subscription-box.corporate .contact-form .captcha-txt input {
  width: 80%;
}

.subscription-box.corporate .contact-form .submitForm {
  width: 150px;
}

.subscription-box.corporate .contact-form #subscription-success-message {
  color: #d21e1e;
  background: #fff;
  border: 1px solid #d21e1e;
  border-radius: 5px;
  margin: 25px 0;
  padding: 3%;
  font-size: 17px;
  position: fixed;
  top: 50%;
  box-shadow: 0 7px 24px rgba(0, 0, 0, .5);
}

#isport-premium h1, #isport-premium-corporate h1 {
  float: left;
  border-top: 2px solid #d21e1e;
  width: 1050px;
  padding-top: 10px;
}

#isport-premium .subscription-box, #isport-premium-corporate .subscription-box {
  margin: 0 auto;
}

#isport-premium .subscription-box:not(.hidden-body) .headline, #isport-premium-corporate .subscription-box:not(.hidden-body) .headline {
  width: 100%;
}

.recentarticles-isport-premium {
  clear: both;
  border-top: 3px solid #d21e1e;
  margin: 0 0 25px;
}

.recentarticles-isport-premium:hover > .title-container h2 a {
  box-shadow: none;
  text-decoration: underline;
}

.recentarticles-isport-premium h2 a {
  font-weight: 700;
}

.recentarticles-isport-premium .main-title {
  margin: 0 0 11px;
  font-size: 18px;
}

.recentarticles-isport-premium .main-button {
  margin: 0 auto 12px;
}

.recentarticles-isport-premium .link {
  font-weight: 700;
}

.recentarticles-isport-premium .title-section {
  border: 0;
}

.recentarticles-isport-premium.tv, .recentarticles-isport-premium.sidebar {
  border-top: none;
  margin-top: 72px;
  padding-top: 0;
}

.recentarticles-isport-premium.tv article.list-article, .recentarticles-isport-premium.sidebar article.list-article {
  border-top: 1px solid #dcdcdc;
  margin: 15px 15px 8px;
  padding: 15px 0 0;
}

.recentarticles-isport-premium.tv article.list-article:first-of-type, .recentarticles-isport-premium.sidebar article.list-article:first-of-type {
  border: none;
  margin-top: 0;
  padding: 0;
}

.recentarticles-isport-premium.tv article.list-article.list-article-vertical-half, .recentarticles-isport-premium.sidebar article.list-article.list-article-vertical-half {
  margin-left: 0;
  margin-right: 0;
}

.recentarticles-isport-premium.tv article.list-article.list-article-vertical-half .meta, .recentarticles-isport-premium.sidebar article.list-article.list-article-vertical-half .meta {
  height: 0;
  margin: 0;
}

.recentarticles-isport-premium.tv article.list-article.list-article-vertical-half .isport-premium, .recentarticles-isport-premium.sidebar article.list-article.list-article-vertical-half .isport-premium {
  top: 0;
}

.recentarticles-isport-premium.tv article.list-article.list-article-vertical-half .flag, .recentarticles-isport-premium.sidebar article.list-article.list-article-vertical-half .flag {
  width: 85px;
  height: 85px;
  margin: -42px 0 0 -42px;
  top: 50%;
  left: 50%;
}

.recentarticles-isport-premium.tv article.list-article.list-article-vertical-half .image-container, .recentarticles-isport-premium.sidebar article.list-article.list-article-vertical-half .image-container {
  height: 185px;
  overflow: hidden;
}

.recentarticles-isport-premium.tv article.list-article.list-article-vertical-half .title-container, .recentarticles-isport-premium.sidebar article.list-article.list-article-vertical-half .title-container {
  margin-bottom: 0;
}

.recentarticles-isport-premium.tv article.list-article.list-article-normal, .recentarticles-isport-premium.sidebar article.list-article.list-article-normal {
  width: auto;
}

.recentarticles-isport-premium.tv article.list-article.list-article-normal .title-container, .recentarticles-isport-premium.sidebar article.list-article.list-article-normal .title-container {
  width: 46.5%;
  margin: 0;
}

.recentarticles-isport-premium.tv article.list-article.list-article-normal .title-container h2, .recentarticles-isport-premium.sidebar article.list-article.list-article-normal .title-container h2 {
  font-family: Roboto, arial, sans-serif;
  font-size: 13px;
  line-height: 1.35;
}

.recentarticles-isport-premium.tv article.list-article.list-article-normal .image-container, .recentarticles-isport-premium.sidebar article.list-article.list-article-normal .image-container {
  width: 150px;
  height: 90px;
  overflow: hidden;
}

.recentarticles-isport-premium.tv article.list-article.list-article-normal .image-container img, .recentarticles-isport-premium.sidebar article.list-article.list-article-normal .image-container img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.recentarticles-isport-premium.tv article.list-article.list-article-normal .image-container img.isport-premium-img, .recentarticles-isport-premium.sidebar article.list-article.list-article-normal .image-container img.isport-premium-img {
  transform: inherit;
}

.recentarticles-isport-premium.tv article.list-article.list-article-normal .isport-premium.small, .recentarticles-isport-premium.sidebar article.list-article.list-article-normal .isport-premium.small {
  width: 73px;
  height: 20px;
}

.recentarticles-isport-premium.tv article.list-article.list-article-normal .flag, .recentarticles-isport-premium.sidebar article.list-article.list-article-normal .flag {
  width: 40px;
  height: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.recentarticles-isport-premium.tv .list-article-vertical-half {
  flex-direction: column;
  display: flex;
}

.recentarticles-isport-premium.tv .list-article-vertical-half .image-container {
  order: -1;
}

.recentarticles-isport-premium.tv article.list-article.list-article-vertical-half .meta {
  margin: 15px 0 10px 20px;
}

.recentarticles-isport-premium.sidebar {
  background: #fff;
  border: 0;
  margin: 0 0 30px;
}

.recentarticles-isport-premium.sidebar .main-title {
  margin: 5px 0;
  font-size: 22px;
}

.recentarticles-isport-premium.sidebar article.list-article.list-article-normal .image-container img {
  height: 100%;
}

.recentarticles-isport-premium.sidebar .link-wrapper {
  text-align: center;
  padding: 0 15px 17px;
}

.recentarticles-isport-premium.sidebar article.list-article.list-article-normal .meta {
  margin-bottom: 6px;
}

.superlife .recentarticles-isport-premium .main-title, .superlife .recentarticles-isport-premium article.list-article:hover > .title-container h2 a, .superlife .recentarticles-isport-premium a.link {
  color: #02a555;
  box-shadow: none;
}

.superlife .recentarticles-isport-premium .main-title .sport-color, .superlife .recentarticles-isport-premium article.list-article:hover > .title-container h2 a .sport-color, .superlife .recentarticles-isport-premium a.link .sport-color {
  color: #02a555;
}

.zoh-pchjongcchang {
  font-family: Roboto, arial, sans-serif;
}

.zoh-pchjongcchang.articles-rightcol {
  margin-bottom: 30px;
}

.zoh-pchjongcchang.articles-rightcol, .zoh-pchjongcchang.table-right {
  float: left;
  background-color: #fff;
  width: 100%;
  padding-bottom: 10px;
  position: relative;
}

.zoh-pchjongcchang.articles-rightcol .article-rightcol-small, .zoh-pchjongcchang.table-right .article-rightcol-small {
  float: left;
  border-bottom: 1px solid #dcdcdc;
  width: calc(100% - 30px);
  margin: 0 0 15px 15px;
  padding-bottom: 15px;
  position: relative;
}

.zoh-pchjongcchang.articles-rightcol .article-rightcol-small .ars-media, .zoh-pchjongcchang.table-right .article-rightcol-small .ars-media {
  float: left;
  width: 150px;
  height: 82px;
  display: block;
  position: relative;
  overflow: hidden;
}

.zoh-pchjongcchang.articles-rightcol .article-rightcol-small .ars-media img, .zoh-pchjongcchang.table-right .article-rightcol-small .ars-media img {
  max-width: 100%;
}

.zoh-pchjongcchang.articles-rightcol .article-rightcol-small .ars-title, .zoh-pchjongcchang.table-right .article-rightcol-small .ars-title {
  float: right;
  color: #1e1e1e;
  width: calc(100% - 160px);
  padding-bottom: 0;
  font-family: roboto, arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.3em;
  display: block;
  position: relative;
}

.zoh-pchjongcchang.articles-rightcol .article-rightcol-small .ars-title:hover, .zoh-pchjongcchang.table-right .article-rightcol-small .ars-title:hover {
  color: #006cb5;
}

.zoh-pchjongcchang .zoh-articles-rightcol-header, .zoh-pchjongcchang .zoh-table-right-header {
  z-index: 1;
  color: #fff;
  background: url("//img2.cncenter.cz/images/isportcz/dist/non-sprite/pchjoncchang-strip.png") 0 0 / cover no-repeat;
  margin-bottom: 20px;
  padding: 10px;
  position: relative;
}

.zoh-pchjongcchang .zoh-articles-rightcol-header .left-part, .zoh-pchjongcchang .zoh-table-right-header .left-part {
  float: left;
  width: 60%;
}

.zoh-pchjongcchang .zoh-articles-rightcol-header .left-part .title, .zoh-pchjongcchang .zoh-table-right-header .left-part .title {
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
}

.zoh-pchjongcchang .zoh-articles-rightcol-header .left-part .title a, .zoh-pchjongcchang .zoh-articles-rightcol-header .left-part .title:hover, .zoh-pchjongcchang .zoh-articles-rightcol-header .left-part .title:focus, .zoh-pchjongcchang .zoh-table-right-header .left-part .title a, .zoh-pchjongcchang .zoh-table-right-header .left-part .title:hover, .zoh-pchjongcchang .zoh-table-right-header .left-part .title:focus {
  color: #fff;
}

.zoh-pchjongcchang .zoh-articles-rightcol-header .left-part .small-title, .zoh-pchjongcchang .zoh-table-right-header .left-part .small-title {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
}

.zoh-pchjongcchang .zoh-articles-rightcol-header .right-part, .zoh-pchjongcchang .zoh-table-right-header .right-part {
  float: left;
  box-sizing: border-box;
  border-left: 1px solid #4ea8f6;
  width: 40%;
  padding-left: 10px;
}

.zoh-pchjongcchang .zoh-articles-rightcol-header .right-part a, .zoh-pchjongcchang .zoh-table-right-header .right-part a {
  color: #fff;
  text-transform: uppercase;
  margin: 3px 0;
  font-size: 13px;
  font-weight: 700;
  display: block;
  position: relative;
}

.zoh-pchjongcchang .box-container.no-margin {
  margin: 0;
}

.blesk-box {
  float: left;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
}

.blesk-box .zoh-pchjongcchang.articles-rightcol, .blesk-box .zoh-pchjongcchang.table-right {
  margin-bottom: 0;
}

.blesk-box .zoh-pchjongcchang .left-part {
  width: 58%;
}

.blesk-box .zoh-pchjongcchang .right-part {
  width: 42%;
}

.blesk-box .zoh-pchjongcchang .article-rightcol-small {
  width: calc(100% - 30px);
  margin: 0 15px 15px;
}

.blesk-box .zoh-pchjongcchang .article-rightcol-small .ars-media {
  width: 135px;
  height: 74px;
}

.blesk-box .zoh-pchjongcchang .article-rightcol-small .ars-title {
  width: calc(100% - 145px);
}

.blesk-box .zoh-pchjongcchang .zoh-articles-rightcol-header .right-part a, .blesk-box .zoh-pchjongcchang .zoh-table-right-header .right-part a {
  line-height: 15px;
}

.reflex-box .zoh-pchjongcchang .left-part {
  width: 58%;
}

.reflex-box .zoh-pchjongcchang .right-part {
  width: 42%;
}

.reflex-box .zoh-pchjongcchang .article-rightcol-small {
  width: 100%;
  margin: 0 0 15px;
}

.reflex-box .zoh-pchjongcchang .zoh-articles-rightcol-header .right-part a, .reflex-box .zoh-pchjongcchang .zoh-table-right-header .right-part a {
  font-size: 12px;
  line-height: 15px;
}

.info-box .zoh-pchjongcchang.articles-rightcol, .info-box .zoh-pchjongcchang.table-right {
  margin-bottom: 0;
}

.info-box .zoh-pchjongcchang .left-part {
  width: 58%;
}

.info-box .zoh-pchjongcchang .right-part {
  width: 42%;
}

.info-box .zoh-pchjongcchang .zoh-articles-rightcol-header .right-part a, .info-box .zoh-pchjongcchang .zoh-table-right-header .right-part a {
  font-size: 12px;
  line-height: 15px;
}

.zoh-special-link {
  text-align: center;
  color: #006cb5;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-decoration: underline;
  display: block;
}

.zoh-special-link:hover {
  color: #006cb5;
  text-decoration: none;
}

.team-basic-info {
  float: left;
  width: 100%;
  display: block;
}

.team-basic-info .team-basic-info__logo {
  float: left;
  text-align: center;
  width: 50%;
  padding-bottom: 15px;
  display: block;
}

.team-basic-info .team-basic-info__logo h1 {
  font-family: roboto, arial, sans-serif;
  font-size: 24px;
  font-weight: normal;
}

.team-basic-info .team-basic-info__text {
  float: right;
  text-align: left;
  width: 50%;
}

.team-basic-info .team-basic-info__text div {
  border-bottom: 1px solid #000;
  width: 100%;
  margin-top: 10px;
  padding-bottom: 5px;
}

.team-basic-info .team-basic-info__text div span {
  font-weight: bold;
}

.team-basic-info .team-basic-info__text div:last-child {
  border-bottom: 0;
}

.team-list {
  float: left;
  background: #fff;
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  display: block;
}

.team-list.team-flex {
  display: flex;
}

.team-list__item {
  float: left;
  border-radius: 10px;
  width: 12.5%;
  height: auto;
  margin: auto;
  display: block;
}

.team-list__item img {
  min-width: 100%;
  height: auto;
}

.team-list__item:hover {
  background-color: #f0f0f0;
  position: relative;
}

.team-list__item[data]:hover:after {
  content: attr(data);
  color: #fff;
  white-space: nowrap;
  z-index: 2;
  border-radius: 5px;
  padding: 4px 8px;
  display: block;
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translateX(-50%);
}

.team-list--football .team-list__item.active, .team-list--football .team-list__item[data]:hover:after {
  background: #00a442;
}

.team-list--hockey .team-list__item.active, .team-list--hockey .team-list__item[data]:hover:after {
  background: #325edc;
}

.team-list.smallerLogos {
  margin-top: -15px;
}

.team-list.smallerLogos .team-list__item {
  width: 6.25%;
}

.team-list--league-25 .team-list__item, .team-list--league-5 .team-list__item {
  width: 14.28%;
}

.team-list--league-5.smallerLogos .team-list__item {
  width: 7.14%;
}

.team-list--season-3781 .team-list__item, .team-list--season-3783 .team-list__item, .team-list--season-3797 .team-list__item, .team-list--season-3795 .team-list__item {
  width: 11%;
}

.team-list-complex {
  float: left;
  background: #fff;
  width: 100%;
  margin-bottom: 15px;
  padding: 15px;
  display: block;
}

.team-list-complex__headline {
  float: left;
  width: 100%;
  margin-top: 10px;
  padding: 0 0 10px;
  font-weight: bold;
}

.team-list-complex__headline .dataimg-arrow-down {
  float: right;
  width: 10px;
  height: 5px;
  margin: 10px 5px 0 0;
}

.team-list-complex__headline .dataimg-arrow-down svg path {
  fill: #00a442;
}

.team-list-complex__headline--link {
  cursor: pointer;
}

.team-list-complex__list {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.team-list-complex__list.hidden {
  display: none;
}

.team-list-complex__item {
  float: left;
  width: calc(25% - 3px);
  margin: 2px;
  display: inline-block;
}

.team-list-complex__link {
  background-color: #f0f0f0;
  border-radius: 5px;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 90px;
  display: flex;
}

.team-list-complex__link:hover {
  color: #fff;
  text-decoration: none;
}

.team-list-complex__link .team-list-complex__logo {
  flex-basis: 30%;
  align-items: center;
  display: flex;
}

.team-list-complex__link .team-list-complex__logo img {
  width: 100%;
  max-height: 90px;
}

.team-list-complex__link .team-list-complex__title {
  text-align: left;
  flex-basis: 70%;
  align-items: center;
  padding: 0 10px;
  font-size: 18px;
  display: flex;
}

.team-list-complex.football .team-list-complex__headline {
  color: #00a442;
}

.team-list-complex.football .team-list-complex__headline .dataimg-arrow-down svg path {
  fill: #00a442;
}

.team-list-complex.football .team-list-complex__link:hover {
  background: #00a442;
}

.team-list-complex.hockey .team-list-complex__headline {
  color: #325edc;
}

.team-list-complex.hockey .team-list-complex__headline .dataimg-arrow-down svg path {
  fill: #325edc;
}

.team-list-complex.hockey .team-list-complex__link:hover {
  background: #325edc;
}

.team-list-complex:not(.team-list-complex--responsive) .team-list-complex__item:nth-child(4n) {
  margin-right: 0;
}

.team-list-complex:not(.team-list-complex--responsive) .team-list-complex__item:first-child, .team-list-complex:not(.team-list-complex--responsive) .team-list-complex__item:nth-child(4n+1) {
  margin-left: 0;
}

.team-matches {
  float: left;
  width: 100%;
}

.team-matches .dataimg-loading {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.team-matches .dataimg-arrow-right path {
  fill: #00a442;
}

.team-matches .warning {
  text-align: center;
  width: 100%;
}

.team-matches .match {
  float: left;
  width: 100%;
}

.team-matches .owl-carousel {
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

.team-matches .owl-carousel .owl-stage {
  margin: auto;
}

.team-matches .owl-carousel.single {
  width: 147px;
}

.team-matches .owl-carousel .video-art.more-art {
  width: 100%;
}

.team-matches .title-section.box-title .main-title {
  padding: 0;
}

.team-matches .box-container .whiteGradient {
  background-image: linear-gradient(rgba(255, 255, 255, 0), #fff);
  width: 100%;
  height: 20px;
  margin-top: -34px;
  position: relative;
}

.team-matches .box-container .box-match #bb-scorers-team1-view, .team-matches .box-container .box-match #bb-scorers-team2-view {
  display: none;
}

.team-matches .box-container .box-match .link--right {
  margin-top: 12px;
}

.team-matches .box-container .box-match .link--right-b {
  margin-top: 22px;
}

.team-matches .box-container .box-match .list-score-middle .match-container.single .team-container .logo-container {
  width: 100%;
  margin-top: 30px;
}

.team-matches .box-container .box-match .list-score-middle .match-container.single .team-container .logo-container img {
  width: auto;
  height: 200%;
}

.team-matches .box-container .box-match .team-container {
  width: 37%;
  margin-top: 5px;
}

.team-matches .box-container .box-match .team-container .logo-container {
  width: 28%;
  margin-top: 0;
}

.team-matches .box-container .box-match .flag {
  font-size: 13px;
  font-weight: 700;
}

.team-matches .box-container .box-match .flag-live {
  margin-top: -60px;
  font-size: 13px;
  float: none !important;
  color: #d21e1e !important;
  top: 9px !important;
}

.team-matches .box-container .box-match .flag-live:before {
  content: " ●";
  margin-top: 3px;
  margin-right: 3px;
  font-size: 15px;
  color: #d21e1e !important;
}

.team-matches .box-container .box-match .list-score-middle .team-name {
  width: 72%;
  font-size: 22px;
  font-weight: normal;
}

.team-matches .box-container .box-match .list-score.list-score-middle .score-container {
  width: 16%;
}

.team-matches .box-container .box-match .list-score.list-score-middle .score-container .score .score-pen {
  float: left;
  margin-top: 7px;
  margin-left: 21px;
  padding-bottom: 10px;
}

.team-matches .box-container .box-match .list-score-structured-wapper .list-score.list-score-small, .team-matches .box-container .box-match .list-score-structured-wrapper .list-score.list-score-small {
  min-height: 50px;
}

.team-matches .box-container .box-match .list-score-structured-wapper .link--right, .team-matches .box-container .box-match .list-score-structured-wrapper .link--right {
  margin-top: 15px;
}

.team-matches .box-container .box-match .list-score-structured-wapper .list-score .datetime-container, .team-matches .box-container .box-match .list-score-structured-wrapper .list-score .datetime-container {
  width: 25%;
  padding-top: 20px;
  padding-bottom: 7px;
}

.team-matches .box-container .box-match .list-score-structured-wapper .with-lap, .team-matches .box-container .box-match .list-score-structured-wrapper .with-lap {
  padding-left: 67px;
}

.team-matches .box-container .box-match .list-score-structured-wapper .list-score .match-container, .team-matches .box-container .box-match .list-score-structured-wrapper .list-score .match-container {
  width: 75%;
  margin-top: -27px;
}

.team-matches .box-container .box-match .list-score-structured-wapper .match-container.no-lap, .team-matches .box-container .box-match .list-score-structured-wrapper .match-container.no-lap {
  margin-top: 10px;
}

.team-matches .box-container .box-match .list-score-structured-wapper .match-bet-cover.with-match-online, .team-matches .box-container .box-match .list-score-structured-wrapper .match-bet-cover.with-match-online {
  margin-left: -26px;
}

.team-matches .box-container .box-match .list-score-structured-wapper .team-home, .team-matches .box-container .box-match .list-score-structured-wrapper .team-home {
  margin-left: 10px;
}

.team-matches .box-container .box-match .list-score-structured-wapper .team-container, .team-matches .box-container .box-match .list-score-structured-wrapper .team-container {
  width: 36%;
}

.team-matches .box-container .box-match .list-score-structured-wapper .team-container .logo-container, .team-matches .box-container .box-match .list-score-structured-wrapper .team-container .logo-container {
  width: 14%;
  margin-top: -2px;
}

.team-matches .box-container .box-match .list-score-structured-wapper .score-container, .team-matches .box-container .box-match .list-score-structured-wrapper .score-container {
  width: 13%;
}

.team-matches .box-container .box-match .list-score-structured-wapper .title-section.box-title .main-title, .team-matches .box-container .box-match .list-score-structured-wrapper .title-section.box-title .main-title, .team-matches .box-container .box-match .list-score-middle {
  padding-top: 20px;
}

.team-matches .box-container .box-match .list-score-middle.no-padding {
  padding: 0;
}

.team-matches .box-container .align-center {
  cursor: pointer;
}

.team-matches .box-container .showMore {
  margin: 10px;
}

.eight-col .team-matches .box-container .box-match .list-score-middle .match-container.single .team-container .logo-container {
  width: 21%;
  margin-top: 0;
}

.eight-col .team-matches .box-container .box-match .list-score-middle .match-container.single .team-container .logo-container img {
  max-width: 100%;
  height: auto;
}

.team-articles, .team-videos {
  float: left;
  width: 100%;
}

.team-articles .box-container, .team-videos .box-container {
  background: none;
  margin-bottom: 0;
}

.team-articles .football .category-name a, .team-videos .football .category-name a {
  color: #00a442;
}

@media only screen and (min-width: 426px) {
  .team-articles .four-col .list-article, .team-videos .four-col .list-article {
    min-height: 350px;
  }
}

.team-articles .list-article-vertical .image-container .flag, .team-videos .list-article-vertical .image-container .flag {
  top: 342px;
}

.team-articles .list-article-normal .dataimg-isport-premium, .team-articles .list-article-vertical .dataimg-isport-premium, .team-videos .list-article-normal .dataimg-isport-premium, .team-videos .list-article-vertical .dataimg-isport-premium {
  height: 100%;
  margin-top: 7px;
}

.box-tournament {
  background-color: #fff;
  padding-bottom: 10px;
  font-family: Roboto, arial, sans-serif;
  position: relative;
}

.box-tournament .list-article.list-article-horizontal-small {
  background-color: #fff;
}

.box-tournament .list-article.list-article-horizontal-small .image-container {
  min-width: inherit;
}

.box-tournament .list-article.list-article-horizontal-small .image-container img {
  width: 100%;
  height: auto;
}

.box-tournament .list-article.list-article-horizontal-small .title-container h2 a {
  text-decoration: none;
}

.box-tournament .list-article.list-article-horizontal-small .title-container h2 a:hover, .box-tournament .list-article.list-article-horizontal-small .title-container h2 a:focus {
  text-decoration: underline;
}

.box-tournament__header {
  z-index: 1;
  color: #fff;
  background-color: #325edc;
  background-size: cover;
  margin-bottom: 20px;
  padding: 10px;
  position: relative;
}

.box-tournament__header a {
  text-decoration: none;
}

.box-tournament__header a:hover, .box-tournament__header a:focus {
  text-decoration: underline;
}

.box-tournament__header-left {
  float: left;
  width: 60%;
}

.box-tournament__title {
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
}

.box-tournament__title a, .box-tournament__title:hover, .box-tournament__title:focus {
  color: #fff;
}

.box-tournament__subtitle {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
}

.box-tournament__header-right {
  float: left;
  box-sizing: border-box;
  border-left: 1px solid #fff;
  width: 40%;
  padding-left: 10px;
}

.box-tournament__header-right a {
  color: #fff;
  text-transform: uppercase;
  margin: 3px 0;
  font-size: 13px;
  font-weight: 700;
  display: block;
  position: relative;
}

.box-tournament__header-right .dataimg-arrow-right svg {
  height: 10px;
  position: absolute;
  top: 2px;
  right: 0;
}

.box-tournament__header-right .dataimg-arrow-right path {
  fill: #fff;
}

.box-tournament__link {
  text-align: center;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-decoration: underline;
  display: block;
}

.box-tournament__link:hover {
  text-decoration: none;
}

.box-tournament--football .box-tournament__header {
  color: #fff;
  background-color: #00a442;
}

.box-tournament--football .box-tournament__link, .box-tournament--football .box-tournament__link:hover, .box-tournament--football .list-article-horizontal-small .title-container h2 a:hover, .box-tournament--football .list-article-horizontal-small .title-container h2 a:focus, .box-tournament--football .list-article-horizontal-small:hover .title-container h2 a {
  color: #00a442;
}

.box-tournament--hockey .box-tournament__header {
  color: #fff;
  background-color: #325edc;
}

.box-tournament--hockey .box-tournament__header-right {
  border-color: #4ea8f6;
}

.box-tournament--hockey .box-tournament__link, .box-tournament--hockey .box-tournament__link:hover, .box-tournament--hockey .list-article-horizontal-small .title-container h2 a:hover, .box-tournament--hockey .list-article-horizontal-small .title-container h2 afocus, .box-tournament--hockey .list-article-horizontal-small:hover .title-container h2 a {
  color: #325edc;
}

.box-tournament--zoh .box-tournament__header {
  color: #fff;
  background: url("//img2.cncenter.cz/images/isportcz/dist/non-sprite/pchjoncchang-strip.png") 0 0 / cover no-repeat;
}

.box-tournament--zoh .box-tournament__header-right {
  border-color: #4ea8f6;
}

.box-tournament--zoh .box-tournament__link, .box-tournament--zoh .box-tournament__link:hover, .box-tournament--zoh .list-article-horizontal-small .title-container h2 a:hover, .box-tournament--zoh .list-article-horizontal-small .title-container h2 afocus, .box-tournament--zoh .list-article-horizontal-small:hover .title-container h2 a {
  color: #006cb5;
}

.league-matches .list-score.list-score-small .play-container {
  display: block;
}

.list-video-preview {
  text-align: center;
  flex-flow: row;
  justify-content: center;
  margin-top: 25px;
  display: flex;
}

.list-video-preview__item {
  flex-basis: 23%;
  margin: 0 1%;
}

.list-video-preview__item:hover .flag.dataimg-play, .list-video-preview__item:hover .flag .circle {
  opacity: .8;
}

.list-video-preview__img {
  display: block;
  position: relative;
}

.list-video-preview__img .flag {
  z-index: 2;
  width: 40px;
  height: 40px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.list-video-preview__img .flag.dataimg-play, .list-video-preview__img .flag .circle {
  opacity: .6;
  transition: opacity .2s ease-in-out;
}

.list-video-preview__title {
  width: 100%;
  min-height: 70px;
  margin: 10px 0;
  font-family: Roboto, arial, sans-serif;
  font-size: 13px;
  font-weight: 700;
}

.list-video-preview__title a {
  display: block;
}

.sportmod-team-link {
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
  align-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: fit-content;
  margin: 20px auto;
  padding: 10px 15px;
  font-size: 17px;
  line-height: 20px;
  display: flex;
}

.sportmod-team-link:hover {
  color: #fff;
}

.sportmod-team-link__logo {
  width: 40px;
  height: 40px;
}

.sportmod-team-link__logo.no-resize {
  width: initial;
  height: initial;
}

.sportmod-team-link--football {
  background: #00a442;
}

.sportmod-team-link--football:hover {
  background: #00b348;
}

.sportmod-team-link--hockey {
  background: #325edc;
}

.sportmod-team-link--hockey:hover {
  background: #3f68de;
}

.tipsport-tv-url {
  color: #0197f4;
  max-height: 25px;
  margin: 0 auto;
  padding-top: 2px;
  display: block;
}

.tipsport-tv-url .match-online .gray, .tipsport-tv-url .match-online .icone, .tipsport-tv-url .match-online .tipsport {
  float: left;
}

.tipsport-tv-url .match-online .gray {
  color: #828282;
  width: 92px;
}

.tipsport-tv-url .match-online .icone {
  width: 26px;
  height: auto;
  margin: 4px 8px;
  display: inline-block;
}

.tipsport-tv-url .match-online .tipsport, .tipsport-tv-url .match-online .match-online-text .tipsport {
  color: #0197f4;
}

.tipsport-tv-url .match-online:before, .tipsport-tv-url .match-online:after {
  content: "";
  display: table;
}

.tipsport-tv-url .match-online:after {
  clear: both;
}

.art-enquiry {
  width: 690px;
  position: relative;
  left: -100px;
}

.art-enquiry:before, .art-enquiry:after {
  content: "";
  display: table;
}

.art-enquiry:after {
  clear: both;
}

.enquiry__title-container {
  z-index: 1;
  background-color: #d21e1e;
  width: 100px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.enquiry__title-container .icon {
  width: 32px;
  height: 33px;
  margin: 15px auto 0;
}

.enquiry__title-container .icon path {
  fill: #fff;
}

.enquiry__title-container span {
  color: #fff;
  text-align: center;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 700;
  display: block;
}

.enquiry__content-container {
  background-color: #fff;
  width: 100%;
  padding: 15px 15px 15px 115px;
}

.enquiry__question {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 700;
  line-height: 120%;
}

.enquiry__answers {
  padding-left: 0;
  list-style-type: none;
}

.enquiry__answers .button--red {
  margin: 0 auto;
}

.enquiry__answer {
  -webkit-user-select: none;
  user-select: none;
  position: relative;
}

.enquiry__answer input[type="radio"] {
  float: left;
  display: none;
}

.enquiry__answer input[type="radio"] + label {
  cursor: pointer;
  float: left;
  padding: 7px 0 15px 30px;
  font-weight: 700;
  display: block;
  position: relative;
}

.enquiry__answer input[type="radio"] + label:before {
  content: "";
  background-color: #fff;
  border: 2px solid #aaa;
  border-radius: 18px;
  width: 18px;
  height: 18px;
  transition: all .3s;
  position: absolute;
  top: 6px;
  left: 0;
}

.enquiry__answer input[type="radio"] + label:after {
  content: "";
  color: #d21e1e;
  background: #d21e1e;
  border-radius: 12px;
  width: 0;
  height: 0;
  font-size: 0;
  transition: all .3s;
  position: absolute;
  top: 18px;
  left: 12px;
}

.enquiry__answer input[type="radio"] + label:hover:before {
  border-color: #aaa;
}

.enquiry__answer input[type="radio"]:checked + label:after {
  width: 11.5px;
  height: 11.5px;
  top: 11px;
  left: 5px;
}

.enquiry__answer input[type="radio"]:disabled + label {
  color: #aaa;
  cursor: not-allowed;
}

.enquiry__answer input[type="radio"]:disabled + label:before {
  border-color: #b7b7b7;
}

.enquiry__answer input[type="radio"]:disabled + label:after {
  background-color: #ea6c6c;
}

.enquiry__answer label {
  cursor: pointer;
}

.enquiry__answer label:hover {
  color: #d21e1e;
  transition: all .2s;
}

.enquiry__answer-text {
  font-size: 13px;
  font-weight: 700;
}

.enquiry__answer-bar {
  float: left;
  width: 80%;
  display: none;
  position: relative;
  top: 7px;
}

.enquiry__answer-column {
  background: #d21e1e;
  max-width: 0;
  height: 10px;
  transition: all 1s linear;
  display: none;
}

.enquiry__answer-perc {
  float: left;
  text-align: right;
  color: #d21e1e;
  width: 20%;
  font-size: 16px;
  font-weight: 700;
  display: none;
  position: relative;
}

.enquiry__thanks-sentence {
  text-align: center;
  color: #aaa;
  margin: 15px auto 0;
  font-size: 16px;
  font-weight: 300;
  display: none;
}

.enquiry__total-votes {
  display: none;
}

@media screen and (max-width: 760px) {
  .art-enquiry {
    width: 100%;
    left: 0;
  }

  .enquiry__title-container {
    width: 100%;
    padding: 2px 0 0;
    position: relative;
  }

  .enquiry__content-container {
    padding: 15px;
  }

  .enquiry__answer-bar {
    width: 75%;
  }
}

.main-article .content .enquiry-bars__question, .enquiry-bars__question {
  float: none;
  margin: 0 0 24px;
  font-family: Roboto Slab, Times New Roman, serif;
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;
}

.main-article .content .enquiry-bars-result-notice__link, .enquiry-bars-result-notice__link {
  box-sizing: border-box;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  background-color: #d21e1e;
  border-radius: 4px;
  width: 142px;
  height: 40px;
  margin: 24px auto 0;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: block;
}

.enquiry-bars {
  clear: both;
  background-color: #fff;
  padding: 15px;
}

.enquiry-bars__title {
  color: #aaa;
  border-top: 4px solid #d21e1e;
  margin-bottom: 24px;
  padding-top: 8px;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}

.enquiry-bars-answers__answer {
  background-color: #f9dfdf;
  border-left: 4px solid #d21e1e;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 28px;
  position: relative;
}

.enquiry-bars-answers__answer:last-child {
  margin-bottom: 24px;
}

.enquiry-bars-answers__answer--hover:hover {
  cursor: pointer;
  border: 1px solid #a81919;
  border-left-width: 4px;
}

.enquiry-bars-answers__answer--hover:hover .enquiry-bars-answer__statement {
  padding: 7px 15px 7px 16px;
}

.enquiry-bars-answer__indicator {
  background-color: #f6b3bf;
  width: 0;
  height: 44px;
  transition: width 1s linear;
  position: absolute;
}

@keyframes enquiry-bars-indicator-slide {
  from {
    width: 0;
  }

  to {
    width: var(--to-width);
  }
}

.enquiry-bars-answer__statement {
  justify-content: space-between;
  padding: 8px 16px;
  display: flex;
  position: relative;
}

.enquiry-bars-answer__statement--selected {
  font-weight: 700;
}

.enquiry-bars__result-notice {
  background-color: #f9dfdf;
  margin-bottom: 24px;
  padding: 24px 32px;
}

.enquiry-bars-result-notice__text {
  text-align: center;
  font-family: arial, "sans-serif";
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}

.enquiry-bars__footer {
  border-bottom: 1px solid #e6e6e6;
  justify-content: space-between;
  padding-bottom: 4px;
  font-size: 16px;
  line-height: 28px;
  display: flex;
}

.enquiry-bars-footer__vote-notice {
  color: #d21e1e;
  font-weight: 700;
}

.enquiry-bars-footer__total-votes {
  text-align: right;
  color: #aaa;
  margin-left: auto;
}

.enquiry-bars-result-notice__link:hover {
  color: #fff;
}

#mcr-cycling-broadcast {
  margin: 20px 0;
}

.mcr-cycling-broadcast__msg {
  float: left;
  background: #fff;
  border: 2px solid #fff;
  width: 100%;
  min-width: 100%;
  min-height: 118px;
  margin-top: 15px;
  position: relative;
  overflow: hidden;
}

.mcr-cycling-broadcast__msg .time {
  background: #fff;
  padding: 5px 10px;
  font-weight: 600;
  position: absolute;
  top: 20px;
  left: 20px;
}

@media only screen and (max-width: 426px) {
  .mcr-cycling-broadcast__msg .time {
    background: #fff;
    padding: 2px 6px;
    font-size: 13px;
    font-weight: normal;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.mcr-cycling-broadcast__msg .description {
  background: #fff;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
  padding: 5px 15px;
  position: absolute;
  bottom: 0;
}

.main-article .content .html-aukce-rosicky {
  background: #fff;
  padding: 0 15px 14px;
}

.main-article .content .html-aukce-rosicky table {
  margin-bottom: 0;
}

.main-article .content .html-aukce-rosicky thead th:nth-child(2) {
  text-align: right;
}

.main-article .content .html-aukce-rosicky tbody {
  font-weight: 700;
}

.main-article .content .html-aukce-rosicky tbody td:nth-child(2) {
  text-align: right;
}

.online-prenos-video {
  display: block;
  position: relative;
}

.box-special {
  background-color: #fff;
  border: 1px solid #cad5df;
  padding-bottom: 10px;
  position: relative;
}

.box-special article.list-article-horizontal-small {
  background: #fff;
  border-bottom: 1px solid #cad5df;
  width: calc(100% - 30px);
  margin: 0 15px;
  padding: 15px 0;
  position: relative;
}

.box-special article.list-article-horizontal-small:before, .box-special article.list-article-horizontal-small:after {
  content: "";
  display: table;
}

.box-special article.list-article-horizontal-small:after {
  clear: both;
}

.box-special article.list-article-horizontal-small:first-of-type {
  padding-top: 0;
}

.box-special article.list-article-horizontal-small:last-of-type {
  margin-bottom: 20px;
}

.box-special article.list-article-horizontal-small .image-container {
  float: left;
  width: 49%;
  height: auto;
  max-height: 180px;
  margin-right: 0;
  overflow: hidden;
}

.box-special article.list-article-horizontal-small .image-container img {
  width: 100%;
  height: auto;
}

.box-special article.list-article-horizontal-small .title-container {
  float: right;
  width: 46.5%;
  margin: 0;
  position: relative;
}

.box-special article.list-article-horizontal-small .title-container h2 {
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.3em;
}

.box-special__header {
  z-index: 1;
  color: #fff;
  background-color: red;
  margin-bottom: 20px;
  padding: 10px;
  position: relative;
}

.box-special__header a {
  text-decoration: none;
}

.box-special__header a:hover, .box-special__header a:focus {
  text-decoration: underline;
}

.box-special__header-left {
  float: left;
  width: 50%;
}

.box-special__title {
  font-size: 22px;
  font-weight: bold;
  line-height: 20px;
}

.box-special__title a, .box-special__title:hover, .box-special__title:focus {
  color: #fff;
}

.box-special__subtitle {
  margin-top: 5px;
  font-size: 13px;
  font-weight: bold;
  line-height: 15px;
}

.box-special__header-right {
  float: left;
  box-sizing: border-box;
  border-left: 1px solid #c51a1a;
  width: 50%;
  min-height: 55px;
  padding-left: 10px;
}

.box-special__header-right a {
  color: #fff;
  text-transform: uppercase;
  padding-right: 20px;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  display: block;
  position: relative;
}

.box-special__header-right a:after {
  content: "";
  border-top: 4px solid rgba(0, 0, 0, 0);
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  border-left: 6px solid #fff;
  display: inline-block;
  position: absolute;
  top: 4px;
  right: 0;
}

.box-special__link {
  text-align: center;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-decoration: underline;
  display: block;
}

.box-special__link:hover {
  text-decoration: none;
}

.box-special__link:after {
  content: "";
  border-top: 4px solid rgba(0, 0, 0, 0);
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  border-left: 6px solid red;
  margin-left: 5px;
  display: inline-block;
}

.box-special--elections .box-special__header {
  color: #16212d;
  background-color: #f4f7f9;
}

.box-special--elections .box-special__subtitle a, .box-special--elections .box-special__title a {
  color: #16212d;
}

.box-special--elections .box-special__link {
  color: red;
}

.box-special--elections .box-special__header-right {
  border-color: #cad5df;
}

.box-special--elections .box-special__header-right a {
  color: #16212d;
  text-decoration: underline;
}

.box-special--elections .box-special__header-right a:hover, .box-special--elections .box-special__header-right a:focus {
  text-decoration: none;
}

.box-special--elections .box-special__header-right a:after {
  border-left-color: red;
}

.box-special--elections .list-article-horizontal-small .title-container h2 a {
  color: #16212d;
  text-decoration: none;
}

.box-special--elections .list-article-horizontal-small .title-container h2 a:hover, .box-special--elections .list-article-horizontal-small .title-container h2 a:focus {
  color: #16212d;
  text-decoration: underline;
}

.behabox-list-article {
  background-color: #fff;
  padding: 10px 0;
}

.behabox-list-article--horizontal-small .title-basic {
  margin: 0 15px 10px;
  display: block;
}

.behabox-list-article--horizontal-small .category-name {
  text-transform: uppercase;
  color: #d21e1e;
  background: #e6e6e6;
  width: 150px;
  height: 20px;
  padding: 0 5px;
  font-size: 11px;
  font-weight: 700;
  line-height: 20px;
  position: absolute;
  top: 85px;
  left: 0;
}

.behabox-list-article--horizontal-small .category-name a {
  color: #d21e1e;
  display: block;
}

.behabox-list-article--horizontal-small .category-name a:hover, .behabox-list-article--horizontal-small .category-name a:focus {
  text-decoration: none;
}

.behabox-list-article--horizontal-small article.list-article-horizontal-small:first-of-type .category-name {
  top: 70px;
}

.behabox-list-article--horizontal-small article.list-article-horizontal-small:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
}

.behabox-list-article--horizontal-small .behabox-list-article__description {
  padding: 10px 0 0;
  font-size: 13px;
}

.behabox-list-article--vertical-medium {
  background: none;
  margin: 30px 0 0;
}

.behabox-list-article--vertical-medium .title-basic {
  text-transform: none;
  margin: 0 15px 10px;
  font-size: 23px;
  display: block;
}

.behabox-list-article--vertical-medium article {
  clear: none;
  float: left;
  width: 240px;
  height: 313px;
  margin: 0 15px 30px;
  padding: 0 0 15px;
  display: inline;
  position: relative;
  overflow: hidden;
}

.behabox-list-article--vertical-medium .category-name {
  text-transform: uppercase;
  color: #d21e1e;
  padding: 15px 15px 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
}

.behabox-list-article--vertical-medium .category-name a {
  color: #d21e1e;
}

.behabox-list-article--vertical-medium .category-name a:hover, .behabox-list-article--vertical-medium .category-name a:focus {
  text-decoration: none;
}

.behabox-list-article--vertical-medium .title {
  height: 93px;
  margin-bottom: 15px;
  padding: 0 15px;
  font-family: Roboto Slab, Times New Roman, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  overflow: hidden;
}

.behabox-list-article--vertical-medium .behabox-list-article__description {
  height: 45px;
  padding: 5px 15px 0;
  font-size: 13px;
  line-height: 20px;
  overflow: hidden;
}

.placeholder_75x55 {
  z-index: 1;
  text-align: center;
  background: #646464;
  width: 75px;
  height: 55px;
  position: relative;
}

.placeholder_75x55 .dataimg-placeholder {
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.placeholder_75x75 {
  z-index: 1;
  text-align: center;
  background: #646464;
  width: 75px;
  height: 75px;
  position: relative;
}

.placeholder_75x75 .dataimg-placeholder {
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.placeholder_97x97 {
  z-index: 1;
  text-align: center;
  background: #646464;
  width: 97px;
  height: 97px;
  position: relative;
}

.placeholder_97x97 .dataimg-placeholder {
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.placeholder_130x95 {
  z-index: 1;
  text-align: center;
  background: #646464;
  width: 130px;
  height: 95px;
  position: relative;
}

.placeholder_130x95 .dataimg-placeholder {
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.placeholder_140x100 {
  z-index: 1;
  text-align: center;
  background: #646464;
  width: 140px;
  height: 100px;
  position: relative;
}

.placeholder_140x100 .dataimg-placeholder {
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.placeholder_165x115 {
  z-index: 1;
  text-align: center;
  background: #646464;
  width: 165px;
  height: 115px;
  position: relative;
}

.placeholder_165x115 .dataimg-placeholder {
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.placeholder_220x155 {
  z-index: 1;
  text-align: center;
  background: #646464;
  width: 220px;
  height: 155px;
  position: relative;
}

.placeholder_220x155 .dataimg-placeholder {
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.placeholder_578x325 {
  z-index: 1;
  text-align: center;
  background: #646464;
  width: 578px;
  height: 325px;
  position: relative;
}

.placeholder_578x325 .dataimg-placeholder {
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.placeholder_690x425 {
  z-index: 1;
  text-align: center;
  background: #646464;
  width: 690px;
  height: 425px;
  position: relative;
}

.placeholder_690x425 .dataimg-placeholder {
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.placeholder_1050x700 {
  z-index: 1;
  text-align: center;
  background: #646464;
  width: 1050px;
  height: 700px;
  position: relative;
}

.placeholder_1050x700 .dataimg-placeholder {
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.isportliga .player td:nth-child(2) {
  text-align: left;
  font-weight: bold;
}

.isportliga .player .player__avatar {
  float: left;
  border: 1px solid #dcdcdc;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  margin-right: 10px;
}

.isportliga .player .player__name--centered {
  white-space: nowrap;
  height: 42px;
  line-height: 42px;
}

.isportliga .player .error-message {
  float: left;
  color: #d21e1e;
  margin-top: 5px;
  font-size: 13px;
  display: none;
}

.isportliga .founded {
  border: 2px solid #d21e1e;
}

.share-strip {
  clear: both;
  float: left;
  flex-flow: row;
  justify-content: space-between;
  width: 140px;
  height: 36px;
  margin-bottom: 15px;
  display: flex;
}

.share-strip > .share .icone {
  background-image: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
}

.share-strip > .share .icone.dataimg-facebook-white-2 {
  background: #4e6fdc;
}

.share-strip > .share .icone.dataimg-facebook-white-2 > svg {
  width: 20px;
  height: 20px;
  position: relative;
  top: 8px;
  left: 8px;
}

.share-strip > .share .icone.dataimg-facebook-white-2 > svg path {
  fill: #fff;
}

.share-strip > .share .icone.dataimg-twitter-white {
  background-color: #34aae6;
}

.share-strip > .share .icone.dataimg-twitter-white > svg {
  width: 20px;
  height: 20px;
  position: relative;
  top: 8px;
  left: 8px;
}

.share-strip > .share .icone.dataimg-twitter-white > svg path {
  fill: #fff;
}

.share-strip > .share .icone.dataimg-messenger {
  background-color: #2196f3;
}

.share-strip > .share .icone.dataimg-messenger > svg {
  width: 20px;
  height: 20px;
  position: relative;
  top: 8px;
  left: 8px;
}

.share-strip > .share .icone.dataimg-messenger > svg path {
  fill: #fff;
}

.share-strip > .share .icone.dataimg-whatsapp {
  background-color: #0ec965;
}

.share-strip > .share .icone.dataimg-whatsapp > svg {
  width: 20px;
  height: 20px;
  position: relative;
  top: 8px;
  left: 8px;
}

.share-strip > .share .icone.dataimg-whatsapp > svg path {
  fill: #fff;
}

.share-strip > .share .icone.dataimg-email-icon {
  background-color: #e80d41;
}

.share-strip > .share .icone.dataimg-email-icon > svg {
  width: 20px;
  height: 20px;
  position: relative;
  top: 8px;
  left: 8px;
}

.share-strip > .share .icone.dataimg-email-icon > svg path {
  fill: #fff;
}

.article-top-right-miniplayer .video-top-right {
  float: right;
  background: #1e1e1e;
  width: 360px;
  height: 390px;
  padding: 10px 15px;
  display: none;
  position: relative;
}

.article-top-right-miniplayer .video-top-right--wrapper {
  width: 100%;
}

.article-top-right-miniplayer .video-top-right .title-section.color-inverze .sport-color, .article-top-right-miniplayer .video-top-right .link.sport-color.color-inverze {
  color: #fff;
}

.article-top-right-miniplayer .video-top-right .link.sport-color.color-inverze {
  text-align: center;
  margin: auto;
  display: block;
  position: absolute;
  bottom: 14px;
  left: 0;
  right: 0;
}

.article-top-right-miniplayer .video-in-right-column, .article-top-right-miniplayer.article-top-video .video-in-right-column {
  margin-top: 320px;
}

.article-top-right-miniplayer.article-top-video.mag-isport .video-top-right {
  z-index: 999;
  margin-top: -21px;
}

.article-top-right-miniplayer.article-top-image .gallery-items ul li:last-child {
  color: #fff;
  background-image: linear-gradient(to right, rgba(30, 30, 30, 0) 0, #1e1e1e 4em);
}

.article-top-right-miniplayer.article-top-image .gallery-strip.gallery-items .additional-value-container.sport-color-hover:hover, .article-top-right-miniplayer.article-top-image .gallery-items ul li:last-child .sport-color {
  color: #fff;
}

.article-top-right-miniplayer.article-top-image .main-article .image-container, .article-top-right-miniplayer.article-top-image .main-article .article-gallery-container, .article-top-right-miniplayer.article-top-image .main-article .article-gallery-container .gallery-items {
  background-color: #1e1e1e;
}

.article-top-right-miniplayer.article-top-image.mag-vip-isport .four-col.right-col {
  margin-top: 620px;
}

.article-top-right-miniplayer.article-top-image.mag-vip-isport .video-top-right {
  height: 412px;
  margin-top: -20px;
}

.article-top-right-miniplayer.article-top-image.mag-vip-isport .article-gallery-container, .article-top-right-miniplayer.article-top-image.mag-isport .article-gallery-container {
  margin-top: -103px;
}

.article-top-right-miniplayer.article-top-image.mag-isport .video-top-right {
  z-index: 999;
  height: 412px;
}

@media screen and (max-width: 760px) {
  .results-wrapper--collapsed:before {
    content: "";
    z-index: 1;
    background: linear-gradient(rgba(255, 255, 255, 0), #fff 57% 100%);
    width: 100%;
    height: 97px;
    position: absolute;
    bottom: 0;
  }

  .results-wrapper {
    width: 100%;
    transition: all 1s ease-in-out;
    position: relative;
    overflow: hidden;
  }

  .results {
    padding-bottom: 53px;
  }

  .results--no-collapsing {
    padding-bottom: 0;
  }

  .results .title-medium {
    border-bottom: 2px solid #dcdcdc;
    margin-bottom: 5px;
    padding: 14px 0 10px;
    font-size: 21px;
  }
}

.results__match {
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 2px;
}

.results__match--other:nth-child(odd) {
  background: #f0f0f0;
}

@media screen and (max-width: 760px) {
  .results__match {
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 2px;
  }
}

.results__list-score {
  border: none !important;
}

.results__list-score--tennis {
  border-bottom: none;
}

.results__match-link {
  display: block;
}

.results__match-link:before, .results__match-link:after {
  content: "";
  display: table;
}

.results__match-link:after {
  clear: both;
}

.results-match-bet__link:hover {
  text-decoration: none;
}

.results-match-bet__image {
  width: 50px;
  height: auto;
  margin: 2px auto;
  display: block;
}

@media screen and (max-width: 760px) {
  .results .link, .results__toggle-button {
    color: #d21e1e;
    background: none;
    border: none;
    width: auto;
    padding: 0;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5;
    text-decoration: underline;
    display: flex;
  }

  .results .link {
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
  }

  .results__toggle-button--hidden {
    display: none;
  }

  .results__toggle-button {
    z-index: 2;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 53px;
    padding: 10px 0 25px;
    position: absolute;
    bottom: 0;
  }
}

.section-nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.section-nav.border-top {
  border-top-style: solid;
  border-top-width: 2px;
}

.section-nav.border-bottom {
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

.section-nav__title {
  margin: 5px 0;
  font-family: Roboto, arial, sans-serif;
  font-size: 22px;
  font-weight: 700;
}

.section-nav__menu {
  display: flex;
}

.section-nav__menu-item {
  text-transform: uppercase;
  padding-left: 15px;
  font-size: 13px;
  font-weight: 700;
  display: block;
}

.page-nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.page-nav__title {
  padding-right: 7px;
  font-family: Roboto, arial, sans-serif;
  font-size: 22px;
  font-weight: 700;
}

.page-nav__sponsor {
  border-left: 1px solid #e4e4e4;
  align-items: center;
  display: flex;
}

.page-nav__sponsor-name {
  width: 60px;
  padding: 0 10px;
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
}

.page-nav__menu--list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.page-nav__menu-item {
  text-transform: uppercase;
  padding-left: 20px;
  font-size: 13px;
  font-weight: 700;
}

.page-nav__btn-toggle {
  color: #fff;
  text-transform: uppercase;
  background: none;
  border: 1px solid #fff;
  border-radius: 2px;
  outline: none;
  padding: 5px 19px 5px 10px;
  font-size: 11px;
  display: none;
  position: relative;
}

.page-nav__btn-toggle:before {
  content: "";
  border: 1px solid #fff;
  border-width: 0 1px 1px 0;
  width: 4px;
  height: 4px;
  transition: all .3s ease-in-out;
  display: inline-block;
  position: absolute;
  top: 8px;
  right: 7px;
  transform: rotate(45deg);
}

.page-nav__btn-toggle--isActive {
  color: #8b2030;
  background-color: #fff;
}

.page-nav__btn-toggle--isActive:before {
  border-color: #8b2030;
  top: 10px;
  transform: rotate(-135deg);
}

.page-nav--loh-tokio {
  background: #fff url("//img2.cncenter.cz/images/isportcz/standalone/loh/loh-tokio-bg-header.png") 100% 0 no-repeat;
  padding: 10px 15px;
}

.page-nav--loh-tokio .page-nav__title-link {
  color: #94754d;
}

.page-nav--loh-tokio .page-nav__sponsor {
  padding-right: 20px;
}

.page-nav--loh-tokio .page-nav__sponsor-image {
  width: 55px;
}

.page-nav--loh-tokio .page-nav__menu-item-link, .page-nav--loh-tokio .page-nav__menu-item-link:hover {
  color: #fff;
}

.page-nav--loh-tokio.page-nav--mobile {
  background: #fff url("//img2.cncenter.cz/images/isportcz/standalone/loh/loh-tokio-bg-header-mobile.png") 100% 0 no-repeat;
}

.page-nav--loh-tokio.page-nav--mobile .page-nav__sponsor {
  padding-right: 30px;
}

.page-nav--mobile:not(:-webkit-any(.page-nav--zoh-peking, .page-nav--euro-2024, .page-nav--loh-paris-2024)) {
  flex-wrap: wrap;
  height: 60px;
}

.page-nav--mobile:not(:is(.page-nav--zoh-peking, .page-nav--euro-2024, .page-nav--loh-paris-2024)) {
  flex-wrap: wrap;
  height: 60px;
}

.page-nav--mobile:not(:-webkit-any(.page-nav--zoh-peking, .page-nav--euro-2024, .page-nav--loh-paris-2024)) .page-nav__title {
  font-size: 18px;
  line-height: 22px;
}

.page-nav--mobile:not(:is(.page-nav--zoh-peking, .page-nav--euro-2024, .page-nav--loh-paris-2024)) .page-nav__title {
  font-size: 18px;
  line-height: 22px;
}

.page-nav--mobile:not(:-webkit-any(.page-nav--zoh-peking, .page-nav--euro-2024, .page-nav--loh-paris-2024)) .page-nav__sponsor {
  border: none;
}

.page-nav--mobile:not(:is(.page-nav--zoh-peking, .page-nav--euro-2024, .page-nav--loh-paris-2024)) .page-nav__sponsor {
  border: none;
}

.page-nav--mobile:not(:-webkit-any(.page-nav--zoh-peking, .page-nav--euro-2024, .page-nav--loh-paris-2024)) .page-nav__sponsor-name {
  display: none;
}

.page-nav--mobile:not(:is(.page-nav--zoh-peking, .page-nav--euro-2024, .page-nav--loh-paris-2024)) .page-nav__sponsor-name {
  display: none;
}

.page-nav--mobile:not(:-webkit-any(.page-nav--zoh-peking, .page-nav--euro-2024, .page-nav--loh-paris-2024)) .page-nav__btn-toggle {
  display: flex;
}

.page-nav--mobile:not(:is(.page-nav--zoh-peking, .page-nav--euro-2024, .page-nav--loh-paris-2024)) .page-nav__btn-toggle {
  display: flex;
}

.page-nav--mobile:not(:-webkit-any(.page-nav--zoh-peking, .page-nav--euro-2024, .page-nav--loh-paris-2024)) .page-nav__btn-toggle--isActive + .page-nav__menu--list {
  display: flex;
}

.page-nav--mobile:not(:is(.page-nav--zoh-peking, .page-nav--euro-2024, .page-nav--loh-paris-2024)) .page-nav__btn-toggle--isActive + .page-nav__menu--list {
  display: flex;
}

.page-nav--mobile:not(:-webkit-any(.page-nav--zoh-peking, .page-nav--euro-2024, .page-nav--loh-paris-2024)) .page-nav__menu--list {
  z-index: 3;
  background: #fff;
  flex-flow: column;
  padding: 10px;
  display: none;
  position: absolute;
  top: 60px;
  right: 15px;
}

.page-nav--mobile:not(:is(.page-nav--zoh-peking, .page-nav--euro-2024, .page-nav--loh-paris-2024)) .page-nav__menu--list {
  z-index: 3;
  background: #fff;
  flex-flow: column;
  padding: 10px;
  display: none;
  position: absolute;
  top: 60px;
  right: 15px;
}

.page-nav--mobile:not(:-webkit-any(.page-nav--zoh-peking, .page-nav--euro-2024, .page-nav--loh-paris-2024)) .page-nav__menu-item {
  padding: 10px 0 5px;
}

.page-nav--mobile:not(:is(.page-nav--zoh-peking, .page-nav--euro-2024, .page-nav--loh-paris-2024)) .page-nav__menu-item {
  padding: 10px 0 5px;
}

.page-nav--mobile:not(:-webkit-any(.page-nav--zoh-peking, .page-nav--euro-2024, .page-nav--loh-paris-2024)) .page-nav__menu-item-link, .page-nav--mobile:not(:-webkit-any(.page-nav--zoh-peking, .page-nav--euro-2024, .page-nav--loh-paris-2024)) .page-nav__menu-item-link:hover {
  color: #1e1e1e;
}

.page-nav--mobile:not(:is(.page-nav--zoh-peking, .page-nav--euro-2024, .page-nav--loh-paris-2024)) .page-nav__menu-item-link, .page-nav--mobile:not(:is(.page-nav--zoh-peking, .page-nav--euro-2024, .page-nav--loh-paris-2024)) .page-nav__menu-item-link:hover {
  color: #1e1e1e;
}

.page-nav--zoh-peking {
  background: #fff url("//img2.cncenter.cz/images/isportcz/standalone/zoh/bg.svg") 100% 0 no-repeat;
  padding: 13px;
}

.page-nav--zoh-peking .page-nav__title-link, .page-nav--zoh-peking .page-nav__title {
  color: #94754d;
}

.page-nav--zoh-peking .page-nav__olympic-rings {
  border-left: 1px solid #e4e4e4;
  margin-left: 30px;
  padding-left: 30px;
}

.page-nav--zoh-peking .page-nav__partner-fosfa {
  border-left: 1px solid #e4e4e4;
  width: 160px;
  height: 33px;
  margin-left: 13px;
  position: relative;
}

.page-nav--zoh-peking .page-nav__partner-fosfa img {
  max-width: 190px;
  position: absolute;
  top: -7px;
  left: 10px;
}

.page-nav--zoh-peking .page-nav__menu--list {
  margin-left: auto;
}

.page-nav--zoh-peking .page-nav__menu--list li:first-child {
  position: relative;
}

.page-nav--zoh-peking .page-nav__menu--list li:first-child:after {
  content: "";
  background: #45ff00;
  border-radius: 10px;
  width: 6px;
  height: 6px;
  animation: 2s linear infinite animate;
  position: absolute;
  top: -3px;
  left: 8px;
}

@keyframes animate {
  0% {
    opacity: .4;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: .4;
  }
}

.page-nav--zoh-peking .page-nav__menu-item-link, .page-nav--zoh-peking .page-nav__menu-item-link:hover {
  color: #fff;
}

.page-nav--loh-paris-2024 {
  background-color: #fff;
  height: 80px;
  padding: 0 0 0 13px;
}

.page-nav--loh-paris-2024 .page-nav__title-link, .page-nav--loh-paris-2024 .page-nav__title {
  color: #ae9e56;
}

.page-nav--loh-paris-2024 .page-nav__olympic-rings {
  margin-left: 16px;
  display: flex;
}

.page-nav--loh-paris-2024 .page-nav__olympic-rings-image {
  width: 80px;
  height: 80px;
}

.page-nav--loh-paris-2024 .page-nav__menu--list {
  background: linear-gradient(90deg, #3c276d 0%, #5b5292 100%);
  border-radius: 100px 0 0;
  align-items: center;
  width: 550px;
  height: 80px;
  margin-left: 48px;
  padding-left: 75px;
}

.page-nav--loh-paris-2024 .page-nav__menu--list li:first-child {
  position: relative;
}

@media (min-width: 768px) {
  .page-nav--loh-paris-2024 .page-nav__menu--list li:first-child {
    margin-left: auto;
  }
}

.page-nav--loh-paris-2024 .page-nav__menu--list li:first-child:after {
  display: none;
}

@media (min-width: 768px) {
  .page-nav--loh-paris-2024 .page-nav__menu-item {
    padding-left: 0;
    padding-right: 20px;
  }
}

.page-nav--loh-paris-2024 .page-nav__menu-item-link, .page-nav--loh-paris-2024 .page-nav__menu-item-link:hover {
  color: #fff;
}

.page-nav--euro-2024 {
  background: #fff url("//img2.cncenter.cz/images/isportcz/standalone/euro-2024/bg.svg") 100% 0 no-repeat;
  padding: 13px;
}

.page-nav--euro-2024 .page-nav__title-link, .page-nav--euro-2024 .page-nav__title {
  color: #94754d;
}

.page-nav--euro-2024 .page-nav__logo {
  width: 130px;
  height: 32px;
  margin-left: auto;
  position: relative;
}

.page-nav--euro-2024 .page-nav__logo img {
  width: 110px;
  position: absolute;
  top: -7px;
  left: 10px;
}

.page-nav--euro-2024 .page-nav__menu--list {
  margin-left: 90px;
}

.page-nav--euro-2024 .page-nav__menu--list li:first-child {
  position: relative;
}

.page-nav--euro-2024 .page-nav__menu--list li:first-child:after {
  content: "";
  background: #45ff00;
  border-radius: 10px;
  width: 6px;
  height: 6px;
  animation: 2s linear infinite animate;
  position: absolute;
  top: -3px;
  left: 8px;
}

.page-nav--euro-2024 .page-nav__menu-item-link, .page-nav--euro-2024 .page-nav__menu-item-link:hover {
  color: #fff;
}

.page-nav--mobile.page-nav--zoh-peking {
  background: none;
  margin: 0 2%;
  padding: 0;
  display: block;
  position: relative;
}

.page-nav--mobile.page-nav--zoh-peking .page-nav__title {
  background: #fff;
  padding: 20px 10px;
  font-size: 16px;
  display: block;
}

.page-nav--mobile.page-nav--zoh-peking .page-nav__olympic-rings {
  border-left: 0;
  margin-left: 0;
  padding-left: 0;
  position: absolute;
  top: 15px;
  right: 15px;
}

.page-nav--mobile.page-nav--zoh-peking .page-nav__partner-fosfa {
  border-left: 0;
  margin-left: 0;
  padding-left: 0;
  position: absolute;
  top: 9px;
  right: 5px;
}

.page-nav--mobile.page-nav--zoh-peking .page-nav__partner-fosfa img {
  max-width: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.page-nav--mobile.page-nav--zoh-peking .page-nav__menu--list {
  background: #1a86df url("//img2.cncenter.cz/images/isportcz/standalone/zoh/bg.svg") -15px no-repeat;
  justify-content: space-evenly;
  margin-left: 0;
  padding: 22px 0;
}

.page-nav--mobile.page-nav--zoh-peking .page-nav__menu-item:not(:first-child) {
  padding-left: 0;
}

@media (max-width: 389px) {
  .page-nav--mobile.page-nav--zoh-peking .page-nav__menu-item {
    font-size: 11px;
  }
}

.page-nav--mobile.page-nav--loh-paris-2024 {
  height: auto;
  margin: 0 2%;
  padding: 0;
  display: block;
  position: relative;
}

.page-nav--mobile.page-nav--loh-paris-2024 .page-nav__title {
  background: #fff;
  padding: 20px 10px;
  font-size: 16px;
  display: block;
}

.page-nav--mobile.page-nav--loh-paris-2024 .page-nav__olympic-rings {
  border-left: 0;
  margin-left: 0;
  padding-left: 0;
  position: absolute;
  top: 5px;
  right: 10px;
}

.page-nav--mobile.page-nav--loh-paris-2024 .page-nav__olympic-rings-image {
  width: 48px;
  height: 48px;
}

.page-nav--mobile.page-nav--loh-paris-2024 .page-nav__menu--list {
  background: linear-gradient(90deg, #3c276d 0%, #5b5292 100%);
  border-radius: 0;
  justify-content: space-evenly;
  width: auto;
  margin-left: 0;
  padding: 22px 0;
}

.page-nav--mobile.page-nav--loh-paris-2024 .page-nav__menu-item:not(:first-child) {
  padding-left: 0;
}

@media (max-width: 389px) {
  .page-nav--mobile.page-nav--loh-paris-2024 .page-nav__menu-item {
    font-size: 11px;
  }
}

.page-nav--mobile.page-nav--euro-2024 {
  background: none;
  margin: 0 2%;
  padding: 0;
  display: block;
  position: relative;
}

.page-nav--mobile.page-nav--euro-2024 .page-nav__title {
  background: #fff;
  padding: 20px 10px;
  font-size: 16px;
  display: block;
}

.page-nav--mobile.page-nav--euro-2024 .page-nav__logo {
  border-left: 0;
  width: 110px;
  margin-left: 0;
  padding-left: 0;
  position: absolute;
  top: 7px;
  right: 8px;
}

.page-nav--mobile.page-nav--euro-2024 .page-nav__logo img {
  position: relative;
  top: 0;
  left: 0;
}

.page-nav--mobile.page-nav--euro-2024 .page-nav__menu--list {
  background-image: linear-gradient(90.22deg, #143cda 34.83%, #122fa4 100%);
  justify-content: space-evenly;
  margin-left: 0;
  padding: 22px 0;
}

.page-nav--mobile.page-nav--euro-2024 .page-nav__menu-item:not(:first-child) {
  padding-left: 0;
}

@media (max-width: 389px) {
  .page-nav--mobile.page-nav--euro-2024 .page-nav__menu-item {
    font-size: 11px;
  }
}

.spider {
  background: #fff;
  transition-duration: 0s;
  transform: translate3d(0, 0, 0);
}

.spider__nav {
  text-align: center;
  border-bottom: 1px solid #dcdcdc;
  margin: 0 30px;
}

.spider__nav--hidden {
  display: none;
}

.spider__nav-item {
  text-align: center;
  color: #323232;
  text-transform: uppercase;
  background: none;
  border: none;
  margin: 0 15px;
  padding: 10px 0;
  font-size: 10px;
  font-weight: 700;
  display: inline-block;
}

.spider__nav-item:first-of-type {
  margin-left: 0;
}

.spider__nav-item:last-of-type {
  margin-right: 0;
}

.spider__nav-item:hover, .spider__nav-item:focus {
  background: none;
  border: none;
}

.spider__controls {
  justify-content: space-between;
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0 30px;
  display: flex;
  position: absolute;
  top: 42px;
  left: 0;
}

.spider__control {
  z-index: 3;
  opacity: .7;
  cursor: pointer;
  background: #fff;
  border: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  transition: all .2s ease-in-out;
  position: relative;
}

.spider__control:hover, .spider__control:focus {
  opacity: 1;
  border: none;
}

.spider__control:disabled {
  visibility: hidden;
}

.spider__round-container {
  background: #fff;
  width: calc(100% - 60px);
  margin: 0 30px;
  padding: 60px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.spider__carousel {
  transition: transform .5s ease-in-out;
  display: flex;
  position: relative;
  left: 0;
  transform: none;
}

.spider__carousel-seat {
  flex-basis: 220px;
}

.spider__round {
  flex-flow: column;
  justify-content: space-around;
  width: 220px;
  min-width: 220px;
  max-height: 100000px;
  transition: max-height .5s ease-out;
  display: flex;
  position: relative;
}

.spider__round--hidden {
  max-height: 0;
}

.spider__round.round-view ~ .spider__round.round-view .spider__match {
  margin-top: auto;
  margin-bottom: auto;
  top: -7px;
}

.spider__round.round-view .spider__match:after {
  height: 90px;
}

.spider__round.round-view + .round-view .spider__match:after {
  height: 180px;
}

.spider__round-caption {
  text-align: center;
  z-index: 1;
  color: #323232;
  text-transform: uppercase;
  width: 100%;
  padding-right: 30px;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  top: -40px;
}

.spider__match {
  background: #f6f6f6;
  border-radius: 4px;
  width: 190px;
  margin-bottom: 15px;
  font-size: 12px;
  position: relative;
}

.spider__match:after, .spider__match:before {
  content: "";
  border-width: 2px;
  border-color: #ececec;
  display: block;
}

.spider__match:before {
  border-top: solid #f4f4f4;
  border-radius: 2px;
  width: 15px;
  height: 1px;
  position: absolute;
  top: calc(50% + 10px);
  left: -15px;
}

.spider__match:after {
  width: 15px;
  height: 45px;
  position: absolute;
  left: 100%;
}

.spider__match:nth-child(odd):after {
  border-bottom-style: solid;
  border-right-style: solid;
  border-bottom-right-radius: 4px;
  bottom: calc(50% - 14px);
}

.spider__match:nth-child(2n):after {
  border-top-style: solid;
  border-right-style: solid;
  border-top-right-radius: 4px;
  top: calc(50% + 10px);
}

.spider .spider-match__header {
  color: #828282;
  justify-content: space-between;
  height: 24px;
  padding: 7px 10px 3px;
  display: flex;
}

.spider .spider-match__time {
  color: #828282;
}

.spider .spider-match__link {
  color: #d21e1e;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  text-decoration: underline;
}

.spider .spider-match__link:hover {
  text-decoration: none;
}

.spider .spider-match__content {
  background: #fff;
  border: 2px solid #ececec;
  border-radius: 4px;
  flex-direction: column;
  justify-content: space-around;
  height: 50px;
  padding: 0 10px;
  display: flex;
}

.spider .spider-match__player {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.spider .spider-match__player-name {
  flex: 1;
  font-weight: 700;
}

.spider .spider-match__player-name--looser {
  font-weight: 400;
}

.spider .spider-match__score-result, .spider .spider-match__score-set {
  min-width: 10px;
  display: inline-block;
}

.spider .spider-match__score-set-value {
  display: inline-block;
  position: relative;
}

.spider .spider-match__score-set-value--tiebreak {
  width: 11px;
}

.spider .spider-match__score-set-value--tiebreak sup {
  position: absolute;
  top: 3px;
  right: -2px;
}

.spider .spider-match__score-result {
  color: #d63d4c;
}

.social-media__wrapper {
  margin-bottom: 30px;
}

.social-media__wrapper.twitter-media iframe, .social-media__wrapper .content .twitter-tweet {
  margin-left: 0;
}

.ad-pr-sticker {
  color: #000;
  text-transform: uppercase;
  background: #d8d8db;
  margin-right: 5px;
  padding: 5px;
  font-size: 11px;
  font-weight: 700;
  line-height: 9px;
  display: inline-block;
}

.premium-sticker {
  background-image: url("http://img2.cncenter.cz/images/default/svg/premium-sticker-dark.svg");
  background-position: 0;
  background-repeat: no-repeat;
  width: 88px;
  height: 20px;
  padding: 10px 12px;
  font-size: 0;
  line-height: 22px;
  text-decoration: none;
  display: flex;
}

.premium-sticker:hover, .premium-sticker:focus {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 760px) {
  .premium-sticker {
    font-size: 0;
    line-height: initial;
    background-size: 70px;
    width: 70px;
  }
}

.premium-sticker--inline {
  vertical-align: text-bottom;
  margin-right: 10px;
  display: inline-block;
}

.premium-sticker--small {
  padding-top: 2px;
  font-size: 0;
  line-height: 12px;
}

.premium-sticker--small-mobile {
  line-height: 21px;
}

.premium-sticker--videoplayer-big {
  padding-left: 10px;
  padding-right: 10px;
  line-height: 38px;
}

@media (max-width: 760px) {
  .related-articles-list .premium-sticker {
    position: absolute;
    top: 0;
    left: 20px;
  }
}

.category-flags--big .premium-sticker {
  background-image: url("http://img2.cncenter.cz/images/default/svg/premium-sticker-dark.svg");
  width: 88px;
  height: 22px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.category-flags--big-mobile {
  height: 24px;
  padding-left: 3px;
}

@media (max-width: 760px) {
  .list-article-vertical-half .premium-sticker {
    background-size: 63px;
  }
}

.category-flags {
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  max-width: 100%;
  display: flex;
}

.category-flags--over-image {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 760px) {
  .category-flags--over-image {
    justify-content: space-between;
    width: 100%;
  }
}

.category-flags--big {
  height: 38px;
}

.category-flags--big-mobile {
  height: 21px;
}

.category-flags--above-author-name {
  margin: -5px 0 5px;
}

.category-flags--mostread {
  margin-bottom: 3px;
}

.list-article-vertical-half .category-flags {
  flex-wrap: nowrap;
}

@media (max-width: 760px) {
  .list-article-vertical-half .category-flags {
    gap: 0;
  }
}

.category-flags__item {
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  font-family: roboto, arial, sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  display: flex;
}

.category-flags--over-image .category-flags__item {
  color: #fff;
  background-color: #d21e1e;
}

@media (max-width: 450px) {
  .list-article-vertical-half .category-flags__item {
    overflow: hidden;
  }

  .list-article-vertical-half .category-flags__text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.e-edition {
  color: #16202d;
  font-size: 17px;
  line-height: 1.5;
}

.e-edition__main-image-wrapper {
  width: 100%;
  margin-bottom: 23px;
  display: block;
}

.e-edition__main-image {
  width: 100%;
}

.e-edition__leadsection {
  margin: 0 0 23px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
}

.e-edition__form-wrapper {
  margin: 20px 0 30px;
}

.e-edition__social-wrapper:before, .e-edition__social-wrapper:after {
  content: "";
  display: table;
}

.e-edition__social-wrapper:after {
  clear: both;
}

.e-edition-form {
  background: #fff;
  padding: 30px;
}

.e-edition-form__inputs-group {
  width: 260px;
  margin-bottom: 20px;
}

.e-edition-form__text-label {
  margin-bottom: 20px;
  display: block;
}

.e-edition-form__text-label:last-child {
  margin-bottom: 0;
}

.e-edition-form__text-input {
  box-sizing: border-box;
  color: #6e7480;
  background: #e8ebf1;
  border: none;
  width: 100%;
  padding: 13px 17px;
  font-size: 12px;
  line-height: 14px;
  display: block;
}

.e-edition-form__input--invalid {
  border: 1px solid #d21e1e;
}

.e-edition-form__input--invalid + .e-edition-form__conditions-text {
  color: #d21e1e;
}

.e-edition-form__error-message--empty-value, .e-edition-form__error-message--invalid-format, .e-edition-form__error-message--invalid-number {
  display: none;
}

.e-edition-form__input--empty-value ~ .e-edition-form__error-message--empty-value, .e-edition-form__input--invalid-format ~ .e-edition-form__error-message--invalid-format, .e-edition-form__input--invalid-number ~ .e-edition-form__error-message--invalid-number {
  display: block;
}

input::-webkit-outer-spin-button {
  margin: 0;
}

input::-webkit-inner-spin-button {
  margin: 0;
}

.e-edition-form__error-message {
  color: #d21e1e;
  padding: 5px 5px 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  display: none;
}

.e-edition-form__conditions {
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 14px;
  display: block;
}

.e-edition-form__checkbox-label {
  cursor: pointer;
  margin-bottom: 10px;
  padding-left: 20px;
  display: block;
  position: relative;
}

.e-edition-form__checkbox-label:last-child {
  margin-bottom: 0;
}

.e-edition-form__checkbox {
  z-index: 1;
  cursor: pointer;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.e-edition-form__conditions a {
  color: #d21e1e;
  text-decoration: none !important;
}

.e-edition-form__conditions a:hover {
  text-decoration: underline !important;
}

.e-edition-form__button {
  color: #fff;
  cursor: pointer;
  background-color: #d21e1e;
  border: none;
  border-radius: 2px;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 21px;
  display: block;
}

.e-edition__sent-form-info {
  margin: 30px 0;
}

.e-edition__sent-form-info--errors {
  border-bottom: 1px solid #e8ebf1;
  margin-bottom: 0;
}

.e-edition__errors-list:last-child {
  margin-bottom: 0 !important;
}

.sent-form-info {
  background-color: #fff;
  padding: 30px;
}

.sent-form-info p {
  margin-bottom: 20px;
}

.sent-form-info p:last-of-type {
  margin-bottom: 0;
}

.sent-form-info__title {
  margin: 0 0 15px;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
}

.e-edition-social {
  color: #98a3af;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 17px;
  font-size: 16px;
  line-height: 1.1;
  display: flex;
}

.e-edition-social__list {
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.e-edition-social svg {
  width: 24px;
  height: 24px;
}

.e-edition-faq {
  background: #fff;
  padding: 15px 15px 30px;
}

.e-edition-faq a {
  text-decoration: underline;
}

.e-edition-faq a:hover {
  text-decoration: none;
}

.e-edition-faq__headline {
  margin: 10px 0 20px;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
}

.e-edition-faq__questions {
  padding: 0;
}

.e-edition-faq__contact {
  text-align: center;
  font-size: 13px;
  line-height: 20px;
}

.e-edition-faq__question {
  border-bottom: 1px solid #d1d4e4;
  margin: 0 0 12px;
  padding: 0 15px 12px 0;
  list-style: none;
  position: relative;
}

.e-edition-faq__question:hover {
  cursor: pointer;
}

.e-edition-faq__question:after {
  content: " ";
  background: url("/images/reflex2016/source/svg/icon-premium-arrow-up.svg") no-repeat;
  width: 10px;
  height: 6px;
  font-size: 15px;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 7px;
  right: 0;
  transform: rotate(180deg);
}

.e-edition-faq__question.active:after {
  transform: rotate(0);
}

.e-edition-faq__question.active .e-edition-faq__answer {
  display: block;
}

.e-edition-faq__answer {
  margin-top: 0;
  display: none;
}

.e-edition-faq__answer p {
  margin: 15px 0 0;
}

.e-edition-wrapper {
  padding-bottom: 50px;
  padding-left: 80px;
}

.e-edition, .e-edition p {
  font-size: 17px;
  line-height: 1.6;
}

.e-edition__title {
  margin-bottom: 15px;
  line-height: 42px;
}

.e-edition__social-wrapper {
  margin: 10px 0 30px;
}

.e-edition-form__text-input {
  padding: 14px 17px 12px;
  font-size: 13px;
  line-height: 1.1;
}

.e-edition-form__conditions {
  font-size: 13px;
  line-height: 1.1;
}

.e-edition-faq__headline {
  font-family: roboto, arial, sans-serif;
  font-size: 15px;
  line-height: 1.5;
}

.e-edition-faq__question {
  font-size: 13px;
  line-height: 1.8;
}

.e-edition-faq__answer p {
  font-size: inherit;
  line-height: 1.2;
}

.e-edition-faq__contact {
  line-height: 1.75;
}

.e-edition-faq__contact a {
  color: #d21e1e;
  font-weight: 700;
}

.matches-month__nav {
  background: #fff;
  width: 100%;
  height: 60px;
  padding: 10px 30px;
  position: relative;
}

.matches-month__nav--hockey .matches-month__nav-item:hover, .matches-month__nav--hockey .selected {
  color: #325edc;
}

.matches-month__nav--football .matches-month__nav-item:hover, .matches-month__nav--football .selected {
  color: #00a442;
}

.matches-month__nav-item {
  text-align: center;
  color: #1e1e1e;
  text-transform: uppercase;
  background: none;
  border: none;
  border-right: 1px solid #e1e1e1;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  font-size: 13px;
  font-weight: 700;
}

.matches-month__nav-item:focus {
  outline: none;
}

.matches-month__nav-control {
  z-index: 3;
  cursor: pointer;
  background: #fff;
  border: 0;
  border-radius: 50%;
  width: 35px;
  height: 60px;
  padding: 10px;
  position: absolute;
  top: 0;
}

.matches-month__nav-control:focus {
  outline: none;
}

.matches-month__nav-control--prev {
  left: 0;
}

.matches-month__nav-control--next {
  right: 0;
}

.matches-month__nav-control .dataimg-arrow-light-left svg path, .matches-month__nav-control .dataimg-arrow-light-right svg path {
  fill: #325edc;
}

.matches-month-loader {
  width: 100%;
  display: none;
  position: relative;
}

.matches-month-loader--show {
  display: block;
}

.matches-month-loader--show + .matches-month-ajax-data {
  opacity: .6;
  transition: opacity .3s ease-out;
}

.matches-month-loader__icon {
  z-index: 999;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
}

.online-report {
  margin-left: -100px;
}

.online-report .title-section .main-title {
  margin: 10px 0;
}

.online-report iframe {
  max-width: 100% !important;
  margin-left: 0 !important;
}

.online-report .media {
  margin-top: 7px;
}

.online-report .media img {
  margin-bottom: 10px;
}

.online-report .twitter-tweet {
  margin-top: 5px !important;
}

.online-report .main-button {
  width: 155px;
  padding: 15px 15px 15px 30px;
  font-size: 19px;
}

.online-report__flag-wrapper {
  position: absolute;
  top: -7px;
  left: -7px;
}

.online-report__flag-ico {
  vertical-align: middle;
  margin-right: 10px;
  display: inline-block;
}

.online-report__flag-ico-important {
  background-image: url("https://img2.cncenter.cz/images/isportcz/dist/svg/important-ico.svg");
  background-repeat: no-repeat;
  width: 18px;
  height: 16px;
  margin: 0 0 0 5px;
}

.online-report__flag-ico-cz {
  background-image: url("https://img2.cncenter.cz/images/isportcz/dist/svg/flag-cz-ico.svg");
  background-repeat: no-repeat;
  width: 18px;
  height: 12px;
  margin: 0 0 0 5px;
}

.online-report__refresh-ico {
  vertical-align: middle;
  background-image: url("https://img2.cncenter.cz/images/isportcz/dist/svg/refresh-ico.svg");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin: 0 0 0 5px;
  display: inline-block;
}

.online-report-date-header {
  text-align: center;
  margin: 30px 0;
  font-size: 26px;
  font-weight: 700;
  display: none;
}

.online-report-item {
  background: #fff;
  max-width: 690px;
  margin-bottom: 20px;
  padding: 20px;
  font-size: 17px;
  position: relative;
}

.online-report-item__left {
  float: left;
  text-align: center;
  width: 80px;
  margin-right: 20px;
}

.online-report-item__right {
  float: left;
  width: 550px;
}

.online-report-item__time {
  font-weight: 700;
}

.online-report-item__date {
  color: #aaadb3;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 1;
}

.online-report-item__important {
  background: #ffd5d5;
  position: relative;
}

.online-report-item__commentator-ico {
  border-radius: 47px;
  width: 47px;
  height: 47px;
  margin-top: 5px;
  display: inline-block;
  overflow: hidden;
}

.online-report-item__commentator-ico img {
  width: 50px;
}

.online-report-item__commentator-name {
  margin-top: 10px;
  font-size: 11px;
  line-height: 1.2;
}

.online-report-item__body {
  line-height: 27px;
  overflow: hidden;
}

.online-report-item__body p {
  margin-bottom: 0 !important;
}

.online-report-item__body .media {
  margin-top: 5px;
}

.online-report-item__body img {
  margin-bottom: 10px;
}

.main-filter .main-filter__item {
  background: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  margin-right: 5px;
  padding: 3px 15px;
  font-size: 15px;
  display: inline-block;
}

.main-filter .main-filter__item.main-filter__item-active {
  border: 2px solid #1e1e1e;
  font-weight: 700;
}

.main-filter .main-filter__item:last-of-type {
  margin-right: 0;
}

.main-filter .main-filter__commentator-wrapper {
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  flex-wrap: wrap;
  margin: 20px 0 0;
  padding: 20px 0 0;
  display: flex;
}

.main-filter .main-filter__commentator {
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  width: 60px;
  margin: 0 30px 25px 0;
  display: inline-block;
}

.main-filter .main-filter__commentator:nth-of-type(8n) {
  margin-right: 0;
}

.main-filter .main-filter__commentator.main-filter__commentator-active .main-filter__commentator-img {
  border: 2px solid #1e1e1e;
}

.main-filter .main-filter__commentator.main-filter__commentator-active .main-filter__commentator-name {
  font-weight: 700;
}

.main-filter .main-filter__commentator-img {
  border-radius: 60px;
  width: 60px;
  height: 60px;
  display: inline-block;
  overflow: hidden;
}

.main-filter .main-filter__commentator-img img {
  width: 100%;
  height: auto;
}

.main-filter .main-filter__commentator-name {
  text-align: center;
  margin-top: 5px;
  font-size: 12px;
  line-height: 15px;
  display: inline-block;
}

.main-filter .online-report-item__flag-ico {
  margin: 0 0 0 10px;
}

.days-filter {
  float: right;
  cursor: pointer;
  width: 143px;
  height: 15px;
  position: relative;
}

.days-filter .active-date {
  margin-right: 17px;
  display: inline-block;
}

.days-filter .dataimg-arrow-down {
  width: 12px;
  display: inline-block;
}

.days-filter .dataimg-arrow-down path {
  fill: #1e1e1e;
}

.days-filter .dataimg-arrow-down.dataimg-arrow-down__turned {
  transform: rotate(180deg);
}

.days-filter .border-bottom {
  border-color: #d21e1e;
}

.days-filter__inner {
  float: right;
  background: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  width: 100%;
  height: auto;
  padding: 4px 5px 4px 15px;
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  top: -55px;
  left: 0;
}

.days-filter__inner.show-day {
  height: 215px;
}

.days-filter__inner .hidden-days {
  z-index: 1;
  background: #fff;
  border-top: 0;
  border-bottom: 1px solid #dcdcdc;
  border-left: 1px solid #dcdcdc;
  border-right: 5px;
  width: 100%;
  height: 162px;
  padding: 15px;
  display: none;
  position: absolute;
  top: 30px;
  left: -1px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.days-filter__inner .hidden-days.show-day {
  display: block;
}

.online-report-items .cnc_article_feed_ad_position {
  background-color: rgba(0, 0, 0, 0);
  min-width: auto;
  min-height: auto;
}

.olympic-games-calendar {
  z-index: 1;
  background: #fff;
  position: relative;
  overflow: hidden;
}

.olympic-games-calendar table {
  border-bottom: 1px solid #dcdcdc;
}

.olympic-games-calendar thead th:last-child, .olympic-games-calendar tbody td:last-child {
  border-right: 0;
}

.olympic-games-calendar thead tr {
  border-bottom: 1px solid #dcdcdc;
}

.olympic-games-calendar thead th {
  border-top: 1px solid #dcdcdc;
}

.olympic-games-calendar tbody td, .olympic-games-calendar th {
  text-align: center;
  border-right: 1px solid #dcdcdc;
  outline: 0;
  width: 30px;
  height: 31px;
  position: relative;
}

.olympic-games-calendar tbody td:hover, .olympic-games-calendar th:hover {
  cursor: pointer;
  color: #fff;
  background: #d21e1e;
}

.olympic-games-calendar tbody td:hover a, .olympic-games-calendar tbody td:hover span, .olympic-games-calendar th:hover a, .olympic-games-calendar th:hover span {
  color: #fff;
  transition: all 10ms;
}

.olympic-games-calendar tbody td:first-child, .olympic-games-calendar th:first-child {
  text-align: left;
  width: 150px;
  padding: 0 0 0 10px;
}

.olympic-games-calendar tbody tr:nth-child(2n) {
  background: #e6e6e6;
}

.olympic-games-calendar .day {
  flex-flow: column;
  align-items: center;
  width: 30px;
  min-height: 100%;
  display: flex;
}

.olympic-games-calendar .day:focus {
  outline: none;
}

.olympic-games-calendar .day .icon-dot {
  margin: auto;
}

.olympic-games-calendar .day .icon-dot.online {
  background: #b11d33;
}

.olympic-games-calendar .day .icon-medail svg {
  width: 18px;
  height: 22px;
  margin: 4px;
}

.olympic-games-calendar .day__name {
  color: #aaa;
  width: 26px;
  margin: 5px 0 0;
  padding-bottom: 5px;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.5;
  display: block;
}

.olympic-games-calendar .day__num {
  color: #323232;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
  display: block;
}

.olympic-games-calendar .day.hovered {
  background-color: #ebe4db;
}

.olympic-games-calendar .day.hovered:hover {
  background: #d21e1e;
}

.olympic-games-calendar .day.hovered:hover svg path, .olympic-games-calendar .day.hovered:hover svg circle {
  fill: #fff;
}

.olympic-games-calendar .day.today {
  background: #d21e1e;
}

.olympic-games-calendar .day.today .day__name, .olympic-games-calendar .day.today .day__num {
  color: #fff;
}

.olympic-games-calendar .day.online svg path, .olympic-games-calendar .day.online svg circle {
  fill: #b11d33;
}

.olympic-games-calendar__legend {
  color: #aaa;
  align-items: center;
  height: 50px;
  padding: 0 15px;
  font-size: 13px;
  display: flex;
}

.olympic-games-calendar__legend--right {
  justify-content: flex-end;
  height: 50px;
  padding: 0;
}

.olympic-games-calendar__legend--right .olympic-games-calendar__legend-item:first-child {
  margin-left: 0;
}

.olympic-games-calendar__legend--mobile {
  flex-wrap: wrap;
  margin-top: 10px;
  padding: 0 10px;
}

.olympic-games-calendar__legend--mobile .olympic-games-calendar__legend-item {
  flex-basis: calc(50% - 20px);
  margin-bottom: 10px;
  margin-left: 0;
}

.olympic-games-calendar__legend--mobile .olympic-games-calendar__legend-item:first-child {
  margin-left: 0;
}

.olympic-games-calendar__legend-item {
  align-items: center;
  margin-left: 14px;
  display: flex;
}

.olympic-games-calendar__legend-item:first-child {
  margin-left: 109px;
}

.olympic-games-calendar__legend-icon.icon-dot, .olympic-games-calendar__legend-icon.icon-medail {
  text-align: center;
  width: 18px;
  margin: 0 7px 0 0;
}

.olympic-games-calendar__legend-icon.icon-medail svg {
  width: 18px;
  height: 22px;
}

.olympic-games-calendar__table-title {
  font-size: 22px;
  display: block;
}

.olympic-games-calendar__table-title:hover {
  color: #1e1e1e;
}

.olympic-games-calendar__table-month {
  font-size: 13px;
}

.olympic-games-calendar__table-month:hover {
  color: #1e1e1e;
}

.olympic-games-calendar__table-sport-link {
  font-size: 12px;
  text-decoration: underline;
}

.olympic-games-calendar__button-day {
  background: none;
  border: none;
  padding: 0;
}

.olympic-games-calendar__table-day {
  background-color: #fff;
  border: 0;
  padding: 0;
}

.olympic-games-calendar__table-day--active, .olympic-games-calendar__table-day--active.day.hovered {
  background-color: #d21e1e;
}

.olympic-games-calendar__table-day--active svg path, .olympic-games-calendar__table-day--active svg circle, .olympic-games-calendar__table-day--active.day.hovered svg path, .olympic-games-calendar__table-day--active.day.hovered svg circle {
  fill: #fff;
}

.olympic-games-calendar__flag {
  width: 17px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
}

.olympic-games-calendar__flag-icon {
  width: 17px;
  height: 15px;
}

.olympic-games-calendar--loh-tokio tbody td:hover, .olympic-games-calendar--loh-tokio th:hover, .olympic-games-calendar--zoh-peking tbody td:hover, .olympic-games-calendar--zoh-peking th:hover {
  background: #94754d;
}

.olympic-games-calendar--loh-tokio tbody td a.day, .olympic-games-calendar--loh-tokio tbody td .day, .olympic-games-calendar--loh-tokio th a.day, .olympic-games-calendar--loh-tokio th .day, .olympic-games-calendar--zoh-peking tbody td a.day, .olympic-games-calendar--zoh-peking tbody td .day, .olympic-games-calendar--zoh-peking th a.day, .olympic-games-calendar--zoh-peking th .day {
  cursor: pointer;
}

.olympic-games-calendar--loh-tokio tbody td a.day.hovered:hover, .olympic-games-calendar--loh-tokio tbody td .day.hovered:hover, .olympic-games-calendar--loh-tokio th a.day.hovered:hover, .olympic-games-calendar--loh-tokio th .day.hovered:hover, .olympic-games-calendar--zoh-peking tbody td a.day.hovered:hover, .olympic-games-calendar--zoh-peking tbody td .day.hovered:hover, .olympic-games-calendar--zoh-peking th a.day.hovered:hover, .olympic-games-calendar--zoh-peking th .day.hovered:hover, .olympic-games-calendar--loh-tokio tbody td a.day.today, .olympic-games-calendar--loh-tokio tbody td .day.today, .olympic-games-calendar--loh-tokio th a.day.today, .olympic-games-calendar--loh-tokio th .day.today, .olympic-games-calendar--zoh-peking tbody td a.day.today, .olympic-games-calendar--zoh-peking tbody td .day.today, .olympic-games-calendar--zoh-peking th a.day.today, .olympic-games-calendar--zoh-peking th .day.today {
  background: #94754d;
}

.olympic-games-calendar--loh-tokio .olympic-games-calendar__table-day--active, .olympic-games-calendar--loh-tokio .olympic-games-calendar__table-day--active.day.hovered, .olympic-games-calendar--zoh-peking .olympic-games-calendar__table-day--active, .olympic-games-calendar--zoh-peking .olympic-games-calendar__table-day--active.day.hovered {
  background-color: #ebe4db;
}

.olympic-games-calendar--loh-paris tbody td:hover, .olympic-games-calendar--loh-paris th:hover {
  background: #ae9e56;
}

.olympic-games-calendar--loh-paris tbody td a.day, .olympic-games-calendar--loh-paris tbody td .day, .olympic-games-calendar--loh-paris th a.day, .olympic-games-calendar--loh-paris th .day {
  cursor: pointer;
}

.olympic-games-calendar--loh-paris tbody td a.day.hovered:hover, .olympic-games-calendar--loh-paris tbody td .day.hovered:hover, .olympic-games-calendar--loh-paris th a.day.hovered:hover, .olympic-games-calendar--loh-paris th .day.hovered:hover, .olympic-games-calendar--loh-paris tbody td a.day.today, .olympic-games-calendar--loh-paris tbody td .day.today, .olympic-games-calendar--loh-paris th a.day.today, .olympic-games-calendar--loh-paris th .day.today {
  background: #ae9e56;
}

.olympic-games-calendar--loh-paris .olympic-games-calendar__table-day--active, .olympic-games-calendar--loh-paris .olympic-games-calendar__table-day--active.day.hovered {
  background-color: #ebe4db;
}

.olympic-games-calendar:before, .olympic-games-calendar:after {
  content: "";
  display: table;
}

.olympic-games-calendar:after {
  clear: both;
}

.olympic-games-tabs__list-item {
  text-align: center;
  border-bottom: 2px solid #d21e1e;
}

.olympic-games-tabs__item {
  text-align: center;
  cursor: pointer;
  background: #fff;
  border: 2px solid #828282;
  border-bottom-color: #d21e1e;
  width: 216px;
  height: 42px;
  margin: 0 0 -2px;
  font-size: 13px;
  font-weight: 700;
}

.olympic-games-tabs__item:focus, .olympic-games-tabs__item:hover {
  outline: none;
}

.olympic-games-tabs__item--active {
  border-color: #d21e1e #d21e1e #fff;
}

.olympic-games-tabs__item + .olympic-games-tabs__item {
  margin-left: 10px;
}

.olympic-games-tabs__content {
  margin: 15px 0 20px;
  display: none;
}

.olympic-games-tabs__content--active {
  display: block;
}

.olympic-games-tabs--mobile .olympic-games-tabs__item {
  width: 155px;
}

.olympic-games-tabs--zoh-peking .olympic-games-tabs__list-item, .olympic-games-tabs--zoh-peking .olympic-games-tabs__item {
  border-bottom: 2px solid #017eff;
}

.olympic-games-tabs--zoh-peking .olympic-games-tabs__item--active {
  border-color: #017eff #017eff #fff;
}

.olympic-games-tabs--loh-paris .olympic-games-tabs__list-item, .olympic-games-tabs--loh-paris .olympic-games-tabs__item {
  border-bottom: 2px solid #5b5292;
}

.olympic-games-tabs--loh-paris .olympic-games-tabs__item--active {
  border-color: #5b5292 #5b5292 #fff;
}

.olympic-games-main-title {
  text-align: center;
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: 700;
}

.olympic-games-main-title--loh-tokio, .olympic-games-main-title--zoh-peking {
  color: #94754d;
}

.olympic-games-main-title--loh-paris {
  color: #ae9e56;
}

.olympic-games-carousel {
  margin-bottom: 10px;
  padding: 0 10px;
}

.olympic-games-carousel__nav {
  justify-content: center;
  align-items: center;
  width: 420px;
  height: 0;
  transition: all .3s;
  display: flex;
  position: relative;
}

.olympic-games-carousel__nav--mobile {
  width: 315px;
  height: 0;
}

.olympic-games-carousel__nav--mobile .olympic-games-carousel__nav-title {
  font-size: 15px;
}

.olympic-games-carousel__nav--active {
  height: 37px;
  margin: 0 auto 15px;
}

.olympic-games-carousel__nav-title {
  text-align: center;
  width: 310px;
  margin: 0;
  padding: 10px 0 0;
  font-weight: 700;
}

.olympic-games-carousel__control {
  cursor: pointer;
  background: #fff;
  border: 0;
  width: 37px;
  height: 37px;
  padding: 0;
}

.olympic-games-carousel__control:focus {
  outline: none;
}

.olympic-games-carousel__control:hover svg circle {
  fill: #d4c2ac;
}

.olympic-games-carousel__control:disabled {
  opacity: .6;
}

.olympic-games-carousel__control-icon {
  width: 37px;
  height: 37px;
}

.olympic-games-carousel__content {
  text-align: center;
  scrollbar-width: none;
  background: #fff;
  justify-content: center;
  width: 100%;
  display: flex;
  position: relative;
}

.olympic-games-carousel__content::-webkit-scrollbar {
  display: none;
}

.olympic-games-carousel__inner-content {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  scroll-behavior: smooth;
  scrollbar-width: none;
  transition: all .2s;
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
}

.olympic-games-carousel__inner-content::-webkit-scrollbar {
  display: none;
}

.olympic-games-carousel__fixed-content {
  text-align: left;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  flex-flow: column;
  flex-basis: 160px;
  display: flex;
}

.olympic-games-carousel__fixed-content + .olympic-games-carousel__inner-content {
  flex-basis: calc(100% - 160px);
}

.olympic-games-carousel__content-title, .olympic-games-carousel__content-subtitle, .olympic-games-carousel__content-sport-link {
  padding-left: 13px;
  font-weight: 700;
}

.olympic-games-carousel__content-title {
  height: 33px;
  padding-top: 6px;
  font-size: 22px;
}

.olympic-games-carousel__content-subtitle {
  height: 20px;
  padding-bottom: 5px;
}

.olympic-games-carousel__content-subtitle, .olympic-games-carousel__content-sport-link {
  font-size: 13px;
}

.olympic-games-carousel__content-sport-link {
  color: #1e1e1e;
  border-top: 1px solid #dcdcdc;
  align-items: center;
  height: 31px;
  text-decoration: underline;
  display: flex;
}

.olympic-games-carousel__item {
  background-color: #fff;
  border: 1px solid #dcdcdc;
  padding: 0;
}

.olympic-games-carousel__item + .olympic-games-carousel__item__item {
  border-left: none;
}

.olympic-games-carousel__item--active, .olympic-games-carousel__item:hover {
  background-color: #ebe4db;
}

.olympic-games-carousel__btn-day {
  background: none;
  border: none;
}

.olympic-games-carousel__btn-day:hover {
  background-color: #ebe4db;
}

.olympic-games-carousel__icon-day {
  background-position: 4px 4px;
  border-top: 1px solid #dcdcdc;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 31px;
  display: flex;
}

.olympic-games-carousel__icon-day svg path, .olympic-games-carousel__icon-day svg circle {
  fill: #94754d;
}

.olympic-games-carousel__icon-day--online svg path {
  fill: #b11d33;
}

.olympic-games-loader {
  width: 100%;
  display: none;
  position: relative;
}

.olympic-games-loader--show {
  display: block;
}

.olympic-games-loader--show + .olympic-games-ajax-data {
  opacity: .3;
  transition: opacity .3s ease-out;
}

.olympic-games-loader__icon {
  z-index: 999;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
}

.olympic-games-table {
  width: 100%;
}

.olympic-games-table thead {
  border-bottom: 1px solid #e6e6e6;
}

.olympic-games-table thead tr {
  color: #aaa;
  background-color: #fff;
}

.olympic-games-table tbody {
  border-bottom: 1px solid #e6e6e6;
}

.olympic-games-table tbody tr:nth-child(odd) {
  background-color: #fff;
}

.olympic-games-table tbody tr:nth-child(2n) {
  background-color: #f0f0f0;
}

.olympic-games-table + .olympic-games-table {
  margin-top: 15px;
}

.olympic-games-table__caption {
  color: #d21e1e;
  text-align: left;
  text-transform: uppercase;
  padding: 10px 0;
  font-size: 13px;
  font-weight: 700;
}

.olympic-games-table__event-date {
  color: #828282;
  border-right: 1px solid #e6e6e6;
  width: 70px;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 700;
}

.olympic-games-table__event-date--live {
  color: #d21e1e;
}

.olympic-games-table__event {
  width: 50%;
  padding: 10px 20px;
}

.olympic-games-table__event-name, .olympic-games-table__event-players {
  color: #828282;
  font-size: 12px;
  font-weight: 700;
}

.olympic-games-table__event-players {
  color: #1e1e1e;
}

.olympic-games-table__event-medals {
  width: 33%;
  padding: 4px 0;
}

.olympic-games-table__event-medals-icon {
  float: left;
  width: 18px;
  height: 22px;
  display: inline-block;
}

.olympic-games-table__event-medals-list {
  float: left;
  color: #1e1e1e;
  margin-left: 15px;
  font-size: 10px;
  display: inline-block;
}

.olympic-games-table__event-online {
  color: #d21e1e;
  width: 60px;
  padding: 10px;
  font-size: 12px;
  font-weight: 700;
}

.olympic-games-table--hide {
  display: none;
}

.olympic-games-table--loh-tokio .olympic-games-table__caption {
  color: #94754d;
}

.olympic-games-timeline-table {
  flex-flow: column;
  display: flex;
}

.olympic-games-timeline-table__caption {
  color: #d21e1e;
  text-align: left;
  text-transform: uppercase;
  border-bottom: 1px solid #dcdcdc;
  width: 100%;
  padding: 10px 0;
  font-size: 13px;
  font-weight: 700;
}

.olympic-games-timeline-table__row {
  border-bottom: 1px solid #dcdcdc;
  flex-wrap: nowrap;
  flex-grow: 1;
  display: flex;
}

.olympic-games-timeline-table__row:nth-of-type(odd) {
  background-color: #f0f0f0;
}

.olympic-games-timeline-table__row.hidden {
  display: none;
}

.olympic-games-timeline-table__table-cell {
  flex-basis: calc(100% - 70px);
  display: flex;
}

.olympic-games-timeline-table__table-cell--mobile {
  flex-flow: column;
  flex-basis: calc(100% - 53px);
}

.olympic-games-timeline-table__table-cell--mobile .olympic-games-timeline-table__event, .olympic-games-timeline-table__table-cell--mobile .olympic-games-timeline-table__event-medals, .olympic-games-timeline-table__table-cell--mobile .olympic-games-timeline-table__event-online {
  flex-basis: 100%;
  padding: 10px;
}

.olympic-games-timeline-table__table-cell--mobile .olympic-games-timeline-table__event-medals, .olympic-games-timeline-table__table-cell--mobile .olympic-games-timeline-table__event-online {
  padding-top: 0;
}

.olympic-games-timeline-table__event-date {
  color: #828282;
  border-right: 1px solid #e6e6e6;
  flex-basis: 70px;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 700;
}

.olympic-games-timeline-table__event-date--live {
  color: #d21e1e;
}

.olympic-games-timeline-table__event-date--mobile {
  flex-basis: 53px;
  padding: 10px;
}

.olympic-games-timeline-table__event {
  flex-basis: 50%;
  padding: 10px 20px;
}

.olympic-games-timeline-table__event-name {
  margin-bottom: 9px;
}

.olympic-games-timeline-table__event-name, .olympic-games-timeline-table__event-players {
  color: #828282;
  font-size: 12px;
  font-weight: 700;
}

.olympic-games-timeline-table__event-players {
  color: #1e1e1e;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  display: flex;
}

.olympic-games-timeline-table__event-medals {
  flex-basis: 33%;
  padding: 4px 0;
  display: flex;
}

.olympic-games-timeline-table__event-medals-icon {
  width: 18px;
  height: 22px;
}

.olympic-games-timeline-table__event-medals-name {
  text-transform: uppercase;
  color: #d21e1e;
  padding-left: 15px;
  font-size: 9px;
  font-weight: 700;
}

.olympic-games-timeline-table__event-medals-list {
  float: left;
  color: #1e1e1e;
  margin-left: 15px;
  font-size: 10px;
  display: inline-block;
}

.olympic-games-timeline-table__event-online {
  color: #d21e1e;
  flex-basis: 60px;
  padding: 10px;
  font-size: 12px;
  font-weight: 700;
}

.olympic-games-timeline-table.hidden {
  display: none;
}

.olympic-games-timeline-table--loh-tokio .olympic-games-timeline-table__caption, .olympic-games-timeline-table--zoh-peking .olympic-games-timeline-table__caption {
  color: #94754d;
}

.olympic-games-timeline-table--loh-paris .olympic-games-timeline-table__caption {
  color: #ae9e56;
}

.olympic-games-notification--info {
  text-align: center;
  padding: 15px 0;
}

.olympic-games-filter {
  background: #fff;
  flex-flow: column;
  padding: 15px 0 0;
  display: flex;
}

.olympic-games-filter__btn {
  background: #fff;
  border: none;
  outline: none;
  align-items: center;
  height: 22px;
  margin-bottom: 15px;
  padding-left: 30px;
  display: flex;
  position: relative;
}

.olympic-games-filter__btn:hover, .olympic-games-filter__btn:focus {
  border: none;
}

.olympic-games-filter__btn:before {
  content: "";
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.olympic-games-filter__btn:after {
  content: "✔";
  color: #1e1e1e;
  opacity: 0;
  font-size: 16px;
  line-height: .8;
  transition: all .3s;
  position: absolute;
  top: 3px;
  left: 2px;
  transform: scale(0);
}

.olympic-games-filter__btn--active:after {
  opacity: 1;
  transform: scale(1);
}

.olympic-games-filter__name {
  margin-left: 10px;
  font-size: 13px;
  font-weight: 700;
}

.olympic-games-filter__icon--medal {
  width: 18px;
  height: 22px;
}

.olympic-games-promo {
  background: #fff;
  padding: 15px;
}

.olympic-games-promo__event-date {
  color: #94754d;
  text-transform: uppercase;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 10px;
  font-size: 13px;
  font-weight: 700;
}

.olympic-games-promo__event-link {
  border-bottom: 1px solid #dcdcdc;
  flex-wrap: nowrap;
  flex-grow: 1;
  display: flex;
}

.olympic-games-promo__event-link:nth-of-type(odd) {
  background-color: #f0f0f0;
}

.olympic-games-promo__event-link:hover {
  text-decoration: none;
}

.olympic-games-promo__event-link:hover .olympic-games-promo__event-name {
  text-decoration: underline;
}

.olympic-games-promo__event-time {
  color: #828282;
  border-right: 1px solid #e6e6e6;
  flex-basis: 52px;
  padding: 10px;
  font-size: 12px;
  font-weight: 700;
}

.olympic-games-promo__event-time:hover {
  text-decoration: none;
}

.olympic-games-promo__event {
  flex-basis: calc(100% - 52px);
  justify-content: space-between;
  padding: 10px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
}

.olympic-games-promo__event-icons {
  flex-flow: column;
  flex-basis: 52px;
  align-items: flex-end;
  padding-left: 10px;
  display: flex;
}

.olympic-games-promo__event-online {
  color: #d21e1e;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 700;
}

.olympic-games-promo__event-online:hover {
  text-decoration: none;
}

.olympic-games-promo__icon-medal {
  width: 18px;
  height: 22px;
}

.articles-rightcol {
  float: left;
  background-color: #fff;
  width: 100%;
  position: relative;
}

.articles-rightcol__header {
  width: 100%;
  height: 52px;
  position: relative;
}

.articles-rightcol__title {
  color: #d21e1e;
  float: left;
  margin: 14px 0 0 15px;
  font-family: roboto, arial, sans-serif;
  font-size: 22px;
  font-weight: 700;
}

.articles-rightcol__subtitle {
  color: #d21e1e;
  float: right;
  margin: 23px 27px 0 0;
  font-size: 13px;
  font-weight: bold;
  position: relative;
}

.articles-rightcol__subtitle .dataimg-arrow-right {
  width: 5px;
  height: 10px;
  position: absolute;
  top: 0;
  right: -12px;
}

.articles-rightcol__subtitle .dataimg-arrow-right path {
  fill: #d21e1e;
}

.articles-rightcol .article-rightcol-big {
  width: 100%;
  margin-bottom: 15px;
  position: relative;
}

.articles-rightcol .article-rightcol-big .arb-media {
  width: 100%;
  height: 180px;
  display: block;
  position: relative;
  overflow: hidden;
}

.articles-rightcol .article-rightcol-big .arb-media .dataimg-play {
  z-index: 10;
  width: 80px;
  height: 80px;
  margin: auto;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.articles-rightcol .article-rightcol-big .arb-media .dataimg-play .circle {
  opacity: .6;
  transition: opacity .2s ease-in-out;
}

.articles-rightcol .article-rightcol-big .arb-media:hover .dataimg-play .circle {
  opacity: 1;
}

.articles-rightcol .article-rightcol-big .arb-title {
  border-bottom: 1px solid #dcdcdc;
  margin: 15px 15px 0;
  padding-bottom: 15px;
  font-family: roboto slab, Times New Roman, serif;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.3em;
  display: block;
  position: relative;
}

.articles-rightcol .article-rightcol-big .video-wrapper .vp-controls-holder {
  z-index: 0;
}

.articles-rightcol .article-rightcol-small {
  float: left;
  border-bottom: 1px solid #dcdcdc;
  width: 300px;
  margin: 0 0 15px 15px;
  padding-bottom: 15px;
  position: relative;
}

.articles-rightcol .article-rightcol-small.last {
  border-bottom: none;
  padding-bottom: 0;
}

.articles-rightcol .article-rightcol-small .ars-media {
  float: left;
  width: 150px;
  height: 90px;
  display: block;
  position: relative;
  overflow: hidden;
}

.articles-rightcol .article-rightcol-small .ars-media .dataimg-play {
  z-index: 10;
  width: 34px;
  height: 34px;
  margin: auto;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.articles-rightcol .article-rightcol-small .ars-media .dataimg-play .circle {
  opacity: .6;
  transition: opacity .2s ease-in-out;
}

.articles-rightcol .article-rightcol-small .ars-media:hover .dataimg-play .circle {
  opacity: 1;
}

.articles-rightcol .article-rightcol-small .ars-title {
  width: 140px;
  padding-bottom: 15px;
  font-family: roboto, arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.3em;
  display: block;
  position: relative;
}

.articles-rightcol .title-section {
  width: 300px;
  margin: 15px 15px 0;
}

.articles-rightcol .ars-body {
  float: right;
  position: relative;
}

.articles-rightcol .ars__category-flags {
  margin-bottom: 7px;
}

.sport-card .link-big .icon {
  margin-top: 3px;
  padding: 0;
}

.cttv-banner {
  text-align: center;
  margin: 30px 0;
}

.cttv-banner__link {
  display: inline-block;
}

.cttv-banner__image {
  max-width: 100%;
  height: auto;
}

.video-articles-smaller__header {
  border-bottom: 2px solid #017eff;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  margin-bottom: 15px;
  padding-bottom: 12px;
  line-height: 1;
  display: flex;
}

.video-articles-smaller__header-arrow {
  width: 12px;
  height: 14px;
}

.video-articles-smaller__content {
  flex: 0 0 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.video-articles-smaller__article {
  width: 185px;
  margin-right: 20px;
}

.video-articles-smaller__article:last-child {
  margin-right: 0;
}

.video-articles-smaller .list-article-smaller .title-container h2 a {
  font-size: 16px;
  line-height: 20px;
  display: block;
}

.box-oh-wrapper {
  background: #fff;
  margin-bottom: 30px;
  display: flex;
}

.top-articles-oh .box-container {
  width: 360px;
  margin-bottom: 0;
  padding: 15px 0 15px 15px;
}

.top-articles-oh .box-container--mobile {
  width: auto;
  margin: 0 2%;
  padding: 15px;
}

.top-articles-oh .box-container--mobile article.list-article-horizontal-small {
  border-right: none;
}

.top-articles-oh .box-container--mobile article.list-article-horizontal-small:not(:first-child) {
  padding-top: 10px;
}

.top-articles-oh .box-container--mobile article.list-article-horizontal-small .title-container {
  float: revert;
  border-right: 0;
  width: auto;
  margin-left: 165px;
}

.top-articles-oh .box-container--mobile article.list-article-horizontal-small .title-container .title {
  font-size: 13px;
}

.top-articles-oh article.list-article-horizontal-small {
  border: 0;
  border-right: 1px solid #e4e4e4;
  width: auto;
  margin: 0;
  padding: 0;
}

.top-articles-oh article.list-article-horizontal-small .image-container {
  float: left;
  width: 150px;
  min-width: auto;
  height: 90px;
  max-height: none;
  margin-right: 15px;
}

.top-articles-oh article.list-article-horizontal-small .category-name {
  text-transform: uppercase;
  color: #d21e1e;
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
}

.top-articles-oh article.list-article-horizontal-small .title-container {
  width: 179px;
  padding-right: 14px;
}

.top-articles-oh article.list-article-horizontal-small .title-container h2 {
  font-family: roboto slab, arial, sans-serif;
}

.top-articles-oh.velky-clanek .box-container--mobile article.list-article-horizontal-small .image-container {
  float: none;
  width: auto;
  height: auto;
  margin-right: 0;
}

.top-articles-oh.velky-clanek .box-container--mobile article.list-article-horizontal-small .category-name {
  margin-top: 5px;
}

.top-articles-oh.velky-clanek .box-container--mobile article.list-article-horizontal-small .title-container {
  float: none;
  width: auto;
  margin-top: 5px;
  margin-left: auto;
  padding-right: 0;
}

.top-articles-oh.velky-clanek .box-container--mobile article.list-article-horizontal-small .title-container .title {
  font-size: 22px;
}

.top-articles-oh.velky-clanek .box-container--mobile article.list-article-horizontal-small:not(:first-child) {
  padding-top: 30px;
}

.matches-ms {
  width: 345px;
}

.matches-ms .box-container {
  margin-bottom: 0;
}

.matches-ms .title-section {
  display: none;
}

.matches-ms .list-score-structured-wapper a.list-score-link .list-score, .matches-ms .list-score-structured-wapper a.list-score-link:hover .list-score {
  background: none;
}

.matches-ms a.list-score-link:first-of-type .list-score {
  border-top: none;
  border-bottom: none;
}

.matches-ms .list-score.list-score-middle, .matches-ms .list-score.list-score-middle .team-container {
  height: 50px;
  min-height: 50px;
}

.matches-ms .list-score .score-container .score {
  font-size: 28px;
}

.matches-ms .match-container {
  position: relative;
}

.matches-ms .match-container .match-bet-cover {
  position: absolute;
  bottom: -4px;
  left: 42%;
}

.matches-ms--mobile {
  width: auto;
  margin: 0 2% 20px;
}

.matches-ms--mobile .list-score.list-score-small {
  min-height: 90px;
}

.matches-ms--mobile .list-score.list-score-small .flag-live {
  font-size: 10px;
}

.matches-ms--mobile .match-container .match-bet-cover {
  margin-left: -25px;
  position: absolute;
  bottom: -20px;
  left: 50%;
}

.top-articles-ms-hokej .box-container {
  flex-direction: row;
  align-items: stretch;
  width: 705px;
  display: flex;
}

.top-articles-ms-hokej article.list-article-horizontal-small {
  width: 350px;
  margin-right: 15px;
}

.top-articles-ms-hokej article.list-article-horizontal-small .title-container {
  width: 164px;
}

.box-oh-online {
  background-color: #fff;
  width: 345px;
  height: 120px;
  padding: 20px 15px 15px;
  font-size: 13px;
}

.box-oh-online__item {
  margin-bottom: 10px;
  display: flex;
}

.box-oh-online__time {
  color: #bebebe;
  width: 40px;
  padding-top: 2px;
}

.box-oh-online__title {
  height: 35px;
  margin-left: 20px;
  overflow: hidden;
}

.box-oh-online__title h3.title {
  font-size: 13px;
}

.box-oh-online__title h3.title p {
  margin-bottom: 0;
  font-size: 13px;
  line-height: normal;
}

.box-oh-online__title h3.title p a {
  color: #1e1e1e;
}

.box-oh-online--mobil {
  width: auto;
  height: auto;
  margin: 0 2%;
  padding: 0 15px;
}

.box-oh-online--mobil .box-oh-online__item:first-child {
  border-top: 1px solid #e4e4e4;
  padding-top: 15px;
}

.box-oh-online--mobil .box-oh-online__item:last-child {
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 0;
  padding-bottom: 15px;
}

.box-oh-online--mobil .box-oh-online__none {
  display: flex;
}

.box-oh-online--mobil h3.title {
  font-family: roboto, arial, sans-serif;
}

.box-oh-online--mobil h3.title p {
  line-height: 1.31;
}

.box-oh-online__none {
  display: none;
}

.box-oh-medaile {
  background: #fff;
  width: 345px;
  height: 120px;
  padding: 15px 15px 15px 0;
}

.box-oh-medaile__vlajka {
  float: left;
  background-image: url("//img2.cncenter.cz/images/isportcz/standalone/zoh/vlajka@2x.png");
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: 150px 90px;
  width: 165px;
  height: 90px;
}

.box-oh-medaile__container {
  float: right;
  width: 150px;
  margin-left: 15px;
}

.box-oh-medaile__cz {
  text-align: center;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 8px;
}

.box-oh-medaile__cz span {
  color: #d21e1e;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.35;
  display: block;
}

.box-oh-medaile__title {
  color: #d21e1e;
  text-transform: uppercase;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  justify-content: space-between;
  padding: 7px 0 5px;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.35;
  display: flex;
}

.box-oh-medaile__icon-arrow {
  width: 5px;
  margin: 0 0 0 4px;
  position: relative;
}

.box-oh-medaile__medaile {
  text-align: center;
  margin-bottom: 15px;
}

.box-oh-medaile__medaile span {
  color: #fff;
  background-repeat: no-repeat;
  background-size: 22px 34px;
  width: 22px;
  height: 34px;
  margin: 8px 5px 0;
  padding: 15px 0 4px;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
}

.box-oh-medaile__gold {
  background-image: url("//img2.cncenter.cz/images/isportcz/standalone/zoh/medal-gold.svg");
}

.box-oh-medaile__silver {
  background-image: url("//img2.cncenter.cz/images/isportcz/standalone/zoh/medal-silver.svg");
}

.box-oh-medaile__bronze {
  background-image: url("//img2.cncenter.cz/images/isportcz/standalone/zoh/medal-bronze.svg");
}

.box-oh-medaile:after {
  clear: both;
}

.box-oh-medaile--mobil {
  justify-content: space-between;
  width: auto;
  height: 80px;
  margin: 0 2% 15px;
  padding: 15px;
  display: flex;
}

.box-oh-medaile--mobil .box-oh-medaile__vlajka {
  float: none;
  background-image: url("//img2.cncenter.cz/images/isportcz/standalone/zoh/vlajka@2x.png");
  background-size: 80px 50px;
  border-left: none;
  width: 80px;
  height: 50px;
}

.box-oh-medaile--mobil .box-oh-medaile__container {
  float: none;
  justify-content: space-between;
  width: calc(100% - 80px);
  margin: 0;
  padding-left: 15px;
  display: flex;
}

.box-oh-medaile--mobil .box-oh-medaile__title {
  border: none;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
}

.box-oh-medaile--mobil .box-oh-medaile__medaile {
  display: flex;
}

.box-oh-medaile--mobil .box-oh-medaile__medaile span {
  margin: 8px 3px 0;
}

.box-oh-medaile--mobil .box-oh-medaile__cz {
  border-bottom: none;
  padding-top: 16px;
  padding-bottom: 0;
  font-size: 13px;
  font-weight: 600;
}

@media (max-width: 389px) {
  .box-oh-medaile--mobil .box-oh-medaile__cz {
    font-size: 12px;
  }
}

.box-oh-medaile--mobil .box-oh-medaile__cz span {
  display: none;
}

.header-promo-league, .header-promo-league a, .header-promo-league a:hover {
  color: #fff;
}

.header-promo-league__container {
  align-items: stretch;
  display: flex;
}

.header-promo-league__logo {
  flex-basis: 22%;
  line-height: 0;
}

.header-promo-league__logo--fortuna, .header-promo-league__logo--liga-mistru {
  max-width: 70px;
}

.header-promo-league__logo--liga-mistru {
  padding: 4px;
}

.header-promo-league__logo--liga-mistru svg path:first-child {
  fill: #00a442;
}

.header-promo-league__logo--liga-mistru svg path {
  fill: #fff;
}

.header-promo-league__logo--tipsport-extraliga {
  flex-basis: auto;
  padding: 10px;
}

.header-promo-league__title {
  flex-direction: column;
  flex-basis: 58%;
  align-self: center;
  margin: 5px 5px 5px 7px;
  display: flex;
}

.header-promo-league__title h3 {
  text-align: center;
  width: 100%;
  font-family: roboto, arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
}

.header-promo-league__title h3 span {
  color: #fff;
  text-transform: initial;
  font-size: 12px;
  font-weight: 300;
  display: block;
}

.header-promo-league__title h3.dropdown-title {
  text-align: left;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 300;
}

.header-promo-league__title--fortuna, .header-promo-league__title--liga-mistru {
  flex-basis: calc(59% - 70px);
}

.header-promo-league__title--tipsport-extraliga {
  margin-left: 0;
}

.header-promo-league__title--tipsport-extraliga h3 {
  text-align: left;
}

.header-promo-league__title.header-promo-league__title--no-logo {
  margin-left: 10px;
}

.header-promo-league__title.header-promo-league__title--no-logo h3 {
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
}

.header-promo-league__links {
  text-transform: uppercase;
  border-left: 1px solid #fff;
  flex-basis: 42%;
  align-items: center;
  margin: 5px 5px 5px 0;
  padding-left: 10px;
  font-weight: 700;
  display: flex;
}

.header-promo-league__links ul {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.header-promo-league__links ul li {
  width: 100%;
  margin: 3px 0;
  font-family: roboto, arial, sans-serif;
  font-size: 11px;
  line-height: 13px;
  position: relative;
}

.header-promo-league__links ul a {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: inline-flex;
  position: relative;
}

.header-promo-league__links svg {
  height: 10px;
}

.header-promo-league__links svg path {
  fill: #fff;
}

.header-promo-league__links--no-logo {
  margin-right: 10px;
}

.header-promo-league__links--fortuna {
  flex-basis: 41%;
}

.header-promo-league__links--liga-mistru, .header-promo-league__links--tipsport-extraliga {
  flex-basis: 38%;
}

.header-promo-league__link-name {
  width: calc(100% - 5px);
}

.header-promo-league__link-icon {
  width: 5px;
}

.header-promo-league__links-text {
  font-family: roboto, arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.3;
}

.header-promo-league__submenu-title {
  text-transform: none;
  cursor: pointer;
  flex-flow: row;
  align-items: center;
  font-family: roboto, arial, sans-serif;
  font-size: 12px;
  display: flex;
  position: relative;
}

.header-promo-league__submenu-title .dataimg-arrow-down {
  width: 10px;
  height: 6px;
  margin: -8px 0 0 5px;
}

.header-promo-league__submenu-title .dataimg-arrow-down svg {
  height: 6px;
}

.header-promo-league__submenu-title .dataimg-arrow-down.rotate-180 {
  transform: rotate(180deg);
}

.header-promo-league__submenu-content {
  z-index: 300;
  background: #fff;
  width: 300px;
  padding-top: 0;
  display: none;
  position: absolute;
}

.header-promo-league__submenu-content.visible {
  display: block;
}

.header-promo-league__submenu-content a.link-big {
  color: #1e1e1e;
}

.header-promo-league__submenu-content-half {
  float: left;
  width: 50%;
  margin-top: 20px;
}

.header-promo-league.football {
  background-color: #00a442;
}

.header-promo-league.hockey {
  background-color: #325edc;
}

.header-promo-league.hockey .header-promo-league__title h3 span {
  color: #fff;
}

.header-promo-league--loh-tokio {
  background: #771a2a url("//img2.cncenter.cz//images/isportcz/standalone/loh/loh-tokio-bg-header-promo.png") 100% 0 no-repeat;
}

.header-promo-league--loh-tokio .header-promo-league__title {
  flex-basis: 50%;
}

.header-promo-league--loh-tokio .header-promo-league__title.header-promo-league__title--no-logo h3 {
  font-size: 17px;
  line-height: 18px;
}

.header-promo-league--loh-tokio .header-promo-league__links {
  border-left: 1px solid #b11d33;
  flex-basis: 50%;
}

.header-promo-league--loh-tokio .header-promo-league__links ul li {
  font-size: 12px;
  line-height: 15px;
}

.header-promo-league--football-euro.football {
  background: #015d6b url("//img2.cncenter.cz//images/isportcz/standalone/euro-2021/bg-header-promo-special.png") 100% 0 no-repeat;
}

.header-promo-league--football-euro.football .header-promo-league__title.header-promo-league__title--no-logo h3 {
  font-size: 17px;
  line-height: 18px;
}

.header-promo-league--football-euro.football .header-promo-league__links {
  border-left: 1px solid #015d6b;
}

.header-promo-league__matches {
  background: #fff;
  padding: 0 0 15px;
}

.header-promo-league__matches .box-container {
  margin: 0;
}

.header-promo-league__matches .list-score.list-score-middle {
  border-bottom: 1px solid #dcdcdc;
}

.header-promo-league__matches .list-score.list-score-middle .team-container, .header-promo-league__matches .list-score.list-score-middle.team-name {
  font-size: 13px;
}

.header-promo-league__matches .list-score.list-score-middle .score-container .flag {
  top: -3px;
}

.header-promo-league__matches .list-score.list-score-small .flag-live {
  font-size: 10px;
}

.header-promo-league__matches .list-score .team-container .logo-container img {
  max-width: 30px;
  margin-top: 3px;
}

.header-promo-league__matches .link.sport-color {
  z-index: 10;
  text-align: center;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#00ffffff", endColorstr= "#fff", GradientType= 0);
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, #fff 17% 18%);
  width: 100%;
  height: 50px;
  margin: 0;
  line-height: 50px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.match-preview {
  color: #1e1e1e;
  flex-flow: column;
  align-items: center;
  padding: 5px;
  display: flex;
}

.match-preview:nth-child(2n) {
  background-color: #fff;
}

.match-preview__link {
  color: #1e1e1e;
  flex-direction: column;
  width: 100%;
  font-family: roboto, arial, sans-serif;
  display: flex;
}

.match-preview__link:hover {
  color: #d21e1e;
  text-decoration: none;
}

.match-preview__link:hover .match-preview__team-name--looser {
  color: #d21e1e;
}

.match-preview__row {
  width: 100%;
  display: flex;
}

.match-preview__team {
  flex-basis: 38.5%;
  align-items: center;
  display: flex;
}

.match-preview__team--home {
  justify-content: flex-end;
}

.match-preview__team--away {
  justify-content: flex-start;
}

.match-preview__team-name {
  font-size: 16px;
  font-weight: 700;
}

.match-preview__team-name--home {
  padding-right: 5px;
}

.match-preview__team-name--away {
  padding-left: 5px;
}

.match-preview__team-name--looser {
  color: #828282;
}

.match-preview__team-name:hover, .match-preview__team-name:hover .match-preview__team-name--looser, .match-preview__team-name:hover .match-preview__score:before {
  color: #d21e1e;
}

.match-preview__team-logo {
  max-width: 100%;
  height: auto;
}

.match-preview__date-score-container {
  flex-flow: column;
  flex-basis: 23%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.match-preview__date-container {
  flex-flow: column;
  place-content: center;
  display: flex;
}

.match-preview__date {
  font-size: 13px;
  font-weight: 400;
}

.match-preview__time {
  font-size: 13px;
  font-weight: 700;
}

.match-preview__flag {
  text-align: center;
  height: 13px;
  font-size: 10px;
  font-weight: 700;
}

.match-preview__flag--live {
  color: #d21e1e;
  text-transform: uppercase;
}

.match-preview__score {
  font-size: 25px;
  font-weight: bold;
  position: relative;
}

.match-preview__score--pen-sn:before, .match-preview__score--pen-ot:before, .match-preview__score--pen:before {
  z-index: 3;
  color: #1e1e1e;
  width: 14px;
  font-size: 10px;
  font-weight: 400;
  line-height: 1;
  display: block;
  position: absolute;
  bottom: 8px;
  right: -18px;
}

.match-preview__score--pen-sn:before {
  content: "SN";
}

.match-preview__score--pen-ot:before {
  content: "P";
}

.match-preview__series-score {
  text-align: center;
  width: 100%;
  font-size: 12px;
}

.match-preview__match-bet-cover {
  width: 100%;
}

.match-preview__match-bet-logo {
  width: 50px;
  height: auto;
  margin: 2px auto;
}

.match-preview__match-bet-logo--large {
  width: 64px;
  height: auto;
}

.match-preview--mobile .match-preview__team-name {
  font-size: 13px;
}

.match-preview--mobile:nth-child(2n) {
  background-color: #f5f5f5;
}

.match-preview-tennis {
  color: #1e1e1e;
  padding: 5px 0;
}

.match-preview-tennis:nth-child(2n) {
  background-color: #fff;
}

.match-preview-tennis__link {
  color: #1e1e1e;
  flex-flow: column;
  font-family: roboto, arial, sans-serif;
  display: flex;
  position: relative;
}

.match-preview-tennis__link:hover {
  color: #d21e1e;
  text-decoration: none;
}

.match-preview-tennis__link:hover .match-preview-tennis__date-time, .match-preview-tennis__link:hover .match-preview-tennis__set-value:last-of-type {
  color: #d21e1e;
}

.match-preview-tennis__player-container {
  margin: 2px 0;
  display: flex;
}

.match-preview-tennis__player {
  flex-basis: 50%;
  align-items: center;
  display: flex;
}

.match-preview-tennis__logo {
  width: auto;
  height: 25px;
}

.match-preview-tennis__player-name {
  flex-basis: calc(100% - 32px);
  padding-left: 5px;
  font-size: 16px;
  font-weight: 700;
}

.match-preview-tennis__score {
  padding-left: 5px;
  font-size: 16px;
  font-weight: 700;
}

.match-preview-tennis__score--live {
  color: #d21e1e;
}

.match-preview-tennis__set-container {
  flex-basis: 50%;
  align-items: center;
  padding-left: 25px;
  display: flex;
}

.match-preview-tennis__set-value {
  text-align: left;
  flex-basis: 25px;
  font-size: 13px;
  font-weight: 400;
}

.match-preview-tennis__set-value:last-of-type {
  color: #ff6000;
}

.match-preview-tennis__date-time {
  color: #828282;
  flex-flow: column;
  justify-content: center;
  width: 50%;
  height: 100%;
  padding-left: 26px;
  font-size: 13px;
  font-weight: 700;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.match-preview-tennis--mobile .match-preview-tennis__player-name, .match-preview-tennis--mobile .match-preview-tennis__score {
  font-size: 13px;
}

.match-preview-tennis--mobile .match-preview-tennis__player-name {
  line-height: 16px;
}

.match-preview-tennis--mobile:nth-child(2n) {
  background-color: #f5f5f5;
}

.match-preview-other {
  color: #1e1e1e;
  border-bottom: 1px solid #dcdcdc;
  font-family: roboto, arial, sans-serif;
  display: flex;
}

.match-preview-other:first-child {
  border-top: 1px solid #dcdcdc;
}

.match-preview-other__link {
  color: #1e1e1e;
  width: 100%;
  font-family: roboto, arial, sans-serif;
  display: flex;
}

.match-preview-other__link:hover {
  color: #d21e1e;
  text-decoration: none;
}

.match-preview-other__link:hover .match-preview-other__date-time, .match-preview-other__link:hover .match-preview-other__event-description, .match-preview-other__link:hover .match-preview-other__event-result {
  color: #d21e1e;
}

.match-preview-other__date-time-container {
  border-right: 1px solid #dcdcdc;
  flex-basis: 20%;
  justify-content: space-between;
  align-items: baseline;
  padding: 8px 10px 5px;
  display: flex;
}

.match-preview-other__date-time {
  color: #828282;
  font-size: 13px;
  font-weight: 700;
}

.match-preview-other__event-container {
  flex-basis: 80%;
  justify-content: space-between;
  align-items: baseline;
  padding: 5px 10px;
  display: flex;
}

.match-preview-other__event-description {
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 700;
}

.match-preview-other__event-result {
  color: #828282;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 700;
  display: block;
}

.match-preview-other__icon--live {
  color: #d21e1e;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
}

.match-preview-other--colors:nth-child(2n) {
  background-color: #f5f5f5;
}

.match-preview-other--mobile .match-preview-other__date-time-container {
  padding: 5px 10px;
}

.match-preview-other--mobile .match-preview-other__event-description {
  font-size: 13px;
}

.match-preview-other--mobile .match-preview-other__event-result {
  margin-top: 0;
  font-size: 13px;
}

.promo-leagues {
  background: #f5f5f5;
  padding: 0 15px;
  position: relative;
  overflow: hidden;
  transition: all 1s ease-in-out !important;
}

.promo-leagues__header {
  border-bottom: 2px solid #dcdcdc;
  height: 60px;
  display: flex;
}

.promo-leagues__title {
  text-transform: uppercase;
  color: #d21e1e;
  align-items: center;
  margin-right: 20px;
  padding-top: 10px;
  font-family: roboto, arial, sans-serif;
  font-size: 25px;
  font-weight: 700;
  display: flex;
  position: relative;
}

.promo-leagues__title:after {
  content: "";
  background-color: #d21e1e;
  width: 100%;
  height: 4px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.promo-leagues__nav {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}

.promo-leagues__nav-item {
  border-left: 1px solid #dcdcdc;
  align-items: center;
  padding: 10px 20px 0;
  display: flex;
}

.promo-leagues__nav-link {
  text-transform: uppercase;
  font-family: roboto, arial, sans-serif;
  font-size: 25px;
  font-weight: 700;
}

@media (max-width: 768px) {
  .promo-leagues__nav-link {
    font-size: 16px;
  }
}

.promo-leagues__list {
  padding-bottom: 53px;
}

.promo-leagues__btn {
  cursor: pointer;
  background: none;
  border: none;
}

.promo-leagues__btn:focus {
  outline: none;
}

.promo-leagues__btn--link {
  color: #d21e1e;
  height: 53px;
  font-size: 13px;
  font-weight: bold;
  text-decoration: underline;
}

.promo-leagues__btn--link:hover {
  text-decoration: none;
}

.promo-leagues__footer {
  z-index: 2;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 53px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.promo-leagues .title-section .icon-section {
  margin-top: 0;
  margin-right: 5px;
}

.promo-leagues.results-wrapper--collapsed:before {
  content: "";
  z-index: 1;
  background: #f5f5f5;
  width: 100%;
  height: 53px;
  transition: all 1s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
}

.promo-leagues .results--no-collapsing {
  padding-bottom: 25px;
}

.promo-leagues .results--no-collapsing + .promo-leagues__footer {
  display: none;
}

.promo-leagues--mobile {
  background: #fff;
}

.promo-leagues--mobile .promo-leagues__title {
  font-size: 16px;
}

.promo-leagues--mobile .promo-leagues__nav {
  display: none;
}

.promo-leagues--mobile.results-wrapper--collapsed:before {
  background: #fff;
}

.user-bonuses {
  line-height: 1.2;
}

.user-bonuses *, .user-bonuses :before, .user-bonuses :after {
  box-sizing: border-box;
}

.user-bonuses__group {
  margin-bottom: 30px;
}

.user-bonuses__group:last-child {
  margin-bottom: 0;
}

.user-bonuses__main-title {
  margin: 0 0 15px;
  font-weight: 700;
}

.user-bonuses__type-title {
  margin-bottom: 5px !important;
}

.user-bonuses__list {
  border: 1px solid #dcdcdc;
  margin: 0;
  list-style: none;
  padding: 15px 30px 15px 15px !important;
}

.user-bonuses__item {
  margin-bottom: 35px;
}

.user-bonuses__item:last-child {
  margin-bottom: 0;
}

.user-bonus {
  align-items: flex-start;
  display: flex;
}

.user-bonus__image-wrapper {
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 65px;
  display: flex;
  overflow: hidden;
}

.user-bonus__image {
  object-fit: contain;
  flex-basis: 100%;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
}

.user-bonus__body {
  flex-basis: calc(100% - 75px);
  align-items: flex-start;
  padding-left: 20px;
  display: flex;
}

.user-bonus__info {
  width: 100%;
  padding: 0 30px 0 0;
  line-height: 1.3;
}

.user-bonus__name {
  font-weight: 700;
}

.user-bonus__link {
  color: #d21e1e;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  display: inline;
}

.user-bonus__link:hover {
  text-decoration: none;
}

.user-bonus__code-wrapper {
  border: 1px solid #d21e1e;
  border-radius: 3px;
  min-width: 150px;
  max-width: 150px;
  margin: auto 0 auto auto;
  padding: 7px 5px 6px 35px;
  position: relative;
}

.user-bonus__code-wrapper:hover, .user-bonus__code-wrapper:focus {
  border-color: #791111;
}

.user-bonus__code-wrapper--has-code:before {
  content: "Kód byl zkopírován";
  z-index: 1;
  color: #fff;
  border-radius: inherit;
  opacity: 0;
  pointer-events: none;
  background: #6cc424;
  justify-content: center;
  align-items: center;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  transition: all .5s ease-in-out;
  display: flex;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
}

.user-bonus__code-wrapper--copied {
  border-color: #6cc424;
  transition: border-color .5s ease-in-out;
}

.user-bonus__code-wrapper--copied:before {
  opacity: 1;
}

.user-bonus__codeurl-wrapper {
  min-width: 150px;
  max-width: 150px;
  height: 30px;
  margin: auto 0 auto auto;
  position: relative;
}

.user-bonus__codeurl-wrapper .user-bonus__codeurl {
  text-align: center;
  border: 1px solid #d21e1e;
  border-radius: 3px;
  min-width: 150px;
  max-width: 150px;
  margin: auto 0 auto auto;
  padding: 7px 5px 6px;
  display: block;
  overflow: hidden;
}

.user-bonus__get-code-button {
  z-index: 1;
  text-align: left;
  color: #fff;
  border-radius: inherit;
  cursor: pointer;
  background-color: rgba(255, 255, 255, .8);
  border: none;
  width: 100%;
  height: 100%;
  padding-left: 15px;
  transition: all .2s ease-in-out;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.user-bonus__get-code-button:hover:before, .user-bonus__get-code-button:focus:before {
  background-color: #791111;
  width: 80px;
}

.user-bonus__get-code-button:hover:after, .user-bonus__get-code-button:focus:after {
  border-bottom-color: #791111;
  border-right-width: 35px;
  left: 80px;
}

.user-bonus__get-code-button:before, .user-bonus__get-code-button:after {
  content: "";
  transition: all .2s ease-in-out;
  position: absolute;
  top: 0;
}

.user-bonus__get-code-button:before {
  background-color: #d21e1e;
  width: 86px;
  height: 100%;
  left: 0;
}

.user-bonus__get-code-button:after {
  border-top: none;
  border-bottom: 28px solid #d21e1e;
  border-left: none;
  border-right: 30px solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  left: 86px;
}

.user-bonus__code-wrapper--has-code .user-bonus__get-code-button {
  display: none;
}

.user-bonus__get-code-button-text {
  z-index: 2;
  position: relative;
}

.user-bonus__cut-code-button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  background-position: 50%;
  border: none;
  width: 20px;
  height: 20px;
  padding: 0;
  display: block;
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.user-bonus__code {
  text-align: center;
  pointer-events: none;
  border: none;
  max-width: 100%;
  padding: 0;
}

.user-bonus__code-wrapper--has-code .user-bonus__code {
  pointer-events: auto;
}

.user-bonus__address {
  text-align: right;
  margin-left: auto;
  line-height: 20px;
}

.user-bonus__address-title {
  font-weight: 700;
  line-height: 15px;
  margin-bottom: 3px !important;
}

.user-bonus__code-valid-to-wrapper {
  z-index: 1;
  text-align: center;
  color: #b0b0b0;
  width: 100%;
  padding: 4px 0 0;
  font-size: 10px;
  line-height: 1.1;
  position: absolute;
  top: 100%;
  left: 0;
}

.user-bonus__no-code-error {
  text-align: center;
  color: #d21e1e;
  align-self: center;
  font-weight: 700;
}

.user-bonus__no-code-error--hidden {
  display: none;
}

.user-bonus-popup-overlay {
  z-index: 1252;
  background-color: rgba(0, 0, 0, .2);
  align-items: flex-start;
  max-height: 100vh;
  padding: 50px 0;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.user-bonus-popup-overlay--hidden {
  display: none;
}

.user-bonus-popup {
  z-index: 1253;
  background-color: #fff;
  border-radius: 3px;
  width: 470px;
  margin: auto;
  padding: 30px 30px 30px 40px;
  font-size: 13px;
  line-height: 1.3;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

.user-bonus-popup__close-button {
  cursor: pointer;
  background: #fff url("https://img2.cncenter.cz/images/default/user-bonus/close-btn.svg") center / 16px no-repeat;
  border: none;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  padding: 0;
  display: block;
  position: absolute;
  top: -7px;
  right: -8px;
}

.user-bonus-popup__header {
  align-items: center;
  margin-bottom: 25px;
  display: flex;
}

.user-bonus-popup__image-wrapper {
  width: 160px;
  height: 80px;
}

.user-bonus-popup__info {
  flex-basis: calc(100% - 160px);
  padding-left: 30px;
}

.user-bonus-popup__code-section {
  text-align: center;
  margin-bottom: 30px;
}

.user-bonus-popup__code-section--hidden {
  display: none;
}

.user-bonus-popup__code-wrapper {
  width: 220px;
  max-width: none;
  margin: 4px auto;
}

.user-bonus-popup__cut-code-button {
  background-size: contain;
  left: 13px;
}

.user-bonus-popup__code {
  text-align: center;
  max-width: 100%;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.user-bonus-popup__code-valid-to-wrapper {
  text-align: center;
  display: block;
  position: static;
}

.user-bonus-popup__no-code-error {
  margin-bottom: 30px !important;
}

.user-bonus-popup__requirements {
  overflow: auto;
}

.user-bonus-popup__requirements p {
  margin-bottom: 1em !important;
}

.user-bonuses {
  border-top: 1px solid #dcdcdc;
  padding: 20px 0 0;
  font-size: 13px;
  line-height: 1.2;
}

.user-bonuses p {
  font-size: inherit;
  margin: 0;
}

.user-bonuses__main-title {
  margin-bottom: 15px !important;
}

.user-bonus__code {
  font-size: 13px;
}

.user-bonus__code:focus {
  outline: none;
}

.user-bonus__code-valid-to-wrapper {
  font-size: 10px !important;
}

.user-bonus__info p {
  line-height: inherit;
}

.user-bonus-popup-overlay {
  padding: 103px 0 0;
}

.user-bonus-popup__code {
  font-size: 20px;
}

.isport-fantasy-banner {
  display: flex;
  position: relative;
}

.isport-fantasy-banner:hover .isport-fantasy-banner__link, .isport-fantasy-banner:hover .isport-fantasy-banner__link-mobile {
  text-decoration-line: underline;
}

.isport-fantasy-banner--desktop {
  background: url("//img2.cncenter.cz/images/isportcz/fantasy/bg-690x190px.jpg") 0 0 no-repeat;
  width: 690px;
  height: 190px;
  margin-bottom: 30px;
  padding: 32px;
}

.isport-fantasy-banner--mobile {
  background: #0f1120 url("//img2.cncenter.cz/images/isportcz/fantasy/bg-strip-mobile.jpg") top no-repeat;
  width: 100%;
  height: 80px;
  margin-bottom: 20px;
  padding: 8px 16px;
}

.isport-fantasy-banner__logo {
  width: 226.402px;
  height: 64px;
}

.isport-fantasy-banner__logo-mobile {
  width: 143.385px;
  height: 40px;
}

.isport-fantasy-banner__link {
  letter-spacing: .18px;
  color: #fff;
  background: #ec0e0e;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 24px;
  font-family: roboto, arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  display: flex;
  position: absolute;
  bottom: 32px;
  right: 32px;
}

.isport-fantasy-banner__link:hover {
  color: #fff;
  text-decoration-line: none;
}

.isport-fantasy-banner__link-mobile-wrapper {
  position: absolute;
  top: 16px;
  right: 16px;
}

.isport-fantasy-banner__link-mobile {
  color: #fff;
  font-family: roboto, arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-decoration-line: underline;
}

.isport-fantasy-banner__link-mobile:hover {
  color: #fff;
  text-decoration-line: none;
}

.isport-fantasy-banner__title {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #30e890 .01%, #4ddfe6 75.87%);
  -webkit-background-clip: text;
  background-clip: text;
  font-family: roboto, arial, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  bottom: 32px;
  left: 32px;
}

.isport-fantasy-banner__title-mobile {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #30e890 .01%, #4ddfe6 75.87%);
  -webkit-background-clip: text;
  background-clip: text;
  font-family: roboto, arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  position: absolute;
  bottom: 8px;
  right: 16px;
}

.artobj-link-fantasy {
  background: #000;
  flex-flow: column;
  align-items: center;
  gap: 16px;
  padding: 16px;
  display: flex;
}

@media (min-width: 768px) {
  .artobj-link-fantasy {
    flex-flow: row;
    justify-content: space-between;
    gap: 0;
    width: 690px;
    height: 82px;
    padding: 32px 16px;
  }
}

.artobj-link-fantasy__title-container {
  align-items: center;
  gap: 8px;
  display: flex;
}

.artobj-link-fantasy__logo-chance {
  flex-shrink: 0;
  width: 90.074px;
  height: 16px;
}

.artobj-link-fantasy__title {
  color: #fff;
  letter-spacing: 0;
  font-family: Roboto, serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.artobj-link-fantasy__button {
  color: #0f1120;
  letter-spacing: 0;
  background: linear-gradient(90deg, #30e890 0%, #4ddfe6 100%);
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 140px;
  height: 40px;
  padding: 8px 16px;
  font-family: Sofia Sans, Roboto, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
}

.artobj-link-fantasy__button:hover {
  background: #7ef7bd;
  text-decoration: none;
}

.artobj-link-fantasy:hover {
  text-decoration: none;
}

.artobj-link-fantasy:hover .artobj-link-fantasy__button {
  background: #7ef7bd;
  text-decoration: none;
}

.video-responsive-embed {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.video-responsive-embed__item {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@font-face {
  font-family: Sofia Sans;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("https://img2.cncenter.cz/dist/assets/default/fonts/vafle_classic.woff2") format("woff2"), url("https://img2.cncenter.cz/dist/assets/default/fonts/vafle_classic.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("https://img2.cncenter.cz/dist/assets/default/fonts/roboto.woff2") format("woff2"), url("https://img2.cncenter.cz/dist/assets/default/fonts/roboto.woff2") format("woff");
}

@font-face {
  font-family: Lato;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("https://img2.cncenter.cz/dist/assets/default/fonts/lato.woff2") format("woff2"), url("https://img2.cncenter.cz/dist/assets/default/fonts/lato.woff2") format("woff");
}

#piano-zamek-za-registraci-custom {
  position: relative;
}

#piano-zamek-za-registraci-custom:empty {
  display: none;
}

#piano-zamek-za-registraci-custom:before {
  content: "";
  background: linear-gradient(rgba(240, 240, 240, .5) 0%, #f0f0f0 100%);
  width: 100%;
  height: 200px;
  display: block;
  position: absolute;
  top: -200px;
}

.piano-registration {
  background: #fff;
  border: 1px solid #f6f6f6;
  flex-direction: column;
  align-items: center;
  width: 590px;
  display: flex;
  position: relative;
  box-shadow: 0 4px 6px -2px rgba(25, 25, 25, .04), 0 12px 16px -4px rgba(25, 25, 25, .08);
}

.piano-registration__content {
  color: #191919;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  display: flex;
}

.piano-registration__text {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 32px 24px 0;
  display: flex;
}

.piano-registration__locked {
  background: #ffdde3;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  display: flex;
}

.piano-registration__titles-wrapper {
  flex-direction: column;
  align-items: center;
  gap: 32px;
  display: flex;
}

.piano-registration__titles {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.piano-registration__titles-text {
  letter-spacing: 0;
  text-align: center;
  font-family: Sofia Sans, Arial, sans-serif;
  font-size: 32px;
  font-weight: 800;
  line-height: 36px;
}

.piano-registration__subtitle {
  letter-spacing: 0;
  text-align: center;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.piano-registration__benefits {
  align-items: left;
  color: #191919;
  letter-spacing: 0;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  width: 80%;
  margin: 0;
  padding: 0;
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  display: flex;
}

.piano-registration__benefits li {
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.piano-registration__benefits li svg {
  flex-shrink: 0;
}

.piano-registration__benefits li strong {
  letter-spacing: 0;
  text-align: left;
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
}

.piano-registration__button {
  color: #fff;
  letter-spacing: .01em;
  text-align: left;
  background: #ec0e0e;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 296px;
  height: 52px;
  padding: 12px 24px;
  font-family: Sofia Sans, Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-decoration: none;
  display: flex;
}

.piano-registration__button:hover {
  color: #fff;
  background: #bd0b0b;
  text-decoration: none;
}

.piano-registration__login {
  background: #f6f6f6;
  border-top: 1px solid #e7e7e7;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  width: 590px;
  padding: 32px 24px 40px;
  display: flex;
}

.piano-registration__login-link {
  color: #191919;
  letter-spacing: 0;
  text-align: left;
  align-items: flex-start;
  gap: 8px;
  font-family: Roboto, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  display: flex;
}

.piano-registration__socials {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: flex;
}

.piano-registration__social {
  color: #191919;
  letter-spacing: .02em;
  text-align: left;
  background: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 296px;
  padding: 10px 16px;
  font-family: Lato, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
  display: flex;
}

.piano-registration__social:hover, .piano-registration__email, .piano-registration__email:hover {
  color: #191919;
  text-decoration: none;
}

.piano-registration__email:hover .underline {
  text-decoration: none;
}

.piano-registration__email .underline {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .piano-registration {
    width: 100%;
  }

  .piano-registration__content {
    gap: 24px;
  }

  .piano-registration__text {
    padding: 24px 16px 0;
  }

  .piano-registration__titles-text {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
  }

  .piano-registration__benefits li {
    align-items: flex-start;
    font-size: 16px;
    font-weight: 400;
  }

  .piano-registration__benefits li strong {
    letter-spacing: 0;
    text-align: left;
    font-family: Roboto, Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
  }

  .piano-registration__benefits li svg {
    margin-top: 4px;
  }

  .piano-registration__button {
    width: 100%;
  }

  .piano-registration__login {
    width: 100%;
    padding: 24px 16px 32px;
  }

  .piano-registration__socials {
    align-items: center;
  }

  .piano-registration__social {
    width: 100%;
  }
}

.picker, .picker__holder {
  width: 100%;
  position: absolute;
}

.picker {
  text-align: left;
  color: #000;
  z-index: 10000;
  -webkit-user-select: none;
  user-select: none;
  font-size: 16px;
  line-height: 1.2;
}

.picker__input {
  cursor: default;
}

.picker__input.picker__input--active {
  border-color: #0089ec;
}

.picker__holder {
  box-sizing: border-box;
  filter: alpha(opacity= 0);
  opacity: 0;
  background: #fff;
  border: 0 solid #aaa;
  border-width: 0 1px;
  border-radius: 0 0 5px 5px;
  min-width: 176px;
  max-width: 466px;
  max-height: 0;
  transition: transform .15s ease-out, opacity .15s ease-out, max-height 0s .15s, border-width 0s .15s;
  overflow-y: auto;
  transform: translateY(-1em)perspective(600px)rotateX(10deg);
}

.picker__frame {
  padding: 1px;
}

.picker__wrap {
  margin: -1px;
}

.picker--opened .picker__holder {
  opacity: 1;
  border-top-width: 1px;
  border-bottom-width: 1px;
  max-height: 25em;
  transition: transform .15s ease-out, opacity .15s ease-out, max-height, border-width;
  transform: translateY(0)perspective(600px)rotateX(0);
  box-shadow: 0 6px 18px 1px rgba(0, 0, 0, .12);
}

.picker__footer, .picker__header, .picker__table {
  text-align: center;
}

.picker__day--highlighted, .picker__select--month:focus, .picker__select--year:focus {
  border-color: #0089ec;
}

.picker__box {
  padding: 0 1em;
}

.picker__header {
  margin-top: .75em;
  position: relative;
}

.picker__month, .picker__year {
  margin-left: .25em;
  margin-right: .25em;
  font-weight: 500;
  display: inline-block;
}

.picker__year {
  color: #999;
  font-size: .8em;
  font-style: italic;
}

.picker__select--month, .picker__select--year {
  border: 1px solid #b7b7b7;
  height: 2em;
  margin-left: .25em;
  margin-right: .25em;
  padding: .5em;
}

.picker__select--month {
  width: 35%;
}

.picker__select--year {
  width: 22.5%;
}

.picker__nav--next, .picker__nav--prev {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .5em 1.25em;
  position: absolute;
  top: -.25em;
}

.picker__nav--prev {
  padding-right: 1.25em;
  left: -1em;
}

.picker__nav--next {
  padding-left: 1.25em;
  right: -1em;
}

@media (min-width: 24.5em) {
  .picker__select--month, .picker__select--year {
    margin-top: -.5em;
  }

  .picker__nav--next, .picker__nav--prev {
    top: -.33em;
  }

  .picker__nav--prev {
    padding-right: 1.5em;
  }

  .picker__nav--next {
    padding-left: 1.5em;
  }
}

.picker__nav--next:before, .picker__nav--prev:before {
  content: " ";
  border-top: .5em solid rgba(0, 0, 0, 0);
  border-bottom: .5em solid rgba(0, 0, 0, 0);
  border-right: .75em solid #000;
  width: 0;
  height: 0;
  margin: 0 auto;
  display: block;
}

.picker__nav--next:before {
  border-left: .75em solid #000;
  border-right: 0;
}

.picker__nav--next:hover, .picker__nav--prev:hover {
  cursor: pointer;
  color: #000;
  background: #b1dcfb;
}

.picker__nav--disabled, .picker__nav--disabled:before, .picker__nav--disabled:before:hover, .picker__nav--disabled:hover {
  cursor: default;
  background: none;
  border-left-color: #f5f5f5;
  border-right-color: #f5f5f5;
}

.picker--focused .picker__day--highlighted, .picker__day--highlighted:hover, .picker__day--infocus:hover, .picker__day--outfocus:hover {
  color: #000;
  cursor: pointer;
  background: #b1dcfb;
}

.picker__table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  font-size: inherit;
  width: 100%;
  margin-top: .75em;
  margin-bottom: .5em;
}

@media (min-height: 33.875em) {
  .picker__table {
    margin-bottom: .75em;
  }
}

.picker__table td {
  margin: 0;
  padding: 0;
}

.picker__weekday {
  color: #999;
  width: 14.2857%;
  padding-bottom: .25em;
  font-size: .75em;
  font-weight: 500;
}

@media (min-height: 33.875em) {
  .picker__weekday {
    padding-bottom: .5em;
  }
}

.picker__day {
  border: 1px solid rgba(0, 0, 0, 0);
  padding: .3125em 0;
  font-weight: 200;
}

.picker__day--today {
  position: relative;
}

.picker__day--today:before {
  content: " ";
  border-top: .5em solid #0059bc;
  border-left: .5em solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  position: absolute;
  top: 2px;
  right: 2px;
}

.picker__day--disabled:before {
  border-top-color: #aaa;
}

.picker__day--outfocus {
  color: #ddd;
}

.picker--focused .picker__day--selected, .picker__day--selected, .picker__day--selected:hover {
  color: #fff;
  background: #0089ec;
}

.picker--focused .picker__day--disabled, .picker__day--disabled, .picker__day--disabled:hover {
  color: #ddd;
  cursor: default;
  background: #f5f5f5;
  border-color: #f5f5f5;
}

.picker__day--highlighted.picker__day--disabled, .picker__day--highlighted.picker__day--disabled:hover {
  background: #bbb;
}

.picker__button--clear, .picker__button--close, .picker__button--today {
  vertical-align: bottom;
  background: #fff;
  border: 1px solid #fff;
  width: 33%;
  padding: .66em 0;
  font-size: .8em;
  font-weight: 700;
  display: inline-block;
}

.picker__button--clear:hover, .picker__button--close:hover, .picker__button--today:hover {
  cursor: pointer;
  color: #000;
  background: #b1dcfb;
  border-bottom-color: #b1dcfb;
}

.picker__button--clear:focus, .picker__button--close:focus, .picker__button--today:focus {
  background: #b1dcfb;
  border-color: #0089ec;
  outline: 0;
}

.picker__button--clear:before, .picker__button--close:before, .picker__button--today:before {
  height: 0;
  display: inline-block;
  position: relative;
}

.picker__button--clear:before, .picker__button--today:before {
  content: " ";
  margin-right: .45em;
}

.picker__button--today:before {
  border-top: .66em solid #0059bc;
  border-left: .66em solid rgba(0, 0, 0, 0);
  width: 0;
  top: -.05em;
}

.picker__button--clear:before {
  border-top: 3px solid #e20;
  width: .66em;
  top: -.25em;
}

.picker__button--close:before {
  content: "×";
  vertical-align: top;
  color: #777;
  margin-right: .35em;
  font-size: 1.1em;
  top: -.1em;
}

.picker__button--today[disabled], .picker__button--today[disabled]:hover {
  color: #ddd;
  cursor: default;
  background: #f5f5f5;
  border-color: #f5f5f5;
}

.picker__button--today[disabled]:before {
  border-top-color: #aaa;
}

.tingle-modal--visible .tingle-modal-box__content .forum__report-modal {
  display: block;
}

@media (max-width: 540px) {
  .tingle-modal-box {
    border-radius: 0;
    width: auto;
  }

  .tingle-modal-box__content {
    overflow-y: scroll;
  }

  .tingle-modal--noClose {
    top: 0;
  }

  .tingle-modal--noOverlayClose {
    padding-top: 0;
  }

  .tingle-modal-box__footer .tingle-btn {
    float: none;
    width: 100%;
    margin-bottom: 1rem;
    display: block;
  }

  .tingle-modal__close {
    box-shadow: none;
    color: #fff;
    background-color: #2c3e50;
    border: none;
    width: 100%;
    height: 60px;
    display: block;
    top: 0;
    left: 0;
    right: 0;
  }

  .tingle-modal__closeLabel {
    vertical-align: middle;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 1.6rem;
    display: inline-block;
  }

  .tingle-modal__closeIcon {
    vertical-align: middle;
    width: 1.6rem;
    margin-right: .8rem;
    font-size: 0;
    display: inline-block;
  }
}

@supports ((-webkit-backdrop-filter: blur(12px)) or (backdrop-filter: blur(12px))) {
  .tingle-modal:before {
    content: "";
    -webkit-backdrop-filter: blur(18px);
    backdrop-filter: blur(18px);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .tingle-enabled .tingle-content-wrapper {
    filter: none;
  }
}

@keyframes scale {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.onlinetalk {
  position: relative;
}

.question-answer {
  border-bottom: 1px solid #aaa;
  margin-bottom: 20px;
  padding: 5px;
  font-size: 16px;
  line-height: 1.7;
  transition: all .2s;
}

.question-answer .text-container {
  margin-bottom: 20px;
  padding: 5px;
  font-size: 16px;
  line-height: 1.7;
  transition: all .2s;
}

.question-answer .text-container .text-container {
  margin-bottom: 20px;
}

.question-answer .text-container .text-container:before, .question-answer .text-container .text-container:after {
  content: "";
  display: table;
}

.question-answer .text-container .text-container:after {
  clear: both;
}

.question-answer .text-container .left {
  float: left;
  width: 15%;
}

.question-answer .text-container .left img {
  border: 1px solid #aaa;
  border-radius: 50%;
  max-width: 80%;
  height: auto;
  display: block;
  position: relative;
  top: 2px;
}

.question-answer .text-container .left .image-wrapper {
  border: 1px solid #aaa;
  border-radius: 50%;
  width: 40%;
  display: block;
}

.question-answer .text-container .right {
  float: right;
  width: 85%;
}

.question-answer .text-container .questioner {
  color: #000;
  margin: 0;
  font-size: 11px;
}

.question-answer .text-container.new {
  background: #aaa;
  border-radius: 5px;
}

.question-answer .chat-header {
  border-top: 3px solid #1e1e1e;
  padding-top: 5px;
}

.question-answer .chat-header .onlinetalk-title {
  color: #1e1e1e;
}

.question-answer .chat-header .onlinetalk-title.title-with-status {
  float: left;
  width: 85%;
}

.question-answer .chat-status {
  float: right;
  text-transform: uppercase;
  text-align: center;
  width: 15%;
  font-size: 13px;
  font-weight: bold;
}

.question-answer .chat-status .dataimg-online, .question-answer .chat-status .dataimg-online-live {
  width: 25px;
  margin: 0 auto;
}

.question-answer .chat-status .dataimg-online g, .question-answer .chat-status .dataimg-online-live g {
  fill: #1e1e1e;
}

.chat-refresh {
  color: #6464fb;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  display: block;
}

.question-answer {
  border-bottom: 1px solid #828282;
  margin-bottom: 20px;
  padding: 5px;
  font-size: 16px;
  transition: all .2s;
}

.question-answer .text-container {
  margin-bottom: 20px;
}

.question-answer .text-container:before, .question-answer .text-container:after {
  content: "";
  display: table;
}

.question-answer .text-container:after {
  clear: both;
}

.question-answer .left {
  float: left;
  width: 15%;
}

.question-answer .left img {
  border: 1px solid #646464;
  border-radius: 50%;
  width: 100%;
  height: auto;
  display: block;
}

.question-answer .left .image-wrapper {
  border: 1px solid #646464;
  border-radius: 50%;
  width: 40%;
  height: 40px;
  display: block;
}

.question-answer .left .image-wrapper .dataimg-logo-isport {
  width: 35px;
  height: 12px;
  position: relative;
  top: 14px;
  left: 2px;
}

.question-answer .right {
  float: right;
  width: 85%;
}

.question-answer .right img {
  border: 1px solid #646464;
  border-radius: 50%;
  width: 40%;
  height: auto;
  display: block;
}

.question-answer .questioner {
  color: #464646;
  margin: 0;
  font-size: 13px;
}

.question-answer.new {
  background: #aaa;
  border-radius: 5px;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height .5s ease-in-out;
}

.owl-carousel {
  tap-highlight-color: transparent;
  z-index: 1;
  width: 100%;
  display: none;
  position: relative;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
}

.owl-carousel .owl-stage:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
  display: block;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-controls .owl-nav .owl-prev, .owl-carousel .owl-controls .owl-nav .owl-next, .owl-carousel .owl-controls .owl-dot {
  cursor: hand;
  -webkit-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  float: left;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  min-height: 1px;
  position: relative;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s;
}

.owl-carousel .owl-item img {
  transform-style: preserve-3d;
  width: 100%;
  display: block;
}

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  user-select: auto;
}

.owl-carousel .owl-grab {
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .owl-video-wrapper {
  background: #000;
  height: 100%;
  position: relative;
}

.owl-carousel .owl-video-play-icon {
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: url("owl.video.play.png") no-repeat;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  transition: scale .1s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.owl-carousel .owl-video-play-icon:hover {
  transition: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn, .owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  transition: opacity .4s;
}

.owl-carousel .owl-video-frame {
  z-index: 1;
  position: relative;
}

.no-js .owl-carousel {
  display: block;
}

.gallery-main-container, .gallery-vertical-container {
  background-color: #1e1e1e;
  width: 100%;
  height: 100%;
  position: relative;
}

.gallery-main-container:before, .gallery-main-container:after, .gallery-vertical-container:before, .gallery-vertical-container:after {
  content: "";
  display: table;
}

.gallery-main-container:after, .gallery-vertical-container:after {
  clear: both;
}

.gallery-main-container .gallery, .gallery-vertical-container .gallery {
  width: 100%;
  height: 91%;
}

.gallery-main-container .gallery .icon-loading, .gallery-vertical-container .gallery .icon-loading {
  width: 35px;
  height: 35px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .images-container, .gallery-vertical-container .gallery .images-container {
  width: 100%;
  height: 100%;
}

.gallery-main-container .gallery .images-container .owl-item, .gallery-vertical-container .gallery .images-container .owl-item {
  height: 100%;
}

.gallery-main-container .gallery .images-container .owl-item img, .gallery-vertical-container .gallery .images-container .owl-item img {
  width: auto;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .images-container .owl-item.advertisement:after, .gallery-vertical-container .gallery .images-container .owl-item.advertisement:after {
  content: "REKLAMA";
  color: #000;
  text-align: center;
  opacity: 0;
  z-index: -1;
  background: rgba(255, 255, 255, .3);
  width: 130px;
  height: 37px;
  padding: 0;
  font-size: 28px;
  font-weight: 600;
  transition: opacity .2s;
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 65px);
}

.gallery-main-container .gallery .images-container .owl-item.active > .advertisement:after, .gallery-vertical-container .gallery .images-container .owl-item.active > .advertisement:after {
  opacity: 1;
}

.gallery-main-container .gallery .images-container .owl-stage-outer, .gallery-vertical-container .gallery .images-container .owl-stage-outer, .gallery-main-container .gallery .images-container .owl-stage-outer .owl-stage, .gallery-vertical-container .gallery .images-container .owl-stage-outer .owl-stage, .gallery-main-container .gallery .images-container .owl-stage-outer .owl-stage .owl-item, .gallery-vertical-container .gallery .images-container .owl-stage-outer .owl-stage .owl-item {
  height: 100%;
}

.gallery-main-container .gallery .btn, .gallery-vertical-container .gallery .btn {
  z-index: 10;
  cursor: pointer;
  background: rgba(30, 30, 30, .6);
  width: 9%;
  height: 91%;
  display: block;
  position: absolute;
}

.gallery-main-container .gallery .btn .icon-arrow, .gallery-vertical-container .gallery .btn .icon-arrow {
  width: 13px;
  height: 26px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .next, .gallery-vertical-container .gallery .next {
  right: 0;
}

.gallery-main-container .gallery .next .image-count, .gallery-vertical-container .gallery .next .image-count {
  text-align: center;
  color: #fff;
  margin: auto;
  font-size: 26px;
  font-weight: 300;
  position: absolute;
  bottom: 5px;
  left: 4%;
  right: 4%;
}

.gallery-main-container .image-description, .gallery-vertical-container .image-description {
  color: #aaa;
  padding: 20px 0;
}

.gallery-main-container .image-description div, .gallery-vertical-container .image-description div {
  display: inline-block;
}

.gallery-main-container .image-description .description, .gallery-vertical-container .image-description .description {
  padding: 0 5px 0 0;
}

.gallery-main-container .image-description .author, .gallery-vertical-container .image-description .author {
  font-style: italic;
}

.gallery-container.vertical .gallery-vertical-container {
  height: auto;
}

.gallery-container.vertical .gallery-vertical-container img[data-src^="http"], .gallery-container.vertical .gallery-vertical-container img[data-src^="http"] + .image-description {
  display: none;
}

.gallery-container.vertical .gallery-vertical-container img {
  margin: 10px 0 0;
}

.gallery-container.vertical .gallery-vertical-container .load-button {
  letter-spacing: 1px;
  margin: 20px auto;
  padding: 10px 0;
}

.gallery-container.vertical .gallery-vertical-container .image-description {
  padding: 20px 10px;
}

.gallery-container.vertical .gallery-vertical-container .image-description .image-description {
  clear: both;
  width: 100%;
  padding: 15px 0;
}

.gallery-container.vertical .gallery-vertical-container .image-description .author {
  float: left;
  display: block;
}

.gallery-container.vertical .gallery-vertical-container .image-description .photo-count {
  float: right;
  color: #fff;
  font-weight: 700;
}

.gallery-container.vertical .gallery-vertical-container .gallery {
  height: auto;
}

.gallery-container.vertical .gallery-vertical-container .images-container {
  margin-top: 35px;
}

.owl-nav.disabled {
  display: none;
}

.gallery-with-video .gallery-item .video-item {
  width: 81%;
  margin: auto;
}

.enquiry-container .question {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 700;
  line-height: 120%;
}

.enquiry-container .form-field input {
  float: left;
  margin-top: 2px;
}

.enquiry-container .form-field label {
  padding: 7px 0 15px 20px;
  font-size: 13px;
  display: block;
}

.enquiry-container .form-field label:hover {
  color: #d21e1e;
  transition: all .2s;
}

.enquiry-container .form-field .button-main {
  text-align: center;
  margin: auto;
  display: block;
}

.enquiry-container .form-field .thank {
  text-align: center;
  color: #aaa;
  margin: 15px auto 0;
  font-size: 16px;
  font-weight: 300;
  display: none;
}

.enquiry-container .form-field .graph {
  clear: both;
  display: none;
}

.enquiry-container .form-field .graph .graph-value {
  float: left;
  background-color: #d21e1e;
  border-radius: 2px;
  height: 12px;
  margin-top: 3px;
  display: block;
  position: relative;
}

.enquiry-container .form-field .graph .value-perc {
  color: #d21e1e;
  padding: 0 8px;
  font-weight: 700;
  line-height: 100%;
}

.enquiry-container.voted .form-field .button-main {
  display: none;
}

.enquiry-container.voted .form-field input[type="radio"] {
  display: none;
}

.enquiry-container.voted .form-field label:before, .enquiry-container.voted .form-field label:after {
  content: none;
}

.enquiry-container.voted .form-field label {
  padding-bottom: 0;
  padding-left: 0;
}

.enquiry-container.voted .form-field label:hover {
  color: #1e1e1e;
}

.enquiry-container.voted .form-field .your-vote {
  color: #d21e1e;
}

.enquiry-container.voted .form-field .your-vote:hover {
  color: #d21e1e;
  transition: all .2s;
}

.enquiry-container.voted .form-field .graph, .enquiry-container.voted .form-field .thank {
  display: block;
}

.enquiry-container.darkenquiry .question, .enquiry-container.darkenquiry .form-field {
  color: #fff;
}

.box-container.darkenquiry {
  background-color: #1e1e1e;
}

.cnc-modal {
  z-index: 99999;
  background-color: rgba(0, 0, 0, .4);
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.cnc-modal-body {
  padding: 5px;
}

.cnc-modal-open {
  overflow: hidden;
}

.cnc-modal-content {
  background-color: #fff;
  width: 60%;
  margin: 30px auto;
  padding: 0;
  animation-name: animatetop;
  animation-duration: .4s;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .2), 0 6px 20px rgba(0, 0, 0, .19);
}

.cnc-modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.cnc-modal-close .dataimg-cross {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.cnc-modal-close .dataimg-cross svg {
  height: 24px;
}

.cnc-modal-close .dataimg-cross svg path {
  fill: #16212d;
}

@keyframes animatetop {
  from {
    opacity: 0;
    top: -300px;
  }

  to {
    opacity: 1;
    top: 0;
  }
}

@media (max-width: 768px) {
  .cnc-modal-content {
    width: 96%;
  }
}

.icon-tipsport {
  background: url("/images/isportcz/dist/non-sprite/tipsport-logo.png") no-repeat;
  width: 150px;
  height: 32px;
  display: block;
}

.synottip-bets .table-header .tab-bets.active, .sazka-fantasy .sazka-fantasy-table .table-header .tab-bets.active {
  border: 2px solid #0097f4;
}

.synottip-bets .table-header, .sazka-fantasy .sazka-fantasy-table .table-header {
  border-bottom: 2px solid #0097f4;
}

.loading-placeholder {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.loading-placeholder img {
  opacity: 0;
  width: 100%;
  transition: opacity 1s;
}

.loading-placeholder.item-loaded {
  padding-bottom: 0 !important;
}

.loading-placeholder.item-loaded img {
  opacity: 1;
  height: auto;
}

.loading-placeholder.item-loaded img.img-isportlife-auto, .loading-placeholder.item-loaded img.img-mobile-auto {
  height: auto;
}

.loading-placeholder:not(.item-loaded) {
  background-color: #e8e8e8;
  height: auto;
  position: relative;
}

.loading-placeholder:not(.item-loaded):after {
  content: " ";
  background-color: #e8e8e8;
  background-image: url("https://img2.cncenter.cz/images/default/lazy-load/lazyload_placeholder.gif");
  background-repeat: repeat-y;
  background-size: 100% 1px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-placeholder:not(.item-loaded) img {
  text-indent: -9999px;
  color: rgba(0, 0, 0, 0);
  position: absolute;
}

@keyframes gradient-animation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

#gdpr-form textarea {
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  border: 1px solid #a9a9a9;
  width: 100%;
  padding: 2px 5px;
}

#gdpr-form .gdpr-form__btns-wrapper {
  text-align: left;
}

#gdpr-form .gdpr-form__btns-wrapper .login-button {
  text-align: center;
  vertical-align: unset;
  min-width: 93px;
  margin-right: 10px;
  padding: 0;
  display: inline-block;
}

.tingle-modal * {
  box-sizing: border-box;
}

.tingle-modal {
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  background: rgba(0, 0, 0, .85);
  flex-direction: column;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.tingle-modal--confirm .tingle-modal-box {
  text-align: center;
}

.tingle-modal--noOverlayClose {
  cursor: default;
}

.tingle-modal--noClose .tingle-modal__close {
  display: none;
}

.tingle-modal__close {
  z-index: 1000;
  color: #fff;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  width: 2rem;
  height: 2rem;
  padding: 0;
  position: fixed;
  top: 2rem;
  right: 2rem;
}

.tingle-modal__close svg * {
  fill: currentColor;
}

.tingle-modal__closeLabel {
  display: none;
}

.tingle-modal__close:hover {
  color: #fff;
}

.tingle-modal-box {
  opacity: 1;
  cursor: auto;
  will-change: transform, opacity;
  background: #fff;
  border-radius: 4px;
  flex-shrink: 0;
  width: 60%;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
}

.tingle-modal-box__content {
  padding: 3rem;
}

.tingle-modal-box__footer {
  cursor: auto;
  background-color: #f5f5f5;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  width: auto;
  padding: 1.5rem 2rem;
}

.tingle-modal-box__footer:after {
  clear: both;
  content: "";
  display: table;
}

.tingle-modal-box__footer--sticky {
  z-index: 10001;
  opacity: 1;
  transition: bottom .3s ease-in-out .3s;
  position: fixed;
  bottom: -200px;
}

.tingle-enabled {
  position: fixed;
  left: 0;
  right: 0;
  overflow: hidden;
}

.tingle-modal--visible .tingle-modal-box__footer {
  bottom: 0;
}

.tingle-enabled .tingle-content-wrapper {
  filter: blur(8px);
}

.tingle-modal--visible {
  visibility: visible;
  opacity: 1;
}

.tingle-modal--visible .tingle-modal-box {
  animation: .2s cubic-bezier(.68, -.55, .265, 1.55) forwards scale;
}

.tingle-modal--overflow {
  padding-top: 8vh;
  overflow-y: scroll;
}

.tingle-btn {
  box-shadow: none;
  color: #fff;
  vertical-align: middle;
  font-size: inherit;
  cursor: pointer;
  background-color: gray;
  border: none;
  margin: 0 .5rem;
  padding: 1rem 2rem;
  font-family: inherit;
  line-height: normal;
  text-decoration: none;
  transition: background-color .4s;
  display: inline-block;
}

.tingle-btn--primary {
  background-color: #3498db;
}

.tingle-btn--danger {
  background-color: #e74c3c;
}

.tingle-btn--default {
  background-color: #34495e;
}

.tingle-btn--pull-left {
  float: left;
}

.tingle-btn--pull-right {
  float: right;
}

@media (max-width: 540px) {
  .tingle-modal-box {
    border-radius: 0;
    width: auto;
  }

  .tingle-modal-box__content {
    overflow-y: scroll;
  }

  .tingle-modal--noClose {
    top: 0;
  }

  .tingle-modal--noOverlayClose {
    padding-top: 0;
  }

  .tingle-modal-box__footer .tingle-btn {
    float: none;
    width: 100%;
    margin-bottom: 1rem;
    display: block;
  }

  .tingle-modal__close {
    box-shadow: none;
    color: #fff;
    background-color: #2c3e50;
    border: none;
    width: 100%;
    height: 60px;
    display: block;
    top: 0;
    left: 0;
    right: 0;
  }

  .tingle-modal__closeLabel {
    vertical-align: middle;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 1.6rem;
    display: inline-block;
  }

  .tingle-modal__closeIcon {
    vertical-align: middle;
    width: 1.6rem;
    margin-right: .8rem;
    font-size: 0;
    display: inline-block;
  }
}

@supports ((-webkit-backdrop-filter: blur(12px)) or (backdrop-filter: blur(12px))) {
  .tingle-modal:before {
    content: "";
    -webkit-backdrop-filter: blur(18px);
    backdrop-filter: blur(18px);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .tingle-enabled .tingle-content-wrapper {
    filter: none;
  }
}

.forum__info {
  float: left;
  width: 100%;
  height: auto;
}

.forum__info-block {
  float: left;
  width: 100%;
}

.forum__info-block--title {
  font-size: 13px;
}

.forum__info-block--title a {
  color: #d21e1e;
  margin: 10px 0 0;
  display: block;
}

.forum__info-block--count {
  margin: 40px 0 30px;
}

.forum__info-headline-count {
  font-size: 25px;
}

.forum__headline {
  font-size: 26px;
  line-height: 34px;
}

.forum__base {
  float: left;
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  height: auto;
  margin: 0 0 20px;
  padding: 25px;
}

.forum__user {
  float: left;
  height: 40px;
  margin: 5px 0 0;
  position: relative;
}

.forum__user--no-mar {
  min-width: 155px;
  margin: 0;
}

.forum__user img {
  float: left;
  text-indent: -9999px;
  border-radius: 100%;
  width: 40px;
  height: 100%;
  font-size: 10px;
}

.forum__user p {
  float: left;
  margin: 8px 0 0 15px;
  font-size: 15px;
  font-weight: 700;
}

.forum__user p a {
  color: inherit;
}

.forum__user-comment {
  float: left;
  min-width: 100px;
  margin: 0 0 0 15px;
  position: relative;
}

.forum__user-comment p {
  margin: 0;
  line-height: 18px;
}

.forum__user-comment .author-editor {
  color: #323232;
  font-size: 15px;
  line-height: 18px;
  display: inline;
  position: static;
}

.forum__user-comment-date {
  color: #828282;
  font-size: 12px;
  position: absolute;
  top: 20px;
  left: 0;
}

.forum__form {
  float: left;
  width: calc(100% - 60px);
  height: auto;
  margin: 0 0 0 20px;
}

.forum__form--logged-in {
  width: calc(100% - 55px);
  margin: 0 0 0 55px;
}

.forum__form-wrap {
  position: relative;
}

.forum__message {
  box-sizing: border-box;
  color: #000;
  resize: none;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  width: 100%;
  padding: 16px;
  font-size: 14px;
  display: block;
  overflow: hidden;
}

.forum__message::placeholder {
  color: inherit;
}

.forum__remaining {
  color: #828282;
  font-size: 12px;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.forum__controls-wrap {
  justify-content: space-between;
  align-items: center;
  margin: 15px 0 0;
  display: flex;
}

.forum__submit {
  box-sizing: border-box;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 10px;
  font-size: 13px;
  font-weight: 700;
}

.forum__submit--send {
  color: #68ae8d;
  border: 1px solid #68ae8d;
}

.forum__submit--red {
  color: #d21e1e;
  border: 1px solid #d21e1e;
}

.forum__submit--blue {
  color: #5e85d1;
  border: 1px solid #5e85d1;
}

.forum__button {
  box-sizing: border-box;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 10px;
  font-size: 13px;
  font-weight: 700;
}

.forum__button--grey {
  color: #9c9c9c;
  border: 1px solid #9c9c9c;
}

.forum__notify {
  width: auto;
  height: 15px;
}

.forum__notify input {
  -webkit-appearance: none;
  appearance: none;
  float: left;
  border: 1px solid #828282;
  border-radius: 2px;
  width: 15px;
  height: 100%;
  margin: 0 5px 0 0;
  position: relative;
}

.forum__notify input:before {
  content: "";
  visibility: hidden;
  opacity: 0;
  border-bottom: 2px solid #828282;
  border-right: 2px solid #828282;
  width: 3px;
  height: 7px;
  transition: all .1s ease-in-out;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 4px;
  transform: rotate(45deg);
}

.forum__notify input:checked:before {
  visibility: visible;
  opacity: 1;
}

.forum__notify label {
  color: #828282;
  float: left;
  font-size: 13px;
  position: relative;
}

.forum__notify label:before {
  content: "Upozornit na odpověď emailem";
  white-space: nowrap;
}

.forum__form-report {
  display: none;
}

.forum__reply-reaction {
  background-color: rgba(0, 0, 0, 0);
  padding: 15px 10px;
}

.forum__filter {
  float: left;
  box-sizing: border-box;
  border-bottom: 1px solid silver;
  width: 100%;
  height: auto;
  margin: 0 0 25px;
  padding: 0 0 10px;
}

.forum__filter ul {
  color: #828282;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 700;
  list-style: none;
  display: flex;
}

.forum__filter ul li {
  margin: 0 30px 0 0;
}

.forum__filter ul li a {
  color: inherit;
}

.forum__filter ul li a:hover {
  color: #000;
  text-decoration: none;
}

.forum__filter ul li a.forum__filter-active {
  color: #000;
  box-sizing: border-box;
  border-bottom: 4px solid #000;
  padding: 0 0 6px;
}

.forum__filter ul li span {
  color: #d21e1e;
}

.forum__filter ul li.forum__notify-msgs {
  margin-left: auto;
  margin-right: 0;
}

.forum__admin-actions {
  float: left;
  width: 100%;
  height: auto;
  margin: 0 0 30px;
}

.forum__admin-actions input[type="radio"] {
  display: none;
}

.forum__admin-actions input[type="radio"]:checked + label {
  color: #fff;
  background-color: #68ae8d;
  border: none;
}

.forum__admin-actions label {
  float: left;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border-right: 1px solid #e6e6e6;
  width: 33.3333%;
  height: 100%;
  font-size: 14px;
  font-weight: 700;
  line-height: 50px;
}

.forum__admin-actions label:last-child {
  border-right: none;
}

.forum__status {
  float: left;
  box-sizing: unset;
  background-color: pink;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  width: 510px;
  max-height: 48px;
  overflow: hidden;
}

.forum-entry {
  float: right;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  background-color: #d92123;
  padding: 11px 53px 10px 10px;
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  transition: background-color .3s;
  display: block;
  position: relative;
}

.forum-entry:hover {
  background-color: #be191b;
  text-decoration: underline;
}

.forum-entry.top {
  margin-right: 170px;
}

.forum-entry.mobile-top {
  float: left;
  margin: 10px 0;
}

.forum-entry.mobile-bottom {
  float: left;
  margin: 10px 0 20px 10px;
}

.forum-entry.superlife {
  text-transform: none;
  background-color: #02a555;
  width: 187px;
  padding: 11px 53px 10px 15px;
}

.forum-entry.superlife.desktop.top {
  margin: 0;
  position: absolute;
  bottom: 30px;
  right: 20px;
}

.forum-entry.superlife.desktop.bottom {
  margin: 0 0 10px;
}

.forum-entry.superlife.mobile.top {
  margin: 10px 0 0;
  position: relative;
  bottom: 0;
  right: auto;
}

.forum-entry.superlife.mobile.bottom {
  margin: 10px;
}

.forum-entry__link, .forum-entry__link:hover {
  color: #fff;
}

.forum-entry__bubble {
  width: 30px;
  height: 25px;
  display: inline-block;
  position: absolute;
  top: 7.5px;
  right: 10px;
}

.forum-entry__count {
  text-align: center;
  width: 23px;
  position: absolute;
  top: 10.5px;
  right: 14px;
  overflow: hidden;
}

.forum__comment {
  padding: 25px 25px 30px;
}

.forum__comment--thread.user-comment {
  background: #fefae9;
}

.forum__comment--thread.admin {
  background: #e9f7fe;
}

.forum__comment--reaction {
  margin: -20px 0;
  padding: 10px 25px 10px 80px;
}

.forum__comment--reaction .comment-inner-wrapper {
  padding: 15px 10px;
}

.forum__comment--reaction.user-comment {
  padding: 15px 15px 15px 80px;
}

.forum__comment--reaction.user-comment .comment-inner-wrapper {
  background: #fefae9;
  padding: 15px 10px;
}

.forum__comment--reaction.admin {
  padding: 25px 15px 25px 80px;
}

.forum__comment--reaction.admin .comment-inner-wrapper {
  background: #e9f7fe;
  padding: 15px 10px;
}

.forum__clap {
  float: right;
  color: #1e1e1e;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  min-width: 40px;
  font-size: 13px;
  line-height: 29px;
  display: flex;
}

.forum__clap .dataimg-clap, .forum__clap .dataimg-clap-pressed {
  width: 29px;
  height: 29px;
  margin-left: 3px;
}

.forum__clap:hover {
  color: #1e1e1e;
  text-decoration: none;
}

.forum__clap:hover .dataimg-clap g {
  fill: #68ae8d;
}

.forum__user .dataimg-avatar {
  float: left;
  width: 40px;
  height: 40px;
}

.forum__user .dataimg-author-editor {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 33px;
  left: 12px;
}

.forum__tip {
  float: right;
  color: #1e1e1e;
  margin: 6.5px 30px 6.5px 0;
  font-size: 12px;
  line-height: 16px;
  display: flex;
}

.forum__tip:hover .dataimg-editor-selection svg circle {
  fill: #9c9c9c;
}

.forum__tip.active {
  cursor: pointer;
}

.forum__tip .dataimg-editor-selection, .forum__tip .dataimg-editor-selection-pressed {
  width: 16px;
  height: 16px;
  margin-left: 7px;
}

.forum__comment-article-title {
  float: left;
  box-sizing: border-box;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  width: calc(100% - 55px);
  height: auto;
  margin: 25px 0 0 55px;
  padding: 15px;
  font-size: 14px;
  font-weight: 700;
}

.forum__comment-reason {
  float: left;
  width: 585px;
  height: auto;
  margin: 30px 0 0 55px;
  font-size: 14px;
}

.forum__comment-reason span {
  font-weight: 700;
}

.forum__comment-visible {
  word-break: break-word;
  margin: 18px 0 25px 55px;
  font-size: 14px;
  line-height: 23px;
}

.forum__comment-actions {
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 0 0 55px;
  font-size: 13px;
  display: flex;
}

.forum__comment-actions .forum__comment-submenu-wrapper, .forum__comment-actions .forum__comment-submenu {
  display: none;
}

.forum__comment-actions .forum__reply {
  color: #9c9c9c;
  margin: 0;
  padding: 0;
  font-weight: 700;
  line-height: 15px;
}

.forum__comment-actions .forum__reply .dataimg-reply {
  float: left;
  width: 15px;
  height: 10px;
  margin: 2px 5px 0 0;
}

.forum__comment-actions .forum__share {
  color: #4773cb;
}

.forum__comment-actions .forum__edit, .forum__comment-actions .forum__report, .forum__comment-actions .change__status {
  color: #cb4747;
}

.forum__comment-user-actions {
  float: right;
}

.forum__comment-user-actions .forum__comment-back-btn {
  margin: 0;
}

.forum__comment-action {
  margin: 0 20px 0 0;
  font-size: 13px;
  line-height: 15px;
}

.forum__comment-action.forum__comment-report-btn {
  color: #cb4747;
}

.forum__comment-action.forum__comment-delete-btn {
  color: #d21e1e;
}

.forum__comment-action.forum__comment-back-btn {
  color: #68ae8d;
}

.forum__comment-deleted {
  color: #cb4747;
  float: right;
  flex-direction: row-reverse;
  width: calc(100% - 155px);
  margin: 8.5px 0;
  font-size: 13px;
  line-height: 23px;
  display: flex;
}

.forum__comment-deleted .dataimg-attention {
  width: 23px;
  margin-left: 5px;
}

.toggle__reactions {
  text-align: center;
  float: left;
  cursor: pointer;
  background: #fff;
  border: 1px solid #e1e1e1;
  width: 100%;
  margin: -20px 0;
  padding: 20px 0;
  font-weight: 700;
}

.forum__modal .tingle-modal-box__content {
  padding: 20px;
}

.forum__modal .forum__form-head {
  margin: 0 0 20px;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

.forum__modal .forum__form-report-reason .forum__form-report-label {
  font-size: 13px;
  font-weight: 700;
  line-height: 23px;
  display: block;
}

.forum__modal .forum__form-report-reason .forum__form-report-reason {
  resize: none;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 100%;
  height: 99px;
  margin: 6px 0 15px;
  padding: 15px;
  font-size: 13px;
  line-height: 17px;
}

.forum__modal-delete, .forum__modal-badwords {
  text-align: center;
}

.forum__modal .forum__share-modal .forum__share-url {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 100%;
  margin: 0 0 20px;
  padding: 16px 18px 16px 15px;
  font-size: 14px;
  line-height: 17px;
}

.forum__badwords {
  float: left;
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  height: auto;
  margin: 30px 0 0;
  padding: 25px;
}

.forum__badwords-title {
  margin: 0 0 15px;
  font-size: 15px;
  font-weight: 700;
  display: block;
}

.forum__badwords button[type="button"] {
  margin: 15px 0 0;
}

.forum__badwords-list {
  float: left;
  width: 100%;
  max-height: 0;
  transition: all .3s ease-in-out;
  overflow: hidden;
}

.forum__badwords-list-expanded {
  max-height: 4000px;
  margin: 20px 0 0;
}

.forum__badwords-list span {
  float: left;
  text-transform: uppercase;
  margin: 20px 0 10px;
  font-size: 12px;
  font-weight: 700;
}

.forum__badwords-group {
  float: left;
  width: 100%;
}

.forum__badwords-word {
  float: left;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0 20px 12px 0;
  padding: 0 20px 0 0;
  font-weight: 400;
  position: relative;
}

.forum__badwords-word p {
  float: left;
  font-size: 12px;
  line-height: 14px;
}

.forum__badwords-word span {
  text-transform: unset;
}

.forum__badwords-word .remove-word {
  width: 10px;
  height: 10px;
  display: none;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.forum__badwords-word .remove-word:before, .forum__badwords-word .remove-word:after {
  content: "";
  background-color: #d21e1e;
  width: 2px;
  height: 10px;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
}

.forum__badwords-word .remove-word:before {
  transform: rotate(45deg);
}

.forum__badwords-word .remove-word:after {
  transform: rotate(-45deg);
}

.forum__badwords-word:hover .remove-word {
  display: block;
}

.forum__badwords-value {
  float: left;
  width: 100%;
  height: auto;
}

.forum__badwords-show {
  float: left;
  text-align: center;
  box-sizing: unset;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  width: 100%;
  height: 60px;
  font-size: 15px;
  font-weight: 700;
  line-height: 60px;
  position: relative;
  left: -1px;
}

.forum__user-detail {
  color: #1e1e1e;
  background: #fff;
  margin: 0 0 20px;
  padding: 30px 180px;
  font-size: 13px;
  line-height: 15px;
}

.forum__user-profile-avatar {
  text-align: center;
}

.forum__user-profile-avatar .forum__user-profile-avatar-image {
  border-radius: 50%;
  width: 70px;
  margin: 0 auto;
}

.forum__user-profile-nick {
  text-align: center;
  margin: 7px 0 20px;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

.forum__user-profile-email, .forum__user-profile-account, .forum__user-profile-level, .forum__user-profile-messages, .forum__user-profile-date-last, .forum__user-profile-date-first {
  border-bottom: 1px solid #dcdcdc;
  justify-content: space-between;
  padding: 10px 0;
  display: flex;
}

.forum__user-profile-email .value, .forum__user-profile-account .value, .forum__user-profile-level .value, .forum__user-profile-messages .value, .forum__user-profile-date-last .value, .forum__user-profile-date-first .value {
  font-weight: 700;
}

.forum__user-profile-email .value.green, .forum__user-profile-account .value.green, .forum__user-profile-level .value.green, .forum__user-profile-messages .value.green, .forum__user-profile-date-last .value.green, .forum__user-profile-date-first .value.green {
  color: #68ae8d;
}

.forum__user-profile-email .value.red, .forum__user-profile-account .value.red, .forum__user-profile-level .value.red, .forum__user-profile-messages .value.red, .forum__user-profile-date-last .value.red, .forum__user-profile-date-first .value.red {
  color: #d21e1e;
}

.forum__user-profile-actions {
  margin: 25px 0 0;
}

.forum__user-profile-actions a:last-of-type {
  margin: 0;
}

.forum__user-profile-actions .btn {
  color: #d21e1e;
  margin-bottom: 15px;
  display: block;
}

.forum__user-profile-actions .btn.btn--green {
  color: #68ae8d;
}

.forum__user__history {
  background: #fff;
  padding: 30px 180px;
  font-size: 13px;
  line-height: 15px;
}

.forum__user__history-title {
  margin: 0 0 5px;
  font-weight: 700;
}

.forum__user__history-warning {
  border-bottom: 1px solid #dcdcdc;
  padding: 10px 0;
}

.forum__user__history-warning .warning-type {
  color: #d21e1e;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  display: flex;
}

.forum__user__history-warning .warning-type-image {
  width: 25px;
  margin-right: 5px;
}

.forum__user__history-warning .warning-type, .forum__user__history-warning .warning-time, .forum__user__history-warning .warning-reason {
  margin-bottom: 5px;
}

@media screen and (max-width: 520px) {
  .sc-gLLuof.fRdbir label.leimcV {
    height: 100px;
    position: relative;
  }

  .sc-gLLuof.fRdbir label.leimcV .keVjxN {
    height: 100px;
    position: absolute;
    top: 4px;
  }

  .sc-gLLuof.fRdbir label.leimcV .keVjxN .ciEuJM {
    margin-top: 35px;
  }
}

@media screen and (max-width: 320px) {
  .sc-gLLuof.fRdbir label.leimcV, .sc-gLLuof.fRdbir label.leimcV .keVjxN {
    height: 130px;
  }

  .sc-gLLuof.fRdbir label.leimcV .keVjxN .ciEuJM {
    margin-top: 60px;
  }
}

#login-wrapper .main-container.profile-with-discussions {
  color: #1e1e1e;
  width: 690px;
  font-family: Roboto, arial, sans-serif;
  font-size: 15px;
}

#login-wrapper .main-container.profile-with-discussions a {
  color: #1e1e1e;
}

#login-wrapper .main-container.profile-with-discussions a:hover {
  text-decoration: none;
}

#login-wrapper .main-container.profile-with-discussions .profile-navigation-menu {
  width: 690px;
  position: absolute;
  top: -40px;
  left: 0;
}

#login-wrapper .main-container.profile-with-discussions .profile-navigation-menu a {
  color: #323232;
}

#login-wrapper .main-container.profile-with-discussions .profile-navigation-menuitem.selected a {
  color: #d21e1e;
}

#login-wrapper .main-container.profile-with-discussions .menusection-settings {
  margin-left: 300px;
}

#login-wrapper .main-container.profile-with-discussions .form-row label.form-input-group {
  width: 360px;
}

#login-wrapper .main-container.profile-with-discussions .form-row input.text {
  width: calc(100% - 50px);
}

#login-wrapper .main-container.profile-with-discussions .form-row #avatar {
  padding-top: 8px;
}

#login-wrapper .main-container.profile-with-discussions .forum__comment-user-actions .forum__comment-report-btn {
  color: #cb4747;
}

#login-wrapper .main-container.profile-with-discussions .forum__comment-user-actions .forum__comment-delete-btn {
  color: #d21e1e;
}

#login-wrapper .main-container.profile-with-discussions .forum__comment-user-actions .forum__comment-back-btn {
  color: #68ae8d;
}

#login-wrapper .main-container.profile-with-discussions .forum__comment-user-actions .forum__share {
  color: #4773cb;
}

#login-wrapper .main-container.profile-with-discussions .forum__comment-user-actions .forum__report, #login-wrapper .main-container.profile-with-discussions .forum__comment-user-actions .change__status {
  color: #cb4747;
}

#login-wrapper .main-container.profile-with-discussions .forum__user-comment p {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
}

.profile-navigation-menu {
  justify-content: space-between;
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 15px;
  list-style: none;
  display: flex;
  position: relative;
  overflow-x: auto;
}

.profile-navigation-menu .profile-navigation-menuitem {
  color: #1e1e1e;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  padding: 13px 15px 12px;
}

.profile-navigation-menu .profile-navigation-menuitem.hidden {
  display: none;
}

.profile-navigation-menu .profile-navigation-menuitem.grow {
  text-align: right;
  flex-grow: 2;
}

.profile-navigation-menu .profile-navigation-menuitem.selected {
  color: #d21e1e;
  background: #fff;
  font-weight: 700;
}

.profile-navigation-menu .profile-navigation-menuitem.selected a {
  color: #d21e1e;
}

.sc-gLLuof.fRdbir h2 {
  color: #191919;
  border-bottom: 1px solid #e7e7e7;
  font-family: Vafle Classic, arial, Helvetica, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.sc-gLLuof.fRdbir label {
  gap: 0;
  flex-direction: column !important;
}

.sc-gLLuof.fRdbir label .eycUNu {
  color: #888;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.sc-gLLuof.fRdbir label .keVjxN {
  padding: 4px 12px 0 0;
}

.sc-gLLuof.fRdbir label .keVjxN input {
  background: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.sc-gLLuof.fRdbir label .keVjxN textarea {
  background: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  flex-direction: column;
  height: 100px;
  min-height: 100px;
  padding: 12px;
  display: flex;
}

.sc-gLLuof.fRdbir label.leimcV .keVjxN {
  clear: both;
  padding: 0 12px 0 0;
  position: relative;
  top: -25px;
}

.sc-gLLuof.fRdbir label.leimcV .keVjxN .ciEuJM {
  padding: 5px 0 0 29px;
}

.sc-gLLuof.fRdbir label.leimcV .eycUNu {
  color: #191919;
  z-index: 10;
  padding: 0 0 0 30px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
}

.sc-gLLuof.fRdbir label.leimcV .eycUNu a {
  text-decoration: underline;
}

@media screen and (max-width: 520px) {
  .sc-gLLuof.fRdbir label.leimcV {
    height: 100px;
    position: relative;
  }

  .sc-gLLuof.fRdbir label.leimcV .keVjxN {
    height: 100px;
    position: absolute;
    top: 4px;
  }

  .sc-gLLuof.fRdbir label.leimcV .keVjxN .ciEuJM {
    margin-top: 35px;
  }
}

@media screen and (max-width: 320px) {
  .sc-gLLuof.fRdbir label.leimcV, .sc-gLLuof.fRdbir label.leimcV .keVjxN {
    height: 130px;
  }

  .sc-gLLuof.fRdbir label.leimcV .keVjxN .ciEuJM {
    margin-top: 60px;
  }
}

.g-tech-rec-articles {
  justify-content: space-between;
  display: flex;
}

.g-tech-rec-article {
  flex-flow: column;
  flex: 165px;
  padding-left: 10px;
  display: flex;
}

.g-tech-rec-article:hover .g-tech-rec-article__link-title {
  color: #d21e1e;
}

.g-tech-rec-article__link-img {
  margin-bottom: 10px;
}

.g-tech-rec-article__image {
  width: 165px;
  height: auto;
}

.g-tech-rec-article__title {
  padding: 0 10px;
  font-size: 16px;
  line-height: 20px;
}

.g-tech-rec-article__category-flags {
  display: flex;
}

.g-tech-rec-article__premium-sticker {
  text-transform: uppercase;
  color: #fff;
  background-color: #cca550;
  margin: 0 10px;
  padding: 0 5px;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  text-decoration: none;
}

.g-tech-rec-article__premium-sticker:hover {
  color: #fff;
  text-decoration: none;
}

